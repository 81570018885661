import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

const NoMobileSupport = () => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open
  >
    <DialogTitle id="alert-dialog-title">{'Not supported device detected.'}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Currently there is no support for mobile devices for B2B App.
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default NoMobileSupport;
