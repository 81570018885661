import api from 'services/api';
import MrpPricing from 'services/MrpPricing';
import moment from 'moment';
import { flatten } from 'inc/utils';
import audiences from 'data/audiences.json';

const entityStatus = (state: string) => {
  const map: Data = {
    needs_work: 'rejected',
    in_review: 'in review',
    ready_for_review: 'ready for review',
  };
  return map[state] ? map[state] : state;
}

export const getEntity = async (type: string, id?: string) => {
  return id
    ? flatten(await api.path(type).get(id) || {})
    : {};
};

export const segmentOfferObject = (offer: Data) => {
  return {
    id: offer['id'] ?? null,
    offerName: offer['name'] ?? null,
    offerValue: offer['content.value'],
    militaryAudienceSegment: (offer['content.audience'] ?? []).map((id: string) => audiences.find(item => item.id === id)?.name),
    offerSubtitle: offer['content.subtitle'],
    offerStatus: offer['state'],
    heroTitle: offer['content.banner.title'],
    heroSubtitle: offer['content.banner.description'],
    customUrlPath: offer['content.url_alias'] ?? '',
    merchantCategoryCode: offer['content.mcc_category'],
    logoUploaded: !!offer['content.image.asset'],
    heroUploaded: !!offer['content.banner.image.asset'],
    redemptionChannels: ['online'],
    publicationStatus: entityStatus(offer['state']),
    published: !!offer['published.revision'],
    publishedDate: offer['published.on'] ? moment.unix(offer['published.on'] / 1000).toISOString() : undefined,
  };
};

export const segmentBrandObject = (brand: Data, company: Data) => {
  const brandValue = (field: string, companyFallback = false) =>
    brand[field] || (companyFallback && company[field]) || undefined;
  return {
    id: brandValue('id') ?? null,
    name: brandValue('name') ?? null,
    phone: brandValue('billing_info.phone', true),
    email: brandValue('billing_info.email', true),
    address: {
      line1: brandValue('billing_info.address.line1', true),
      line2: brandValue('billing_info.address.line2', true),
      city: brandValue('billing_info.address.city', true),
      state: brandValue('billing_info.address.state', true),
      country: brandValue('billing_info.address.country', true),
      postalCode: brandValue('billing_info.address.postal_code', true),
    },
    createdBy: brandValue('created.by.name'),
    naicsCode: brandValue('content.naics_category'),
    merchantCategoryCode: brandValue('content.mcc_category'),
    headline: brandValue('content.headline'),
    logoUploaded: !!brandValue('content.logo.asset'),
    heroUploaded: !!brandValue('content.hero.asset'),
    publicationStatus: entityStatus(brand['state']),
    published: !!brand['published.revision'],
    publishedDate: brand['published.on'] ? moment.unix(brand['published.on'] / 1000).toISOString() : undefined,
  };
};

/**
 * Returns only values required for sending object reference.
 */
export const segmentBrandObjectReference = (brand: Data) => {
  return {
    id: brand['id'] ?? null,
    name: brand['name'] ?? null,
  };
};

export const segmentCompanyObject = (company: Data) => {
  return {
    id: company['id'] ?? null,
    name: company['name'] ?? null,
    phone: company['billing_info.phone'],
    email: company['billing_info.email'],
    address: {
      line1: company['billing_info.address.line1'],
      line2: company['billing_info.address.line2'],
      city: company['billing_info.address.city'],
      state: company['billing_info.address.state'],
      country: company['billing_info.address.country'],
      postalCode: company['billing_info.address.postal_code'],
    },
    createdAt: company['created.on'] ? moment.unix(company['created.on'] / 1000).toISOString() : undefined,
    createdBy: company['created.by.name'] ?? undefined,
  };
};

/**
 * Returns only values required for sending object reference.
 */
export const segmentCompanyObjectReference = (company: Data) => {
  return {
    id: company['id'] ?? null,
    name: company['name'] ?? null,
  };
};

export const segmentProgramObject = (program: Data) => {
  const codesCount = parseInt(program['mrp_batch.coupons'], 10) ?? undefined;
  return {
    id: program['id'],
    type: 'mrp',
    internalTitle: program['name'],
    codesCount,
    codesCountInitial: codesCount,
    deliveryMethod: program['method'],
    recipient: { title: program['content.recipients'], type: program['content.recipients'] },
    pricingRateId: program['discount.id'],
    processingFee: MrpPricing.priceoff('fee'),
    total: MrpPricing.sum('priceoff', codesCount),
    travelProtectionAddon: !!program['config.travel_protection'],
  };
};

export const segmentUserObject = (user: Data) => {
  // @TODO: Pass more detailed information about user.
  return {
    id: user['sub'],
    email: user['email'],
    username: user['nickname'],
  };
};

/**
 * Returns segment event context object.
 */
export function segmentContextObject (company: Data, brand?: Data, offer?: Data): { groupId: string } {
  return {
    groupId: [company?.id, brand?.id, offer?.id].filter(v => v).join('.'),
  };
}
