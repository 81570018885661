import { programCreated } from 'services/typewriter';
import { flatten } from 'inc/utils';
import {
  getEntity,
  segmentCompanyObject,
  segmentBrandObject,
  segmentProgramObject, segmentContextObject
} from './helpers';

export default async ({ data: values }: Data) => {
  const program = flatten(values);
  const company = await getEntity('companies', program['company']);
  const brand = await getEntity('brands', program['brand']);
  programCreated({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
    program: segmentProgramObject(program),
  });
};
