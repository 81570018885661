import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import withAccess from 'components/Visibility/withAccess';

type Props = {
  children: React.ReactNode,
  defaultExpanded?: boolean,
  label: string
}

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded:first-child': {
      marginTop: 10,
    },
    '&$expanded': {
      marginTop: 10,
    },
    marginTop: 10,
  },
  expanded: {},
}) (Accordion);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: 8,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
}) (AccordionSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    display: 'block',
    padding: theme.spacing(2),
  },
})) (AccordionDetails);

function CustomExpansionPanel({ label, defaultExpanded = true, children }: Props) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  return (
    <ExpansionPanel
      defaultExpanded={defaultExpanded}
      onChange={(e, expanded) => setExpanded(expanded)}
      square
    >
      <ExpansionPanelSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {expanded ? <ArrowDropDown/> : <ArrowRight /> }<Typography>{label}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default withAccess(CustomExpansionPanel);
