import { useState } from 'react';

const useTabs = (defaultTabIndex: number) => {
  const [tabIndex, setTabIndex] = useState<number>(defaultTabIndex);
  const handleTabChange = (event: React.ChangeEvent<{}> | null, newValue: number) => {
    setTabIndex(newValue);
  };
  return {
    tabIndex,
    setTabIndex,
    handleTabChange,
  };
}

export default useTabs;
