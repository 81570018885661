import React, { useEffect } from 'react';
import { FieldError } from 'react-hook-form/dist/types';
import useField from 'inc/hooks/useField';
import clsx from 'clsx';

type Props = {
  className?: string,
  name: string,
  value: string | boolean,
}

function Hidden({ className, name, value }: Props) {
  const { formRendered, error, setValue } = useField(name, {});
  useEffect(() => { formRendered && setValue(value); }, [formRendered, value]);
  return (
    <>
      {error && (
        <div className={clsx('error-message', className)}>{(error as FieldError).message}</div>
      )}
    </>
  );
}

export default Hidden;
