import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import customStyles from '../../theme/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import {
  EditFormWrapper,
  FieldWrapper,
  Select,
  Switch,
  TextField
} from 'components/Form';
import { PageBlock } from 'components/Layout';
import useFormCrud from 'inc/hooks/useFormCrud';
import { CompanySchema } from 'inc/validation';
import usStates from 'data/usStates.json';

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  formBlock: {
    marginTop: theme.spacing(4)
  }
}));

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Company>;

const CompanyDetails = ({ className, history, match }: Props) => {
  const classes = useStyles();
  const {
    docId,
    handleEditSubmit,
    methods,
    isRevision,
  } = useFormCrud({
    id: match.params['companyId'] || '',
    revisionId: match.params['revisionId'] || '',
    success: () => history.push('/settings/company/:companyId' === match.path ? '/settings/company' : '/companies'),
    path: 'companies',
    schema: CompanySchema
  });
  return (
    <div className={classes.root}>
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(handleEditSubmit())}>
          <EditFormWrapper
            className={className}
            isRevision={isRevision}
            loading={false}
            subheader="WeSalute welcomes your new company. Together, we form a mutually-beneficial coalition that aims to reward the greater military community. We unify our partner coalition under one common objective: to thank the greater military for their service."
            title={!docId ? 'Create Company' : 'Edit Company'}
          >
            <PageBlock>
              <TextField
                helperText={
                  <p>
                    Use the legal company name. This is for internal use only and will never appear to customers.
                  </p>
                }
                label="Company Name"
                name="name"
                required
              />
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={
                  <p>This should be the listed address where your company receives correspondence. This may be the physical address listed on your companies tax documents.</p>
                }
                label="Company Contact Details"
                name="billing_info"
              >
                <div className={classes.formBlock}>
                  <TextField
                    label="Address Line 1"
                    name="billing_info.address.line1"
                    required
                  />
                </div>
                <div className={classes.formBlock}>
                  <TextField
                    label="Apt, Floor, suite, etc. (Optional)"
                    name="billing_info.address.line2"
                  />
                </div>
                <div className={classes.formBlock}>
                  <TextField
                    inputProps={{ maxLength: 5 }}
                    label="Postal Code"
                    name="billing_info.address.postal_code"
                    required
                  />
                </div>
                <div className={classes.formBlock}>
                  <TextField
                    label="City"
                    name="billing_info.address.city"
                    required
                  />
                </div>
                <div className={classes.formBlock}>
                  <Select
                    label="State"
                    name="billing_info.address.state"
                    options={usStates}
                    required
                  />
                </div>
                <div className={classes.formBlock}>
                  <TextField
                    label="Contact Email"
                    name="billing_info.email"
                    required
                  />
                </div>
                <div className={classes.formBlock}>
                  <TextField
                    label="Contact Phone Number"
                    name="billing_info.phone"
                    required
                  />
                </div>
              </FieldWrapper>
            </PageBlock>
            <PageBlock adminView>
              <FieldWrapper
                helperText="List of features that will be enabled/disabled within this company."
                label="Features"
                name="features"
              >
                <div style={{ border: '1px solid #000', padding: 20 }}>
                  <Switch
                    label="Offers"
                    name="features.offer"
                  />
                </div>
              </FieldWrapper>
            </PageBlock>
          </EditFormWrapper>
        </form>
      </FormContext>
    </div>
  );
};

export default withRouter(CompanyDetails);
