import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
import api from './api';
import {
  ACCESS_CREATE,
  ACCESS_UPDATE,
  ACCESS_DELETE,
  ACCESS_READ,
  ACCESS_LIST,
  ACCESS_FETCH,
} from 'inc/constants';
import error from 'services/ErrorHandler';
import event from 'services/EventManager';

/**
 * Auth0 global class.
 */
export class Auth0 {

  /**
   * An instance of the Auth0Client service.
   */
  client: Auth0Client;

  /**
   * A list of auth0 client options.
   */
  private options: Auth0ClientOptions;

  /**
   * A user access object.
   */
  private userAccess: User.Access;

  /**
   * User specific entity IDs.
   */
  private userEntities: { brands?: string[], companies?: string[] };

  /**
   * A list of user roles.
   */
  private userRoles: string[];

  /**
   * A user company.
   */
  private userCompany: Document.Base;

  /**
   * A flag indicates whether current user is logged in.
   */
  private isAuthenticated: boolean;

  /**
   * A user data object.
   */
  private user: User.Data;

  /**
   * Constructor for the Auth0 object.
   */
  constructor(initOptions: Auth0ClientOptions) {
    this.options = initOptions;
    this.userAccess = {};
    this.userEntities = {};
    this.userRoles = [];
  }

  /**
   * Inits auth0 client and authenticate if token code is provided.
   */
  async initClient() {
    let justLoggedIn = false;
    if (!this.client) {
      this.client = new Auth0Client(this.options);
    }
    this.isAuthenticated = await this.client.isAuthenticated();
    const search = window.location.search;
    if (!this.isAuthenticated && (search.includes('code=') || search.includes('state='))) {
      await this.client.handleRedirectCallback();
      this.isAuthenticated = justLoggedIn = await this.client.isAuthenticated();
    }
    if (this.isAuthenticated) {
      this.user = await this.client.getUser();
    }
    this.user && await this.processRealms();
    justLoggedIn && event.dispatch('USER_LOGIN');
    return this.user;
  }

  /**
   * Processes user realms got from the auth0 access claim.
   */
  async processRealms() {
    const userClaims: Data = {};
    Object.keys(this.user || {}).forEach(attr => {
      const found = attr.match(/https:\/\/.*\/([a-zA-Z0-9\-_]+)/i);
      if (found && found[1]) {
        userClaims[found[1]] = this.user[attr];
      }
    });
    const brands: string[] = [];
    const companies: string[] = [];
    const { access, 'auth0-delegated-admin': auth0Roles = {} } = userClaims;
    const [ roles, ..._realms ] = access.split(':');
    const realms = _realms.join(':');
    if (auth0Roles.roles) {
      this.setUserAccess('__users__', auth0Roles.roles.includes('Delegated Admin - User'));
    }
    if (roles && realms) {
      this.setUserRoles(roles.split(','));
      if ('*' === realms) {
        this.setUserAccess('__full__', true);
      }
      else {
        (realms.split(',') || []).forEach((realm: string) => {
          let [ company, brand, offer ] = realm.split('.');
          company && (company = company.replace('company:', ''));
          brand && (brand = brand.replace('brand:', ''));
          offer && (offer = offer.replace('offer:', ''));
          if (!company) {
            return;
          }
          companies.push(company);
          this.setUserAccess('__assets__', ACCESS_LIST | ACCESS_FETCH);
          this.setUserAccess('__categories__', ACCESS_LIST | ACCESS_FETCH);
          if (!brand) {
            this.setUserAccess(company, ACCESS_READ | ACCESS_UPDATE);
            this.setUserAccess(`${company}.__brands__`, ACCESS_READ | ACCESS_UPDATE | ACCESS_DELETE);
            this.setUserAccess(`${company}.__offers__`, ACCESS_READ | ACCESS_UPDATE | ACCESS_DELETE);
            this.setUserAccess('__brands__', ACCESS_CREATE | ACCESS_LIST | ACCESS_FETCH);
            this.setUserAccess('__offers__', ACCESS_CREATE | ACCESS_LIST | ACCESS_FETCH);
          }
          else if ('*' === brand) {
            this.setUserAccess(company, ACCESS_READ);
            this.setUserAccess(`${company}.__brands__`, ACCESS_READ | ACCESS_UPDATE);
            this.setUserAccess(`${company}.__offers__`, ACCESS_READ | ACCESS_UPDATE | ACCESS_DELETE);
            this.setUserAccess('__brands__',  ACCESS_LIST | ACCESS_FETCH);
            this.setUserAccess('__offers__', ACCESS_CREATE | ACCESS_LIST | ACCESS_FETCH);
          }
          else if (!offer) {
            brands.push(brand);
            this.setUserAccess(company, ACCESS_READ);
            this.setUserAccess(brand, ACCESS_READ | ACCESS_UPDATE);
            this.setUserAccess(`${brand}.__offers__`, ACCESS_READ | ACCESS_UPDATE | ACCESS_DELETE);
            this.setUserAccess('__brands__', ACCESS_LIST);
            this.setUserAccess('__offers__', ACCESS_CREATE | ACCESS_LIST | ACCESS_FETCH);
          }
          else if ('*' === offer) {
            this.setUserAccess(company, ACCESS_READ);
            this.setUserAccess(brand, ACCESS_READ);
            this.setUserAccess(`${brand}.__offers__`, ACCESS_READ | ACCESS_UPDATE);
            this.setUserAccess('__offers__', ACCESS_LIST | ACCESS_FETCH);
          }
          else {
            this.setUserAccess(company, ACCESS_READ);
            this.setUserAccess(brand, ACCESS_READ);
            this.setUserAccess(offer, ACCESS_READ | ACCESS_UPDATE);
          }
        });
      }
    }
    this.setUserEntities({ companies, brands })
    await this.getEntities(companies, 'companies');
    await this.getEntities(brands, 'brands');
  }

  /**
   * Fetches the realm entities from api.
   */
  async getEntities(entities: string[], type: string, forceUpdate?: boolean) {
    const documents: Document.Base[] = await api.use('ls').path(type).getAll();
    const cached: Document.Base[] = documents
      .filter((document: Document.Base) => {
        return !forceUpdate || !entities.includes(document.id);
      });
    const promises = (entities || [])
      .filter((entityId: string) => {
        return forceUpdate || !cached.find((item: Document.Base) => item.id === entityId);
      })
      .map((id: string) => api.path(type).get(id));
    if (promises.length) {
      const data = await Promise.all(promises);
      const items = cached.concat(data);
      return items;
    }
    return cached;
  }

  /**
   * Returns the auth0 user object.
   */
  getUser() {
    return this.user || {};
  }

  /**
   * Returns a user token silently.
   */
  async getToken() {
    try {
      return await this.client.getTokenSilently();
    }
    catch (err) {
      error.handle('auth0.token-not-fetched', err);
    }
  }

  /**
   * Returns a flag whether user is authenticated.
   */
  authenticated() {
    return this.isAuthenticated;
  }

  /**
   * Logs user out from auth0.
   */
  logout() {
    event.dispatch('USER_LOGOUT');
    return this.client.logout({
      returnTo: window.location.origin + process.env.PUBLIC_URL,
    });
  }

  /**
   * Returns an active company.
   */
  async company() {
    if (this.canListCompanies()) {
      return null;
    }
    if (this.userCompany) {
      return this.userCompany;
    }
    const companies = await this.getEntities(
      this.userEntities['companies'] || [],
      'companies'
    );
    if (companies[0]) {
      this.userCompany = companies[0];
    }
    return this.userCompany;
  }

  /**
   * Checks whether the current user is admin.
   */
  isAdmin = () => {
    return !!this.getUserAccess('__full__');
  }

  /**
   * Checks whether the current user has the given role.
   */
  hasRole(role: string) {
    return this.userRoles.includes(role);
  }

  /**
   * Sets user roles got from access realm.
   */
  setUserRoles(roles: string[]) {
    this.userRoles = roles;
  }

  /**
   * Returns user roles.
   */
  getUserRoles() {
    return this.userRoles;
  }

  /**
   * Resets user access.
   */
  resetUserAccess() {
    this.userAccess = {}
  }

  /**
   * Set user access to the given realm.
   */
  setUserAccess(realm: string, access: number | boolean) {
    this.userAccess[realm] = +access;
  }

  /**
   * Returns user access by the given realm.
   */
  getUserAccess(realm: string) {
    return this.userAccess[realm];
  }

  /**
   * Returns user entities.
   */
  getUserEntities() {
    return this.userEntities;
  }

  /**
   * Set user specific entity IDs.
   */
  setUserEntities(entities: { brands: string[], companies: string[] }) {
    this.userEntities = entities;
  }

  /**
   * Checks whether a user can perform the given operation.
   */
  canDo(op: number, type?: string, parentId?: string, itemId?: string) {
    return !!(this.getUserAccess('__full__')
      || (type && (this.getUserAccess(`__${type}__`) & op))
      || (parentId && (this.getUserAccess(`${parentId}.__${type}__`) & op))
      || (itemId && (this.getUserAccess(itemId) & op)));
  }

  /**
   * Checks whether a user can create a document of the given type.
   */
  canCreate(type: string) {
    return this.canDo(ACCESS_CREATE, type);
  }

  /**
   * Checks whether a user can list documents of the given type.
   */
  canList(type: string) {
    return this.canDo(ACCESS_LIST, type);
  }

  /**
   * Checks whether a user can update a document of the given type.
   */
  canUpdate(type?: string, parentId?: string, itemId?: string) {
    return this.canDo(ACCESS_UPDATE, type, parentId, itemId);
  }

  /**
   * Checks whether a user can delete a document of the given type.
   */
  canDelete(type?: string, parentId?: string, itemId?: string) {
    return this.canDo(ACCESS_DELETE, type, parentId, itemId);
  }

  /**
   * Checks whether a user can read a document of the given type.
   */
  canRead(type?: string, parentId?: string, itemId?: string) {
    return this.canDo(ACCESS_READ, type, parentId, itemId);
  }

  /**
   * Checks whether a user can fetch document from API.
   */
  canFetch(type: string) {
    return this.canDo(ACCESS_FETCH, type);
  }

  /**
   * Checks whether a user can list brand documents.
   */
  canListBrands = () => {
    return this.canList('brands');
  }

  /**
   * Checks whether a user can list company documents.
   */
  canListCompanies = () => {
    return this.canList('companies');
  }

  /**
   * Checks whether a user can create brand documents.
   */
  canCreateBrands = () => {
    return this.canCreate('brands');
  }

  /**
   * Checks whether a user can create company documents.
   */
  canCreateCompanies = () => {
    return this.canCreate('companies');
  }

  /**
   * Checks whether a user can update brand documents.
   */
  canUpdateBrands = ({ companyId, brandId }: { companyId?: string, brandId?: string } = {}) => {
    const { companies = [] } = this.userEntities;
    return this.canUpdate('brands', companyId || companies[0], brandId);
  }

  /**
   * Checks whether a user can update company documents.
   */
  canUpdateCompanies = ({ companyId }: { companyId?: string } = {}) => {
    return this.canUpdate('companies', undefined, companyId);
  }

  /**
   * Checks whether a user can manage users.
   */
  canManageUsers = () => {
    return this.isAdmin() || !!this.getUserAccess('__users__');
  }

}

export default new Auth0({
  audience: process.env.REACT_APP_AUTH0_AUDIENCE!, // eslint-disable-line
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!, // eslint-disable-line
  domain: process.env.REACT_APP_AUTH0_DOMAIN!, // eslint-disable-line
  cacheLocation: 'localstorage',
  redirect_uri: window.location.origin + process.env.PUBLIC_URL,
});
