import React from 'react';
import DialogWrapper from './DialogWrapper';

type Props = {
  document: Document.Base,
  text: string,
}

const ConfirmationDialog = ({ text, ...props }: Props) => {
  return (
    <DialogWrapper
      title="Confirmation Window"
      {...props}
    >
      {text}
    </DialogWrapper>
  );
};

export default ConfirmationDialog;
