import { useEffect, useState } from 'react';
import MrpPricing from 'services/MrpPricing';

const useMrpPricing = (discountId?: string) => {
  const [ pricing, setPricing ] = useState<Data>({
    sub: 0,
    tp: 0,
    fee: 0,
  });
  useEffect(() => {
    (async () => {
      setPricing(await MrpPricing.loadPricing(discountId));
    })()
  }, [discountId]);
  return pricing;
}

export default useMrpPricing;
