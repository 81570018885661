import * as yup from 'yup';
import { message, assetSchema } from './helpers';

export default yup.object().shape({
  name: yup.string().required(message('required', 'Name')),
  content: yup.object().shape({
    icon: assetSchema(true, 'Icon'),
    icon_active: assetSchema(true, 'Icon Active'),
  }),
});
