import B2b from './providers/B2b';
import LocalStorage from './providers/LocalStorage';

/**
 * API Wrapper class.
 */
class ApiWrapper {

  /**
   * A name or path to the resource.
   */
  private __resource: string;

  /**
   * A provider ID.
   */
  private __provider: 'b2b' | 'ls' | undefined;

  /**
   * A list of provider instances.
   */
  private __providers: {[k: string]: B2b | LocalStorage};

  /**
   * A default provider ID.
   */
  private __defaultProvider: 'b2b' | 'ls';

  /**
   * An error handler.
   */
  private errorHandler: (e: Error) => void | undefined;

  /**
   * In process promises.
   */
  private promises: Data = {};

  /**
   * A cache loaded objects.
   */
  private cache: Data = {};

  /**
   * Constructor for the ApiWrapper object.
   */
  constructor(providers: {name: string, instance: B2b | LocalStorage}[], defaultProvider: 'b2b' | 'ls') {
    this.__defaultProvider = defaultProvider;
    this.__providers = {};
    providers.forEach(({ name, instance }) => {
      this.addProvider(name, instance);
    });
  }

  /**
   * Adds a provider to the list of providers.
   */
  addProvider(name: string, instance: B2b | LocalStorage) {
    this.__providers[name] = instance;
  }

  /**
   * Returns an instanse of the given provider name.
   */
  provider(name?: string) {
    const ret = this.__providers[name || this.__provider || this.__defaultProvider];
    this.__provider && this.use();
    return ret;
  }

  /**
   * Makes the given provider a default one.
   */
  use(provider?: 'b2b' | 'ls') {
    this.__provider = provider;
    return this;
  }

  /**
   * Sets an active resource.
   */
  path(resource: string) {
    this.__resource = resource;
    return this;
  }

  /**
   * Gets an entity by the given ID.
   */
  get(id: string, revisionId?: string, cache?: boolean) {
    const key = [id, revisionId]
      .filter(item => !!item)
      .join('-');
    if (cache && this.cache[key]) {
      return new Promise(resolve => resolve(this.cache[key]));
    }
    if (this.promises[key]) {
      return this.promises[key];
    }
    this.promises[key] = this.provider(this.__provider)
      .getDocument(this.__resource, id, revisionId || '')
      .then((data) => {
        delete this.promises[key];
        this.cache[key] = data;
        return data;
      });
    return this.promises[key];
  }

  /**
   * Gets a list of entities by the given filter parameters.
   */
  getAll(pars?: Omit<ApiListParameters, 'collection'> & { after?: string }): Promise<Document.Base[]> {
    return (this.provider(this.__provider).getDocuments({
      ...pars,
      collection: this.__resource,
    }) as Promise<Document.Base[]>);
  }

  /**
   * Removes an entity by the given ID.
   */
  delete(id: string, revisionId: string) {
    return this.provider(this.__provider).deleteDocument(this.__resource, id, revisionId);
  }

  /**
   * Creates or updates an entity by the given data and ID.
   */
  save(data: Document.Base, id?: string) {
    return (id
      ? this.provider().updateDocument(this.__resource, data, id)
      : this.provider().createDocument(this.__resource, data)
    );
  }

  /**
   * Invokes error handler if it's set.
   */
  handleError = (e: Error) => {
    this.errorHandler && this.errorHandler(e);
  }

  /**
   * Sets an error handler.
   */
  setErrorHandler(errorHandler: (e: Error) => void) {
    this.errorHandler = errorHandler;
  }
}

export default new ApiWrapper([
  {
    name: 'b2b',
    instance: new B2b({
      baseUrl: process.env.REACT_APP_B2B_API_BASE_URL!, // eslint-disable-line
    })
  },
  {
    name: 'ls',
    instance: new LocalStorage()
  }
], 'b2b');
