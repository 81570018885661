import auth0 from 'services/Auth0';

/**
 * Flags handler global class.
 */
class FeaturesHandler {

  /**
   * An object that contains app features.
   */
  private config: { [key: string]: boolean } = {};

  /**
   * Checks whether the given feature is allowed.
   */
  async isAllowed(feature: string) {
    await this.load();
    return this.isAllowedFromCache(feature);
  }

  /**
   * Checks whether the given feature is allowed in cached object.
   */
  isAllowedFromCache(feature: string) {
    if (auth0.isAdmin()) {
      return true;
    }
    return this.getValue(feature);
  }

  /**
   * Gets boolean value from the config object.
   */
  getValue(variable: string) {
    return !!this.config[variable];
  }

  /**
   * Loads feature flagging settings.
   */
  async load() {
    const company = await auth0.company();
    this.config = company?.features ?? {};
  }

}

export default new FeaturesHandler();
