import React from 'react';
import Typography from '@material-ui/core/Typography';
import { GridContainer, Grid } from 'components/Layout';

const NotFound = () => {
  return (
    <div style={{ paddingTop: '150px', textAlign: 'center' }}>
      <GridContainer>
        <Grid>
          <Typography variant="h1">
            404: This page does not exist or is not accessible.
          </Typography>
        </Grid>
      </GridContainer>
    </div>
  );
};

export default NotFound;
