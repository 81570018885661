import React from 'react';
import auth0 from 'services/Auth0';

type Props = {
  access?: () => boolean,
  adminView?: boolean,
} & Data;

const withAccess = (Component: React.ElementType) => ({ access, adminView, ...props }: Props) => {
  if (adminView) {
    access = auth0.isAdmin;
  }
  if (access && !access()) {
    return null;
  }
  return (
    <Component
      className={adminView && 'admin-wrapper'}
      {...props}
    />
  );
}

export default withAccess;
