import React from 'react';
import Fieldset from 'components/Display/Fieldset';
import {
  CheckboxGroup,
  Date,
  RichEditor,
  Select,
  TextField
} from 'components/Form';
import { Grid, GridContainer } from 'components/Layout';
import access from 'data/access.json';
import schemes from 'data/schemes.json';
import daysOfWeek from 'data/daysOfWeek.json';

type Props = {
  data: Data
}

const RedemptionCredit = ({ data }: Props) => {
  return (
    <div>
      <CheckboxGroup
        label="Access Control"
        name="roles"
        options={access}
        required
      />
      <Fieldset label="Offer">
        <GridContainer>
          <Grid>
            <TextField
              name="attributes.name"
              placeholder="Name"
            />
          </Grid>
          <Grid>
            <Select
              label="Type"
              name="attributes.type"
              // onChange={(e) => setType(e.target.value)}
              options={[
                { id: 'amount', name: 'Amount' },
                { id: 'discount', name: 'Discount' },
              ]}
            />
          </Grid>
          {'amount' === data['attributes.type'] && (
            <Grid>
              <TextField
                name="attributes.amount"
                placeholder="Amount"
              />
            </Grid>
          )}
          {'discount' === data['attributes.type'] && (
            <Grid>
              <TextField
                name="attributes.discount"
                placeholder="Discount"
              />
            </Grid>
          )}
          <Grid>
            <Date
              name="attributes.start_date"
              placeholder="Start Date"
            />
          </Grid>
          <Grid>
            <Date
              name="attributes.end_date"
              placeholder="End Date"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.country"
              placeholder="Country Code"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.publisher"
              placeholder="Publisher ID"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.brand_id"
              placeholder="Brand ID"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.brand_name"
              placeholder="Brand Name"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.brand_logo"
              placeholder="Brand Logo URL"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.max_redemptions"
              placeholder="Max Redemptions"
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.return_period"
              placeholder="Return Period"
            />
          </Grid>
          <Grid>
            <CheckboxGroup
              label="Schemes"
              name="attributes.schemes"
              options={schemes}
            />
          </Grid>
          <Grid>
            <CheckboxGroup
              label="Days of Week"
              name="attributes.week_days"
              options={daysOfWeek}
            />
          </Grid>
          <Grid>
            <TextField
              name="attributes.terms"
              placeholder="Additional Terms"
            />
          </Grid>
          <Grid>
            <RichEditor
              label="Instructions"
              name="instructions"
              required
            />
          </Grid>
        </GridContainer>
      </Fieldset>
    </div>
  );
};

export default RedemptionCredit;
