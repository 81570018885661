import React from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import FieldWrapper from './FieldWrapper';
import useField from 'inc/hooks/useField';

type Props = {
  helperText?: React.ReactNode | string,
  helperTextTooltip?: React.ReactNode | string,
  helperTextBottom?: React.ReactNode | string,
  label?: string,
  name: string,
  required?: boolean,
  variant?: 'outlined' | 'filled' | undefined,
} & Omit<TextFieldProps, 'variant'>;

function TextField({ label, helperText, helperTextTooltip, helperTextBottom, name, required, variant, ...props }: Props) {
  const { error, register, setLocalValue, setValue } = useField(name, {
    callback: (data: Document.Base) => setValue(data[name]),
    controlled: false,
  });
  if (process.env.REACT_APP_TESTING) { // eslint-disable-line
    props['inputProps'] = {
      'data-testid': `${name}-input`,
    };
  }
  return (
    <FieldWrapper
      helperText={helperText}
      helperTextBottom={helperTextBottom}
      helperTextTooltip={helperTextTooltip}
      label={label}
      name={name}
      required={required}
    >
      <MuiTextField
        error={!!error}
        fullWidth
        inputRef={register}
        margin="none"
        name={name}
        onChange={(e) => {
          setLocalValue(e.target.value);
          !!error && setValue(e.target.value, true, true);
        }}
        variant={variant ? variant : 'outlined'}
        {...props}
      />
    </FieldWrapper>
  );
}

export default TextField;
