import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import BrandTabs from './BrandTabs';
import useServiceTable from 'inc/hooks/useServiceTable';
import TablePagination from 'components/Table';
import moment from 'moment';

const BrandRevisions = ({ match }: RouteComponentProps<MatchParams.Brand>) => {
  const { brandId } = match.params || {};
  const { fetchItems, listActions } = useServiceTable({
    path: 'brands',
    parentId: brandId,
    revision: true,
  });
  const fields = [
    {
      id: 'name',
      label: 'Title',
      sortable: true,
      width: '50%',
    },
    {
      id: 'updated.on',
      label: 'Updated',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
    {
      id: 'log',
      label: 'Message',
    }
  ];
  return <div>
    <BrandTabs
      docId={brandId}
      index={1}
    />
    <TablePagination
      actions={listActions}
      fetch={fetchItems}
      fields={fields}
    />
  </div>
}

export default withRouter(BrandRevisions);
