import React from 'react';
import { useFormContext } from 'react-hook-form';
import useDefaultValue from 'inc/hooks/useDefaultValue';

type Props = {
  label?: string,
  name: string,
  value: string,
}

function ReadOnly({ label, name, value }: Props) {
  const { setValue } = useFormContext();
  useDefaultValue(name, (data: Document.Base) => {
    setValue(name, data[name], true);
  });
  return (
    <>
      <div>{label}</div>
      <div><b>{value}</b></div>
    </>
  );
}

export default ReadOnly;
