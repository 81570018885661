// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import FieldWrapper from './FieldWrapper';
import useField from 'inc/hooks/useField';
import CustomAutocomplete from 'components/Custom/Autocomplete';

type Props = {
  defaultValue?: Data,
  description?: React.ReactNode | string,
  label?: string,
  name: string,
  options: CategoryItem[],
  required?: boolean,
} & AutocompleteProps;

function Autocomplete({ defaultValue, description, label, name, options, required, ...props }: Props) {
  const [fieldValue, setFieldValue] = useState();
  const { error, formRendered, setValue } = useField(name, {
    callback: async (data: Document.Base) => {
      setValue(data[name], true);
      if (props.multiple) {
        setFieldValue(data[name] || []);
      }
      else {
        setFieldValue(options.find(option => option.id === data[name]));
      }
    },
  });
  useEffect(() => {
    formRendered && defaultValue && defaultValue.id && setValue(defaultValue.id, true);
  }, [formRendered]);
  if (process.env.REACT_APP_TESTING) {
    props['data-testid'] = `${name}-input`;
  }
  return (
    <FieldWrapper
      helperText={description}
      label={label}
      name={name}
      required={required}
    >
      <CustomAutocomplete
        error={!!error}
        onChange={(e, option) => {
          if (props.multiple) {
            setValue(option || [], true, true);
          }
          else {
            setValue((option || {}).id, true, true);
          }
          setFieldValue(option);
        }}
        options={options}
        value={fieldValue || defaultValue || null}
        {...props}
      />
    </FieldWrapper>
  );
}

export default Autocomplete;
