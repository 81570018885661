import React from 'react';
import { CheckboxGroup, RichEditor } from 'components/Form';
import access from 'data/access.json';

const RedemptionInPerson = () => {
  return (
    <div>
      <CheckboxGroup
        label="Access Control"
        name="roles"
        options={access}
        required
      />
      <RichEditor
        label="Instructions"
        name="instructions"
        required
      />
    </div>
  );
};

export default RedemptionInPerson;
