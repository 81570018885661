import React, { Component, ErrorInfo } from 'react';
import error from 'services/ErrorHandler';

class ErrorBoundary extends Component {
  public state = {
    hasError: false
  };

  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public componentDidCatch(err: Error, info: ErrorInfo) {
    error.handle('react.fatal', { message: err.message, datails: info });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <h1>
          Error occured. We are already working on solving this problem.
          <a href="/">Click here</a>{' '}to go back to the start page.
        </h1>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
