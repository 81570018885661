import React from 'react';
import clsx from 'clsx';
import withAccess from 'components/Visibility/withAccess';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core';

type Props = {
  className?: string,
  children: React.ReactNode,
}

const useStyles = makeStyles((theme: Theme) => ({
  pageBlock: {
    marginBottom: theme.spacing(4),
    '&.admin-wrapper': {
      padding: theme.spacing(3),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.green,
      backgroundColor: fade(String(theme.custom.green), 0.18),
      fontFamily: 'Akkurat Mono LL, monospace',
    }
  }
}));

function PageBlock({ className, children, ...props }: Props) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.pageBlock, className)}
      {...props}
    >
      {children}
    </div>
  );
}

export default withAccess(PageBlock);
