import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import auth0 from 'services/Auth0';
import event from 'services/EventManager';
import error from 'services/ErrorHandler';

type B2bConfig = {
  baseUrl: string,
}

/**
 * List of moderation status values applicable to document.
 */
export enum ModerationStatus {
  draft = 'draft',
  needsWork = 'needs_work',
  readyForReview = 'ready_for_review',
  inReview = 'review',
  readyForRelease = 'ready_for_release',
  published = 'published',
}

/**
 * B2b api provider class.
 */
class B2b {

  /**
   * The configuration object.
   */
  private config: B2bConfig;

  /**
   * An instance of the axios service.
   */
  private app: AxiosInstance;

  /**
   * Constructor for the B2b object.
   */
  constructor(config: B2bConfig) {
    this.config = config;
    this.app = axios.create({
      baseURL: this.config.baseUrl,
      headers: {
        'content-type': 'application/json',
      },
    });
    this.app.interceptors.request.use(async (config) => {
      const b2bAuthToken = auth0.authenticated() && await auth0.getToken();
      config.headers.common['authorization'] = `Bearer ${b2bAuthToken}`;
      return config;
    });
    this.app.interceptors.response.use(response => response, async (err) => {
      const data = (err.response ?? {}).data;
      const {
        type = 'unknown',
        message = '',
        details = {}
      } = (data ?? {}).error ?? {};
      return Promise.reject(await error.handle(`b2b-api.${type}`, err, {
        details,
        message: typeof data === 'string' ? err.response : message,
      }));
    });
  }

  /**
   * Sends workflow events.
   */
  private sendWorkflowEvent(collection: string, data: Document.Base) {
    switch (data['state']) {
      case ModerationStatus.readyForReview:
        event.dispatch(`${event.getEventPrefix(collection)}_SUBMITTED_FOR_REVIEW`, { data });
        break;
      case ModerationStatus.readyForRelease:
        event.dispatch(`${event.getEventPrefix(collection)}_APPROVED`, { data });
        break;
      case ModerationStatus.needsWork:
        event.dispatch(`${event.getEventPrefix(collection)}_REJECTED`, { data });
        break;
      case ModerationStatus.published:
        event.dispatch(`${event.getEventPrefix(collection)}_PUBLISHED`, { data });
        break;
    }
  }

  /**
   * Moderates the given document.
   */
  moderate(collection: string, id: string, data: Document.Base) {
    const { comment, state } = data;
    return this.app.post(`/${collection}/${id}/transit`, { comment, state }).then(res => {
      const resData = (res.data || {}).data || {};
      this.sendWorkflowEvent(collection, resData);
      return {...data, ...resData};
    });
  }

  /**
   * Unpublishes a document by the given collection and id.
   */
  requestUnpublish(collection: string, id: string) {
    return this.app.post(`/${collection}/${id}/request-unpublish`).then(res => {
      return (res.data || {}).data || {};
    });
  }

  /**
   * Unpublishes a document by the given collection and id.
   */
  unpublish(collection: string, id: string) {
    return this.app.post(`/${collection}/${id}/unpublish`).then(res => {
      const resData = (res.data || {}).data || {};
      event.dispatch(`${event.getEventPrefix(collection)}_UNPUBLISHED`, { data: resData });
      return resData;
    });
  }

  /**
   * Downloads the given asset.
   */
  assetDownload(assetId: string) {
    return this.app.get(`/assets/${assetId}/download`, { responseType: 'blob' }).then(res => {
      return res.data;
    });
  }

  /**
   * Uploads the given file data.
   */
  upload(data: Data, config: AxiosRequestConfig) {
    return this.app.post('/assets', data, config).then(res => {
      return (res.data || {}).data || {};
    }).catch(() => ({}));
  }

  /**
   * Downloads coupons from the given program.
   */
  couponsDownload(programId: string) {
    return this.app.get(`/mrp-coupons/${programId}/download`).then(res => {
      event.dispatch('PROGRAM_CODES_DOWNLOADED', { programId });
      return res.data;
    });
  }

  /**
   * Returns subscription info.
   */
  subscriptionInfo(id: string) {
    return this.app.get(`/subscriptions/${id}/info`)
      .then(res => (res.data || {}).data || {})
      .catch(() => ({}));
  }

  /**
   * Returns a list of subscriptions.
   */
  subscriptionList() {
    return this.app.get('/subscriptions/list')
      .then(res => (res.data || {}).data || [])
      .catch(() => ([]));
  }

  /**
   * Triggers an action to the given subscription.
   */
  subscriptionTrigger(id: string, action: string) {
    return this.app.post(`/subscriptions/${id}`, { action })
      .then(res => (res.data || {}).data || {});
  }

  /**
   * Loads pricing for mrp.
   */
  mrpPricing() {
    return this.app.get('/mrp-pricing')
      .then(res => (res.data || {}).data || {})
      .catch(() => ({}));
  }

  /**
   * Loads pricing for mrp.
   */
  mrpDiscounts(id: string) {
    return this.app.get(`/mrp-discounts/${id}`)
      .then(res => (res.data || {}).data || {})
      .catch(() => ({}));
  }

  /**
   * Sorts documents based on their weight.
   */
  setSorting(collection: string, parentId: string, data: { [k: string]: number }) {
    return this.app.post(`/${collection}/order?id=${parentId}`, data).then(res => {
      const resData = (res.data || {}).data || {};
      return {...data, ...resData};
    }).catch(() => ({}));
  }

  /**
   * Creates a document by a given collection and data.
   */
  createDocument(collection: string, data: Document.Base) {
    return this.app.post(`/${collection}`, data).then(res => {
      const resData = (res.data || {}).data || {};
      event.dispatch(`${event.getEventPrefix(collection)}_CREATED`, { data: resData });
      return {...data, ...resData};
    });
  }

  /**
   * Updates a document by a given collection, data and document ID.
   */
  updateDocument(collection: string, data: Document.Base, id: string) {
    return this.app.put(`/${collection}/${id}`, data).then(res => {
      const resData = (res.data || {}).data || {};
      !!resData['state'] && this.sendWorkflowEvent(collection, resData);
      return {...data, ...resData};
    });
  }

  /**
   * Removes a document by the given collection and ID.
   */
  deleteDocument(collection: string, id: string, revisionId: string) {
    return this.app
      .delete(`/${collection}/${id}${revisionId ? `/revisions/${revisionId}` : ''}`);
  }

  /**
   * Returns a document by the given collection and ID.
   */
  getDocument(collection: string, id: string, revisionId: string) {
    return this.app
      .get(`/${collection}/${id}${revisionId ? `/revisions/${revisionId}` : ''}`)
      .then(res => (res.data || {}).data || {});
  }

  /**
   * Returns a list of documents by the given filter parameters.
   */
  getDocuments({ after, collection, limit, condition = [], order, published = false, revision = '', parentId = '' }: ApiListParameters & { after?: string }) {
    const params: B2bListParameters = {};
    if (limit) {
      params.limit = limit;
    }
    if (order && order.by) {
      params.order = `.${order.by}:${order.dir || 'asc'}`;
    }
    if (published) {
      params.status = 'published';
    }
    if (after) {
      params.after = after;
    }
    condition.forEach((item: ApiConditionItem) => {
      params[`.${item.field}`] = item.value;
    });
    return this.app
      .get(`/${collection}${parentId && revision ? `/${parentId}/revisions` : ''}`, { params })
      .then(res => (res.data || {}).data || []);
  }
}

export default B2b;
