import React from 'react';
import { Grid, GridContainer } from 'components/Layout';
import { CheckboxGroup, RichEditor, TextField } from 'components/Form';
import access from 'data/access.json';

const RedemptionMobile = () => {
  return (
    <div>
      <CheckboxGroup
        label="Access Control"
        name="roles"
        options={access}
        required
      />
      <GridContainer>
        <Grid>
          <TextField
            name="attributes.apps"
            placeholder="Apps"
          />
        </Grid>
        <Grid>
          <TextField
            name="attributes.app_id"
            placeholder="App ID"
          />
        </Grid>
        <Grid>
          <TextField
            name="attributes.path"
            placeholder="Path"
          />
        </Grid>
      </GridContainer>
      <RichEditor
        label="Instructions"
        name="instructions"
        required
      />
    </div>
  );
};

export default RedemptionMobile;
