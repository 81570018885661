export default {
  root: {
    backgroundColor: '#F0F1F4',
    '&$selected': {
      backgroundColor: '#DFDDDD'
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: '#DFDDDD'
      }
    }
  },
  head: {
    backgroundColor: 'transparent'
  }
};
