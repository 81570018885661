import React from 'react';
import { useFormContext } from 'react-hook-form';
import { ServiceList } from 'containers';
import { Select, TextField } from 'components/Form';
import useCompanyId from 'inc/hooks/useCompanyId';
import auth0 from 'services/Auth0';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

type Props = {
  image: {
    ext?: string,
    id: string,
    file: File,
    fileContent?: string,
    name: string,
    preview: string,
    status: number,
  },
  onRemove: () => void,
  uploading: boolean,
}

const useStyles = makeStyles((theme: Theme) => ({
  col: {
    flex: '0 0 33.333333%',
    maxWidth: '33.333333%',
    padding: '0 30px',
    marginBottom: 30,
    display: 'flex',
  },
  item: {
    backgroundColor: theme.custom.white,
    boxShadow: '0px 8px 32px rgba(0,0,0,0.12)',
    padding: 20,
    width: '100%',
  },
  image: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    paddingBottom: '75%',
    margin: '-20px -20px 20px',
  },
  label: {
    position: 'absolute',
    left: 0,
    top: 24,
    whiteSpace: 'nowrap',
    backgroundColor: theme.custom.orange,
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: 10,
    padding: '0 8px',
    letterSpacing: '0.16em',
    textTransform: 'uppercase',
  },
  remove: {
    backgroundColor: fade(String(theme.custom.white), 0.6),
    borderRadius: 11,
    height: 22,
    width: 22,
    fontSize: 22,
    position: 'absolute',
    right: 10,
    top: 10,
    zIndex: 999,
    padding: 0,
    minWidth: 0,
  },
  spinner: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: theme.custom.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    objectFit: 'contain',
  },
  property: {
    marginBottom: 20,
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiSelect-outlined': {
      minWidth: 0,
    },
  },
}));

const PopupAsset = ({ image, onRemove, uploading }: Props) => {
  const classes = useStyles();

  const methods = useFormContext();
  const { setValue, watch } = methods;
  const clientCompanyId = useCompanyId();
  const companyFieldId = `company-${image.id}`;
  const brandFieldId = `brand-${image.id}`;
  const nameFieldId = `name-${image.id}`;
  const companyId = watch(companyFieldId) || clientCompanyId;
  // const brandId = watch(brandFieldId);
  return (
    <div className={classes.col}>
      <div className={classes.item}>
        <div
          className={classes.image}
          key={image.name}
        >
          <img
            className={classes.img}
            src={image.preview}
          />
          <div className={classes.label}>{image.ext}</div>
          {uploading && (<div className={classes.spinner}><CircularProgress color="inherit"/></div>)}
          <Button
            className={classes.remove}
            component="label"
            onClick={onRemove}
            variant="contained"
          >
            <HighlightOffIcon fontSize="inherit"/>
          </Button>
        </div>
        <TextField
          className={classes.property}
          defaultValue={image.name}
          label="Name"
          name={nameFieldId}
        />
        <>
          {auth0.canListCompanies() && (
            <div className={classes.property}>
              <ServiceList
                component={(items, loading) =>
                  <Select
                    disabled={loading}
                    label="Company"
                    name={companyFieldId}
                    onChange={() => setValue(brandFieldId, null)}
                    options={items}
                  />
                }
                path="companies"
                waitForLoad={false}
              />
            </div>
          )}
          {!!companyId && auth0.canListBrands() && (
            <div className={classes.property}>
              <ServiceList
                component={(items, loading) =>
                  <Select
                    disabled={loading}
                    label="Brand"
                    name={brandFieldId}
                    options={items}
                  />
                }
                condition={[
                  {
                    field: 'company',
                    value: companyId as string,
                    op: '=='
                  }
                ]}
                path="brands"
                waitForLoad={false}
              />
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default PopupAsset;
