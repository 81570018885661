import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TablePagination from 'components/Table';
import useServiceTable from 'inc/hooks/useServiceTable';

const InvoiceList = () => {
  const { fetchItems } = useServiceTable({
    path: 'mrp-batches',
  });
  const fields = [
    {
      id: 'invoice.id',
      label: 'Invoice ID',
      sortable: true,
      width: '40%',
    },
    {
      id: 'created.on',
      label: 'Time',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
    {
      id: 'coupons',
      label: 'Codes',
    },
    {
      id: 'invoice.paid',
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      prepare: (value: boolean, item: Document.Base) => {
        return value ? 'Paid' : (
          <a
            href={item['invoice.hosted_invoice_url']}
            rel="noopener noreferrer"
            style={{ alignItems: 'flex-start', color: '#FF3C5F', display: 'flex' }}
            target="_blank"
          >
              Unpaid <CreditCardIcon style={{ fontSize: 20, marginLeft: 5 }} />
          </a>
        );
      },
      label: 'Status',
    },
    {
      id: 'created.by.name',
      label: 'User',
    },
  ];
  const listActions = [
    {
      external: true,
      label: 'Download',
      class: 'blue',
      url: (item: Document.Base) => item['invoice.invoice_pdf'],
    },
  ];
  return (
    <div>
      <Typography
        component="h2"
        paragraph
        variant="h3"
      >
        Invoice History
      </Typography>
      <Typography
        gutterBottom
        paragraph
        variant="subtitle2"
      >
        WeSalute Programs allow you to make a bulk order of 12-month WeSalute+ subscriptions for your chosen user base.
        For example, does your company employ veterans? You can give them the gift of WeSalute with a Military Recognition Program.
      </Typography>
      <TablePagination
        actions={listActions}
        fetch={fetchItems}
        fields={fields}
      />
    </div>
  );
};

export default InvoiceList;
