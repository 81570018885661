import React, { useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ApiProgress,
  Fieldset,
  Preview,
  State,
  TabPanel,
} from 'components/Display';
import {
  AssetImage,
  Autocomplete,
  Hidden,
  MultipleTextField,
  RichEditor,
  TextField,
} from 'components/Form';
import { RedemptionsWrapper } from 'containers';
import { Grid, GridContainer } from 'components/Layout';
import { ServiceList } from 'containers';
import useFormCrud from 'inc/hooks/useFormCrud';
import { OfferCreateSchema } from 'inc/validation';
import shortid from 'shortid';
import ls from 'inc/storage/LocalStorage';
import merchantCategories from 'data/merchantCategories.json';
import useObservable from 'inc/hooks/useObservable';
import api from 'services/api';
import auth0 from 'services/Auth0';
import states from 'data/states.json';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Brand>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    brands: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > a': {
        margin: theme.spacing(1.5),
      },
      '& > a div.header, & > a div.footer': {
        height: theme.spacing(3),
      },
      '& > a div.header': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 10px',
      },
      '& > a div.content': {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: theme.spacing(28),
        height: theme.spacing(10),
      },
    },
  }),
);

const OfferCreate = ({ history, match }: Props) => {
  const classes = useStyles();
  const brandId = match.params['brandId'] || '';
  const company = useObservable('company');
  const [activeStep, setActiveStep] = useState(brandId ? 1 : 0);
  const [brand, setBrand] = useState<Data>({});
  const [offer, setOffer] = useState<Data>({});
  const { handleEditSubmit, methods, triggerValidation } = useFormCrud({
    path: 'offers',
    schema: OfferCreateSchema,
    success: (offer) => { setOffer(offer); setActiveStep(4) },
    process: (data) => {
      const redemptions: { [k: string]: Document.Redemption } = {};
      const redemptions_order: string[] = [];
      ((data.content || {}).redemptions || []).forEach((r: Document.Redemption) => {
        const uuid = `${r.type}-${shortid.generate()}`;
        redemptions[uuid] = r;
        redemptions_order.push(uuid);
      });
      const res = {
        ...data,
        content: {
          ...data.content,
          keywords: (data.content.keywords || []).join(', '),
          redemptions
        },
        'content.redemptions': redemptions,
        __redemptions__order: redemptions_order
      };
      delete res.content.redemptions;
      return res;
    }
  });
  useEffect(() => {
    if (!brandId) return;
    (async () => {
      const brand = await api.path('brands').get(brandId);
      setBrand(brand);
    })();
  }, [brandId]);
  useEffect(() => {
    company.id && brand.company && brand.company !== company.id && history.push('/offers/create');
  }, [company.id]);
  // const [company, setCompany] = useState<Document.Base>();
  // useEffect(() => {
  //   if ((values[FORM_STATE_DATA] || {}).company) {
  //     api
  //       .path('companies')
  //       .get(values[FORM_STATE_DATA].company)
  //       .then((company: Document.Base) => setCompany(company));
  //   }
  // }, [values[FORM_STATE_LOADED]]);
  const config = {
    prepare: async (values: Document.Base) => {
      const {
        content = {},
      } = values;
      return {
        ...values,
        content: {
          ...values.content,
          keywords: (content.keywords || '').split(','),
          redemptions: (values['__redemptions__order'] || []).map((uuid: string) => {
            const [rId, ] = uuid.split('-');
            const { roles, instructions, attributes } = (content.redemptions || {})[uuid] || {};
            return { roles, instructions, attributes, type: rId };
          })
        }
      }
    },
  };
  const steps = [
    'Choose Brand',
    'Offer Information',
    'Upload Assets',
    'Select Redemption Channel',
    'Send for Review',
  ];
  const fieldsToValidate = [
    [],
    [
      { name: 'name' },
      { name: 'brand' },
      { name: 'content.title' },
      { name: 'content.mcc_category' },
      { name: 'content.keywords' },
      { name: 'content.summary' },
      { name: 'content.details' },
      { name: 'content.description' },
    ],
    [
      { name: 'content.image.asset' },
      { name: 'content.image.alt' },
    ]
  ];
  return (
    <div style={{ padding: '0 120px' }}>
      <Card>
        <ApiProgress />
        <CardContent>
          <Typography
            component="h2"
            variant="h5"
          >
            Create Offer
          </Typography>
          <Typography
            component="p"
            variant="body2"
          >
            WeSalute Offers are the primary value drivers to the greater military community,
            created by you and delivered by us. Offers are the backbone of our partnerships
            and our goal is to give you the tools to create quality offers.
          </Typography>
        </CardContent>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditSubmit(config))}>
            <GridContainer>
              <Grid>
                <TabPanel
                  index={0}
                  value={activeStep}
                >
                  <Grid>
                    <ServiceList
                      component={(items) => (
                        <>
                          <div className={classes.brands}>
                            {items.map(item => {
                              const state = (states as ModerationState[]).find(s => s.id === item.state);
                              return (
                                <Link
                                  data-testid={`brand-ref-${item.id}`}
                                  key={item.id}
                                  to={`/offers/create/${item.id}`}
                                >
                                  <Paper elevation={2}>
                                    <div className="header">
                                      <div className="country">
                                        {item.content?.country}
                                      </div>
                                      <div className="state">
                                        {state ? <State state={state} /> : null}
                                      </div>
                                    </div>
                                    <div className="content">
                                      {item.name}
                                    </div>
                                    <div className="footer" />
                                  </Paper>
                                </Link>
                              )
                            })}
                            {!items.length && (
                              <div style={{ width: '100%', textAlign: 'center' }}>
                                No brands created yet. Create a brand first, then you can create an offer.
                              </div>
                            )}
                          </div>
                          {auth0.canCreateBrands && (
                            <div style={{ marginTop: 10, textAlign: 'center' }}>
                              <Button
                                color="primary"
                                component={Link}
                                to="/brands/create"
                                variant="contained"
                              >
                                {items.length ? 'Or, Create New Brand' : 'Create Brand'}
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                      condition={[
                        {
                          field: 'company',
                          // value: ls.get('company')?.id,
                          value: company.id || ls.get('company')?.id,
                          op: '=='
                        }
                      ]}
                      loadComponent={
                        <div style={{ textAlign: 'center' }}>
                          <CircularProgress />
                          <div>loading brands...</div>
                        </div>
                      }
                      path="brands"
                    />
                  </Grid>
                </TabPanel>
                <TabPanel
                  index={1}
                  value={activeStep}
                >
                  <GridContainer>
                    <Grid>
                      <TextField
                        helperText={
                          <span>
                            Pretty self-explanitory! *Note - this can be the same as your company if your brand and company are the same. View example.
                          </span>
                        }
                        label="Offer Name"
                        name="name"
                        required
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        helperText={
                          <span>
                            This will appear on your dedicated brand web page where WeSalute can promote our partnership.
                            <br />*Tip - Effective headlines are less than 16 characters. View example.
                          </span>
                        }
                        label="Offer Subtitle"
                        name="content.title"
                        required
                      />
                    </Grid>
                    <Grid>
                      <Hidden
                        name="brand"
                        value={brandId}
                      />
                    </Grid>
                    <Grid>
                      <Autocomplete
                        description={
                          <span>
                            Help WeSalute users quickly and easily find your brand by choosing your merchant category code.
                            <br />Don’t see a code that fits? Not to worry, just pick the code that best fits your brand.
                          </span>
                        }
                        label="Merchant Category Code"
                        name="content.mcc_category"
                        options={merchantCategories}
                        required
                      />
                    </Grid>
                    <Grid>
                      <RichEditor
                        description={
                          <span>
                            Summarize your offer in simple terms and use marketing copy here to entice users.
                            There is no technical restirction on length however we encourage you to keep it short and to-the-point. View example.
                          </span>
                        }
                        label="Summary"
                        name="content.summary"
                        required
                      />
                    </Grid>
                    <Grid>
                      <Fieldset
                        defaultExpanded={false}
                        label="Banner"
                      >
                        <GridContainer>
                          <Grid>
                            <TextField
                              label="Title"
                              name="content.banner.title"
                            />
                          </Grid>
                          <Grid>
                            <TextField
                              label="Description"
                              multiline
                              name="content.banner.description"
                              rows={2}
                              rowsMax={4}
                            />
                          </Grid>
                          <Grid>
                            {brandId && (
                              <AssetImage
                                entityId={brandId}
                                entityType="brand"
                                label="Image"
                                name="content.banner.image"
                              />
                            )}
                          </Grid>
                          <Grid>
                            <Fieldset label="CTA Link">
                              <GridContainer>
                                <Grid>
                                  <TextField
                                    label="Title"
                                    name="content.banner.cta.title"
                                  />
                                </Grid>
                                <Grid>
                                  <TextField
                                    label="Url"
                                    name="content.banner.cta.url"
                                  />
                                </Grid>
                              </GridContainer>
                            </Fieldset>
                          </Grid>
                        </GridContainer>
                      </Fieldset>
                    </Grid>
                    <Grid>
                      <MultipleTextField
                        helperText={
                          <span>
                            Summarize your offer in simple terms and use marketing copy here to entice users.
                            There is no technical restirction on length however we encourage you to keep it short and to-the-point. View example.
                          </span>
                        }
                        label="Details"
                        name="content.details"
                        required
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        label="Keywords"
                        name="content.keywords"
                      />
                    </Grid>
                    <Grid>
                      <RichEditor
                        description={
                          <span>
                            Summarize your offer in simple terms and use marketing copy here to entice users.
                            There is no technical restirction on length however we encourage you to keep it short and to-the-point. View example.
                          </span>
                        }
                        label="Terms and Conditions"
                        name="content.terms_conditions"
                      />
                    </Grid>
                  </GridContainer>
                </TabPanel>
                <TabPanel
                  index={2}
                  value={activeStep}
                >
                  <GridContainer>
                    <Grid>
                      {brandId && (
                        <AssetImage
                          description="This is the image used for the offer page. JPG, PNG, SVG files are acceptable. File size must be less than 10 mb."
                          entityId={brandId}
                          entityType="brand"
                          label="Offer Hero Image"
                          name="content.image"
                          required
                        />
                      )}
                    </Grid>
                  </GridContainer>
                </TabPanel>
                <TabPanel
                  index={3}
                  value={activeStep}
                >
                  <RedemptionsWrapper name="content.redemptions" />
                </TabPanel>
                <TabPanel
                  index={4}
                  value={activeStep}
                >
                  <GridContainer>
                    <Grid>
                      <Typography
                        component="h2"
                        variant="h5"
                      >
                        Congrats! Your new offer is submitted for review.
                      </Typography>
                      <div style={{ paddingLeft: 48, paddingRight: 48, paddingTop: 16 }}>
                        <div style={{ padding: '20px 0' }}>
                          <Typography
                            component="h2"
                            paragraph
                            variant="h5"
                          >
                            <State
                              state={{
                                id: 'pending',
                                name: `${offer?.name} is pending review`,
                                color: '#ccc'
                              }}
                            />
                          </Typography>
                          <Typography
                            component="h2"
                            paragraph
                            variant="h5"
                          >
                            <State
                              state={{
                                id: 'pending',
                                name: 'Remption channel is pending review',
                                color: '#ccc'
                              }}
                            />
                          </Typography>
                          <Typography
                            component="h2"
                            paragraph
                            variant="h5"
                          >
                            <State
                              state={{
                                id: 'pending',
                                name: `${brand?.name} is pending review`,
                                color: '#ccc'
                              }}
                            />
                          </Typography>
                        </div>
                        <Typography
                          component="h2"
                          paragraph
                          variant="h5"
                        >
                          What now?
                        </Typography>
                        <Typography
                          component="p"
                          paragraph
                          variant="body1"
                        >
                          1. We are reviewing your offer, this final review ensures your offer is set up for success.
                          Our review typically takes about 1-3 hours, although it can take up to 24 hours.
                          We’ll send you an email when your review is complete.
                        </Typography>
                        <Typography
                          component="p"
                          paragraph
                          variant="body1"
                        >
                          2. To check the status of your offer’s review, go to <Link to="/offers">manage your offers</Link>.
                        </Typography>
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          color="primary"
                          component={Link}
                          to="/offers"
                          variant="contained"
                        >
                          Go to offer management
                        </Button>
                      </div>
                    </Grid>
                  </GridContainer>
                </TabPanel>
                {(activeStep > 1 && activeStep < steps.length - 1) ? (
                  <Button
                    color="primary"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setActiveStep(activeStep - 1);
                    }}
                    style={{ marginBottom: 24, marginLeft: 24 }}
                    variant="contained"
                  >
                    Back
                  </Button>
                ) : null}
                {(activeStep > 0 && activeStep < steps.length - 2) ? (
                  <Button
                    color="primary"
                    data-testid="submit-next"
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const result = await triggerValidation(fieldsToValidate[activeStep], true);
                      result && setActiveStep(activeStep + 1);
                    }}
                    style={{ marginBottom: 24, marginLeft: 24 }}
                    variant="contained"
                  >
                    Go to the next step &gt;
                  </Button>
                ) : null}
                {(activeStep >= steps.length - 2 && activeStep < steps.length - 1) && (
                  <>
                    <Preview style={{ marginBottom: 24, marginLeft: 24 }} />
                    <Button
                      color="primary"
                      data-testid="submit-save"
                      style={{ marginBottom: 24, marginLeft: 24 }}
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </>
                )}
              </Grid>
            </GridContainer>
          </form>
        </FormContext>
        <ApiProgress />
      </Card>
    </div>
  );
};

export default withRouter(OfferCreate);
