import { useState } from 'react';

type Props = {
  actions?: {
    confirmTitle?: string,
    onClick: (data: Data, handleClose: () => void, setLoading: (loading: boolean) => void) => void,
  },
  hideHeader?: boolean,
  onClose?: () => void,
  onOpen?: () => void,
  title: string,
  width?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined,
}

const useDialog = ({ actions, hideHeader, title, onClose, onOpen, width }: Props) => {
  const [ open, setOpen ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const [ dialogData, setDialogData ] = useState<Data>({});
  const closeDialog = () => {
    setOpen(false);
    onClose && onClose();
  }
  const openDialog = (data: Data) => {
    setOpen(true);
    setDialogData(data);
    onOpen && onOpen();
  }
  return {
    dialogProps: {
      actions,
      data: dialogData,
      handleClose: closeDialog,
      hideHeader,
      setLoading,
      loading,
      open,
      title,
      width,
    },
    closeDialog,
    openDialog,
  };
}

export default useDialog;
