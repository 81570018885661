import React from 'react';

type Props = {
  children: React.ReactNode,
  index: number,
  value: number,
};

function TabPanel({ children, value, index, ...props }: Props) {
  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...props}
    >
      {children}
    </div>
  );
}

export default TabPanel;
