import React, { useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import FieldWrapper from './FieldWrapper';
import useField from 'inc/hooks/useField';
import MediaDialog from 'containers/dialogs/MediaDialog';
import ClassicEditor from 'components/Custom/CKEditor';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type Props = {
  description?: React.ReactNode | string,
  label?: string,
  name: string,
  required?: boolean,
} & ComponentInputProps;

const useStyles = makeStyles((theme: Theme) => ({
  counter: {
    color: theme.custom.grayDark,
    fontSize: 12,
    textAlign: 'right',
    fontFamily: 'Akkurat Mono LL, monospace'
  }
}));

const RichEditor = ({ description, name, label, required, ...props }: Props) => {
  const classes = useStyles();
  const [ counter, setCounter ] = useState(0);
  const { error, editValue, setValue } = useField(name, {
    callback: (data: Document.Base) => setValue(data[name]),
  });
  if (process.env.REACT_APP_TESTING) { // eslint-disable-line
    props['data-testid'] = `${name}-input`;
  }
  const [ rendered, setRendered ] = useState(false);
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ assetCallback, setAssetCallback ] = useState<{ callback?: (url: string) => void }>({});
  setTimeout(() => {
    setRendered(true);
  }, 350);
  return (
    <FieldWrapper
      helperText={description}
      label={label}
      name={name}
      required={required}
      {...props}
    >
      {rendered && (
        <>
          <CKEditor
            config={{
              assets: {
                loadPopup: (cb: (imageUrl: string) => void) => {
                  setDialogOpen(dialogOpen => !dialogOpen);
                  setAssetCallback({ callback: cb });
                }
              }
            }}
            data={editValue || ''}
            editor={ClassicEditor}
            onChange={(event: Event, editor: Data) => {
              const data = editor.getData();
              const text = data.replace(/<\/?[^>]+(>|$)/g, '').trim();
              setValue(data, !!error && !!text, true);
              setCounter(
                text
                  .replace(/&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;|&lt;|&amp;/g, ' ')
                  .length
              );
            }}
            onInit={(editor: Data) => {
              if (process.env.REACT_APP_TESTING) { // eslint-disable-line
                editor.editing.view.document.on('clipboardInput', ( evt: Data, data: Data ) => {
                  setValue(data.dataTransfer.getData());
                  evt.stop();
                });
              }
            }}
          />
          <div className={classes.counter}>{counter}</div>
        </>
      )}
      <MediaDialog
        document={{ id: 'void', name: 'void' } as Document.Base}
        onClose={() => { setDialogOpen(false) }}
        onFileSelect={(doc) => {
          assetCallback.callback && assetCallback.callback(doc.file);
          setDialogOpen(false);
        }}
        open={dialogOpen}
        width="md"
      />
    </FieldWrapper>
  );
};

export default RichEditor;
