import React, { useCallback, useState } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useDropzone } from 'react-dropzone';
import { ServiceList } from 'containers';
import { Select } from 'components/Form';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import useCompanyId from 'inc/hooks/useCompanyId';
import GalleryList from 'components/List/GalleryList';
import ServicePaginationList from 'components/ServiceList';
import useDialog from 'inc/hooks/useDialog';
import Dialog from 'containers/dialogs/Dialog';
import PopupAsset from './components/PopupAsset';
import shortid from 'shortid';
import auth0 from 'services/Auth0';
import api from 'services/api';
import B2b from 'services/api/providers/B2b';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import * as yup from 'yup';
import { alertStyles } from 'theme/styles';

type AssetObject = {
  ext?: string,
  id: string,
  file: File,
  fileContent?: string,
  name: string,
  preview: string,
  status: number,
}

const useStyles = makeStyles((theme: Theme) => ({
  ...alertStyles(theme),
  headerContainer: {
    paddingTop: theme.spacing(7),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(5),
    marginBottom: 0,
  },
  button: {
    fontSize: 16,
    padding: '15px 40px',
    '& .MuiSvgIcon-root': {
      marginRight: 6,
    }
  },
  filterContainer: {
    marginTop: theme.spacing(9),
    display: 'flex',
    flexWrap: 'wrap',
  },
  filterItem: {
    marginLeft: 20,
    '&:first-child': {
      marginLeft: 0,
    },
    '& .MuiSelect-outlined': {
      minWidth: 210,
    },
  },
  assetPopup: {
    textAlign: 'center',
    borderColor: theme.custom.blue,
    borderWidth: 1,
    borderStyle: 'dashed',
    padding: theme.spacing(20, 2),
  },
  assetPopupTitle: {
    fontSize: theme.spacing(5),
    marginBottom: 21,
  },
  assetPopupButton: {
    marginTop: 15,
    minWidth: 280,
    fontWeight: 400,
  },
  assetTip: {
    fontSize: 16,
    textAlign: 'center',
    paddingTop: theme.spacing(3.5),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
  }
}));

const AssetList = () => {
  const fileMaxSize = 10485760;
  const classes = useStyles();
  const [assetBrands, setAssetBrands] = useState<{[key: string]: string}>({});
  const [images, setImages] = useState<{[key: string]: AssetObject}>({});
  const [itemChanged, setItemChanged] = useState(0);
  const [errors, setErrors] = useState<string[]>([]);
  const [processingImages, setProcessingImages] = useState<string[]>([]);
  const clientCompanyId = useCompanyId();
  const methods = useForm({ validationSchema: yup.lazy(() => {
    const valid: Data = {};
    const files = Object.values(images);
    for (let i = 0; i < files.length; i++) {
      valid[`company-${files[i].id}`] = yup.string().nullable().required('Company is required.');
      valid[`brand-${files[i].id}`] = yup.string().nullable().required('Brand is required.');
    }
    return yup.object().shape(valid);
  }) });
  const { getValues, setValue, triggerValidation, watch } = methods;
  const companyId = watch('company') || clientCompanyId;
  const brandId = watch('brand');
  const orderBy = watch('order') || 'updated.on';
  const condition = [];
  companyId && condition.push({ field: 'company', value: companyId });
  brandId && condition.push({ field: 'brand', value: brandId });
  const { openDialog, closeDialog, dialogProps } = useDialog({
    onClose: () => {
      setErrors([]);
      setImages({});
    },
    title: 'Add Media Assets',
  });
  const uploadFiles = async () => {
    const values = getValues();
    const valid = [];
    const files = Object.values(images);
    for (let i = 0; i < files.length; i++) {
      valid.push({ name: `company-${files[i].id}` });
      valid.push({ name: `brand-${files[i].id}` });
    }
    if (await triggerValidation(valid, true)) {
      for (let i = 0; i < files.length; i++) {
        setProcessingImages([files[i].id]);
        const fileUpload: Data = {
          brand: values[`brand-${files[i].id}`] || null,
          company: values[`company-${files[i].id}`] || null,
          name: files[i].name,
          type: 'image',
          filename: files[i].name + '.' + files[i].ext,
          private: false,
          file: files[i].fileContent,
        };
        await (api.provider('b2b') as B2b).upload(fileUpload, {});
        setProcessingImages([]);
      }
      setErrors([]);
      setImages({});
      setItemChanged(itemChanged + 1);
      closeDialog();
    }
  };
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    const errors: string[] = [];
    const files: {[key: string]: AssetObject} = {};
    if (fileRejections.length) {
      const rejectedBySize = fileRejections.filter((file: File) => file.size > fileMaxSize);
      const rejectedByType = fileRejections.filter((file: File) => !file.type.startsWith('image/'));
      if (rejectedBySize.length) {
        errors.push(`The following files ${rejectedBySize.map((file: File) => file.name).join(', ')} could not be uploaded since they reach 10 Mb limit.`);
      }
      if (rejectedByType.length) {
        errors.push(`The following files ${rejectedByType.map((file: File) => file.name).join(', ')} could not be uploaded because of wrong format.`);
      }
    }
    const maxFiles = 6;
    if (acceptedFiles.length > maxFiles) {
      const rejectedFiles = acceptedFiles.slice(maxFiles);
      acceptedFiles = acceptedFiles.slice(0, maxFiles);
      errors.push(`The following files ${rejectedFiles.map((file: File) => file.name).join(', ')} could not be uploaded since only ${maxFiles} can be uploaded at a time.`);
    }
    setErrors(errors);
    acceptedFiles.forEach((file: File) => {
      const fileId = shortid.generate();
      const fileParts = file.name.split('.');
      const extension = fileParts.pop();
      files[fileId] = {
        ext: extension,
        id: fileId,
        file,
        name: fileParts.join('.'),
        preview: URL.createObjectURL(file),
        status: 0,
      };
    })
    setImages(files);
    Object.values(files).forEach((asset: AssetObject) => {
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        setImages(images => ({
          ...images,
          [asset.id]: {
            ...images[asset.id],
            fileContent: window.btoa(reader.result as string),
          },
        }));
      }
      // @todo replace with more stable approach.
      // reader.readAsDataURL(file.rawFile)
      reader.readAsBinaryString(asset.file);
    })
  }, []);
  const {getRootProps, getInputProps, open: uploadDesktopFiles} = useDropzone({
    accept: 'image/*',
    maxSize: fileMaxSize,
    multiple: true,
    noClick: true,
    onDrop,
  });
  return (
    <div>
      <FormContext {...methods}>
        <div className={classes.headerContainer}>
          <Typography
            className={classes.title}
            component="h2"
            variant="h2"
          >
            Assets Management
          </Typography>
          <Button
            className={classes.button}
            color="primary"
            onClick={() => openDialog({})}
            variant="contained"
          >
            <CloudUploadIcon /> Upload media
          </Button>
        </div>
        <div className={classes.filterContainer}>
          {auth0.canListCompanies() && (
            <div className={classes.filterItem}>
              <ServiceList
                component={(items, loading) =>
                  <Select
                    disabled={loading}
                    label="Company"
                    name="company"
                    onChange={() => setValue('brand', null)}
                    options={items}
                  />
                }
                path="companies"
                waitForLoad={false}
              />
            </div>
          )}
          {!!companyId && auth0.canListBrands() && (
            <div className={classes.filterItem}>
              <ServiceList
                component={(items, loading) =>
                  <Select
                    disabled={loading}
                    label="Brand"
                    name="brand"
                    options={items}
                    style={{ marginRight: 10 }}
                  />
                }
                condition={[
                  {
                    field: 'company',
                    value: companyId as string,
                    op: '=='
                  }
                ]}
                path="brands"
                waitForLoad={false}
              />
            </div>
          )}
          <div className={classes.filterItem}>
            <Select
              defaultValue="updated.on"
              label="Order By"
              name="order"
              options={[
                { id: 'updated.on', name: 'Most Recent' },
                { id: 'name', name: 'Name' },
              ]}
            />
          </div>
        </div>
        <ServicePaginationList
          Component={GalleryList}
          componentProps={{
            fields: {
              image: 'file',
              name: 'name',
              brand: (item: Document.Base) => {
                return assetBrands[item.brand];
              }
            },
          }}
          condition={condition}
          defaults={{
            pageRows: 12
          }}
          onLoad={async (items: Document.Base[], setItem) => {
            const promises: Array<Promise<Document.Base>> = [];
            items.forEach(entity => {
              entity.brand && promises.push(api.path('brands').get(entity.brand));
            });
            const brands = await Promise.all(promises);
            brands.forEach(brand => {
              assetBrands[brand.id] = brand.name;
            });
            setAssetBrands(assetBrands);
            setItem((itemCount: number) => itemCount + 1);
          }}
          order={{ by: orderBy, dir: 'name' === orderBy ? 'asc' : 'desc' }}
          path="assets"
          reload={itemChanged}
        />
        <Dialog
          {...dialogProps}
          component={() => {
            const imagesList = Object.values(images);
            return (
              <div
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                {!!errors.length && (
                  <Alert
                    className={classes.alert}
                    icon={<ErrorIcon fontSize="inherit" />}
                    severity="error"
                  >
                    <div>
                      {errors.map(error => <div key={error}>{error}</div>)}
                    </div>
                  </Alert>
                )}
                {!imagesList.length && (
                  <div>
                    <div className={classes.assetPopup}>
                      <Typography
                        className={classes.assetPopupTitle}
                        component="h2"
                        variant="h2"
                      >
                        Drag and drop files
                      </Typography>
                      <p>or</p>
                      <Button
                        className={classes.assetPopupButton}
                        color="primary"
                        component="label"
                        onClick={(e: React.MouseEvent) => {
                          e.preventDefault();
                          uploadDesktopFiles();
                        }}
                        variant="contained"
                      >
                        Upload from Computer
                      </Button>
                    </div>
                    <div className={classes.assetTip}>
                      Supported file types are PNG, GIF, JPG, JPEG, SVG.
                    </div>
                  </div>
                )}
                {!!imagesList.length && (
                  <div>
                    <div className={classes.row}>
                      {imagesList.map(image => (
                        <PopupAsset
                          image={image}
                          key={image.id}
                          onRemove={() => {
                            setImages(images => {
                              // eslint-disable-next-line
                              const { [image.id]: omit, ...rest } = images;
                              return rest;
                            });
                          }}
                          uploading={processingImages.includes(image.id)}
                        />
                      ))}
                    </div>
                    <Button
                      className={classes.assetPopupButton}
                      color="primary"
                      component="label"
                      disabled={!!processingImages.length}
                      onClick={(e: React.MouseEvent) => {
                        e.preventDefault();
                        uploadFiles();
                      }}
                      variant="contained"
                    >
                      Upload the files ({imagesList.length})
                    </Button>
                  </div>
                )}
              </div>
            );
          }}
        />
      </FormContext>
    </div>
  );
};

export default AssetList;
