import { useEffect, useState } from 'react';
import Config from 'services/Config';

const useConfigAsync = (variable: string) => {
  const [configVariable, setConfigVariable] = useState('');
  useEffect(() => {
    (async () => {
      setConfigVariable(await Config.getAsync(variable));
    })();
  }, []);
  return configVariable;
}

export default useConfigAsync;
