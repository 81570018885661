import { useEffect } from 'react';
import useForm from 'react-hook-form';
import api from 'services/api';
import { isObject, cacheFormKey, flatten, filterEmptyData, filterFormData } from 'inc/utils';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import { ObjectSchema } from 'yup';
import store from 'inc/store';
import ls from 'inc/storage/LocalStorage';

type FormCrudProps = {
  path: string,
  id?: string
  revisionId?: string,
  schema?: ObjectSchema,
  process?: (data: Document.Base) => Document.Base,
  success?: (data: Document.Base) => void,
  provider?: 'b2b' | 'ls',
}

type SubmitProps = {
  prepare?: (data: Document.Base) => Promise<Document.Base>,
  requests?: {
    after?: (data: Document.Base) => Promise<void>[],
    before?: (data: Document.Base) => Promise<void>[],
  },
  syncRequests?: (data: Document.Base) => Promise<void>[],
}

const useFormCrud = ({ path, id: docId = '', revisionId = '', schema, process, success, provider = 'b2b' }: FormCrudProps) => {
  const isRevision = !!revisionId;
  const methods = useForm({ validationSchema: schema });
  const { clearError, register, reset, getValues, triggerValidation, setError, errors, formState, watch } = methods;
  const values = getValues();
  const formType = docId ? 'edit' : 'create';
  const cacheKey = cacheFormKey(path, formType);
  const processData = (data: Document.Base) => {
    store('data.loading').set(false);
    if (data.log) {
      data.revision_log = data.log;
      delete data.log;
    }
    if (typeof process === 'function') {
      data = process(data);
    }
    reset({...getValues(),
      ...data,
      [FORM_STATE_LOADED]: true,
      [FORM_STATE_DATA]: {...data, ...flatten(data)},
    });
  }
  useEffect(() => {
    register({ name: '__form_id' });
    register({ name: '__form_type' });
    register({ name: FORM_STATE_DATA });
    register({ name: FORM_STATE_LOADED });
    let initValues = {
      __form_id: path,
      __form_type: formType,
    };
    const data = filterEmptyData(ls.get(cacheKey));
    if (isObject(data, true)) {
      initValues = {
        ...initValues,
        ...data,
        [FORM_STATE_LOADED]: true,
        [FORM_STATE_DATA]: data,
      };
      store('page.message').set({ text: 'You have unsaved data.', type: 'info' });
    }
    reset(initValues);
  }, []);
  useEffect(() => {
    if (docId) {
      store('data.loading').set(true);
      api
        .use(provider)
        .path(path)
        .get(docId, revisionId)
        .then(processData)
    }
  }, [docId]);
  // @todo create an interface for an error object. Api.Error, Data.Form
  const processServerErrors = (error: Api.Error, values: Data) => {
    const errors = [];
    const { message, details = {} } = error;
    if (details.field) {
      const field = details.field.substring(1);
      if (field.includes('redemptions[')) {
        const order = values['__redemptions__order'];
        const redId = field.match(/\d+/);
        if (redId && order[redId[1]]) {
          errors.push({
            type: 'server-error',
            name: `redemptions.${order[redId[1]]}${field.substring(12 + redId.toString().length)}`,
            message,
          });
          errors.push({
            type: 'server-error',
            name: `__redemptions__order.${redId}`,
            message,
          });
        }
      }
      else {
        errors.push({
          type: 'server-error',
          name: field,
          message,
        });
      }
    }
    return errors;
  }
  const handleEditSubmit = ({ prepare, syncRequests, requests = {} }: SubmitProps = {}) => async (values: Data) => {
    values = prepare ? await prepare(values as Document.Base) : values;
    store('page.loading').set(true);
    try {
      if (syncRequests) {
        const requests = syncRequests(values as Document.Base);
        for (let i = 0; i < requests.length; i++) {
          await requests[i];
        }
      }
      if (requests.before) {
        const req = requests.before(values as Document.Base);
        for (let i = 0; i < req.length; i++) {
          await req[i];
        }
      }
      const result = await api.use(provider)
        .path(path)
        .save(filterFormData(values), docId);
      if (requests.after) {
        const req = requests.after(result as Document.Base);
        for (let i = 0; i < req.length; i++) {
          await req[i];
        }
      }
      ls.remove(cacheKey);
      store('page.loading').set(false);
      store('page.message').set({ text: 'The item has been stored', type: 'success' });
      success && success(result as Document.Base);
    } catch(error) {
      clearError();
      store('page.loading').set(false);
      store('page.message').set({ text: error.message, type: 'error' });
      processServerErrors(error, values).forEach(({ name, type, message }) => {
        setError(name, type, message);
      });
    }
  }
  return {
    docId,
    isRevision,
    formState,
    clearError,
    handleEditSubmit,
    triggerValidation,
    methods,
    values,
    errors,
    watch,
  };
}

export default useFormCrud;
