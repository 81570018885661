import { programCodesDownloaded } from 'services/typewriter';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
  segmentProgramObject,
} from './helpers';

export default async ({ programId }: Data) => {
  const program = await getEntity('mrps', programId);
  const company = await getEntity('companies', program['company']);
  const brand = await getEntity('brands', program['brand']);
  programCodesDownloaded({
    context: segmentContextObject(company, brand),
    brand: segmentBrandObject(brand, company),
    company: segmentCompanyObject(company),
    program: segmentProgramObject(program),
  });
};
