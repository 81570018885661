import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { Theme } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ListIcon from '@material-ui/icons/List';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PhotoIcon from '@material-ui/icons/Photo';
import { Profile, SidebarNav } from './components';
import auth0 from 'services/Auth0';
import useFeatureFlagging from 'inc/hooks/useFeatureFlagging';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 350,
    paddingLeft: 70,
    paddingTop: 100,
    border: 'none',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerOpen: {
    width: 350,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    border: 'none',
    paddingTop: 100
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  button: {
    color: theme.custom.grayDark,
    fontSize: 16,
    lineHeight: 1.75,
    padding: '11px 24px 11px 0',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: 400
  },
  buttonShort: {
    paddingLeft: 24,
    '& $icon': {
      marginRight: 40
    }
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

type Props = {
  className?: string,
  open: boolean,
  toggleSidebar: () => void,
  variant?: 'permanent' | 'persistent' | 'temporary',
}

const Sidebar = (props: Props) => {
  const { open, variant, className, toggleSidebar, ...rest } = props;
  const offersAllowed = useFeatureFlagging('offer');
  const classes = useStyles();
  const pages = [
    {
      title: 'Create',
      icon: <AddBoxIcon />,
      items: [
        {
          access: auth0.isAdmin,
          title: 'Create Company',
          to: '/companies/add',
        },
        {
          access: auth0.canCreateBrands,
          title: 'Create Brand',
          exact: true,
          to: '/brands/create',
        },
        {
          access: () => offersAllowed,
          title: 'Create Offer',
          exact: true,
          to: '/offers/create',
        },
        {
          title: 'Create Program',
          to: '/programs/create',
        },
      ]
    },
    {
      title: 'Manage',
      icon: <ListIcon />,
      items: [
        {
          access: auth0.isAdmin,
          active: /^\/companies\/edit\/[^/]+$/g,
          title: 'Manage Companies',
          to: '/companies',
        },
        {
          access: auth0.canListBrands,
          active: /^\/brands\/(create|edit)\/[^/]+$/g,
          title: 'Manage Brands',
          to: '/brands',
        },
        {
          access: () => offersAllowed,
          active: /^\/offers\/(create|edit)\/[^/]+$/g,
          title: 'Manage Offers',
          to: '/offers',
        },
        {
          active: /^\/programs\/edit\/[^/]+$/g,
          title: 'Manage Programs',
          to: '/programs',
        },
      ]
    },
    {
      active: /^\/assets\/edit\/[^/]+$/g,
      title: 'Assets',
      to: '/assets',
      icon: <PhotoIcon />
    },
    {
      title: 'Billing',
      to: '/billing',
      icon: <ReceiptIcon />
    },
    // {
    //   access: auth0.canManageUsers,
    //   title: 'Users',
    //   to: process.env.REACT_APP_USERS_MANAGEMENT_URL!, // eslint-disable-line
    //   icon: <PeopleIcon />,
    //   external: true,
    // },
    {
      access: () => !auth0.isAdmin(),
      title: 'Settings',
      icon: <SettingsIcon />,
      items: [
        {
          active: /^\/settings\/company\/[^/]+$/g,
          title: 'Company Information',
          to: '/settings/company',
        },
      ]
    }
  ];

  return (
    <Drawer
      anchor="left"
      classes={{
        paper: clsx({
          [classes.drawer]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      className="sidebar"
      open
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div>
          <Profile withName={open} />
          <Divider className={classes.divider} />
          <SidebarNav
            className={classes.nav}
            collapsed={!open}
            pages={pages}
          />
        </div>
        <div style={{ width: '100%' }}>
          <Button
            className={clsx(classes.button, {[classes.buttonShort]: !open})}
            component="label"
            onClick={toggleSidebar}
          >
            <div className={classes.icon}>
              {open ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            </div>
            Toggle sidebar
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
