import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import {
  State,
  TabPanel,
  ToggleBox,
} from 'components/Display';
import {
  AssetImage,
  Autocomplete,
  CheckboxGroup,
  Hidden,
  MultipleTextField,
  RadioGroup,
  Reward,
  RichEditor,
  Select,
  TextField,
  FieldWrapper,
} from 'components/Form';
import { PageBlock } from 'components/Layout';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import customStyles, { newStyles } from 'theme/styles';
import RedemptionSchema from 'inc/validation/Redemption';
import { Offer as OfferPreview } from 'components/PreviewTemplates';
import { RedemptionOnline } from 'containers/RedemptionsWrapper/redemptions';
import { Grid, GridContainer } from 'components/Layout';
import { ServiceList } from 'containers';
import Reference from 'components/Form/Reference';
import useFormCrud from 'inc/hooks/useFormCrud';
import { OfferCreateSchema } from 'inc/validation';
import merchantCategories from 'data/merchantCategories.json';
import audiences from 'data/audiences.json';
import categories from 'data/offerCategories.json';
import useCompanyId from 'inc/hooks/useCompanyId';
import useObservable from 'inc/hooks/useObservable';
import BrandList from 'containers/BrandList';
import OfferList from 'containers/OfferList';
import useConfigAsync from 'inc/hooks/useConfigAsync';
import useScroll from 'inc/hooks/useScroll';
import api from 'services/api';
import auth0 from 'services/Auth0';
import event from 'services/EventManager';
import clsx from 'clsx';
import helperTexts from 'inc/constants/fields/offer';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import moderationStates from 'data/states.json';
import Message from './components/Message';
import { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import {fade} from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  ...newStyles(theme),
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  errorRedemptionTypes: {
    marginBottom: theme.spacing(1),
    backgroundColor: fade(String(theme.custom.pink), 0.18),
    fontSize: 18,
    borderRadius: 4,
    padding: theme.spacing(1, 2),
  },
}));

const OfferCreate = ({ history, match }: RouteComponentProps<MatchParams.Offer>) => {
  const classes = useStyles();
  const saveLoading = useObservable('page.loading');
  const offerId = match.params['offerId'] || '';
  const { setTriggered: setErrorsTriggered } = useScroll('.field-error');
  const [activeStep, setActiveStep] = useState(0);
  const [brandId, setBrandId] = useState('');
  const [redemptionTypes, setRedemptionTypes] = useState<string[]>([]);
  const [brand, setBrand] = useState<Data>({});
  const [offer, setOffer] = useState<Data>({});
  const [states, setStates] = useState(moderationStates);
  const platformUrl = useConfigAsync('platformUrl');
  const clientCompanyId = useCompanyId();
  const { clearError, docId, handleEditSubmit, methods, triggerValidation, values } = useFormCrud({
    id: offerId,
    path: 'offers',
    schema: OfferCreateSchema,
    success: (offer) => {
      if (offerId) {
        history.push(`/offers/edit/${offerId}`);
      }
      else {
        setOffer(offer);
        setActiveStep(5);
      }
    },
    process: (data) => {
      const redemptions: Data = {};
      (data.content?.redemptions ?? []).forEach((redemption: Data) => {
        const { type } = redemption;
        redemptions[type] = redemption;
      });
      return {
        ...data,
        content: {
          ...data.content,
          redemptions,
        },
      };
    }
  });
  const companyId = methods.watch('company') || clientCompanyId;
  useEffect(() => {
    if (!brandId) return;
    (async () => {
      const brand = await api.path('brands').get(brandId);
      setBrand(brand);
    })();
  }, [brandId]);
  const data = values[FORM_STATE_DATA] || {};
  const dataLoaded = !!values[FORM_STATE_LOADED];
  const config = {
    prepare: async (values: Document.Base) => {
      const audience = values?.content?.audience ?? '';
      const redemptions = values?.content?.redemptions ?? {};
      const updateOptions: string[] = data['meta.state.update'] || [];
      const state = updateOptions.includes(values?.state) ? values?.state : updateOptions[0];
      return {
        ...values,
        content: {
          ...values.content,
          audience: audience.split(','),
          redemptions: Object.keys(redemptions).map((type: string) => {
            return {...redemptions[type], type };
          })
        },
        state: auth0.isAdmin() ? state : 'ready_for_review',
      }
    },
  };
  const steps = offerId ?
    [
      'Offer Information',
      'Redemption',
      'Upload Assets',
      'Submit for Review',
    ] : [
      'Get Started',
      'Redemption',
      'Offer Information',
      'Upload Assets',
      'Submit for Review',
    ];
  const fields: { name: string }[] = [];
  redemptionTypes.forEach(type => {
    const attributes = RedemptionSchema[type as Redemption.Type].attributes ?? {};
    fields.push(...Object
      .keys(attributes.fields ?? {})
      .map(fieldKey => ({ name: `content.redemptions.${type}.attributes.${fieldKey}` })));
  });
  useEffect(() => {
    // If no id means its a form used for document creation.
    if (!docId && !!values['__form_id']) {
      const eventContext = { offer: values, stepName: steps[activeStep] };
      steps[activeStep] && event.dispatch('OFFER_CREATION_STEP_VIEWED', eventContext);
      activeStep > 0 && event.dispatch('OFFER_CREATION_STEP_COMPLETED', {
        ...eventContext,
        stepName: steps[activeStep - 1]
      });
    }
  }, [activeStep, values['__form_id']]);

  const initFieldsToValidate = [
    { name: 'brand' },
    { name: 'content.value' },
    { name: 'content.audience' },
  ];
  const redemptionFieldsToValidate = [
    { name: 'content.redemption_types' },
    ...fields,
  ];
  const mainFieldsToValidate = [
    { name: 'name' },
    { name: 'content.title' },
    { name: 'content.mcc_category' },
    { name: 'content.category' },
    { name: 'content.keywords' },
    { name: 'content.summary' },
    { name: 'content.details' },
    { name: 'content.description' },
    { name: 'content.testimonial.signature' },
    { name: 'content.testimonial.text' },
    { name: 'content.benefits.headline' },
    { name: 'content.benefits.description' },
  ];
  const assetFieldsToValidate = [
    { name: 'content.image.asset' },
    { name: 'content.image.alt' },
    { name: 'content.banner.image.asset' },
    { name: 'content.banner.image.alt' },
  ];

  const fieldsToValidate = docId ? [
    [...initFieldsToValidate, ...mainFieldsToValidate],
    redemptionFieldsToValidate,
    assetFieldsToValidate,
  ] : [
    initFieldsToValidate,
    redemptionFieldsToValidate,
    mainFieldsToValidate,
    assetFieldsToValidate,
  ];
  const redemptionTypeToggle = (type: string) => (open: boolean) => {
    if (!open && redemptionTypes.includes(type)) {
      setRedemptionTypes(redemptionTypes.filter((id) => id !== type));
    }
    else if (open) {
      setRedemptionTypes([...redemptionTypes, type]);
    }
  }
  useEffect(() => {
    if (dataLoaded) {
      if (data['content.redemptions.online.attributes.url']) {
        redemptionTypeToggle('online')(true);
      }
      if (data['meta.state.update']) {
        const update = data['meta.state.update'] || [];
        setStates(moderationStates.filter(state => update.includes(state.id)));
      }
    }
  }, [dataLoaded]);
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.titleWrapper}>
            <Typography
              className={classes.title}
              component="h2"
              variant="h2"
            >
              {data['id'] ? <>Manage <span>{data.name}</span></> : 'Create Offer'}
            </Typography>
            {!!data['id'] && (
              <State
                className={classes.state}
                state={moderationStates.find(state => data['state'] === state.id) as ModerationState}
              />
            )}
          </div>
          <Typography
            component="p"
            variant="body2"
          >
            {helperTexts.sub_title}
          </Typography>
          {!!data['id'] && (
            <Typography
              className={classes.lastUpdated}
              component="h2"
              variant="h2"
            >
              Last Updated: <span>{moment.unix(data['updated.on'] / 1000).format('MM/DD/YYYY - HH:mm')}</span>
            </Typography>
          )}
        </CardContent>
        {docId && 'needs_work' === data['state'] && (
          <Message>
            {(data['history'] ?? [])[0]?.comment || data['revision_log']}
          </Message>
        )}
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditSubmit(config))}>
            {!docId && (
              <TabPanel
                index={!offerId ? 0 : 9999}
                value={activeStep}
              >
                <div className={classes.formContent}>
                  {auth0.isAdmin() && (
                    <PageBlock>
                      <ServiceList
                        component={(items, loading) =>
                          <Select
                            description={helperTexts.company}
                            disabled={loading}
                            label="Company"
                            name="company"
                            options={items}
                          />
                        }
                        path="companies"
                        waitForLoad={false}
                      />
                    </PageBlock>
                  )}
                  {!!companyId && (
                    <PageBlock>
                      <ServiceList
                        component={(items, loading) =>
                          <Select
                            description={helperTexts.brand}
                            disabled={loading}
                            label="Choose Brand"
                            name="brand"
                            onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => setBrandId(e.target.value as string)}
                            options={items}
                            style={{ marginRight: 10 }}
                          />
                        }
                        condition={[
                          {
                            field: 'company',
                            value: companyId as string,
                            op: '=='
                          }
                        ]}
                        path="brands"
                        waitForLoad={false}
                      />
                    </PageBlock>
                  )}
                  <PageBlock>
                    <RadioGroup
                      helperText={helperTexts.offer_value}
                      label="Offer Value"
                      name="content.value"
                      options={[
                        { id: 'premium', name: 'Premium (offers with a high value, only available to WeSalute+ Users)' },
                        { id: 'basic', name: 'Basic (offers with lower value, available to all WeSalute Users)' },
                      ]}
                    />
                  </PageBlock>
                  <PageBlock>
                    <CheckboxGroup
                      helperText={helperTexts.audience}
                      label="Military Audience Segment"
                      name="content.audience"
                      options={audiences}
                    />
                  </PageBlock>
                </div>
              </TabPanel>
            )}
            <TabPanel
              index={1}
              value={activeStep}
            >
              <div className={classes.formContent}>
                <PageBlock>
                  <Hidden
                    className={classes.errorRedemptionTypes}
                    name="content.redemption_types"
                    value={redemptionTypes.join(',')}
                  />
                  {((!docId && !!values['__form_id']) || !!data['id']) && (
                    <ToggleBox
                      description={helperTexts.redemptions}
                      label="Online"
                      onChange={redemptionTypeToggle('online')}
                      open={!!data['content.redemptions.online.attributes.url']}
                    >
                      <RedemptionOnline namePrefix="content.redemptions.online." />
                    </ToggleBox>
                  )}
                </PageBlock>
                <PageBlock>
                  <ToggleBox
                    description="User will be able to redeem your offer via their credit card. The discount must match the offer title, description, and online redemption (if applicable)."
                    disabled
                    label="Credit Card-linked (COMING SOON)"
                  >
                    -
                  </ToggleBox>
                </PageBlock>
                <PageBlock>
                  <ToggleBox
                    description="Generate a deep link that directs users directly to your app to redeem the offer right in-app. The deep link would automatically open the app if it’s downloaded to the user’s phone, or open their app store to download."
                    disabled
                    label="Mobile App Deep-Link (COMING SOON)"
                  >
                    -
                  </ToggleBox>
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={!offerId ? 2 : 0}
              value={activeStep}
            >
              <div className={classes.formContent}>
                {!!docId && (
                  <PageBlock>
                    <div className={classes.settings}>
                      <Typography
                        className={classes.settingsTitle}
                        component="h3"
                        variant="h3"
                      >
                        Offer settings
                      </Typography>
                      <PageBlock adminView>
                        <Select
                          defaultValue="draft"
                          label="State"
                          name="state"
                          options={states}
                        />
                        {'needs_work' === values['state'] && (
                          <TextField
                            label="Comment"
                            multiline
                            name="log"
                            required
                            rows={2}
                            rowsMax={4}
                          />
                        )}
                      </PageBlock>
                      <PageBlock adminView>
                        <GridContainer>
                          {!!platformUrl && (
                            <Grid col={7}>
                              <TextField
                                className={classes.domainUrl}
                                disabled
                                label="Domain URL"
                                name="domain_url"
                                value={platformUrl.split('/b2b/')[0]}
                                variant="filled"
                              />
                            </Grid>
                          )}
                          <Grid col={platformUrl ? 5 : 12}>
                            <TextField
                              label="Custom URL Path"
                              name="content.url_alias"
                              placeholder="/"
                            />
                          </Grid>
                        </GridContainer>
                      </PageBlock>
                      <PageBlock>
                        <RadioGroup
                          helperText={helperTexts.offer_value}
                          label="Offer Value"
                          name="content.value"
                          options={[
                            { id: 'premium', name: 'Premium (offers with a high value, only available to WeSalute+ Users)' },
                            { id: 'basic', name: 'Basic (offers with lower value, available to all WeSalute Users)' },
                          ]}
                        />
                      </PageBlock>
                      <PageBlock>
                        <CheckboxGroup
                          helperText={helperTexts.audience}
                          label="Military Audience Segment"
                          name="content.audience"
                          options={audiences}
                        />
                      </PageBlock>
                    </div>
                  </PageBlock>
                )}
                <PageBlock>
                  <TextField
                    helperText={helperTexts.name}
                    label="Offer Name"
                    name="name"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={helperTexts.title}
                    label="Offer Subtitle"
                    name="content.title"
                    required
                  />
                </PageBlock>
                {(!!docId && !!data['brand']) && (
                  <PageBlock>
                    <Hidden
                      name="brand"
                      value={data['brand']}
                    />
                  </PageBlock>
                )}
                <PageBlock>
                  <Autocomplete
                    description={helperTexts.mcc_category}
                    label="Merchant Category Code"
                    name="content.mcc_category"
                    options={merchantCategories}
                    required
                  />
                </PageBlock>

                <PageBlock>
                  <RichEditor
                    description={helperTexts.summary}
                    label="Summary"
                    name="content.summary"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <MultipleTextField
                    addMoreText="Add More Details"
                    helperText={helperTexts.details}
                    label="Details"
                    name="content.details"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <RichEditor
                    description={helperTexts.terms_conditions}
                    label="Terms and Conditions"
                    name="content.terms_conditions"
                  />
                </PageBlock>
                <PageBlock>
                  <FieldWrapper
                    helperText={helperTexts.testimonial}
                    label="Testimonials"
                    name="content.testimonial"
                  >
                    <TextField
                      className={classes.marginBottom}
                      label="Author"
                      name="content.testimonial.signature"
                    />
                    <RichEditor
                      label="Quote"
                      name="content.testimonial.text"
                    />
                  </FieldWrapper>
                </PageBlock>
                <PageBlock>
                  <FieldWrapper
                    helperText={helperTexts.benefits}
                    label="Benefits Multiplier"
                    name="content.benefits"
                  >
                    <TextField
                      className={classes.marginBottom}
                      label="Title"
                      name="content.benefits.headline"
                    />
                    <RichEditor
                      label="Description"
                      name="content.benefits.description"
                    />
                  </FieldWrapper>
                </PageBlock>
                <PageBlock adminView>
                  <Autocomplete
                    defaultValue={[]}
                    description="To add a new keyword press Enter."
                    freeSolo
                    label="Keywords"
                    multiple
                    name="content.keywords"
                    options={[]}
                    renderTags={(value: string[], getTagProps: AutocompleteGetTagProps) =>
                      value.map((option: string, index: number) => (
                        <Chip
                          key={index}
                          label={option}
                          variant="outlined"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    style={{ width: '100%' }}
                  />
                </PageBlock>
                <PageBlock adminView>
                  <TextField
                    defaultValue={0}
                    helperText={helperTexts.weight}
                    label="Offer Weight"
                    name="weight"
                    type="number"
                  />
                </PageBlock>
                <PageBlock adminView>
                  <Reward
                    categories={categories}
                    name="content.category"
                    required
                  />
                </PageBlock>
                <PageBlock adminView>
                  <Reference
                    Component={BrandList}
                    entityType="brands"
                    label="Exclude Brand Offer"
                    name="content.competing_brands"
                    selectLabel="Select a Brand"
                  />
                </PageBlock>
                <PageBlock adminView>
                  <Reference
                    Component={OfferList}
                    componentProps={{
                      company: values['company'] || data['company'],
                    }}
                    entityType="offers"
                    label="Override Brand Offer"
                    name="content.related_offers"
                    selectLabel="Select an Offer"
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={!offerId ? 3 : 2}
              value={activeStep}
            >
              <div className={classes.formContent}>
                <PageBlock>
                  {!!values['brand'] && (
                    <AssetImage
                      entityId={values['brand']}
                      entityType="brand"
                      helperText={helperTexts.image}
                      label="Offer Tile Image"
                      name="content.image"
                      required
                    />
                  )}
                </PageBlock>
                <PageBlock>
                  {!!values['brand'] && (
                    <AssetImage
                      entityId={values['brand']}
                      entityType="brand"
                      helperText={helperTexts.banner}
                      label="Offer page hero image"
                      name="content.banner.image"
                    />
                  )}
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={helperTexts.banner_title}
                    label="Hero Title"
                    name="content.banner.title"
                  />
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={helperTexts.banner_description}
                    label="Hero Subtitle"
                    multiline
                    name="content.banner.description"
                    rows={6}
                    rowsMax={6}
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={!offerId ? 4 : 3}
              value={activeStep}
            >
              <PageBlock>
                <OfferPreview document={values} />
              </PageBlock>
            </TabPanel>
            <TabPanel
              index={!offerId ? 5 : 9999}
              value={activeStep}
            >
              <GridContainer>
                <Grid>
                  <Typography
                    component="h2"
                    variant="h5"
                  >
                    Congrats! Your new offer is submitted for review.
                  </Typography>
                  <div style={{ paddingLeft: 48, paddingRight: 48, paddingTop: 16 }}>
                    <div style={{ padding: '20px 0' }}>
                      <Typography
                        component="h2"
                        paragraph
                        variant="h5"
                      >
                        <State
                          state={{
                            id: 'pending',
                            name: `${offer?.name} is pending review`,
                            color: '#ccc'
                          }}
                        />
                      </Typography>
                      <Typography
                        component="h2"
                        paragraph
                        variant="h5"
                      >
                        <State
                          state={{
                            id: 'pending',
                            name: 'Remption channel is pending review',
                            color: '#ccc'
                          }}
                        />
                      </Typography>
                      <Typography
                        component="h2"
                        paragraph
                        variant="h5"
                      >
                        <State
                          state={{
                            id: 'pending',
                            name: `${brand?.name} is pending review`,
                            color: '#ccc'
                          }}
                        />
                      </Typography>
                    </div>
                    <Typography
                      component="h2"
                      paragraph
                      variant="h5"
                    >
                      What now?
                    </Typography>
                    <Typography
                      component="p"
                      paragraph
                      variant="body1"
                    >
                      1. We are reviewing your offer, this final review ensures your offer is set up for success.
                      Our review typically takes about 1-3 hours, although it can take up to 24 hours.
                      We’ll send you an email when your review is complete.
                    </Typography>
                    <Typography
                      component="p"
                      paragraph
                      variant="body1"
                    >
                      2. To check the status of your offer’s review, go to <Link to="/offers">manage your offers</Link>.
                    </Typography>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      color="primary"
                      component={Link}
                      to="/offers"
                      variant="contained"
                    >
                      Go to offer management
                    </Button>
                  </div>
                </Grid>
              </GridContainer>
            </TabPanel>
            <div className={classes.actions}>
              {(activeStep > 0 && activeStep < steps.length) ? (
                <Button
                  className={clsx(classes.button, classes.formButtonMedium)}
                  color="primary"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveStep(activeStep - 1);
                    clearError();
                  }}
                  variant="outlined"
                >
                  Back
                </Button>
              ) : null}
              {(activeStep < steps.length - 1) ? (
                <Button
                  className={clsx(classes.button, classes.formButtonLarge)}
                  color="primary"
                  data-testid="submit-next"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const result = await triggerValidation(fieldsToValidate[activeStep], true);
                    result ? setActiveStep(activeStep + 1) : setErrorsTriggered(triggered => triggered + 1);
                  }}
                  variant="contained"
                >
                  Next step
                </Button>
              ) : null}
              {(activeStep === steps.length - 1) && (
                <>
                  <Button
                    className={classes.formButtonLarge}
                    color="primary"
                    data-testid="submit-save"
                    disabled={saveLoading}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </form>
        </FormContext>
      </Card>
    </div>
  );
};

export default withRouter(OfferCreate);
