import { accountUpdated } from 'services/typewriter';
import { flatten } from 'inc/utils';
import {
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const company = flatten(values);
  window.analytics.group(company['id'], {
    company: segmentCompanyObject(company),
  });
  accountUpdated({
    context: segmentContextObject(company),
    company: segmentCompanyObject(company),
  });
};
