import custom from '../custom';

export default {
  root: {
    color: custom.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: custom.slate,
    borderRadius: '50%',
    '&$active': {
      color: custom.slate,
      '& $text': {
        fill: custom.white
      },
    },
    '&$completed': {
      color: custom.grayMedium,
      borderColor: custom.grayMedium
    }
  },
  text: {
    fill: custom.slate
  }
};
