import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

type Props = {
  className?: string,
};

const Footer = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    />
  );
};

export default Footer;
