import api from 'services/api';
import B2b from './api/providers/B2b';

type DiscountSettingsData = {
  id?: string,
  amount_off: number,
  percent_off: number,
  products: string[],
}

/**
 * MRP Pricing class.
 */
class MrpPricing {

  /**
   * An object contains products pricing.
   */
  public static pricingDefault = {
    sub: 0,
    tp: 0,
    fee: 0,
  }

  /**
   * An object contains products pricing.
   */
  private pricing: PricingData = MrpPricing.pricingDefault;

  /**
   * An object contains products pricing.
   */
  private discountSettings: DiscountSettingsData = {
    amount_off: 0,
    percent_off: 0,
    products: [],
  };

  /**
   * Loads products pricing.
   */
  async loadPricing(discountId?: string, reset = false) {
    discountId && await this.loadDiscount(discountId, reset);
    if (this.pricing.sub && !reset) {
      return { pricing: this.pricing, discount: this.discountSettings };
    }
    const { products: {
      subscription = {},
      travel_protection = {},
      processing_fee = {}
    } = {} } = await (api.provider('b2b') as B2b).mrpPricing() ?? {};
    this.pricing = {
      sub: parseInt(subscription.price, 10) / 100,
      tp: parseInt(travel_protection.price, 10) / 100,
      fee: parseInt(processing_fee.price, 10) / 100,
    }
    return { pricing: this.pricing, discount: this.discountSettings };
  }

  /**
   * Loads products pricing.
   */
  async loadDiscount(discountId: string, reset = false) {
    if (this.discountSettings.id && this.discountSettings.id === discountId && !reset) {
      return;
    }
    const {
      id,
      amount_off = 0,
      percent_off = 0,
      products = [],
    } = await (api.provider('b2b') as B2b).mrpDiscounts(discountId) ?? {};
    this.discountSettings = {
      id,
      amount_off,
      percent_off,
      products,
    };
  }

  /**
   * Returns a price for the specific product.
   */
  discountable(type?: keyof PricingData) {
    const { products, percent_off, amount_off } = this.discountSettings;
    if (!type) {
      return amount_off || percent_off;
    }
    return !products.length || products.includes(type);
  }

  /**
   * Returns a price for the specific product.
   */
  discount(type: keyof PricingData) {
    const { percent_off, amount_off } = this.discountSettings;
    return percent_off
      ? this.price(type) * (percent_off / 100)
      : amount_off;
  }

  /**
   * Returns a price for the specific product.
   */
  price(type: keyof PricingData) {
    return this.pricing[type];
  }

  /**
   * Returns a price for the specific product.
   */
  priceoff(type: keyof PricingData) {
    const { percent_off, amount_off } = this.discountSettings;
    return percent_off
      ? this.price(type) * ((100 - percent_off) / 100)
      : this.price(type) - amount_off;
  }

  /**
   * Returns a sum of all products.
   */
  sum(type: 'price' | 'discount' | 'priceoff', total: number, exclude: { [key in keyof PricingData]?: boolean } = {}) {
    return total * Object.keys(this.pricing).reduce((prev, current) => {
      if (exclude[current as keyof PricingData]) {
        return prev;
      }
      return prev + this[type](current as keyof PricingData);
    }, 0);
  }

}

export default new MrpPricing();
