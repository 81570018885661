import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import useDefaultValue from 'inc/hooks/useDefaultValue';
import { useDropzone } from 'react-dropzone';
import FieldWrapper from './FieldWrapper';

type Props = {
  label?: string,
  name?: string,
  required?: boolean,
};

const useStyles = makeStyles(() => ({
  root: {
    border: '1px dashed #ccc',
    padding: '35px 10px',
    textAlign: 'center',
  }
}));

const Asset = ({ label, name = '', required }: Props) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState('');
  const { setValue } = useFormContext();
  useDefaultValue(`${name}file`, (data: Document.Base) => {
    setValue(`${name}file`, data[`${name}file`]);
    setPreviewUrl(data[`${name}file`]);
  });
  useDefaultValue(`${name}filename`, (data: Document.Base) => {
    setValue(`${name}filename`, data[`${name}filename`])
  });
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      setPreviewUrl(URL.createObjectURL(file));
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setValue(`${name}file`, window.btoa(reader.result as string));
        setValue(`${name}filename`, file.name);
      }
      // @todo replace with more stable approach.
      reader.readAsBinaryString(file);
    })
  }, [])
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    multiple: false,
    onDrop,
  });
  return (
    <FieldWrapper
      label={label}
      name={name}
      required={required}
    >
      <div {...getRootProps({className: classes.root})}>
        <input {...getInputProps()} />
        {!previewUrl && (
          <>
            <h3>Drag & Drop</h3>
            <p>your files here</p>
          </>
        )}
        {!!previewUrl && (
          <p>
            <img
              src={previewUrl}
              width="180"
            />
          </p>
        )}
      </div>
    </FieldWrapper>
  )
};

export default Asset;
