import React from 'react';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import TablePagination from 'components/Table';
import ListToolbar from 'components/Display/ListToolbar';
import useServiceTable from 'inc/hooks/useServiceTable';

const ProgramList = () => {
  const { fetchItems } = useServiceTable({
    path: 'mrps',
  });
  const fields = [
    {
      id: 'name',
      label: 'Program Title',
      sortable: true,
      width: '40%',
    },
    {
      id: 'updated.on',
      label: 'Updated',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
    {
      id: '__type',
      label: 'Type',
      prepare: () => {
        return 'MRP';
      },
    },
  ];
  const listActions = [
    {
      label: 'Edit',
      url: (item: Document.Base) => `/programs/edit/${item.id}`,
    }
  ];
  return (
    <div>
      <Typography
        component="h2"
        paragraph
        variant="h3"
      >
        Manage Programs
      </Typography>
      <Typography
        gutterBottom
        paragraph
        variant="subtitle2"
      >
        WeSalute Offers are the primary value drivers to the greater military community, created by you and delivered by us.
        Offers are the backbone of our partnerships and our goal is to give you the tools to create quality offers.
      </Typography>
      <ListToolbar
        buttons={[{
          name: 'Create New Program',
          path: '/programs/create'
        }]}
      />
      <TablePagination
        actions={listActions}
        fetch={fetchItems}
        fields={fields}
      />
    </div>
  );
};

export default ProgramList;
