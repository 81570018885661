import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type Props = {
  children: React.ReactNode,
  className?: string,
}

const useStyles = makeStyles((theme: Theme) => ({
  readOnly: {
    fontSize: 18,
    lineHeight: 1.55,
    color: theme.custom.black,
    paddingTop: 10,
    paddingBottom: 10,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.custom.grayMedium,
    '& img': {
      maxWidth: '100%',
      height: 'auto'
    }
  }
}));

function ReadOnly({ children, className }: Props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.readOnly, className)}>
      {children}
    </div>
  );
}

export default ReadOnly;
