import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import moderationStates from 'data/states.json';
import Config from 'services/Config';
import useDialog from 'inc/hooks/useDialog';
import Dialog from 'containers/dialogs/Dialog';
import api from 'services/api';
import store from 'inc/store';
import B2b from 'services/api/providers/B2b';
import auth0 from 'services/Auth0';
import Typography from '@material-ui/core/Typography';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import {
  TextField,
} from 'components/Form';
import audiences from 'data/audiences.json';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

type Props = {
  data: Data,
  docId: string,
  setData: (data: Data) => void,
  values: Data
}

const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: theme.custom.divider,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  root: {
    padding: '10px 30px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.custom.slate,
    borderRadius: 3,
    marginBottom: theme.spacing(5),
    fontFamily: 'Akkurat Mono LL, monospace'
  },
  row: {
    margin: theme.spacing(2, 0),
    fontSize: 18,
    color: theme.custom.black
  },
  head: {
    paddingRight: 10,
    whiteSpace: 'nowrap',
  },
  button: {
    fontSize: 16,
    padding: '8px 15px',
  },
  buttonEdit: {
    padding: '9px 15px',
    marginRight: 10
  },
  buttonPublish: {
    marginRight: 10
  },
  buttonActionsWrapper: {
    marginRight: 10
  },
  buttonActions: {
    marginRight: 0
  },
  url: {
    color: theme.custom.grayDark,
    wordBreak: 'break-word',
  },
  title: {
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: 18,
    fontWeight: 400,
    margin: '10px 0 10px auto',
    letterSpacing: 0,
    '& span': {
      color: theme.custom.grayDark,
    }
  },
  dialogTitle: {
    textAlign: 'center',
    fontSize: 48,
    lineHeight: 1.2,
    letterSpacing: '-0.05em',
    marginBottom: theme.spacing(2),

  },
  dialogContent: {
    textAlign: 'center',
    fontSize: 16,
    lineHeight: 1.5,
  },
}));

function Moderation({ data, docId, setData, values }: Props) {
  const classes = useStyles();
  const [states, setStates] = useState(moderationStates);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getValues } = useFormContext();
  const moderateDocument = async (data: Data, handleClose: () => void, setLoading: (loading: boolean) => void) => {
    const { id, comment } = data;
    const { name }: ModerationState = (moderationStates.find(state => id === state.id) as ModerationState) ?? {};
    setLoading(true);
    try {
      let values: Data;
      if ('request_unpublish' === id) {
        values = await (api.provider('b2b') as B2b).requestUnpublish('offers', docId);
        setData((data: Data) => ({...data, ['unpublish.requested']: !!values?.unpublish?.requested}));
      }
      else if ('unpublish' === id) {
        values = await (api.provider('b2b') as B2b).unpublish('offers', docId);
        setData((data: Data) => ({...data, state: values['state'], published: !!values['published']}));
      }
      else {
        values = await (api.provider('b2b') as B2b).moderate('offers', docId, {
          id,
          name,
          state: id,
          comment: comment || `Transition to ${name}`,
        });
        setData((data: Data) => ({...data, history: values['history'], state: id}));
      }
      setLoading(false);
      store('page.message').set({ text: 'The item has been moderated', type: 'success' });
      const update = values?.meta?.state?.transit ?? [];
      setStates(moderationStates.filter(state => update.includes(state.id)));
      handleClose();
    }
    catch (err) {
      setLoading(false);
      handleClose();
      err.message && store('page.message').set({ text: err.message, type: 'error' });
    }
  }
  const { openDialog, dialogProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        await moderateDocument(data, handleClose, setLoading);
      }
    },
    title: 'Confirmation',
  });
  const { openDialog: openPublishDialog, dialogProps: dialogPublishProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        await moderateDocument({ id: 'ready_for_review' }, handleClose, setLoading);
      }
    },
    title: `Publish ${data['name']}`,
  });
  const { openDialog: openReqUnpublishDialog, dialogProps: dialogReqUnpublishProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        await moderateDocument({ id: 'request_unpublish' }, handleClose, setLoading);
      }
    },
    title: `Unpublish ${data['name']}`,
  });
  const { openDialog: openUnpublishDialog, dialogProps: dialogUnpublishProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        await moderateDocument({ id: 'unpublish' }, handleClose, setLoading);
      }
    },
    title: `Unpublish ${data['name']}`,
  });
  const { openDialog: openUpdatesDialog, dialogProps: dialogUpdatesProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        const values = getValues();
        await moderateDocument({ id: 'needs_work', comment: values['updates.description'] }, handleClose, setLoading);
      }
    },
    title: 'Updates Required',
  });
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStateClick = (state: ModerationState) => () => {
    setAnchorEl(null);
    'needs_work' === state.id ? openUpdatesDialog(state) : openDialog(state);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (values[FORM_STATE_LOADED] && values[FORM_STATE_DATA]['meta.state.transit']) {
      const update = values[FORM_STATE_DATA]['meta.state.transit'];
      setStates(moderationStates.filter(state => update.includes(state.id)));
    }
  }, [values[FORM_STATE_LOADED]]);
  const [platformUrl, setPlatformUrl] = useState('');
  useEffect(() => {
    (async () => {
      setPlatformUrl(await Config.getAsync('platformUrl'));
    })();
  }, []);
  return (
    <>
      <div className={classes.actionsWrapper}>
        <Button
          className={clsx(classes.button, classes.buttonEdit)}
          color="primary"
          component={NavLink}
          to={`/offers/create/${docId}`}
          variant="contained"
        >
          Edit Offer
        </Button>
        {(!auth0.isAdmin() && !!data['id'] && !data['published'] && ['draft', 'needs_work'].includes(data['state'])) && (
          <Button
            className={clsx(classes.button, classes.buttonPublish)}
            color="primary"
            onClick={() => openPublishDialog({})}
            variant="outlined"
          >
            Request to Publish
          </Button>
        )}
        {(!auth0.isAdmin() && !!data['published']) && (
          <Button
            className={clsx(classes.button, classes.buttonPublish)}
            color="primary"
            disabled={!!data['unpublish.requested']}
            onClick={() => openReqUnpublishDialog({})}
            variant="outlined"
          >
            {data['unpublish.requested'] ? 'Unpublish has been requested' : 'Request to Unpublish'}
          </Button>
        )}
        {(auth0.isAdmin() && !!data['published']) && (
          <Button
            className={clsx(classes.button, classes.buttonPublish)}
            color="primary"
            onClick={() => openUnpublishDialog({})}
            variant="outlined"
          >
            Unpublish
          </Button>
        )}
        {auth0.isAdmin() && (
          <span className={classes.buttonActionsWrapper}>
            <Button
              className={clsx(classes.button, classes.buttonActions)}
              color="primary"
              onClick={handleClick}
              variant="outlined"
            >
              Actions <ArrowDropDown/>
            </Button>
            <Menu
              anchorEl={anchorEl}
              id="simple-menu"
              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}
            >
              {states.map(state => (
                <MenuItem
                  key={state.id}
                  onClick={handleStateClick(state)}
                >
                  {state.name}
                </MenuItem>
              ))}
            </Menu>
          </span>
        )}
        {!!data['id'] && (
          <Typography
            className={classes.title}
            component="h2"
            variant="h2"
          >
            Last Updated: <span>{moment.unix(data['updated.on'] / 1000).format('MM/DD/YYYY - HH:mm')}</span>
          </Typography>
        )}
      </div>
      <div className={classes.root}>
        {(!!data['published'] && !!platformUrl) && (
          <div className={classes.row}>
            <span className={classes.head}>
              Offer URL:
            </span>
            <span className={classes.url}>
              {platformUrl
                .replace('{id}', docId)
                .replace('{type}', 'offer')
              }
            </span>
          </div>
        )}
        <div className={classes.row}>
          <span className={classes.head}>
            Offer Value:
          </span>
          <span className={classes.url}>
            {data['content.value']}
          </span>
        </div>
        <div className={classes.row}>
          <span className={classes.head}>
            Audience:
          </span>
          <span className={classes.url}>
            {(data['content.audience'] ?? []).map((aid: string) => audiences.find(audience => aid === audience.id)?.name).join(', ')}
          </span>
        </div>
        {!!data['unpublish.requested'] && (
          <div className={classes.row}>
            <span className={classes.head}>
              Unpublish Requested:
            </span>
            <span className={classes.url}>
              Yes
            </span>
          </div>
        )}
      </div>
      <Dialog
        {...dialogProps}
        component={(data) => {
          return (
            <div>Are you sure you want to move this document to the state <strong>{data.name}</strong>.</div>
          );
        }}
      />
      <Dialog
        {...dialogPublishProps}
        component={() => {
          return (
            <div>Are you sure you want to Publish this document.</div>
          );
        }}
      />
      <Dialog
        {...dialogUnpublishProps}
        component={() => {
          return (
            <div>Are you sure you want to Unpublish this document.</div>
          );
        }}
      />
      <Dialog
        {...dialogReqUnpublishProps}
        component={() => {
          return (
            <>
              <Typography
                className={classes.dialogTitle}
                component="h2"
                variant="h2"
              >
                Are you sure you want to unpublish this offer?
              </Typography>
              <div className={classes.dialogContent}>
                When your request to unpublish this offer is processed, the offer will be removed
                from the Marketplace and the URL will redirect the Marketplace homepage.
                Your offer will remain available for republishing here in your Business Manager.
              </div>
            </>
          );
        }}
      />
      <Dialog
        {...dialogUpdatesProps}
        component={() => {
          return (
            <div>
              <TextField
                helperText="Briefly explain the updates needed to make this offer acceptable for publishing."
                label="Description"
                multiline
                name="updates.description"
                required
                rows={2}
                rowsMax={4}
              />
            </div>
          );
        }}
      />
    </>
  );
}

export default Moderation;
