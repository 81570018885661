import { useEffect, useState } from 'react';
import auth0 from 'services/Auth0';

const useCompanyId = () => {
  const [companyId, setCompanyId] = useState('');
  useEffect(() => {
    (async () => {
      const company = await auth0.company();
      setCompanyId(company ? company.id : '');
    })();
  }, []);
  return companyId;
}

export default useCompanyId;
