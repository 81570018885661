import { offerCreationStepCompleted } from 'services/typewriter';

import {
  getEntity,
  segmentBrandObject,
  segmentOfferObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ offer, stepName }: Data) => {
  const brand = await getEntity('brands', offer['brand']);
  const company = await getEntity('companies', brand['company']);
  offerCreationStepCompleted({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
    offer: segmentOfferObject(offer),
    stepName,
  });
};
