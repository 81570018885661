import React, { useState } from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Card from '@material-ui/core/Card';
import TablePagination from 'components/Table';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  ApiProgress,
  TabPanel,
} from 'components/Display';
import {
  Select,
  TextField,
} from 'components/Form';
import { Grid, GridContainer } from 'components/Layout';
import { ServiceEntity } from 'containers';
import useFormCrud from 'inc/hooks/useFormCrud';
import useMrpPricing from 'inc/hooks/useMrpPricing';
import { MrpAddCodesSchema } from 'inc/validation';
import useDialog from 'inc/hooks/useDialog';
import useServiceTable from 'inc/hooks/useServiceTable';
import Invoice from './components/Invoice';
import ThankYou from './components/ThankYou';
import api from 'services/api';
import B2b from 'services/api/providers/B2b';
import useObservable from 'inc/hooks/useObservable';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import { FORM_STATE_DATA } from 'inc/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import DoneIcon from '@material-ui/icons/Done';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Alert from '@material-ui/lab/Alert';
import fileDownload from 'js-file-download';
import Dialog from 'containers/dialogs/Dialog';
import customStyles, { alertStyles } from '../../theme/styles';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '@material-ui/icons/Error';
import { PageBlock } from 'components/Layout';
import MrpPricing from 'services/MrpPricing';
import { priceFormat, couponsFileName } from 'inc/utils';

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  ...alertStyles(theme),
  container: {
  },
  header: {
    marginBottom: '50px'
  },
  headerRight: {
    marginBottom: '50px',
    textAlign: 'right'
  },
  graySummary: {
    background: theme.custom.grayBox,
    padding: '22px 15px',
    fontSize: '18px',
    lineHeight: '160%',
    '& button': {
      marginTop: '20px'
    }
  },
  graySummaryTitle: {
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: '12px',
    lineHeight: '1.2',
    letterSpacing: '0.16em',
    textTransform: 'uppercase'
  },
  graySummaryNumber: {
    fontSize: '48px',
    letterSpacing: '-0.05em',
    lineHeight: '140%',
    '& > span': {
      fontSize: '32px',
      lineHeight: '32px',
      display: 'inline-block',
      paddingBottom: '13px',
      verticalAlign: 'bottom'
    }
  },
  pricingRateForm: {
    alignItems: 'flex-start'
  },
  pricingRateFormButton: {
    marginTop: 22
  },
  subTitle: {
    margin: '70px 0 35px'
  },
  dataList: {
    fontSize: '18px',
    lineHeight: '155%',
    marginBottom: '26px',
    '& label': {
      fontFamily: 'Akkurat Mono LL, monospace',
      fontSize: '12px',
      lineHeight: '140%',
      textTransform: 'uppercase',
      letterSpacing: '0.16em',
    }
  },
  modalButton: {
    display: 'block',
    margin: '45px auto 0',
    width: 246,
    fontSize: '18px',
    lineHeight: '160%',
  },
  modalButtonXSmall: {
    marginTop: 24,
  },
  modalPricesWrapper: {
    marginTop: 15,
  },
  modalPrices: {
    fontSize: 16,
    lineHeight: '155%',
    color: theme.custom.grayDarkest,
    marginTop: 6,
  },
  modalTotalPriceWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: '18px',
    lineHeight: '120%',
    letterSpacing: '-0.05em',
  },
  modalTotalPrice: {
    fontSize: '32px',
    lineHeight: '120%',
    letterSpacing: '-0.05em',
    marginTop: '24px',
    marginBottom: '-3px',
  },
  modalCopyright: {
    fontFamily: 'Arial',
    fontStyle: 'italic',
    fontSize: '10px',
    lineHeight: '16px',
    margin: '35px 0',
  },
  recipientsWrapper: {
    display: 'flex',
  },
  recipientsIcon: {
    marginTop: 36,
    marginLeft: 20,
  },
}));

type Props = {
  className?: string,
} & RouteComponentProps<{ programId?: string }>;

const ProgramEdit = ({ match }: Props) => {
  const [batchesCreated, setBatchesCreated] = useState(0);
  const [dialogStep, setDialogStep] = useState(0);
  const [alertOpen, setAlertOpen] = React.useState(true);
  const [editable, setEditable] = useState(false);
  const [saveStatus, setSaveStatus] = useState<{[k: string]: number}>({test: 1});
  const classes = useStyles();
  const loading = useObservable('data.loading');
  const programId = match.params['programId'] || '';
  const { handleEditSubmit, methods, values, triggerValidation } = useFormCrud({
    id: programId,
    path: 'mrps',
    schema: MrpAddCodesSchema,
    process: (data) => {
      return data;
    }
  });
  const data = values[FORM_STATE_DATA] || {};
  const coupons = parseInt(values['coupons_total'] || 0, 10);
  const programTitle = values['content.title'];
  useMrpPricing();
  const config = {
    requests: {
      after: (program: Document.Base) => {
        const requests: Promise<any>[] = [];
        if (program.id) {
          requests.push(api.path('mrp-batches').save({
            id: '1',
            name: '1',
            mrp: program.id,
            coupons,
          }));
        }
        return requests;
      }
    }
  };
  const { fetchItems } = useServiceTable({
    path: 'mrp-batches',
  });
  const { openDialog: openInvoiceDialog, dialogProps: invoiceDialogProps } = useDialog({
    title: 'Program Invoice',
    hideHeader: true,
  });
  const { openDialog: openAddCodesDialog, dialogProps: addCodesDialogProps } = useDialog({
    title: 'Add more codes',
    onOpen: () => { setDialogStep(0) },
    width: dialogStep === 0 ? 'xs' : 'md',
  });
  const fields = [
    {
      id: 'invoice.id',
      label: 'Invoice ID',
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      prepare: (value: boolean, item: Document.Base) => {
        return (
          <a
            className={'blue'}
            href={item['invoice.hosted_invoice_url']}
            onClick={async (e) => {
              e.preventDefault();
              openInvoiceDialog({
                coupons: item['coupons'],
                travelProtection: data['travel.protection'],
              });
            }}
            rel="noopener noreferrer"
            target="_blank"
          >
            {value}
          </a>
        );
      },
      sortable: true,
      width: '40%',
    },
    {
      id: 'created.on',
      label: 'Time',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM.DD.YYYY') : '';
      },
      sortable: true,
    },
    {
      id: 'coupons',
      label: 'Codes',
    },
    {
      id: 'invoice.paid',
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      prepare: (value: boolean, item: Document.Base) => {
        return value ? 'Paid' : (
          <a
            href={item['invoice.hosted_invoice_url']}
            rel="noopener noreferrer"
            style={{ alignItems: 'flex-start', color: '#FF3C5F', display: 'flex' }}
            target="_blank"
          >
              Unpaid <CreditCardIcon style={{ fontSize: 20, marginLeft: 5 }} />
          </a>
        );
      },
      label: 'Status',
    },
  ];
  const listActions = [
    {
      external: true,
      label: 'Download',
      class: 'blue',
      url: (item: Document.Base) => item['invoice.invoice_pdf'],
    },
  ];
  const travelProtection = !!data['config.travel_protection'];
  const couponsUsed = data['coupons.redeemed'] || 0;
  const couponsGenerated = data['coupons.generated'] || 0;
  const couponsTotal = methods.watch('coupons_total') || 0;
  const recipients: Data = {
    employees: 'Employees',
    customers: 'Customers',
    member_organizations: 'Member Organization',
  };
  return (
    <div className={classes.root}>
      {!!data['id'] && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
        >
          <ServiceEntity
            component={(entity) =>
              <span>{!!entity && entity.name}</span>
            }
            fromCache
            id={data['company']}
            path="companies"
          />
          <ServiceEntity
            component={(entity) =>
              <span>{!!entity && entity.name}</span>
            }
            fromCache
            id={data['brand']}
            path="brands"
          />
          <span>{data.name}</span>
        </Breadcrumbs>
      )}
      <Dialog
        {...invoiceDialogProps}
        component={(data) => {
          return (
            <Invoice
              coupons={data.coupons}
              travelProtection={travelProtection}
            />
          );
        }}
      />
      <Card className={classes.card}>
        <ApiProgress />
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditSubmit(config))}>
            <Dialog
              {...addCodesDialogProps}
              closeTitle={2 === dialogStep ? 'Done' : 'Cancel'}
              component={() => {
                return (
                  <>
                    <TabPanel
                      index={0}
                      value={dialogStep}
                    >
                      <PageBlock>
                        <TextField
                          helperTextTooltip={
                            <span>
                              Pretty self-explanitory! *Note - this can be the same as your company if your brand and company are the same. View example.
                            </span>
                          }
                          inputProps={{ min: 1, max: 10000 }}
                          label="Code Amount"
                          name="coupons_total"
                          required
                          type="number"
                        />
                        <div className={classes.modalPricesWrapper}>
                          <div className={classes.modalPrices}>
                            ${priceFormat(MrpPricing.price('sub'))} per user
                          </div>
                          {travelProtection && (
                            <div className={classes.modalPrices}>
                            ${priceFormat(MrpPricing.price('tp'))} travel protection per user
                            </div>
                          )}
                          <div className={classes.modalPrices}>
                            ${priceFormat(MrpPricing.price('fee'))} fee per user
                          </div>
                          <div className={classes.modalTotalPriceWrapper}>
                            <span>Grand Total</span> <span className={classes.modalTotalPrice}>${priceFormat(MrpPricing.sum('price', couponsTotal, { tp: !travelProtection }))}</span>
                          </div>
                        </div>
                      </PageBlock>
                      <Button
                        className={classes.modalButton + ' ' + classes.modalButtonXSmall}
                        color="primary"
                        onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const result = await triggerValidation([{ name: 'coupons_total' }], true);
                          result && setDialogStep(1);
                        }}
                        variant="contained"
                      >
                        Next
                      </Button>
                      <div className={classes.modalCopyright}>
                        Travel protection fee will be applied.
                      </div>
                    </TabPanel>
                    <TabPanel
                      index={1}
                      value={dialogStep}
                    >
                      <Invoice
                        coupons={parseInt(values['coupons_total'], 10) || 0}
                        travelProtection={travelProtection}
                      />
                      <Button
                        className={classes.modalButton}
                        color="primary"
                        disabled={!!saveStatus['dialog.save']}
                        onClick={async () => {
                          setSaveStatus(status => ({...status, 'dialog.save': 1}));
                          await api.path('mrp-batches').save({
                            id: '1',
                            name: '1',
                            mrp: programId,
                            coupons: parseInt(values['coupons_total'], 10) || 0,
                          });
                          setBatchesCreated(batchesCreated + 1)
                          setSaveStatus(status => ({...status, 'dialog.save': 0}));
                          setDialogStep(2);
                        }}
                        variant="contained"
                      >
                        {!saveStatus['dialog.save'] && (
                          <>Submit</>
                        )}
                        {saveStatus['dialog.save'] === 1 && (
                          <CircularProgress style={{ height: '24px', width: '24px' }} />
                        )}
                      </Button>
                    </TabPanel>
                    <TabPanel
                      index={2}
                      value={dialogStep}
                    >
                      <ThankYou
                        coupons={values['coupons_total']}
                        program={data}
                      />
                    </TabPanel>
                  </>
                );
              }}
            />
            <TabPanel
              index={0}
              value={0}
            >
              <div className={classes.container}>
                <GridContainer>
                  <Grid
                    className={classes.header}
                    item
                    md={6}
                  >
                    <Typography
                      component="h1"
                      variant="h2"
                    >
                      {programTitle}
                    </Typography>
                  </Grid>
                  <Grid
                    className={classes.headerRight}
                    item
                    md={6}
                  >
                    <Button
                      color="primary"
                      disabled={!!saveStatus['coupons.download']}
                      onClick={async () => {
                        try {
                          setSaveStatus(status => ({...status, 'coupons.download': 1}));
                          const content = await (api.provider('b2b') as B2b)
                            .couponsDownload(programId);
                          fileDownload(content, couponsFileName(data['name']));
                          setSaveStatus(status => ({...status, 'coupons.download': 0}));
                        }
                        catch (e) {
                          setSaveStatus(status => ({...status, 'coupons.download': 0}));
                        }
                      }}
                      size="large"
                      style={{ background: '#fff', borderRadius: 1, color: '#000' }}
                      variant="outlined"
                    >
                      {!saveStatus['coupons.download'] && (
                        <span>Download Codes</span>
                      )}
                      {saveStatus['coupons.download'] === 1 && (
                        <CircularProgress style={{ height: '24px', width: '24px' }} />
                      )}
                      <ArrowDropDownIcon />
                    </Button>
                  </Grid>
                  {data['invoices.unpaid'] > 1 && (
                    <Grid>
                      <Collapse
                        className={classes.alertWrapper}
                        in={alertOpen}
                      >
                        <Alert
                          className={classes.alert}
                          icon={<ErrorIcon fontSize="inherit" />}
                          onClose={() => {
                            setAlertOpen(false);
                          }}
                          severity="error"
                        >
                          <div>
                            Your due date is expiring soon. Please follow the link to address your <Link to="/billing">unpaid invoices</Link>
                          </div>
                        </Alert>
                      </Collapse>
                    </Grid>
                  )}
                  <Grid col={6}>
                    <div className={classes.graySummary}>
                      <div className={classes.graySummaryTitle}>Codes balance for the program</div>
                      <div className={classes.graySummaryNumber}>
                        {couponsUsed}<span>/{couponsGenerated}</span>
                      </div>
                      <div>You have used {couponsUsed} codes of {couponsGenerated} codes in total</div>
                      <Button
                        className={classes.orangeButton}
                        color="primary"
                        onClick={openAddCodesDialog}
                        variant="contained"
                      >
                        <AddIcon style={{ fontSize: '16px' }} /> Add more codes
                      </Button>
                    </div>
                    {loading && <LinearProgress />}
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.graySummary}>
                      <div className={classes.graySummaryTitle}>Available codes for the program</div>
                      <div className={classes.graySummaryNumber}>
                        {couponsGenerated - couponsUsed}
                      </div>
                      <div>You have {couponsGenerated - couponsUsed} more codes available</div>
                      <Button
                        className={classes.orangeButton}
                        color="primary"
                        disabled={!!saveStatus['coupons.download.all']}
                        onClick={async () => {
                          try {
                            setSaveStatus(status => ({...status, 'coupons.download.all': 1}));
                            const content = await (api.provider('b2b') as B2b)
                              .couponsDownload(programId);
                            fileDownload(content, couponsFileName(data['name']));
                            setSaveStatus(status => ({...status, 'coupons.download.all': 0}));
                          }
                          catch (e) {
                            setSaveStatus(status => ({...status, 'coupons.download.all': 0}));
                          }
                        }}
                        variant="contained"
                      >
                        {!saveStatus['coupons.download.all'] && (
                          <><GetAppIcon style={{ fontSize: '16px' }} /> Download available codes</>
                        )}
                        {saveStatus['coupons.download.all'] === 1 && (
                          <CircularProgress style={{ height: '24px', width: '24px' }} />
                        )}
                      </Button>
                    </div>
                    {loading && <LinearProgress />}
                  </Grid>
                  <Grid
                    adminView
                    style={{marginTop: 30}}
                  >
                    <GridContainer className={classes.pricingRateForm}>
                      <Grid
                        col={8}
                        item
                      >
                        <TextField
                          helperTextTooltip={
                            <p>
                              Pricing rate id
                            </p>
                          }
                          inputProps={{ maxLength: 20 }}
                          label="Pricing rate id"
                          name="discount.id"
                        />
                      </Grid>
                      <Grid
                        col={4}
                        item
                      >
                        <Button
                          className={classes.pricingRateFormButton}
                          color="primary"
                          disabled={!!saveStatus['discount.id']}
                          onClick={async () => {
                            const values = methods.getValues();
                            try {
                              setSaveStatus(status => ({...status, 'discount.id': 1}));
                              await api.path('mrps')
                                .save({
                                  id: programId,
                                  name: data['name'],
                                  discount: { id: values['discount.id'] || null }
                                }, programId);
                              setSaveStatus(status => ({...status, 'discount.id': 2}));
                              setTimeout(() => {
                                setSaveStatus(status => ({...status, 'discount.id': 0}));
                              }, 1000);
                            }
                            catch (e) {
                              methods.setError('discount.id', 'error', e.message);
                              setSaveStatus(status => ({...status, 'discount.id': 0}));
                            }
                          }}
                          variant="contained"
                        >
                          {!saveStatus['discount.id'] && (
                            <span>Validate & Save</span>
                          )}
                          {saveStatus['discount.id'] === 1 && (
                            <CircularProgress style={{ height: '24px', width: '24px' }} />
                          )}
                          {saveStatus['discount.id'] === 2 && (
                            <DoneIcon />
                          )}
                        </Button>
                      </Grid>
                    </GridContainer>
                  </Grid>
                  <Grid>
                    <Typography
                      className={classes.subTitle}
                      component="h3"
                      variant="h2"
                    >
                      Program History
                    </Typography>
                    <TablePagination
                      actions={listActions}
                      condition={[
                        {
                          field: 'mrp',
                          value: programId,
                          op: '=='
                        },
                      ]}
                      dependencies={[batchesCreated]}
                      fetch={fetchItems}
                      fields={fields}
                    />
                  </Grid>
                  <Grid>
                    <Typography
                      className={classes.subTitle}
                      component="h3"
                      variant="h2"
                    >
                      Program Information
                    </Typography>
                    <TextField
                      disabled={!editable}
                      helperTextBottom={
                        <span>
                          Pretty self-explanitory! *Note - this can be the same as your company if your brand and company are the same. View example.
                        </span>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            {editable ? (
                              <Button
                                className={classes.orangeButton}
                                color="primary"
                                disabled={!!saveStatus['content.title']}
                                onClick={async () => {
                                  const values = methods.getValues();
                                  setSaveStatus(status => ({...status, 'content.title': 1}));
                                  await api.path('mrps')
                                    .save({
                                      id: programId,
                                      name: data['name'],
                                      content: { recipients: data['content.recipients'], title: values['content.title'] }
                                    }, programId);
                                  setSaveStatus(status => ({...status, 'content.title': 2}));
                                  setTimeout(() => {
                                    setSaveStatus(status => ({...status, 'content.title': 0}));
                                    setEditable(false);
                                  }, 1000);
                                }}
                                variant="contained"
                              >
                                {!saveStatus['content.title'] && (
                                  <span>Save</span>
                                )}
                                {saveStatus['content.title'] === 1 && (
                                  <CircularProgress style={{ height: '24px', width: '24px' }} />
                                )}
                                {saveStatus['content.title'] === 2 && (
                                  <DoneIcon />
                                )}
                              </Button>
                            ) : (
                              <>

                                <Button
                                  className={classes.transparentButton}
                                  color="primary"
                                  onClick={async () => {
                                    setEditable(true);
                                  }}
                                  variant="contained"
                                >
                                  Edit
                                </Button>
                              </>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      label="Customer Facing Program Title"
                      name="content.title"
                      variant={'filled'}
                    />
                  </Grid>
                  <Grid
                    adminView
                  >
                    <div className={classes.recipientsWrapper}>
                      <Select
                        disabled={saveStatus['recipients'] === 1}
                        label="Program Recipients"
                        name="content.recipients"
                        onChange={async (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                          setSaveStatus(status => ({...status, 'recipients': 1}));
                          await api.path('mrps')
                            .save({
                              id: programId,
                              name: data['name'],
                              content: { recipients: e.target.value }
                            }, programId);
                          setSaveStatus(status => ({...status, 'recipients': 2}));
                          setTimeout(() => {
                            setSaveStatus(status => ({...status, 'recipients': 0}));
                          }, 1000);
                        }}
                        options={[
                          { id: 'employees', name: 'Employees' },
                          { id: 'customers', name: 'Customers' },
                          { id: 'member_organizations', name: 'Member Organization' },
                        ]}
                      />
                      {saveStatus['recipients'] === 1 && (
                        <CircularProgress
                          className={classes.recipientsIcon}
                          size={24}
                        />
                      )}
                      {saveStatus['recipients'] === 2 && (
                        <DoneIcon
                          className={classes.recipientsIcon}
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.dataList}>
                      <label>Brand</label>
                      {!!data['brand'] && (
                        <ServiceEntity
                          component={(entity) =>
                            <div>{!!entity && entity.name}</div>
                          }
                          fromCache
                          id={data['brand']}
                          path="brands"
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.dataList}>
                      <label>Delivery Methods</label>
                      <div>Bulk Order Recipient Codes</div>
                    </div>
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.dataList}>
                      <label>Program Recipients</label>
                      <div>{!!values['content.recipients'] && recipients[values['content.recipients']]}</div>
                    </div>
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.dataList}>
                      <label>Travel Protection</label>
                      <div>Travel Protection {travelProtection ? 'Includes' : 'Excludes'}</div>
                    </div>
                  </Grid>
                  <Grid col={6}>
                    <div className={classes.dataList}>
                      <label>Program Type</label>
                      <div>MRP</div>
                    </div>
                  </Grid>
                </GridContainer>
              </div>
            </TabPanel>
          </form>
        </FormContext>
        <ApiProgress />
      </Card>
    </div>
  );
};

export default withRouter(ProgramEdit);
