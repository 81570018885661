import React from 'react';
import { useFormContext } from 'react-hook-form';
import CheckboxGroup from './CheckboxGroup';
import Select from './Select';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type Props = {
  categories: { id: string, name: string }[],
  name: string,
  required?: boolean,
}
const useStyles = makeStyles((theme: Theme) => ({
  rewardTop: {
    marginBottom: theme.spacing(4),
  }
}));


const Reward = ({ name, required, categories = [] }: Props) => {
  const classes = useStyles();
  const { getValues } = useFormContext();
  const values = getValues();
  return (
    <div>
      <div
        className={classes.rewardTop}
      >
        <Select
          label="Primary Category"
          name={name}
          options={categories.filter(c => !(values[`${name}_secondary`] || []).includes(c.id))}
          required={required}
        />
      </div>
      <div>
        <CheckboxGroup
          label="Secondary"
          name={`${name}_secondary`}
          options={categories.filter(c => c.id !== values[name])}
          updateAnyway
        />
      </div>
    </div>
  );
};

export default Reward;
