import custom from '../custom';

export default {
  label: {
    color: custom.slate,
    '&$active': {
      fontWeight: 400
    },
    '&$completed': {
      fontWeight: 400
    }
  },
};
