type LocalStorageConfig = {
  prefix?: string,
}

type LocalStorageData = {
  data: any,
  expired?: number,
}

class LocalStorage {
  private config: LocalStorageConfig;

  constructor(config: LocalStorageConfig = {}) {
    this.config = config;
  }

  private getKey(key: string) {
    return this.config.prefix + key;
  }

  public get(key: string) {
    const value = localStorage.getItem(this.getKey(key));
    if (!value) {
      return null;
    }
    try {
      const { data, expired } = JSON.parse(value);
      if (expired && new Date().getTime() > expired) {
        return null;
      }
      return data;
    }
    catch (e) {
      return value;
    }
  }

  public set(key: string, value: Data | number | string | ((data: any) => any), expiredIn?: number) {
    const data: LocalStorageData = { data: value };
    if ('function' === typeof value) {
      data.data = value(this.get(key));
    }
    if (expiredIn) {
      data.expired = new Date().getTime() + expiredIn;
    }
    localStorage.setItem(this.getKey(key), JSON.stringify(data));
  }

  public remove(key: string) {
    localStorage.removeItem(this.getKey(key));
  }
}

export default new LocalStorage({ prefix: 'b2b.' });
