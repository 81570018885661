import React, { PropsWithChildren, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import auth0 from 'services/Auth0';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'assets/css/index.css';
import { CssBaseline } from '@material-ui/core';
import config from 'services/Config';
import Routes from './Routes';
import SplashScreen from './SplashScreen';
import ErrorBoundary from 'components/Error/ErrorBoundary';
import { isMobileOnly } from 'react-device-detect';
import NoMobileSupport from 'containers/NoMobileSupport';
import NotAuthorized from 'containers/NotAuthorized';
import {
  APP_INIT_LOAD,
  APP_INIT_SUCCESS,
  APP_INIT_ERROR
} from 'inc/constants';
import 'inc/events';

config.get('sentryDSN') && Sentry.init({
  dsn: config.get('sentryDSN'),
  environment: config.get('environment'),
});

const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const initAuth0 = async (cb: (error?: Error) => void) => {
  try {
    await auth0.initClient();
    auth0.authenticated() ? cb() : auth0.client.loginWithRedirect();
  }
  catch (e) {
    cb(e)
  }
};

const AppWrapper = ({ children }: PropsWithChildren<{}>) => (
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </ErrorBoundary>
)

// eslint-disable-next-line react/no-multi-comp
const App = () => {
  if (isMobileOnly) {
    return <AppWrapper><NoMobileSupport /></AppWrapper>;
  }
  const [status, setStatus] = useState(APP_INIT_LOAD);
  initAuth0(
    (error?: Error) => setStatus(!error ? APP_INIT_SUCCESS : APP_INIT_ERROR),
  );
  if (APP_INIT_LOAD === status) {
    return <AppWrapper><SplashScreen /></AppWrapper>;
  }
  if (APP_INIT_ERROR === status) {
    return <AppWrapper><NotAuthorized /></AppWrapper>;
  }
  return (
    <AppWrapper>
      <Router history={browserHistory}>
        <Routes />
      </Router>
    </AppWrapper>
  );
}

export default App;
