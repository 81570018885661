import { brandCreationStepViewed } from 'services/typewriter';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ brand, stepName }: Data) => {
  const company = await getEntity('companies', brand['company']);
  brandCreationStepViewed({
    context: segmentContextObject(company, brand),
    brand: segmentBrandObject(brand, company),
    company: segmentCompanyObject(company),
    stepName,
  });
};
