import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { priceFormat } from 'inc/utils';
import MrpPricing from 'services/MrpPricing';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.custom.grayMedium,
    borderRadius: 2,
    margin: '0 auto',
    maxWidth: 800
  },
  title: {
    background: theme.custom.white,
    fontSize: 24,
    margin: '-12px auto 0',
    textAlign: 'center',
    width: 270
  },
  content: {
    padding: '50px 100px'
  },
  contentItem: {
    marginBottom: 50,
    '&:last-child': {
      marginBottom: 0
    }
  },
  head: {
    background: theme.custom.slate,
    color: theme.custom.white,
    borderRadius: 2,
    fontSize: 18,
    fontWeight: 700,
    padding: 16
  },
  headLight: {
    background: theme.custom.grayMedium,
    color: 'inherit'
  },
  subHead: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16,
    fontSize: 16
  },
  subHeadLarge: {
    fontSize: 48,
    lineHeight: 1,
    paddingTop: 45,
    letterSpacing: '-0.05em'
  },
  bold: {
    fontWeight: 700
  }
}));

type Props = {
  coupons: number,
  travelProtection?: boolean
}

function Invoice({ coupons, travelProtection }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Your Program Invoice
      </div>
      <div className={classes.content}>
        <div className={classes.contentItem}>
          <div className={classes.head}>
            Military Veterans Employee Gift
          </div>
          <div className={classes.subHead}>
            <div>12 Months Subscription</div>
            <div>${priceFormat(MrpPricing.priceoff('sub'))}</div>
          </div>
          {travelProtection && (
            <div className={classes.subHead}>
              <div>Travel Protection Add-On</div>
              <div>${priceFormat(MrpPricing.priceoff('tp'))}</div>
            </div>
          )}
          <div className={classes.subHead}>
            <div>Processing Fee</div>
            <div>${priceFormat(MrpPricing.priceoff('fee'))}</div>
          </div>
          <Divider />
          <div className={clsx(classes.subHead, classes.bold)}>
            <div>Subtotal for each subscription</div>
            <div>${priceFormat(MrpPricing.sum('priceoff', 1, { tp: !travelProtection }))}</div>
          </div>
        </div>
        <div className={classes.contentItem}>
          <div className={clsx(classes.head, classes.headLight)}>
            Bulk Order
          </div>
          <div className={classes.subHead}>
            <div>12 Month Subscription {travelProtection ? 'w/' : 'w/o'} Travel Protection</div>
            <div>{coupons} {coupons === 1 ? 'subscription' : 'subscriptions'}</div>
          </div>
          <Divider />
          <div className={clsx(classes.subHead, classes.subHeadLarge)}>
            <div>Grand Total</div>
            <div>${priceFormat(MrpPricing.sum('priceoff', coupons, { tp: !travelProtection }))}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Invoice;
