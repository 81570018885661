import { useEffect, useState } from 'react';
import store from 'inc/store';

const useObservable = (storeName: string) => {
  const str = store(storeName);
  const [state, setState] = useState(str.initialState);
  useEffect(()=> {
    const subscription = str.subscribe(setState);
    return () => subscription.unsubscribe();
  }, []);
  return state;
}

export default useObservable;
