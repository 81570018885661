import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import useObservable from 'inc/hooks/useObservable';

type Props = {
  loading?: boolean,
}

function ApiProgress({ loading }: Props) {
  const saveLoading = useObservable('page.loading');
  return saveLoading || loading ? <LinearProgress/> : <div style={{height: '4px'}} />;
}

export default ApiProgress;
