import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type Props = {
  children: React.ReactNode,
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: '1px solid #ccc',
    borderTop: '5px solid ' + theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
  }
}));

function Details({ children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {children}
    </div>
  );
}

export default Details;
