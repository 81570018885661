const errors: Data = {
  '.discount.id': 'Wrong Pricing Rate ID value. Please go back and add a valid one.',
  '.content.banner.image.asset': 'Wrong Banner Image uploaded.',
  '.content.hero.image.asset': 'Wrong Hero Image uploaded.',
};
export default (context: Data) => {
  const { details: { field = '.' } = {} } = context;
  return {
    details: { field },
    message: errors[field] || '',
    severity: 'info',
  };
}
