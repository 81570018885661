import { useEffect, useState } from 'react';
import api from 'services/api';
import { FORM_STATE_DATA } from 'inc/constants';

const useDocumentHistory = (type: string, docId: string, values: Data) => {
  const [revisions, setRevisions] = useState<Data[]>([]);
  const data = values[FORM_STATE_DATA] || {};
  const history: Document.Base[] = [];
  const currentRevision = {
    id: data['revision'],
    name: data['name'],
    state: data.state,
    log: data.revision_log,
    updated: data.updated || {},
    history: (data['history'] || []).map((item: Document.Base, index: number) => ({...item, id: `${data['revision']}-${index}`, name: data['name']})),
  }
  useEffect(() => {
    (async () => {
      if (docId) {
        const revisions = await api.path(type)
          .getAll({
            order: { by: 'updated.on', dir: 'desc' },
            revision: docId,
            limit: 10,
            parentId: docId,
          });
        setRevisions(revisions);
      }
    })();
  }, [docId]);
  const allRevisions = [currentRevision, ...revisions];
  allRevisions.forEach((revision, index) => {
    if (!revision.state) {
      return;
    }
    const nextRevision = allRevisions[index + 1] || {};
    revision.history.forEach((item: Document.Base, index2: number) => history.push({...item, id: `${revision.revision}-${index2}`, name: item['name'] || revision.name}));
    history.push({
      id: `${revision.revision}-N`,
      name: revision.name,
      from: (nextRevision.history || [])[0]?.to || nextRevision.state,
      to: revision.history[revision.history.length - 1]?.from || revision.state,
      comment: revision.log,
      by: revision.updated?.by,
      on: revision.updated?.on,
    });
  });
  return {
    history
  };
}

export default useDocumentHistory;
