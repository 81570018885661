import React, { useEffect } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { flatten } from 'inc/utils';
import { RedemptionSchema } from 'inc/validation';
import { Hidden } from 'components/Form';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';

type Props = {
  component: Data,
  handleSubmit: (data: Data) => void,
  name: string,
  onClose: () => void,
  open: boolean,
}

const RedemptionForm = ({ component, handleSubmit, name, open, onClose }: Props) => {
  const [type, ] = component.uuid.split('-');
  const methods = useForm({
    validationSchema: yup.object().shape(RedemptionSchema[type as Redemption.Type]),
  });
  const { getValues, register, reset } = methods;
  useEffect(() => {
    register({ name: 'overrides' });
    register({ name: FORM_STATE_DATA });
    register({ name: FORM_STATE_LOADED });
    if (component.initValues) {
      reset({...component.initValues,
        overrides: component.initValues.__overrides,
        [FORM_STATE_LOADED]: true,
        [FORM_STATE_DATA]: flatten(component.initValues),
      });
    }
    if (component.overrides) {
      reset({
        overrides: component.overrides,
      });
    }
  }, []);
  return (
    <Dialog
      aria-describedby="scroll-dialog-description"
      aria-labelledby="scroll-dialog-title"
      disableEnforceFocus
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={open}
      scroll="paper"
    >
      <DialogTitle id="scroll-dialog-title">{component.name}</DialogTitle>
      <DialogContent dividers>
        <FormContext {...methods}>
          <form
            onSubmit={(e) => { e.stopPropagation(); methods.handleSubmit(handleSubmit)(e); }}
          >
            <Hidden
              name="__overrides"
              value={component.overrides || (component.initValues || {}).__overrides}
            />
            <component.component
              data={getValues()}
              id={component.uuid}
              name={name}
            />
          </form>
        </FormContext>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          data-testid="dialog-form-submit"
          onClick={(e) => {e.stopPropagation(); methods.handleSubmit(handleSubmit)(e);}}
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedemptionForm;
