import custom from '../custom';

export default {
  root: {
    boxSizing: 'border-box',
    '.Mui-error &': {
      color: custom.red
    }
  },
  outlined: {
    borderRadius: 0,
    fontSize: 16,
    padding: '19px 16px'
  },
  icon: {
    color: custom.slate
  }
};
