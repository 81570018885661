import React, { useEffect, useState } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import {
  Select,
  TextField,
} from 'components/Form';
import DialogWrapper from './DialogWrapper';
import * as yup from 'yup';
import { message } from 'inc/validation/helpers';
import moderationStates from 'data/states.json';
import api from 'services/api';
import auth0 from 'services/Auth0';
import B2b from 'services/api/providers/B2b';
import store from 'inc/store';

type Props = {
  collection: string,
  document: Document.Base,
  onClose: () => void,
  onComplete: () => void,
}

const ModerationForm = ({ collection, document, onClose, onComplete }: Props) => {
  const [ states, setStates ] = useState<CategoryItem[]>([]);
  const methods = useForm({
    validationSchema: yup.object().shape({
      comment: yup.string().required(message('required', 'Comment')),
      state: yup.string().required(message('required', 'State')),
    }),
  });
  // @todo replace IData with smth else;
  const confirmHandler = (document: Document.Base, { handleFormErrors, setLoading }: Data) => {
    return methods.handleSubmit(async (data) => {
      setLoading(true);
      try {
        await (api.provider('b2b') as B2b).moderate(collection, document.id, (data as Document.Base));
        if (!auth0.canFetch('brands') && 'brands' === collection) {
          await auth0.getEntities([document.id], collection, true);
        }
        setLoading(false);
        store('page.message').set({ text: 'The item has been moderated', type: 'success' });
        onComplete && onComplete();
      }
      catch (err) {
        setLoading(false);
        handleFormErrors(err);
      }
    });
  }
  useEffect(() => {
    if (document['meta.state.transit']) {
      setStates((moderationStates as CategoryItem[]).filter(state => document['meta.state.transit'].includes(state.id)));
    }
  }, []);
  return (
    <DialogWrapper
      confirmHandler={confirmHandler}
      document={document}
      onClose={onClose}
      title="Moderation Window"
    >
      <FormContext {...methods}>
        <form>
          <Select
            label="State"
            name="state"
            options={states}
          />
          <TextField
            label="Transition comment"
            multiline
            name="comment"
            rows={2}
            rowsMax={4}
          />
        </form>
      </FormContext>
    </DialogWrapper>
  );
};

export default ModerationForm;
