import React from 'react';
import { Link } from 'react-router-dom';

export default {
  sub_title: 'WeSalute Offers are the primary value drivers to the greater military community, created by you and delivered by us. Offers are the backbone of our partnerships and our goal is to give you the tools to create quality offers.',
  company: 'Which company will this offer be associated with?',
  brand: <>Which brand will this offer be associated with? <Link to="/brands/create">Go here to create a new brand</Link>.</>,
  offer_value: <>Get help determining the Offer Value by visiting <Link to="/brands/create">our guide here</Link>.</>,
  audience: 'You can select all audience segments or you can pick specific segments. We will gate your offer according to the Military Audience Segment selection.',
  name: 'Used internally for easier search of your offer.',
  title: 'This will appear as the title on the offer page. Best practice is to keep it short and descriptive.',
  mcc_category: 'Merchant Category Code (MCC) is used to classify your offer by the types of goods or services it provides to consumers.',
  summary: 'In the summary you can go more in-depth about your offer. This section can include more context or history about your offer and brand. If there are special instructions, please do not list those here, the ‘Details’ section is more appropriate for any special instructions. [1000 characters or less is recommended]',
  details: (
    <>
      Please reiterate the specific offer as the first detail.
      Include details relevant to the offer but <strong>do not</strong> list anything that may allow users to redeem without first being gated.
      <strong>DO NOT</strong> list url’s where the offer can be redeemed.
    </>
  ),
  terms_conditions: 'This section can include any legal copy that protects the integrity of the brand and offer.',
  testimonial: 'Include a testimonial from a customer about this brand, offer, or Veterans Advantage.',
  benefits: 'An opportunity to enhance the positioning of the offer with important benefits of becoming a customer of a brand. What other perks can be combined, such as loyalty program points, or opportunities to get additional savings combined with what customers can get through WeSalute.',
  weight: 'Set your offer importance score.',
  image: (
    <>
      <p>Image Requirements:</p>
      <ol>
        <li>JPG, PNG, SVG file types are accepted</li>
        <li>Must be less than 10 mb</li>
        <li>Optimal size: 462x260</li>
      </ol>
      <p>This image will appear as the background of the offer tile in the Military Discounts marketplace. For consistency, we recommend using the same image as the offer hero, adjusted for size requirements.</p>
    </>
  ),
  banner: (
    <>
      <p>Image Requirements:</p>
      <ol>
        <li>JPG, PNG, SVG file types are accepted</li>
        <li>Must be less than 10 mb</li>
        <li>Optimal size: 2000x539</li>
      </ol>
      <p>Your offer hero image will be used as the header image for your offer page. We will apply an overlay to the image so it may appear differently on the page.</p>
    </>
  ),
  banner_title: 'This will appear on top of the hero image. Write a line here about your offer. The best headlines are short and to-the-point.',
  banner_description: 'This will appear on top of the hero image, underneath the Hero Title.',
  redemptions: 'The redemption channel is the method for redeeming an offer. Users will be presented with easy instructions to redeem your offer according to your selection.',
  redemptions_online: 'An offer link will be generated and automatically applied to the offer page. The destination URL will tell us where users can redeem the offer on your site and we’ll take care of the rest.',
  need_updates_description: 'Breifly explain the updates needed to make this offer acceptable for publishing.',
}
