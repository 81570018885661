import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useFormContext } from 'react-hook-form';
import store from 'inc/store';

type Props = {
  children: React.ReactNode,
  document: Document.Base,
  loading?: boolean,
  confirmHandler?: (document: Document.Base, options: Data) => (e: React.BaseSyntheticEvent<object, any, any>) => Promise<void>,
  onClose?: () => void,
  onComplete?: () => void,
  open?: boolean,
  title: string,
  width?: 'md' | 'xs' | 'sm' | 'lg' | 'xl',
}

const DialogWrapper = ({ children, document, onComplete, confirmHandler, onClose, open = true, title, width = 'md' }: Props) => {
  const [ loading, setLoading ] = useState(false);
  const { formState, setError } = useFormContext() || {};
  const closeDialog = () => {
    onClose && onClose();
  }
  const handleErrors = (err: Api.Error) => {
    const {
      message = 'Error',
      details = {},
    } = err;
    setLoading(false);
    if (formState && details.field) {
      setError(details.field.substring(1), 'server-error', message);
    }
    else {
      store('page.message').set({ text: message, type: 'error' });
    }
  }
  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    confirmHandler && confirmHandler(document, {
      handleErrors,
      handleFormErrors: handleErrors,
      onClose,
      onComplete,
      setLoading,
    })(e);
  }
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth={width}
      onClose={closeDialog}
      open={open}
      scroll="paper"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      {loading ? <LinearProgress/> : <div style={{height: '4px'}} />}
      <DialogContent dividers>
        {children}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeDialog}
        >
          Cancel
        </Button>
        {!!confirmHandler && (
          <Button
            autoFocus
            color="primary"
            data-testid="dialog-form-submit"
            onClick={handleClick}
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogWrapper;
