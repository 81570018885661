import * as yup from 'yup';
import { message } from './helpers';

export const MrpCreateSchema = yup.object().shape({
  name: yup.string().required(message('required', 'Label')),
  type: yup.string().required(message('required', 'Type')),
  brand: yup.string().required(message('required', 'Brand')),
  method: yup.string().required(message('required', 'Method')),
  mrp_batch: yup.object().shape({
    coupons: yup
      .mixed()
      .transform(value => parseInt(value) || null)
      .test('coupons-max', 'Number of coupons should be in a range betwee 0 and 10000.', (value) => {
        return value >= 0 && value <= 10000;
      }),
  }),
  config: yup.object().shape({
    travel_protection: yup.boolean(),
  }),
  content: yup.object().shape({
    title: yup.string().required(message('required', 'Title')),
    recipients: yup.string().required(message('required', 'Recipients')),
  }),
  __confirm: yup.boolean()
    .required('This one must be accepted.')
    .oneOf([true], 'This one must be accepted.'),
});

export const MrpAddCodesSchema = yup.object().shape({
  coupons_total: yup
    .mixed()
    .transform(value => parseInt(value) || null)
    .test('coupons-max', 'Number of coupons should be more than 0 and less than 10000.', (value) => {
      return value >= 1 && value <= 10000;
    }),
});
