import React from 'react';
import moment from 'moment';
import { useFormContext } from 'react-hook-form';
import TextField from './TextField';
import useDefaultValue from 'inc/hooks/useDefaultValue';

type Props = {
  label?: string,
  name: string,
  placeholder?: string,
};

function Date({ name, ...props }: Props) {
  const { setValue } = useFormContext();
  useDefaultValue(name, (data: Document.Base) => {
    const v = parseInt(data[name]);
    setValue(name, moment.unix(v / 1000).format('YYYY-MM-DDTHH:mm'));
  }, false);
  return (
    <TextField
      name={name}
      type="datetime-local"
      {...props}
    />
  );
}

export default Date;
