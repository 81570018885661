import { createMuiTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import custom from './custom';
import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createMuiTheme({
  custom: custom,
  palette: palette,
  typography: (typography as Typography),
  overrides: (overrides as Overrides),
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
