import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import RedemptionDialogForm from './RedemptionDialogForm';
import * as RedemptionComponents from './redemptions';
import { Draggable, Select } from 'components/Form';
import shortid from 'shortid';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';

const redemptions: Redemption.Channel[] = [
  {
    type: 'card',
    name: 'Add to Credit Card',
    component: 'RedemptionCredit',
  },
  {
    type: 'coupon',
    name: 'Coupon Redemption',
    component: 'RedemptionCoupon',
    custom: true,
  },
  {
    type: 'mobile',
    name: 'Mobile App Redemption',
    component: 'RedemptionMobile',
  },
  {
    type: 'online',
    name: 'Online Redemption',
    component: 'RedemptionOnline',
  },
  {
    type: 'person',
    name: 'In-Person Redemption',
    component: 'RedemptionInPerson',
    custom: true,
  },
  {
    type: 'phone',
    name: 'Phone Redemption',
    component: 'RedemptionPhone',
    custom: true,
  },
];

const redemptionList: CategoryItem[] = redemptions.map(item => ({
  id: item.type,
  name: item.name,
}));

type Props = {
  name: string,
}

const RedemptionshWrapper = ({ name }: Props) => {
  const methods = useFormContext();
  const { register, setValue, getValues } = methods;
  const values = getValues();
  const [redemptionId, setRedemptionId] = useState<Redemption.Type | 'custom' | ''>('');
  const [items, setItems] = useState<Redemption.Item[]>([]);
  const [activeComponent, setActiveComponent] = useState<Redemption.Item | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const getRedemption = (uuid?: string, type?: Redemption.Type, overrides?: Data): Redemption.Item => {
    const values = getValues();
    const rId = (uuid ? uuid.split('-')[0] : type) as Redemption.Type;
    const { name: redemptionName, component } = redemptions.find(r => r.type === rId) as Redemption.Channel;
    return {
      name: redemptionName,
      id: uuid || `${rId}-${shortid.generate()}`,
      uuid: uuid || `${rId}-${shortid.generate()}`,
      isNew: !uuid,
      component: (RedemptionComponents as Data)[component],
      overrides,
      initValues: uuid ? values[name][uuid] : null,
    }
  }
  const handleRedemptionEditClick = ({ id }: Data) => (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveComponent(getRedemption(id));
    setOpen(true);
  }
  const handleRedemptionDeleteClick = ({ id }: Data) => (e: React.MouseEvent) => {
    e.preventDefault();
    setItems(items.filter((r: Data) => r.uuid !== id));
  }
  const addRedemption = (redemption?: Data) => (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveComponent(getRedemption(undefined, redemption?.type, redemption?.attributes));
    setOpen(true);
  }
  useEffect(() => {
    register({ name });
  }, []);
  useEffect(() => {
    if (values[FORM_STATE_LOADED]) {
      const order = values[FORM_STATE_DATA]['__redemptions__order'] || [];
      setItems(order.map((uuid: string) => {
        return getRedemption(uuid);
      }));
    }
  }, [values[FORM_STATE_LOADED]]);
  const handleClose = () => {
    setActiveComponent(undefined);
    setOpen(false);
  };
  const handleEditRedemption = (data: Data) => {
    if (!activeComponent) {
      return;
    }
    const values = getValues();
    if (activeComponent.isNew) {
      setItems((items: Redemption.Item[]) => ([{...activeComponent, name: activeComponent.name}, ...items]));
    }
    setValue(name, {...values[name], [activeComponent.uuid]: data || {}});
    handleClose();
  }
  return (
    <>
      <div style={{display: 'flex', maxWidth: '270px', justifyContent: 'space-between'}}>
        <Select
          name="__redemption_id"
          onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => setRedemptionId((e.target.value as Redemption.Type))}
          options={redemptionList}
          value={redemptionId}
        />
        <Button
          color="primary"
          data-testid="redemption-add-button"
          onClick={addRedemption(redemptions.find(red => red.type === redemptionId) as Redemption.Channel)}
          variant="contained"
        >
          Add
        </Button>
      </div>
      <Draggable
        actions={[
          {
            label: 'Edit',
            callback: handleRedemptionEditClick,
          },
          {
            label: 'Delete',
            callback: handleRedemptionDeleteClick,
          }
        ]}
        emptyText="No redemptions added yet."
        idKey="uuid"
        items={items as Pick<Redemption.Item, 'id' | 'name'>[]}
        name="__redemptions__order"

      />
      {activeComponent && activeComponent.component && (
        <RedemptionDialogForm
          component={activeComponent}
          handleSubmit={handleEditRedemption}
          name={name}
          onClose={handleClose}
          open={open}
        />
      )}
    </>
  );
};

export default RedemptionshWrapper;
