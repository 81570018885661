import React, { useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  DatePickerProps,
} from '@material-ui/pickers';
import InputAdornment from '@material-ui/core/InputAdornment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useFormContext } from 'react-hook-form';
import useDefaultValue from 'inc/hooks/useDefaultValue';
import FieldWrapper from './FieldWrapper';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

type Props = {
  defaultValue?: ParsableDate,
  description?: React.ReactNode | string,
  label?: string,
  name: string,
  required?: boolean,
} & Partial<DatePickerProps>;

function DatePicker({ description, defaultValue, label, name, required, ...props }: Props) {
  const { setValue } = useFormContext();
  const [selectedDate, handleDateChange] = useState<ParsableDate>(defaultValue ?? new Date());
  useDefaultValue(name, (data: Document.Base) => {
    handleDateChange(data[name])
  });
  useEffect(() => {
    selectedDate && setValue(name, setValue);
  }, [selectedDate]);
  return (
    <FieldWrapper
      helperText={description}
      label={label}
      name={name}
      required={required}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiDatePicker
          format="MM/DD/YYYY"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <KeyboardArrowDownIcon />
              </InputAdornment>
            ),
          }}
          inputVariant="outlined"
          onChange={handleDateChange}
          style={{ background: '#fff', width: '98%' }}
          value={selectedDate}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </FieldWrapper>
  );
}

export default DatePicker;
