import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import { PageBlock } from 'components/Layout';
import api from 'services/api';
import B2b from 'services/api/providers/B2b';
import fileDownload from 'js-file-download';
import CircularProgress from '@material-ui/core/CircularProgress';
import { couponsFileName } from 'inc/utils';

type Props = {
  coupons: number,
  program: Document.Base | null,
}

const useStyles = makeStyles((theme: Theme) => ({
  topContent: {
    margin: '0 auto',
    maxWidth: 600
  },
  downloadContent: {
    textAlign: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.custom.grayMedium,
    borderRadius: 2,
    margin: '0 auto',
    maxWidth: 800,
    padding: theme.spacing(7, 5)
  },
  title: {
    fontSize: 32,
    letterSpacing: 0,
    lineHeight: 1.6
  },
  button: {
    display: 'block',
    width: '384px',
    margin: '0 auto'
  },
  buttonDownload: {
    marginBottom: 30
  },
  buttonBilling: {}
}));

function Invoice({ coupons, program }: Props) {
  const classes = useStyles();
  const [saveStatus, setSaveStatus] = useState<{[k: string]: number}>({test: 1});
  return (
    <>
      <PageBlock>
        <Box
          className={classes.topContent}
          fontWeight="fontWeightBold"
        >
          Thank you for creating a program and doing your part to support the greater military community.
          Providing a meaningful way to recognize and reward the greater military community will always be our collaborative goal.
          <br />
          <br />
          -WeSalute Team
        </Box>
      </PageBlock>
      <PageBlock>
        <div className={classes.downloadContent}>
          <Typography
            className={classes.title}
            gutterBottom
            variant="h2"
          >
            <Box
              component="span"
              fontWeight="fontWeightBold"
            >
              {coupons}
            </Box>
             &nbsp;recipient codes are ready!
          </Typography>
          {!!program && (
            <Button
              className={clsx(classes.button, classes.buttonDownload)}
              color="primary"
              disabled={!!saveStatus['coupons.download']}
              onClick={async () => {
                try {
                  setSaveStatus(status => ({...status, 'coupons.download': 1}));
                  const content = await (api.provider('b2b') as B2b)
                    .couponsDownload(program.id);
                  fileDownload(content, couponsFileName(program.name));
                  setSaveStatus(status => ({...status, 'coupons.download': 0}));
                }
                catch (e) {
                  setSaveStatus(status => ({...status, 'coupons.download': 0}));
                }
              }}
              variant="contained"
            >
              {!saveStatus['coupons.download'] && (
                <span>Download .csv file</span>
              )}
              {saveStatus['coupons.download'] === 1 && (
                <CircularProgress style={{ height: '24px', width: '24px' }} />
              )}
            </Button>
          )}
          <Button
            className={clsx(classes.button, classes.buttonBilling)}
            color="primary"
            component={Link}
            to="/billing"
            variant="outlined"
          >
            Go to Billing to pay your invoice
          </Button>
        </div>
      </PageBlock>
    </>
  );
}

export default Invoice;
