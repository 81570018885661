import React from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import auth0 from 'services/Auth0';

const NotAuthorized = () => (
  <Dialog
    aria-describedby="alert-dialog-description"
    aria-labelledby="alert-dialog-title"
    open
  >
    <DialogTitle id="alert-dialog-title">Failed to authenticate</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <div>
          Something went wrong when attempting to authenticate the user.
        </div>
        <Button
          color="primary"
          onClick={() => auth0.logout()}
          variant="contained"
        >Login</Button>
      </DialogContentText>
    </DialogContent>
  </Dialog>
);

export default NotAuthorized;
