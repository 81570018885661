import { offerCreated } from 'services/typewriter';
import { flatten } from 'inc/utils';
import {
  getEntity,
  segmentBrandObject,
  segmentOfferObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const offer = flatten(values);
  const brand = await getEntity('brands', offer['brand']);
  const company = await getEntity('companies', brand['company']);
  window.analytics.group(offer['id'], {
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
  });
  offerCreated({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
    offer: segmentOfferObject(offer),
  });
};
