import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import useFeatureFlagging from 'inc/hooks/useFeatureFlagging';
import { RouteWithLayout } from './components';
import { Main as MainLayout } from './layouts';
import auth0 from 'services/Auth0';

import {
  AssetList as AssetListView,
  AssetEdit as AssetEditView,
  CompanyList as CompanyListView,
  CompanyEdit as CompanyEditView,
  CompanyInfo as CompanyInfoView,
  BrandList as BrandListView,
  BrandListPublished as BrandListPublishedView,
  BrandCreate as BrandCreateView,
  BrandEdit as BrandEditView,
  BrandRevisions as BrandRevisionsView,
  InvoiceList as InvoiceListView,
  OfferList as OfferListView,
  OfferListPublished as OfferListPublishedView,
  OfferCreateNew as OfferCreateNewView,
  OfferEdit as OfferEditView,
  OfferEditNew as OfferEditNewView,
  OfferRevisions as OfferRevisionsView,
  ProgramCreate as ProgramCreateView,
  ProgramEdit as ProgramEditView,
  ProgramList as ProgramListView,
  Settings as SettingsView,
  NotFound as NotFoundView
} from './views';

const RefreshComponent = (Component: React.ElementType) => () => {
  return <Component key={Date.now()} />;
}

const Routes = () => {
  const offersAllowed = useFeatureFlagging('offer');
  let indexPath = '/offers';
  if (auth0.canList('companies')) {
    indexPath = '/companies';
  }
  else if (auth0.canList('brands')) {
    indexPath = '/brands';
  }
  const _routes = [
    {
      redirect: indexPath,
      path: '/',
    }, {
      component: AssetListView,
      path: '/assets',
    }, {
      component: AssetEditView,
      path: '/assets/add',
    }, {
      component: AssetEditView,
      path: '/assets/edit/:assetId',
    }, {
      access: auth0.canListCompanies,
      component: RefreshComponent(CompanyListView),
      path: '/companies',
    }, {
      access: auth0.canCreateCompanies,
      component: CompanyEditView,
      path: '/companies/add',
    }, {
      access: auth0.canUpdateCompanies,
      component: CompanyEditView,
      path: '/companies/edit/:companyId',
    }, {
      access: () => !auth0.isAdmin(),
      component: CompanyInfoView,
      path: '/settings/company',
    }, {
      // access: auth0.canListCompanies,
      component: CompanyEditView,
      path: '/settings/company/:companyId',
    }, {
      access: auth0.canListBrands,
      component: BrandListView,
      path: '/brands',
    }, {
      access: auth0.canListBrands,
      component: BrandListPublishedView,
      path: '/brands/published',
    }, {
      access: auth0.canCreateBrands,
      component: BrandEditView,
      path: '/brands/add',
    }, {
      access: auth0.canCreateBrands,
      component: RefreshComponent(BrandCreateView),
      path: '/brands/create',
    }, {
      access: auth0.canUpdateBrands,
      component: BrandCreateView,
      path: '/brands/create/:brandId',
    }, {
      access: auth0.canUpdateBrands,
      component: BrandEditView,
      path: '/brands/edit/:brandId',
    }, {
      access: auth0.canUpdateBrands,
      component: BrandRevisionsView,
      path: '/brands/edit/:brandId/revisions',
    }, {
      access: auth0.canUpdateBrands,
      component: BrandEditView,
      path: '/brands/edit/:brandId/revisions/:revisionId',
    }, {
      access: () => offersAllowed,
      component: OfferListView,
      path: '/offers',
    }, {
      component: OfferListPublishedView,
      path: '/offers/published',
    }, {
      component: OfferEditNewView,
      path: '/offers/add',
    }, {
      component: RefreshComponent(OfferCreateNewView),
      path: '/offers/create',
    }, {
      component: OfferCreateNewView,
      path: '/offers/create/:offerId',
    }, {
      component: OfferEditNewView,
      path: '/offers/edit/:offerId',
    }, {
      component: OfferRevisionsView,
      path: '/offers/edit/:offerId/revisions',
    }, {
      component: OfferEditView,
      path: '/offers/edit/:offerId/revisions/:revisionId',
    }, {
      component: ProgramListView,
      path: '/programs',
    }, {
      component: RefreshComponent(ProgramCreateView),
      path: '/programs/create',
    }, {
      component: ProgramEditView,
      path: '/programs/edit/:programId',
    }, {
      component: RefreshComponent(InvoiceListView),
      path: '/billing',
    }, {
      access: auth0.isAdmin,
      component: SettingsView,
      path: '/settings',
    }, {
      component: NotFoundView,
      path: '/not-found',
    },
  ];
  return (
    <Switch>
      {_routes.map((route: RouteProps) => (
        <RouteWithLayout
          access={route.access}
          component={route.component}
          exact
          key={route.path}
          layout={route.layout || MainLayout}
          path={route.path}
          redirect={route.redirect}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
