import { brandRejected } from 'services/typewriter';
import { flatten } from '../../../utils';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const { history, log, updated } = values;
  const lastTransition = history[ history.length -1 ] ?? updated;
  const brand = flatten(values);
  const company = await getEntity('companies', brand['company']);
  brandRejected({
    context: segmentContextObject(company, brand),
    brand: segmentBrandObject(brand, company),
    company: segmentCompanyObject(company),
    rejection: {
      reason: lastTransition?.comment ?? log,
      rejector: {
        id: lastTransition?.by?.id,
        email: lastTransition?.by?.mail,
      }
    }
  });
};
