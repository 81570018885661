import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Card,
  CardContent,
  CardHeader,
  Divider
} from '@material-ui/core';
import {
  Button,
} from '@material-ui/core';
import api from 'services/api';
import B2b from 'services/api/providers/B2b';

const SettingsEdit = () => {
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState<Document.Base>();
  useEffect(() => {
    (async () => {
      const list = await (api.provider('b2b') as B2b).subscriptionList();
      list[0] && list[0].id && setSubscription(list[0]);
    })();
  }, []);
  const handleSync = async () => {
    try {
      setLoading(true);
      subscription && await (api.provider('b2b') as B2b).subscriptionTrigger(subscription.id, 'sync');
      setLoading(false);
    } catch(err) {
      console.log(err);
    }
  }
  return (
    <Card>
      <CardHeader
        subheader="Trigger the Sync process on the subscription server side."
        title="Sync Settings"
      />
      <Divider />
      <CardContent>
        <Button
          color="primary"
          disabled={!subscription || !subscription.id || loading}
          onClick={() => { handleSync() }}
          variant="contained"
        >
          Sync
        </Button>
      </CardContent>
    </Card>

  );
};

export default withRouter(SettingsEdit);
