import config from 'services/Config';
import { VALUE_NULL } from 'inc/constants';

export const isObject = (data: any, checkEmpty?: boolean) => {
  return !!data
    && 'object' === typeof data
    && Object === data.constructor
    && (!checkEmpty || !!Object.keys(data).length);
}

export const filterFormData = (data: Data): any => {
  if (isObject(data)) {
    const ret: Data = Object.keys(data).reduce((obj, attr) => {
      const ret = filterFormData(data[attr]);
      const privateAttr = '__' === attr.substring(0, 2);
      return (ret || ret === false) && !privateAttr
        ? {...obj, [attr]: ret === VALUE_NULL ? null : ret}
        : !privateAttr ? {...obj, [attr]: null} : obj;
    }, {});
    return Object.keys(ret).filter(item => !!ret[item] || ret[item] === false).length !== 0 ? ret : null;
  }
  else if (Array.isArray(data)) {
    const ret: Data[] = data.reduce((arr, item) => {
      const ret = filterFormData(item);
      return (ret || ret === false) ? [...arr, ret] : arr;
    }, []);
    return ret.length ? ret : null
  }
  return (data || data === false) ? data : null;
}

export const filterEmptyData = (data: Data, level = 0): any => {
  if (isObject(data)) {
    const ret: Data = Object.keys(data).reduce((obj, attr) => {
      const ret = filterEmptyData(data[attr], level + 1);
      return (ret || ret === false) && '__' !== attr.substring(0, 2)
        ? {...obj, [attr]: ret === VALUE_NULL ? null : ret}
        : obj;
    }, {});
    return Object.keys(ret).length || level === 0 ? ret : null;
  }
  else if (Array.isArray(data)) {
    const ret: Data[] = data.reduce((arr, item) => {
      const ret = filterEmptyData(item, level + 1);
      return (ret || ret === false) ? [...arr, ret] : arr;
    }, []);
    return ret.length || level === 0 ? ret : null
  }
  return (data || data === false) ? data : null;
}

export const flatten = (obj: Data, prefix = ''): any => {
  let ret: Data = {};
  Object.keys(obj).forEach(key => {
    if ('__' === key.substring(0, 2)) {
      return;
    }
    const retKey = prefix ? `${prefix}.${key}` : key;
    if (isObject(obj[key])) {
      ret = {...ret, ...flatten(obj[key], retKey)};
    }
    else {
      ret[retKey] = obj[key];
    }
  });
  return ret;
}

export const priceFormat = (price: number) => {
  return price.toFixed(2);
}

export const couponsFileName = (programName: string) => {
  return `${programName
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')}-codes.csv`;
}

export const cacheFormKey = (formId: string, formType: string) => {
  return `form.${config.get('environment')}.${formType}.${formId}`;
}

/**
 * Turns a plural word to a single one. Simple implementation to avoid pulling
 * a contrib library.
 */
export const toSingular = (word: string): string => {
  if (word.endsWith('ies')) {
    return word.replace(/ies$/, 'y');
  }
  if (word.endsWith('es')) {
    return word.replace(/es$/, '');
  }
  if (word.endsWith('s')) {
    return word.replace(/s$/, '');
  }
  return word;
}
