import * as yup from 'yup';
import { message } from './helpers';

export default yup.object().shape({
  name: yup.string().required(message('required', 'Name')),
  billing_info: yup.object().shape({
    email: yup.string().ensure().required(message('required', 'E-mail')),
    phone: yup.string().ensure().required(message('required', 'Phone Number')),
    address: yup.object().shape({
      line1: yup.string().ensure().required(message('required', 'Line1')),
      city: yup.string().ensure().required(message('required', 'City')),
      state: yup.string().ensure().required(message('required', 'State')),
      postal_code: yup.string().ensure().required(message('required', 'ZIP')),
    }),
  }),
});
