import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import TextField from './TextField';

type Props = {
  from: string,
  label?: string,
  name: string,
  readonly: boolean,
  required?: boolean,
}

function MachineName({ from, label, name, readonly, required = false }: Props) {
  const { watch } = useFormContext();
  const fromValue = watch(from, '');
  const [autoGenerated, setAutoGenerated] = useState(true);
  return (
    <TextField
      disabled={autoGenerated}
      helperText={
        readonly ? null : autoGenerated ? (
          <>
            A unique machine-readable name, to edit it click{' '}
            <a
              href="/"
              onClick={e => {
                e.preventDefault();
                setAutoGenerated(!autoGenerated);
              }}
            >
              here
            </a>
            .
          </>
        ) : (
          <>
            A unique machine-readable name. Can only contain
            lowercase letters, numbers, and underscores.
          </>
        )
      }
      label={label}
      name={name}
      required={required}
      {...(readonly || !autoGenerated
        ? {}
        : {
          value: (fromValue || '')
            .toLowerCase()
            .replace(/[^a-z0-9_]/g, '')
        })}
    />
  );
}

export default MachineName;
