import { colors } from '@material-ui/core';
import custom from './custom';

const white = custom.white;
const black = custom.slate;

export default {
  primary: {
    contrastText: white,
    dark: black,
    main: black,
    light: black
  },
  secondary: {
    contrastText: white,
    dark: black,
    main: black,
    light: black
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: black,
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  background: {
    default: white,
    paper: white
  },
  icon: colors.blueGrey[600],
  divider: custom.grayMedium
};
