import { useEffect, useState } from 'react';

const useScroll = (selector: string) => {
  const [triggered, setTriggered] = useState(0);
  useEffect(() => {
    const element: HTMLElement | null = document.querySelector(selector);
    if (element) {
      window.scrollTo(0, element.offsetTop - 100);
    }
  }, [triggered]);
  return {
    triggered,
    setTriggered,
  }
}

export default useScroll;
