import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import Typography from '@material-ui/core/Typography';

type Props = {
  className?: string,
  children: React.ReactNode,
}

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    marginTop: theme.spacing(7.5),
    position: 'relative',
    backgroundColor: fade(String(theme.custom.pink), 0.18),
    padding: '16px 10px 16px 32px',
    fontSize: 12,
    lineHeight: 1.17,
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
  messageTitle: {
    fontSize: 18,
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    letterSpacing: 0,
  },
  messageIcon: {
    fontSize: 12,
    position: 'absolute',
    top: 22,
    left: 12
  },
}));

function Message({ className, children, ...props }: Props) {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.message, className)}
      {...props}
    >
      <HelpOutlinedIcon className={classes.messageIcon}/>
      <Typography
        className={classes.messageTitle}
        component="h4"
        variant="h4"
      >
        Change Request Requires Update
      </Typography>
      {children}
    </div>
  );
}

export default Message;
