import React from 'react';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {
  FieldWrapper,
} from 'components/Form';
import { PageBlock, ReadOnly } from 'components/Layout';
import useFormCrud from 'inc/hooks/useFormCrud';
import { FORM_STATE_DATA } from 'inc/constants';
import useObservable from 'inc/hooks/useObservable';
import usStates from 'data/usStates.json';
import auth0 from 'services/Auth0';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Brand>;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 auto',
    maxWidth: 770
  },
  title: {
    fontSize: 40,
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(10)
  },
  button: {
    minWidth: 295,
    marginTop: theme.spacing(5),
  }
}));

const CompanyInfo = () => {
  const classes = useStyles();
  const company = useObservable('company');
  const {
    docId,
    methods,
    values,
  } = useFormCrud({
    id: company.id,
    path: 'companies'
  });
  const data = values[FORM_STATE_DATA] || {};
  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        component="h2"
        variant="h2"
      >
        Company Information
      </Typography>
      <FormContext {...methods}>
        <PageBlock>
          <FieldWrapper
            label="Company ID"
            name="id"
          >
            <ReadOnly>
              {data['id']}
            </ReadOnly>
          </FieldWrapper>
        </PageBlock>
        <PageBlock>
          <FieldWrapper
            label="Company Name"
            name="name"
          >
            <ReadOnly>
              {data['name']}
            </ReadOnly>
          </FieldWrapper>
        </PageBlock>
        <PageBlock>
          <FieldWrapper
            label="Contact Email"
            name="billing_info.email"
          >
            <ReadOnly>
              {data['billing_info.email']}
            </ReadOnly>
          </FieldWrapper>
        </PageBlock>
        <PageBlock>
          <FieldWrapper
            label="Contact Phone Number"
            name="billing_info.phone"
          >
            <ReadOnly>
              {data['billing_info.phone']}
            </ReadOnly>
          </FieldWrapper>
        </PageBlock>
        <PageBlock>
          <FieldWrapper
            label="Company Address"
            name="billing.address"
          >
            <ReadOnly>
              {[
                data['billing_info.address.line1'],
                data['billing_info.address.line2'],
                data['billing_info.address.city'],
                usStates.find(item => item.id === data['billing_info.address.state'])?.name,
                data['billing_info.address.postal_code'],
                data['billing_info.address.country'],
              ].filter(item => !!item).join(', ')}
            </ReadOnly>
          </FieldWrapper>
        </PageBlock>
      </FormContext>
      {auth0.canFetch('brands') && (
        <Button
          className={classes.button}
          color="primary"
          component={NavLink}
          to={`/settings/company/${docId}`}
          variant="contained"
        >
          Edit
        </Button>
      )}
    </div>
  );
};

export default withRouter(CompanyInfo);
