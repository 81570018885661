import * as yup from 'yup';
import { message } from './helpers';

export default ({
  card: {
    attributes: yup.object().shape({
      name: yup.string().required(message('required', 'Name')),
      type: yup.string().required(message('required', 'Type')),
      start_date: yup.mixed().transform((value) => {
        return new Date(value).getTime();
      }).required(message('required', 'Start Date')),
      end_date: yup.mixed().transform((value) => {
        return new Date(value).getTime();
      }),
      amount: yup.mixed().when('type', {
        is: 'amount',
        then: yup.mixed().transform((value) => {
          return parseFloat(value);
        }).required(message('required', 'Amount')),
      }),
      discount: yup.mixed().when('type', {
        is: 'discount',
        then: yup.mixed().transform((value) => {
          return parseFloat(value);
        }).required(message('required', 'Discount')),
      }),
      max_redemptions: yup.mixed().transform((value) => {
        return parseInt(value);
      }),
      return_period: yup.mixed().transform((value) => {
        return parseInt(value);
      }),
      country: yup.string().required(message('required', 'Country Code')),
      publisher: yup.string().required(message('required', 'Publisher ID')),
      brand_id: yup.string().required(message('required', 'Brand ID')),
      week_days: yup.array().transform(value => {
        return value.map((v: string) => parseInt(v));
      }),
    }),
  },
  coupon: {
    attributes: yup.object().shape({
      coupon_code: yup.string().required(message('required', 'Coupon')),
    }),
  },
  mobile: { },
  online: {
    attributes: yup.object().shape({
      url: yup.string().required(message('required', 'URL')),
    }),
  },
  person: { },
  phone: {
    attributes: yup.object().shape({
      phone_number: yup.string().required(message('required', 'Phone')),
    }),
  },
} as { [key in Redemption.Type]: { attributes?: any } });
