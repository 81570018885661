import React from 'react';
import { Tabs } from 'components/Display';

type Props = {
  docId?: string,
  index: number,
}

const OfferTabs = ({ docId, index = 0 }: Props) => {
  return <>
    {!!docId && (
      <Tabs
        tabs={[
          {
            label: 'Edit',
            to: `/offers/edit/${docId}`
          },
          {
            label: 'Revisions',
            to: `/offers/edit/${docId}/revisions`
          }
        ]}
        value={index}
      />
    )}
  </>
}

export default OfferTabs;
