import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import useObservable from 'inc/hooks/useObservable';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import auth0 from 'services/Auth0';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'fit-content',
    paddingRight: 30,
  },
  rootClosed: {
    '& $avatar': {
      width: 40,
      height: 40,
      marginLeft: theme.spacing(2)
    },
  },
  avatar: {
    width: 120,
    height: 120
  },
  name: {
    fontSize: 24,
    lineHeight: 1.3,
    fontWeight: 400,
    letterSpacing: 0,
    marginBottom: 0,
    marginTop: theme.spacing(4),
    wordBreak: 'break-word'
  },
  attributeName: {
    color: theme.custom.grayDarkest,
    fontSize: 12,
    lineHeight: 1.6,
    fontWeight: 400,
    letterSpacing: '0.16em',
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
    fontFamily: 'Akkurat Mono LL, monospace',
  },
  attributeValue: {
    fontSize: 14,
    lineHeight: 1.55,
    fontWeight: 400,
    letterSpacing: 0
  },
  hubAdmin: {
    backgroundColor: theme.custom.green,
    alignSelf: 'flex-start',
    fontSize: 16,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    padding: '2px 13px',
    borderRadius: 3
  },
  icon: {
    color: theme.custom.grayDark,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  }
}));

type Props = {
  className?: string,
  withName?: boolean,
};

const Profile = ({ className, withName = true }: Props) => {
  const classes = useStyles();
  const user = auth0.getUser();
  const isHubAdmin = auth0.isAdmin();
  const company = useObservable('company');
  const roles = [auth0.hasRole('hub') ? 'Admin' : 'Editor'];
  auth0.getUserAccess('__users__') && roles.push('User Manager');
  return (
    <div className={clsx(classes.root, className, {[classes.rootClosed]: !withName})}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.picture}
        to="/"
      />
      {withName && (
        <>
          <Typography
            className={classes.name}
            variant="h4"
          >
            {user.name}
          </Typography>
          {!isHubAdmin && (
            <>
              <Typography
                className={classes.attributeName}
                variant="h5"
              >
                Company
              </Typography>
              <Typography
                className={classes.attributeValue}
                variant="h5"
              >
                {company.name}
              </Typography>
            </>
          )}
          <Typography
            className={classes.attributeName}
            variant="h5"
          >
            Permissions
          </Typography>
          <Typography
            className={clsx(classes.attributeValue, {[classes.hubAdmin]: isHubAdmin})}
            variant="h5"
          >
            {isHubAdmin &&
              <SettingsApplicationsIcon
                className={classes.icon}
                fontSize={'small'}
              />
            }
            {roles.join(', ')}
          </Typography>
        </>
      )}
    </div>
  );
};

export default Profile;
