import { Subject } from 'rxjs';

/**
 * Config global class.
 */
class EventManager {

  /**
   * A map object with entity collections and event prefixes.
   */
  private static entityMap: Data = {
    brands: 'BRAND',
    companies: 'COMPANY',
    mrps: 'PROGRAM',
    offers: 'OFFER',
  }

  /**
   * An object of subjects.
   */
  private subjects: Data = {};

  /**
   * Returns event prefix.
   */
  getEventPrefix(collection: string) {
    return EventManager.entityMap[collection] || 'ENTITY';
  }

  /**
   * Dispatches an event with the given payload data object.
   */
  dispatch(name: string, data?: Data) {
    this.subjects[name] || (this.subjects[name] = new Subject());
    this.subjects[name].next(data);
  }

  /**
   * Adds a listener to an event.
   */
  on(name: string, handler: any) {
    this.subjects[name] || (this.subjects[name] = new Subject());
    return this.subjects[name].subscribe(handler);
  }

}

export default new EventManager();
