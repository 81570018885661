import auth0  from 'services/Auth0';
import { signedOut } from 'services/typewriter';
import {
  segmentContextObject,
  segmentUserObject
} from './helpers';
import { flatten } from 'inc/utils';

export default async () => {
  const company = flatten(await auth0.company() || {});
  const userData = segmentUserObject(auth0.getUser());
  signedOut({
    context: segmentContextObject(company),
    username: userData['username'],
    user: userData,
  });
};
