import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

type Props = {
  state: ModerationState,
  className?: string,
}

const useStyles = makeStyles(() => ({
  container: {},
  circle: {
    borderRadius: '50%',
    display: 'inline-block',
    height: '8px',
    marginRight: '8px',
    width: '8px',
    verticalAlign: 'middle',
  }
}));

function State({ state, className }: Props) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.container, className)}>
      <span
        className={classes.circle}
        style={{ background: state.color }}
      />
      {state.name}
    </div>
  );
}

export default State;
