import { Subject } from 'rxjs';

const stores: any = {};
const createStore = (name: string, initialState: any) => {
  if (name in stores) {
    throw new Error('Store already exists.');
  }
  const subject = new Subject();
  stores[name] = {
    init: () => {
      subject.next(initialState);
    },
    subscribe: (setState: any) => subject.subscribe(setState),
    clear: () => {
      subject.next(initialState);
    },
    get: function () {
      return this.state;
    },
    set: function (value: any) {
      this.state = value;
      subject.next(value);
    },
    initialState,
    state: initialState,
  };
}

createStore('data.loading', false);
createStore('page.loading', false);
createStore('page.message', { text: '', type: 'info' });
createStore('company', { id: null, name: '' });

export default (name: string) => {
  if (!(name in stores)) {
    throw new Error('No store found.');
  }
  return stores[name];
};
