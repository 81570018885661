import React, { useState } from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import FieldWrapper from './FieldWrapper';
import useField from 'inc/hooks/useField';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
  multipleTextField: {
    marginBottom: theme.spacing(1),
  },
  multipleTextFieldLinkWrapper: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
  },
  multipleTextFieldLink: {
    backgroundColor: theme.custom.orange,
    borderRadius: 0,
    color: theme.custom.black,
    padding: '8px 10px',
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: '16px',
    lineHeight: '1.2',
    '&:hover, &:focus': {
      background: theme.custom.orangeDark
    }
  },
}));

type Props = {
  addMoreText?: string,
  defaultNumLines?: number,
  helperText?: React.ReactNode | string,
  helperTextTooltip?: React.ReactNode | string,
  helperTextBottom?: React.ReactNode | string,
  label?: string,
  name: string,
  required?: boolean,
  variant?: 'outlined' | 'filled' | undefined,
} & Omit<TextFieldProps, 'variant'>;

function MultipleTextField({ addMoreText = 'Add More', defaultNumLines = 3, label, helperText, helperTextTooltip, helperTextBottom, name, required, variant, ...props }: Props) {
  const classes = useStyles();
  const [itemsCount, setItemsCount] = useState(defaultNumLines);
  const { error, editValue, setValue, getValues } = useField(name, {
    callback: (data: Document.Base) => {
      const items = Array.isArray(data[name]) ? data[name] : [];
      setItemsCount(items.length > defaultNumLines ? items.length : defaultNumLines);
      items.length && setValue(items);
    },
  });
  return (
    <FieldWrapper
      helperText={helperText}
      helperTextBottom={helperTextBottom}
      helperTextTooltip={helperTextTooltip}
      label={label}
      name={name}
      required={required}
    >
      {[...Array(itemsCount)].map((v: any, index: number) => (
        <MuiTextField
          className={classes.multipleTextField}
          defaultValue={(Array.isArray(editValue) ? editValue : [])[index]}
          error={!!error}
          fullWidth
          key={(editValue ? 'edit' : 'add') + index}
          margin="none"
          name={`${name}[${index}]`}
          onChange={(e) => {
            const values = getValues();
            const val = Array.isArray(values[name]) ? values[name] : [];
            val[index] = e.target.value;
            setValue(val, false, true);
          }}
          variant={variant ? variant : 'outlined'}
          {...props}
        />
      ))}
      <div className={classes.multipleTextFieldLinkWrapper}>
        <Button
          className={classes.multipleTextFieldLink}
          color="primary"
          onClick={() => {
            setItemsCount(itemsCount + 1);
          }}
          variant="contained"
        >
          <AddIcon style={{ fontSize: '16px' }} /> {addMoreText}
        </Button>
      </div>
    </FieldWrapper>
  );
}

export default MultipleTextField;
