import * as yup from 'yup';
import RedemptionSchema from './Redemption';
import { message, assetSchema, bundleSchema, richSchema } from './helpers';
import { filterEmptyData, isObject } from 'inc/utils';
import auth0 from 'services/Auth0';

export const OfferCreateSchema = yup.object().shape({
  name: yup.string().required(message('required', 'Label')),
  brand: yup.string().required(message('required', 'Brand')),
  weight: yup.number().transform(function(value) {
    return value ? value : 0;
  }),
  content: yup.object().shape({
    title: yup.string().required(message('required', 'Title')),
    value: yup.string().required(message('required', 'Offer Value')),
    audience: yup.string().required(message('required', 'Audience Segment')),
    banner: yup.object().shape({
      image: assetSchema(),
    }),
    category: yup.mixed().test('primary-category', message('required', 'Primary Category'), (value) => {
      return !!value || !auth0.isAdmin();
    }),
    testimonial: yup.lazy((data?: Data) => {
      const filled = isObject(filterEmptyData(data || {}), true);
      return yup.object().shape({
        signature: bundleSchema('Signature', filled),
        text: bundleSchema('Text', filled),
      });
    }),
    benefits: yup.lazy((data?: Data) => {
      const filled = isObject(filterEmptyData(data || {}), true);
      return yup.object().shape({
        headline: bundleSchema('Headline', filled),
        description: bundleSchema('Description', filled),
      });
    }),
    mcc_category: yup.string().required(message('required', 'Merchant Category')),
    summary: richSchema.required(message('required', 'Summary')),
    image: assetSchema(true),
    details: yup.array().required(message('required', 'Details')),
    redemption_types: yup.string().required('Should be at least 1 redemption method added.'),
    redemptions: yup.lazy((value?: Data) => {
      return yup.object().shape(
        Object.keys(value || {})
          .reduce((a: Data, key: string) => {
            const [type, ] = key.split('-');
            a[key] = RedemptionSchema[type as Redemption.Type]
              ? yup.object().shape(RedemptionSchema[type as Redemption.Type])
              : yup.mixed();
            return a;
          }, {})
      );
    }),
    flags: yup.object().shape({
      exclusive: yup.boolean(),
      featured: yup.boolean(),
      limited_time: yup.boolean(),
      community: yup.boolean(),
      new: yup.boolean(),
      unpublish_expired: yup.boolean(),
      unverified: yup.boolean(),
    }),
    // competing_brands
  }),
});
