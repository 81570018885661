import {useEffect, useState} from 'react';
import api from 'services/api';
import auth0 from 'services/Auth0';
import axios from 'axios';

type Props = {
  component: (items: Document.Base[], loading: boolean) => React.ReactElement | null,
  condition?: ApiConditionItem[],
  loadComponent?: React.ReactElement | null,
  onLoad?: (items: CategoryItem[]) => void,
  order?: ListOrder,
  path: string,
  provider?: 'b2b' | 'ls',
  waitForLoad?: boolean,
}

const ServiceList = ({ component, condition = [], loadComponent, order, onLoad, path, provider, waitForLoad = true }: Props) => {
  const [items, setItems] = useState<Document.Base[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let mount = true;
    setLoading(true);
    const source = axios.CancelToken.source();
    mount && api.use(provider || (auth0.canFetch(path) ? 'b2b' : 'ls'))
      .path(path)
      .getAll(({ condition, order }))
      .then((items) => {
        setItems((items as CategoryItem[]));
        onLoad && onLoad((items as CategoryItem[]));
      }).finally(() => {
        setLoading(false);
      });
    return () => {
      mount = false;
      source && source.cancel();
    };
  }, condition.map(item => item.value));
  if (waitForLoad && loading) {
    return loadComponent || null;
  }
  return component(items || [], loading);
};

export default ServiceList;
