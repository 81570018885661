import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import api from 'services/api';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import { Theme } from '@material-ui/core';
import { Grid, GridContainer } from '../Layout';
import Button from '@material-ui/core/Button';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import clsx from 'clsx';

type Props = {
  document: Data,
};

const useStyles = makeStyles((theme: Theme) => ({
  offerHero: {
    position: 'relative',
  },
  offerHeroImage: {
    '& img': {
      position: 'relative',
      zIndex: 0,
      width: '100%',
      maxWidth: '100%',
      height: 'auto',
      display: 'block',
    },
    '&:before': {
      content: '" "',
      display: 'block',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.custom.slate,
      opacity: 0.75,
      zIndex: 1,
    },
  },
  offerHeroWithoutImage: {
    minHeight: 459,
    backgroundColor: theme.custom.black,
  },
  offerHeroText: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  offerHeroTitle: {
    fontSize: 28,
    color: theme.custom.white,
    marginBottom: 5,
  },
  offerHeroDescription: {
    color: theme.custom.white,
    marginBottom: 5,
  },
  offerHeroCaption: {
    position: 'absolute',
    left: 0,
    top: -21,
    backgroundColor: theme.custom.orange,
    padding: 10,
    color: theme.custom.black,
    fontSize: 16,
    zIndex: 3
  },
  offerContent: {
    paddingTop: 87,
    margin: '0 auto',
    maxWidth: 800,
  },
  offerContentMain: {
    position: 'relative',
    backgroundColor: theme.custom.grayLight,
    marginBottom: theme.spacing(4.5),
    padding: theme.spacing(7.5, 5, 5),
  },
  offerContentMainLogo: {
    padding: 10,
    backgroundColor: theme.custom.white,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.custom.grayMedium,
    width: 193,
    height: 76,
    position: 'absolute',
    left: 40,
    top: -38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: 'auto',
      height: 'auto',
      maxWidth: 173,
      maxHeight: 56
    }
  },
  offerContentMainTitle: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: 0,
    marginBottom: theme.spacing(2),
  },
  offerContentMainSummary: {
    '& p:last-child': {
      marginBottom: 0,
    }
  },
  offerContentDetails: {
    marginBottom: theme.spacing(4.5),
    padding: theme.spacing(0, 3),
  },
  offerContentDetailsTitle: {
    fontSize: 16,
    lineHeight: 1.375,
    fontWeight: 400,
    letterSpacing: 0,
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.custom.slate,
    marginBottom: theme.spacing(2),
  },
  offerContentDetailsSummary: {
    fontSize: 12,
  },
  offerContentDetailsSummaryList: {
    margin: 0,
    paddingLeft: theme.spacing(3),
    '& li': {
      marginBottom: theme.spacing(2),
    }
  },
  offerContentTerms: {
    marginBottom: theme.spacing(4.5),
    padding: theme.spacing(0, 3),
  },
  offerContentTermsTitle: {
    fontSize: 16,
    lineHeight: 1.375,
    fontWeight: 400,
    letterSpacing: 0,
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.custom.slate,
    marginBottom: theme.spacing(2),
  },
  offerContentTermsSummary: {
    fontSize: 10,
    lineHeight: 1.2,
    fontStyle: 'italic',
    padding: theme.spacing(0, 1),
  },
  offerRedemption: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4.5),
    backgroundColor: theme.custom.grayLight,
    padding: theme.spacing(2.5, 5),
  },
  offerRedemptionTitle: {
    fontSize: 14,
    lineHeight: 1.571,
    fontWeight: 400,
    letterSpacing: 0,
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.custom.slate,
    marginBottom: theme.spacing(2),
  },
  offerRedemptionSummary: {
    textAlign: 'center',
  },
  offerRedemptionButton: {
    fontSize: 16,
    padding: theme.spacing(1, 2),
  },
  offerTestimonial: {
    marginBottom: theme.spacing(4.5),
    padding: theme.spacing(3, 5, 3, 9),
  },
  offerTestimonialQuote: {
    fontSize: 18,
    lineHeight: 1.44,
    fontWeight: 300,
    margin: theme.spacing(2, 0),
  },
  offerTestimonialText: {
    position: 'relative'
  },
  offerTestimonialAuthor: {
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: 0,
  },
  offerQuoteIcon: {
    fontSize: 32,
    position: 'absolute',
  },
  offerQuoteIconTop: {
    left: theme.spacing(-6),
    top: theme.spacing(-2),
  },
  offerQuoteIconBottom: {
    right: theme.spacing(-4.5),
    bottom: theme.spacing(-2),
    transform: 'rotate(-180deg)',
  },
  offerBenefits: {
    marginBottom: theme.spacing(4.5),
  },
  offerBenefitsHeadline: {
    backgroundColor: theme.custom.slate,
    color: theme.custom.white,
    fontSize: 18,
    lineHeight: 1.4,
    fontWeight: 400,
    letterSpacing: 0,
    padding: theme.spacing(1, 3),
  },
  offerBenefitsDescription: {
    backgroundColor: theme.custom.grayLight,
    fontSize: 10,
    lineHeight: 1.6,
    padding: theme.spacing(2, 3),
  }
}));

function Offer({ document }: Props) {
  const classes = useStyles();
  const [asset, setAsset] = useState<Document.Base>();
  // eslint-disable-next-line react/no-multi-comp
  const QuoteIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon
        viewBox="0 0 32 32"
        {...props}
      >
        <path d="M12.751 26.1396C9.96551 28.7294 5.22181 28.0447 3.00076 25.3024C0.372057 22.0636 -0.152813 15.9253 4.42674 10.4343C6.69825 7.71071 9.43579 5.6457 12.6366 4.23926L14.1128 6.61681C9.55956 8.82321 6.15638 11.8147 5.67412 16.8303C7.72847 16.8303 9.43292 16.7558 10.9511 17.2991C12.6795 17.9175 13.7293 19.0646 14.1602 20.3798C14.8437 22.4392 14.5124 24.5019 12.751 26.1396ZM29.2599 26.1396C26.4745 28.7294 21.7308 28.0447 19.5097 25.3024C16.881 22.0636 16.3561 15.9253 20.9357 10.4343C23.2072 7.71071 25.9447 5.6457 29.1455 4.23926L30.6217 6.61681C26.0685 8.82321 22.6653 11.8147 22.1831 16.8303C24.2374 16.8303 25.9419 16.7558 27.46 17.2991C29.1884 17.9175 30.2383 19.0646 30.6692 20.3798C31.3527 22.4392 31.0213 24.5019 29.2599 26.1396Z" />
      </SvgIcon>
    );
  };
  useEffect(() => {
    if (!document.brand) return;
    (async () => {
      const brand = await api.path('brands').get(document.brand);
      const assetId = brand?.content?.logo?.asset;
      if (assetId) {
        setAsset(await api.path('assets').get(assetId));
      }
    })();
  }, [document.brand]);
  return (
    <>
      <div
        className={classes.offerHero}
      >
        {!!document['content.banner.image.__preview'] && (
          <div className={classes.offerHeroImage}>
            <img
              src={document['content.banner.image.__preview']}
            />
          </div>
        )}
        {!document['content.banner.image.__preview'] &&
        (!!document['content.banner.title'] || !!document['content.banner.description']) && (
          <div className={classes.offerHeroWithoutImage}/>
        )}
        {(!!document['content.banner.title'] || !!document['content.banner.description']) && (
          <div className={classes.offerHeroText}>
            {!!document['content.banner.title'] && (
              <Typography
                className={classes.offerHeroTitle}
                component="h1"
                variant="h1"
              >
                {document['content.banner.title']}
              </Typography>
            )}
            {!!document['content.banner.description'] && (
              <Typography
                className={classes.offerHeroDescription}
                component="p"
                variant="body1"
              >
                {document['content.banner.description']}
              </Typography>
            )}
          </div>
        )}
        <div className={classes.offerHeroCaption}>
          {'OFFER PAGE PREVIEW'}
        </div>
      </div>
      <div className={classes.offerContent}>
        <GridContainer>
          <Grid col={8}>
            <div className={classes.offerContentMain}>
              <div className={classes.offerContentMainLogo}>
                {(asset && asset.file) ? (
                  <img
                    src={asset.file}
                  />
                ) : (
                  <CircularProgress />
                )}
              </div>
              <Typography
                className={classes.offerContentMainTitle}
                component="h3"
                variant="h3"
              >
                {document['content.title']}
              </Typography>
              <div
                className={classes.offerContentMainSummary}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: document['content.summary'] }}
              />
            </div>
            <div className={classes.offerContentDetails}>
              <Typography
                className={classes.offerContentDetailsTitle}
                component="h3"
                variant="h3"
              >
                Details of the Offer
              </Typography>
              <div className={classes.offerContentDetailsSummary}>
                {Array.isArray(document['content.details']) && (
                  <ul className={classes.offerContentDetailsSummaryList}>
                    {document['content.details']
                      .map((t: string, index: number) =>
                        <React.Fragment key={index}>
                          <li>{t}</li>
                        </React.Fragment>
                      )}
                  </ul>
                )}
              </div>
            </div>
            {!!document['content.testimonial.text'] && (
              <div className={classes.offerTestimonial}>
                <div className={classes.offerTestimonialText}>
                  <QuoteIcon className={clsx(classes.offerQuoteIcon, classes.offerQuoteIconTop)} />
                  <blockquote
                    className={classes.offerTestimonialQuote}
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: document['content.testimonial.text'] }}
                  />
                  <QuoteIcon className={clsx(classes.offerQuoteIcon, classes.offerQuoteIconBottom)} />
                </div>
                <Typography
                  className={classes.offerTestimonialAuthor}
                  component="h3"
                  variant="h3"
                >
                  {document['content.testimonial.signature']}
                </Typography>
              </div>
            )}
            {!!document['content.benefits.headline'] && (
              <div className={classes.offerBenefits}>
                <Typography
                  className={classes.offerBenefitsHeadline}
                  component="h3"
                  variant="h3"
                >
                  {document['content.benefits.headline']}
                </Typography>
                <div
                  className={classes.offerBenefitsDescription}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: document['content.benefits.description'] }}
                />
              </div>
            )}
            {!!document['content.terms_conditions'] && (
              <div className={classes.offerContentTerms}>
                <Typography
                  className={classes.offerContentTermsTitle}
                  component="h3"
                  variant="h3"
                >
                  Terms &amp; Conditions
                </Typography>
                <div
                  className={classes.offerContentTermsSummary}
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: document['content.terms_conditions'] }}
                />
              </div>
            )}
          </Grid>
          <Grid col={4}>
            <div className={classes.offerRedemption}>
              <Typography
                className={classes.offerRedemptionTitle}
                component="h3"
                variant="h3"
              >
                Online Redemption
              </Typography>
              <div className={classes.offerRedemptionSummary}>
                <Button
                  className={classes.offerRedemptionButton}
                  color="primary"
                  href={document['content.redemptions.online.attributes.url']}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="contained"
                >
                  Get your offer
                </Button>
              </div>
            </div>
          </Grid>
        </GridContainer>
      </div>
    </>
  );
}

export default Offer;
