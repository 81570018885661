import React from 'react';
import {createStyles, makeStyles, Theme, withStyles, WithStyles} from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: '0 10px',
      padding: '20px 25px',
      borderBottom: '1px solid ' + theme.custom.grayLight,
    },
    title: {
      fontSize: '18px',
      lineHeight:'160%',
    },
    closeButton: {
      fontSize: '12px',
      fontFamily: 'Akkurat Mono LL, monospace',
      letterSpacing: '0.16em',
      position: 'absolute',
      right: 35,
      top: 20,
      color: theme.custom.red,
      textDecoration: 'underline',
      '&:hover, &:focus': {
        textDecoration: 'none'
      }
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  closeTitle: string,
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, closeTitle, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      className={classes.root}
      disableTypography
      {...other}
    >
      <Typography
        className={classes.title}
        component="p"
      >
        {children}
      </Typography>
      {onClose ? (
        <a
          className={classes.closeButton}
          href="#"
          onClick={(e) => { e.preventDefault(); onClose(); }}
        >
          {closeTitle}
        </a>
      ) : null}
    </MuiDialogTitle>
  );
});

const contentStyles = () =>
  createStyles({
    root: {
      padding: '56px 50px',
      border: '0 none'
    },
    narrow: {
      padding: '38px 99px',
      border: '0 none'
    }
  });

export interface DialogContentProps extends WithStyles<typeof contentStyles> {
  children: React.ReactNode;
  width?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined,
  dividers?: boolean;
}

const DialogContent = withStyles(contentStyles)((props: DialogContentProps) => {
  const { children, classes, width, dividers, ...other } = props;
  return (
    <MuiDialogContent
      className={width === 'xs' ? classes.narrow : classes.root}
      dividers={dividers}
      {...other}
    >
      {children}
    </MuiDialogContent>
  );
});


type Props = {
  actions?: {
    confirmTitle?: string,
    onClick: (data: Data, handleClose: () => void, setLoading: (loading: boolean) => void) => void,
  },
  component?: (data: Data) => React.ReactElement | null,
  data?: Data,
  handleClose: () => void,
  hideHeader?: boolean,
  loading: boolean,
  open: boolean,
  setLoading: (loading: boolean) => void,
  title: string,
  closeTitle?: string,
  width?: false | 'md' | 'xs' | 'sm' | 'lg' | 'xl' | undefined,
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    justifyContent: 'center',
    paddingBottom: 50,
    '& > .MuiButton-root': {
      minWidth: theme.spacing(30),
    }
  },
}));

const Dialog = ({ actions, component, loading, open, data = {}, handleClose, setLoading, hideHeader, closeTitle, title, width }: Props) => {
  const classes = useStyles();
  return (
    <MuiDialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      maxWidth={width ? width : 'md'}
      onClose={handleClose}
      open={open}
    >
      {!hideHeader &&
        <DialogTitle
          closeTitle={closeTitle || 'Cancel'}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </DialogTitle>
      }
      {loading ? <LinearProgress/> : <div style={{height: '4px'}} />}
      <DialogContent
        dividers
        width={width}
      >
        { component && component(data) }
      </DialogContent>
      {!!actions && (
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            data-testid="dialog-form-submit"
            onClick={() => { actions.onClick(data, handleClose, setLoading) }}
            variant="contained"
          >
            {actions.confirmTitle || 'Confirm'}
          </Button>
        </DialogActions>
      )}
      {/* <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Save changes
        </Button>
      </DialogActions> */}
    </MuiDialog>
  );
};

export default Dialog;
