import { offerPublished } from 'services/typewriter';
import { flatten } from '../../../utils';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentOfferObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const offer = flatten(values);
  const brand = await getEntity('brands', offer['brand']);
  const company = await getEntity('companies', brand['company']);
  offerPublished({
    context: segmentContextObject(company, brand),
    brand: segmentBrandObject(brand, company),
    company: segmentCompanyObject(company),
    offer: segmentOfferObject(offer),
  });
};
