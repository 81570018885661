import React, { useEffect, useState } from 'react';
import {withRouter, RouteComponentProps, NavLink} from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import {
  FieldWrapper,
} from 'components/Form';
import { State, ToggleBox } from 'components/Display';
import { ReadOnly, PageBlock } from 'components/Layout';
import useFormCrud from 'inc/hooks/useFormCrud';
import { OfferCreateSchema } from 'inc/validation';
import merchantCategories from 'data/merchantCategories.json';
import categories from 'data/offerCategories.json';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import useDocumentHistory from 'inc/hooks/useDocumentHistory';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Moderation from './components/Moderation';
import Message from './components/Message';
import Typography from '@material-ui/core/Typography';
import helperTexts from 'inc/constants/fields/offer';
import { ServiceEntity } from 'containers';
import moderationStates from 'data/states.json';
import TablePagination from 'components/Table';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { editStyles } from 'theme/styles';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Offer>;

const useStyles = makeStyles((theme: Theme) => ({
  ...editStyles(theme),
}));

const OfferEdit = ({ history, match }: Props) => {
  const classes = useStyles();
  const [data, setData] = useState<Data>({});
  const { handleEditSubmit, docId, methods, values } = useFormCrud({
    id: match.params['offerId'] || '',
    revisionId: match.params['revisionId'] || '',
    path: 'offers',
    schema: OfferCreateSchema,
    success: () => history.push('/offers'),
    process: (data) => {
      const redemptions: Data = {};
      (data.content?.redemptions ?? []).forEach((redemption: Data) => {
        const { type } = redemption;
        redemptions[type] = redemption;
      });
      return {
        ...data,
        content: {
          ...data.content,
          redemptions,
        },
      };
    }
  });
  const { history: docHistory } = useDocumentHistory('offers', docId, values);
  const prepareData = async (values: Document.Base) => {
    const {
      content = {},
    } = values;
    return {
      ...values,
      content: {
        ...values.content,
        keywords: (content.keywords || '').split(','),
        redemptions: (values['__redemptions__order'] || []).map((uuid: string) => {
          const [rId, ] = uuid.split('-');
          const { roles, instructions, attributes } = (content.redemptions || {})[uuid] || {};
          return { roles, instructions, attributes, type: rId };
        })
      }
    }
  }
  useEffect(() => {
    if (values[FORM_STATE_LOADED]) {
      setData(values[FORM_STATE_DATA] || {});
    }
  }, [values[FORM_STATE_LOADED]]);
  const fetch = () => {
    return new Promise<Document.Base[]>(resolve => resolve(docHistory));
  }
  const fields = [
    {
      id: 'name',
      label: 'Version',
      sortable: true,
      width: '25%',
    },
    {
      id: 'by.name',
      label: 'Submitted by',
      width: '25%',
      class: 'word-break',
    },
    {
      id: 'on',
      label: 'Submitted On',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
      width: '25%',
    },
    {
      id: 'to',
      label: 'Status',
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      prepare: (value: string) => {
        return (
          <State
            state={moderationStates.find(state => value === state.id) as ModerationState}
          />
        );
      }
    }
  ];
  return (
    <div className={classes.root}>
      {!!data['id'] && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
        >
          <ServiceEntity
            component={(entity) =>
              <span>{!!entity && entity.name}</span>
            }
            id={data['company']}
            path="companies"
          />
          <ServiceEntity
            component={(entity) =>
              <span>{!!entity && entity.name}</span>
            }
            id={data['brand']}
            path="brands"
          />
          <span>{data.name}</span>
        </Breadcrumbs>
      )}
      <div className={classes.titleWrapper}>
        <Typography
          className={classes.title}
          component="h2"
          variant="h2"
        >
          Manage <span>{data.name}</span>
        </Typography>
        {!!data['id'] && (
          <State
            className={classes.state}
            state={moderationStates.find(state => data['state'] === state.id) as ModerationState}
          />
        )}
      </div>
      <Typography
        component="p"
        variant="body2"
      >
        {helperTexts.sub_title}
      </Typography>
      {docId && 'needs_work' === data['state'] && (
        <Message>
          {(data['history'] ?? [])[0]?.comment || data['revision_log']}
        </Message>
      )}
      <FormContext {...methods}>
        <Moderation
          data={data}
          docId={docId}
          setData={setData}
          values={values}
        />
        <form onSubmit={methods.handleSubmit(handleEditSubmit({ prepare: prepareData }))}>
          <div className={classes.contentWrapper}>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.name}
                label="Offer Name"
                name="name"
              >
                <ReadOnly>
                  {data['name']}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.title}
                label="Offer Subtitle"
                name="content.title"
              >
                <ReadOnly>
                  {data['content.title']}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.mcc_category}
                label="Merchant Category Code"
                name="content.mcc_category"
              >
                <ReadOnly>
                  {merchantCategories.find(item => item.id === data['content.mcc_category'])?.name}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock adminView>
              <FieldWrapper
                label="Primary Category"
                name="content.category"
              >
                <ReadOnly>
                  {categories.find(item => item.id === data['content.category'])?.name}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.summary}
                label="Summary"
                name="content.summary"
              >
                <ReadOnly>
                  <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: data['content.summary'] }}
                  />
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.details}
                label="Details"
                name="content.details"
              >
                <ReadOnly>
                  {Array.isArray(data['content.details']) && (
                    <ul>
                      {data['content.details']
                        .map((t: string, index: number) =>
                          <React.Fragment key={index}>
                            <li>{t}</li>
                          </React.Fragment>
                        )}
                    </ul>
                  )}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            {!!data['content.terms_conditions'] &&
              <PageBlock>
                <FieldWrapper
                  helperText={helperTexts.terms_conditions}
                  label="Terms and Conditions"
                  name="content.terms_conditions"
                >
                  <ReadOnly>
                    <div
                      // eslint-disable-next-line
                      dangerouslySetInnerHTML={{ __html: data['content.terms_conditions'] }}
                    />
                  </ReadOnly>
                </FieldWrapper>
              </PageBlock>
            }
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.image}
                label="Offer Tile Image"
                name="content.image"
              >
                {!!data['content.image.asset'] && (
                  <ReadOnly>
                    <ServiceEntity
                      component={(entity) =>
                        <div>{!!entity && <img src={entity.file} />}</div>
                      }
                      id={data['content.image.asset']}
                      path="assets"
                    />
                  </ReadOnly>
                )}
              </FieldWrapper>
            </PageBlock>
            {!!data['content.banner.image.asset'] &&
              <PageBlock>
                <FieldWrapper
                  helperText={helperTexts.banner}
                  label="Offer page hero image"
                  name="content.banner.image"
                >
                  <ReadOnly>
                    <ServiceEntity
                      component={(entity) =>
                        <div>{!!entity && <img src={entity.file} />}</div>
                      }
                      id={data['content.banner.image.asset']}
                      path="assets"
                    />
                  </ReadOnly>
                </FieldWrapper>
              </PageBlock>
            }
            {!!data['content.banner.title'] &&
              <PageBlock>
                <FieldWrapper
                  helperText={helperTexts.banner_title}
                  label="Hero Title"
                  name="content.banner.title"
                >
                  <ReadOnly>
                    {data['content.banner.title']}
                  </ReadOnly>
                </FieldWrapper>
              </PageBlock>
            }
            {!!data['content.banner.description'] &&
              <PageBlock>
                <FieldWrapper
                  helperText={helperTexts.banner_description}
                  label="Hero Subtitle"
                  name="content.banner.description"
                >
                  <ReadOnly>
                    {data['content.banner.description']}
                  </ReadOnly>
                </FieldWrapper>
              </PageBlock>
            }
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.banner_description}
                label="Redemption Channels"
                name="content.redemptions"
              >
                <ToggleBox
                  description={helperTexts.redemptions}
                  label="Online"
                  open
                >
                  <FieldWrapper
                    label="Destination URL"
                    name="content.redemptions.online.url"
                  >
                    <ReadOnly>
                      {data['content.redemptions.online.attributes.url']}
                    </ReadOnly>
                  </FieldWrapper>
                </ToggleBox>
              </FieldWrapper>
            </PageBlock>
            <div className={classes.actions}>
              <Button
                className={classes.button}
                color="primary"
                component={NavLink}
                to={`/offers/create/${docId}`}
                variant="contained"
              >
                Edit
              </Button>
            </div>
          </div>
        </form>
      </FormContext>
      <div className={classes.history}>
        <Typography
          className={classes.subTitle}
          component="h3"
          variant="h2"
        >
          History Log
        </Typography>
        <TablePagination
          defaults={{
            pageRows: 50
          }}
          dependencies={[docHistory.length]}
          fetch={fetch}
          fields={fields}
        />
      </div>
    </div>
  );
};

export default withRouter(OfferEdit);
