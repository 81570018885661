import React from 'react';
import { Grid, GridContainer } from 'components/Layout';
import {
  TextField
} from 'components/Form';

type Props = {
  namePrefix?: string
}

const RedemptionOnline = ({ namePrefix = '' }: Props) => {
  return (
    <div>
      <GridContainer>
        <Grid>
          <TextField
            helperText="An offer link will be generated and automatically applied to the offer page. The destination URL will tell us where users can redeem the offer on your site and we’ll take care of the rest."
            label="Destination URL"
            name={`${namePrefix}attributes.url`}
            required
          />
        </Grid>
      </GridContainer>
    </div>
  );
};

export default RedemptionOnline;
