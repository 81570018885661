import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import OfferTabs from './OfferTabs';
import useServiceTable from 'inc/hooks/useServiceTable';
import TablePagination from 'components/Table';
import moment from 'moment';

const OfferRevisions = ({ match }: RouteComponentProps<MatchParams.Offer>) => {
  const { offerId } = match.params || {};
  const { fetchItems, listActions } = useServiceTable({
    path: 'offers',
    parentId: offerId,
    revision: true,
  });
  const fields = [
    {
      id: 'name',
      label: 'Label',
      sortable: true,
      width: '50%',
    },
    {
      id: 'updated.on',
      label: 'Updated',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
    {
      id: 'log',
      label: 'Message',
    }
  ];
  return <div>
    <OfferTabs
      docId={offerId}
      index={1}
    />
    <TablePagination
      actions={listActions}
      fetch={fetchItems}
      fields={fields}
    />
  </div>
}

export default withRouter(OfferRevisions);
