import React from 'react';
import TablePagination from 'components/Table';
import ListToolbar from 'components/Display/ListToolbar';
import useServiceTable from 'inc/hooks/useServiceTable';
import auth0 from 'services/Auth0';

const CompanyList = () => {
  const { fetchItems, listActions } = useServiceTable({
    path: 'companies',
    provider: auth0.isAdmin() ? 'b2b' : 'ls',
  });
  const fields = [
    {
      id: 'name',
      label: 'Name',
      sortable: true,
      width: '80%',
    },
  ];
  return (
    <div>
      <ListToolbar
        buttons={[{
          name: 'Add Company',
          path: '/companies/add'
        }]}
      />
      <TablePagination
        actions={listActions}
        fetch={fetchItems}
        fields={fields}
      />
    </div>
  );
};

export default CompanyList;
