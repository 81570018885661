import React from 'react';
import TablePagination from 'components/Table';
import ListToolbar from 'components/Display/ListToolbar';
import { Select } from 'components/Form';
import moderationStates from 'data/states.json';
import useCompanyId from 'inc/hooks/useCompanyId';
import useServiceTable from 'inc/hooks/useServiceTable';
import useForm, { FormContext } from 'react-hook-form';
import auth0 from 'services/Auth0';
import { State } from 'components/Display';
import { ServiceList } from 'containers';
import states from 'data/states.json';
import moment from 'moment';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  listToolbar: {
    [theme.breakpoints.down('md')]: {
      '& .tabs-wrapper': {
        flexWrap: 'wrap',
        height: 'auto'
      },
      '& .tabs-spacer': {
        width: '100%',
        marginBottom: 20
      }
    }
  },
  select: {
    marginLeft: theme.spacing(1)
  }
}));


const BrandList = () => {
  const classes = useStyles();
  const methods = useForm();
  const clientCompanyId = useCompanyId();
  const companyId = methods.watch('company') || clientCompanyId;
  const state = methods.watch('state-filter');
  const condition = [];
  companyId && condition.push({ field: 'company', value: companyId });
  state && condition.push({ field: 'state', value: state });
  const { fetchItems, listActions } = useServiceTable({
    moderate: true,
    path: 'brands',
    provider: auth0.canFetch('brands') ? 'b2b' : 'ls',
  });
  const fields: ListField[] = [
    {
      id: 'name',
      label: 'Title',
      sortable: true,
      width: '55%',
    },
    {
      id: 'updated.on',
      label: 'Updated',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
    {
      id: 'state',
      label: 'State',
      // eslint-disable-next-line react/display-name, react/no-multi-comp
      prepare: (value: string) => {
        const state = (states as ModerationState[]).find(s => s.id === value);
        return state ? <State state={state} /> : value;
      },
      width: '180px',
    },
  ];
  return (
    <div>
      {(auth0.isAdmin() || !!companyId) && (
        <FormContext {...methods}>
          <ListToolbar
            buttons={[
              {
                name: 'Add Brand',
                path: '/brands/create',
              }
            ]}
            className={classes.listToolbar}
            tabs={[
              {
                name: 'All',
                path: '/brands'
              },
              {
                name: 'Live',
                path: '/brands/published'
              }
            ]}
          >
            {auth0.isAdmin() && (
              <ServiceList
                component={(items, loading) =>
                  <Select
                    disabled={loading}
                    label="Company"
                    name="company"
                    options={items}
                  />
                }
                path="companies"
                waitForLoad={false}
              />
            )}
            <div className={classes.select}>
              <Select
                label="State"
                name="state-filter"
                options={moderationStates}
              />
            </div>
          </ListToolbar>
          <TablePagination
            actions={listActions}
            condition={condition}
            fetch={fetchItems}
            fields={fields}
          />
        </FormContext>
      )}
    </div>
  );
};

export default BrandList;
