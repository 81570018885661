import React from 'react';
import MuiGrid from '@material-ui/core/Grid';
import withAccess from 'components/Visibility/withAccess';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Theme } from '@material-ui/core';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode,
  col?: false | true | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12,
  className?: string,
} & typeof MuiGrid;

const useStyles = makeStyles((theme: Theme) => ({
  pageBlock: {
    '&.admin-wrapper': {
      padding: theme.spacing(3),
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.green,
      backgroundColor: fade(String(theme.custom.green), 0.18),
      marginLeft: 4,
      marginRight: 4,
      fontFamily: 'Akkurat Mono LL, monospace',
    }
  }
}));

function Grid({ col = 12, children, className, ...props }: Props) {
  const classes = useStyles();

  return (
    <MuiGrid
      className={clsx(classes.pageBlock, className)}
      item
      md={col}
      xs={col}
      {...props}
    >
      {children}
    </MuiGrid>
  );
}

export default withAccess(Grid);
