import React, { useState } from 'react';
import clsx from 'clsx';
import MuiCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import withAccess from 'components/Visibility/withAccess';

type Props = {
  children: React.ReactNode,
  description?: string,
  disabled?: boolean,
  label: string,
  onChange?: (open: boolean) => void,
  open?: boolean,
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#f4f6f8',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
  },
  content: {
    paddingLeft: theme.spacing(4),
  },
  description: {
    fontSize: theme.spacing(1.5),
  },
  disabled: {
    color: '#898989',
  }
}));

function ToggleBox({ children, description, disabled, label, onChange, open: pOpen = false }: Props) {
  const [open, setOpen] = useState(pOpen);
  const classes = useStyles();
  return (
    <div
      className={clsx({
        [classes.container]: true,
        [classes.disabled]: disabled,
      })}
    >
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={open}
            color="primary"
            disabled={disabled}
            onChange={() => { setOpen(!open); onChange && onChange(!open); }}
            value
          />
        }
        label={label}
      />
      <div className={classes.content}>
        <div className={classes.description}>{description}</div>
        {open && children}
      </div>
    </div>
  );
}

export default withAccess(ToggleBox);
