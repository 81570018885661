import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { NavLink as RouterLink } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PerfectScrollbar from 'react-perfect-scrollbar';

type Props = {
  actions?: List.Action[],
  fields: ListField[],
  items: Document.Base[],
  listActions?: Data,
  loading?: boolean,
  onFileSelect: (doc: Document.Base) => void,
  order: ListOrder,
  page?: number,
  pageRows?: number,
  total?: number,
  paginationProps?: Data,
}

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 768,
    overflowX: 'auto'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function TableList({ actions = [], fields, items, loading, order, page = 0, pageRows = 10, total = 0, listActions = {}, paginationProps }: Props) {
  const classes = useStyles();
  const { setOrder, setPage, setPageRows } = listActions;
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageRows(event.target.value);
  };
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {loading ? <LinearProgress /> : <div style={{height: '4px'}} />}
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedItems.length === items.length}
                      color="primary"
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < items.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                  {fields.map(field => <TableCell key={field.id}>
                    <TableSortLabel
                      active={order.by === field.id}
                      data-testid={`sortable-${field.id}`}
                      direction={order.dir}
                      onClick={() => setOrder({ by: field.id, dir: 'desc' })}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>)}
                  {!!actions.length && (
                    <TableCell>Operations</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && !items.length && (
                  <TableRow>
                    <TableCell colSpan={fields.length + 2}>
                      There are no items found.
                    </TableCell>
                  </TableRow>
                )}
                {items.map((item: Document.Base) => (
                  <TableRow
                    data-testid={`table-row-${item.id}`}
                    hover
                    key={item.id}
                    // selected={selectedItems.indexOf(item.id) !== -1}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.indexOf(item.id) !== -1}
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell> */}
                    {fields.map(field => {
                      const value = item[field.id] || '';
                      return (
                        <TableCell
                          key={field.id}
                          style={{ width: field.width || 'auto' }}
                        >
                          {field.prepare ? field.prepare(value, item) : value}
                        </TableCell>
                      );
                    })}
                    {!!actions.length && (
                      <TableCell>
                        {actions.length && actions.map((action, index): React.ReactNode | string => {
                          if (action.component) {
                            return (
                              <a
                                href="/"
                                key={index}
                                onClick={(e) => { e.preventDefault(); }}
                              >{action.label}</a>
                            )
                          }
                          if (action.callback) {
                            return (
                              <a
                                href="/"
                                key={index}
                                onClick={(e) => { e.preventDefault(); action.callback && action.callback(item); }}
                              >{action.label}</a>
                            )
                          }
                          if (action.url && typeof action.url === 'function') {
                            return (
                              action.external ? (
                                <a
                                  className={action.class}
                                  href={action.url(item as Document.Base)}
                                  key={index}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >{action.label}</a>
                              ) : (
                                <RouterLink
                                  className={action.class}
                                  key={index}
                                  to={action.url(item as Document.Base)}
                                >{action.label}</RouterLink>
                              )
                            )
                          }
                          return action.label;
                        }).reduce((prev, curr) => [prev, ' | ', curr])}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <MuiTablePagination
          component="div"
          count={+total}
          labelDisplayedRows={({from, to}) => `${from}-${to}`}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={+page}
          rowsPerPage={+pageRows}
          rowsPerPageOptions={[1, 2, 5, 10, 25]}
          {...paginationProps}
        />
      </CardActions>
    </Card>
  );
}

export default TableList;
