export const APP_INIT_LOAD    = 0;
export const APP_INIT_SUCCESS = 1;
export const APP_INIT_ERROR   = 2;

export const FORM_STATE_DATA    = '__object';
export const FORM_STATE_LOADED  = '__loaded';

export const ACCESS_READ        = 0x1;
export const ACCESS_LIST        = 0x2;
export const ACCESS_CREATE      = 0x4;
export const ACCESS_UPDATE      = 0x8;
export const ACCESS_DELETE      = 0x10;
export const ACCESS_FETCH       = 0x20;

export const FILE_DEFAULT       = 0x0;
export const FILE_UPLOADING     = 0x1;
export const FILE_REMOVED       = 0x2;
export const FILE_LOADING       = 0x4;
export const FILE_ERROR         = 0x8;
export const FILE_ABORT         = 0x16;

export const UI_MESSAGES        = {
  documentCreated:    'The document has been created.',
  documentDeleted:    'The document has been deleted.',
  documentModerated:  'The document has been moderated.',
  documentUpdated:    'The document has been updated.',
  documentError:      'The were errors occurred.'
}

export const VALUE_NULL = Symbol.for('NULL');

export const ERROR_MESSAGES: any = {
  'data.required': 'There are some missing required fields. Please fill them.',
  'access.document.tune': 'The offer can\'t be published since its tune one was not yet created.',
  'access.document.publish.validator': 'The document can\'t be published since not all fields have beed filled.',
}
