import config from 'services/Config';
import event from 'services/EventManager';
import {
  brandApproved,
  brandCreated,
  brandCreationStepCompleted,
  brandCreationStepViewed,
  brandDeleted,
  brandPublished,
  brandRejected,
  brandSubmittedForReview,
  brandUnpublished,
  brandUpdated,
  companyCreated,
  companyDeleted,
  companyUpdated,
  offerApproved,
  offerCreated,
  offerCreationStepCompleted,
  offerCreationStepViewed,
  offerDeleted,
  offerPublished,
  offerRejected,
  offerSubmittedForReview,
  offerUnpublished,
  programCodesDownloaded,
  programCreated,
  programCreationStepCompleted,
  programCreationStepViewed,
  userLogin,
  userLogout,
} from './segment/functions';

if (config.get('segmentKey')) {
  window.analytics.load(config.get('segmentKey'));
  event.on('BRAND_APPROVED', brandApproved);
  event.on('BRAND_CREATED', brandCreated);
  event.on('BRAND_CREATION_STEP_COMPLETED', brandCreationStepCompleted);
  event.on('BRAND_CREATION_STEP_VIEWED', brandCreationStepViewed);
  event.on('BRAND_DELETED', brandDeleted);
  event.on('BRAND_PUBLISHED', brandPublished);
  event.on('BRAND_REJECTED', brandRejected);
  event.on('BRAND_SUBMITTED_FOR_REVIEW', brandSubmittedForReview);
  event.on('BRAND_UNPUBLISHED', brandUnpublished);
  event.on('BRAND_UPDATED', brandUpdated);
  event.on('COMPANY_CREATED', companyCreated);
  event.on('COMPANY_DELETED', companyDeleted);
  event.on('COMPANY_UPDATED', companyUpdated);
  event.on('OFFER_APPROVED', offerApproved);
  event.on('OFFER_CREATED', offerCreated);
  event.on('OFFER_CREATION_STEP_COMPLETED', offerCreationStepCompleted);
  event.on('OFFER_CREATION_STEP_VIEWED', offerCreationStepViewed);
  event.on('OFFER_DELETED', offerDeleted);
  event.on('OFFER_PUBLISHED', offerPublished);
  event.on('OFFER_REJECTED', offerRejected);
  event.on('OFFER_SUBMITTED_FOR_REVIEW', offerSubmittedForReview);
  event.on('OFFER_UNPUBLISHED', offerUnpublished);
  event.on('PROGRAM_CODES_DOWNLOADED', programCodesDownloaded);
  event.on('PROGRAM_CREATED', programCreated);
  event.on('PROGRAM_CREATION_STEP_COMPLETED', programCreationStepCompleted);
  event.on('PROGRAM_CREATION_STEP_VIEWED', programCreationStepViewed);
  event.on('USER_LOGIN', userLogin);
  event.on('USER_LOGOUT', userLogout);
}
