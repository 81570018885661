import React, {useEffect, useState, useRef} from 'react';
import api from 'services/api';
import auth0 from 'services/Auth0';
import { flatten } from 'inc/utils';
import axios from 'axios';

type Props = {
  Component: React.ElementType,
  componentProps?: Data,
  condition?: ApiConditionItem[],
  onLoad?: (items: Document.Base[], setItem: (num: number | ((itemCount: number) => number)) => void) => void,
  order?: ListOrder,
  defaults?: ListConfigDefault,
  published?: boolean,
  path: string,
  reload?: number,
  provider?: 'b2b' | 'ls',
  revisionParentId?: string,
}

type ListConfigDefault = {
  order?: ListOrder,
  pageRows?: number,
}

function usePrevious(value: number) {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ServiceList = ({ Component, condition = [], defaults = {}, path, onLoad, order, provider, reload = 0, revisionParentId, published, componentProps = {} }: Props) => {
  const [items, setItems] = useState<Document.Base[]>([]);
  // const [order, setOrder] = useState<ListOrder>(defaults.order ?? { by: 'updated.on', dir: 'desc' });
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [total, setTotal] = useState(0);
  const [pageRows, setPageRows] = useState(defaults.pageRows ?? 10);
  const [lastLoaded, setLastLoaded] = useState<{ [k: number]: Document.Base | null }>({});
  const [loading, setLoading] = useState(false);
  const [, setItemChangedLocally] = useState(0);
  const [itemChanged, setItemChanged] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const prevPage = usePrevious(page);
  useEffect(() => {
    let mount = true;
    setLoading(true);
    const source = axios.CancelToken.source();
    let after = null;
    if ((page - 1) in lastLoaded && prevPage !== page) {
      after = lastLoaded[page - 1] || {};
    }
    const afterId = ((after as Data) || {})[revisionParentId ? 'revision' : 'id'];
    const isNextPage = !!page && prevPage !== page;
    if (isNextPage && !afterId) {
      return;
    }
    setLoadingMore(isNextPage);
    setHasMorePages(isNextPage);
    mount && api.use(provider || (auth0.canFetch(path) ? 'b2b' : 'ls'))
      .path(path)
      .getAll({
        after: afterId,
        condition,
        limit: pageRows + 1,
        order,
        revision: revisionParentId ?? '',
        parentId: revisionParentId,
        published,
      })
      .then(async (items) => {
        const hasMore = !!items.length && !!items[pageRows];
        setHasMorePages(hasMore);
        setLastLoaded(lastLoaded => ({...lastLoaded, [page]: items.length > 1 ? items[items.length - 2] : null}));
        setTotal((page + 1) * pageRows + (hasMore ? 1 : 0));
        setItems(items.slice(0, pageRows).map((item: Document.Base) => flatten(item)));
        if (onLoad) {
          onLoad(items, setItemChangedLocally);
          // setItemChangedLocally(itemChangedLocally + 1);
        }
      }).finally(() => {
        setLoading(false);
      });
    return () => {
      mount = false;
      source && source.cancel();
    };
  }, [pageRows, page, [order?.by, order?.dir].filter(i => i).join(','), itemChanged, reload, condition.map(item => item.value).join('')]);
  return (
    <Component
      hasMorePages={hasMorePages}
      items={items}
      listActions={{
        // setOrder,
        setPage,
        setPageRows,
        setItemChanged,
      }}
      loading={loading}
      loadingMore={loadingMore}
      order={order}
      page={page}
      pageRows={pageRows}
      total={total}
      {...componentProps}
    />
  );
};

export default ServiceList;
