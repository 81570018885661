declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    custom: {
      black: React.CSSProperties['color'],
      blue: React.CSSProperties['color'],
      white: React.CSSProperties['color'],
      slate: React.CSSProperties['color'],
      divider: React.CSSProperties['color'],
      grayDarkest: React.CSSProperties['color'],
      grayDark: React.CSSProperties['color'],
      grayMedium: React.CSSProperties['color'],
      grayLight: React.CSSProperties['color'],
      grayBox: React.CSSProperties['color'],
      green: React.CSSProperties['color'],
      orange: React.CSSProperties['color'],
      orangeDark: React.CSSProperties['color'],
      red: React.CSSProperties['color'],
      pink: React.CSSProperties['color']
    }
  }
  interface ThemeOptions {
    custom: {
      black: React.CSSProperties['color'],
      blue: React.CSSProperties['color'],
      white: React.CSSProperties['color'],
      slate: React.CSSProperties['color'],
      divider: React.CSSProperties['color'],
      grayDarkest: React.CSSProperties['color'],
      grayDark: React.CSSProperties['color'],
      grayMedium: React.CSSProperties['color'],
      grayLight: React.CSSProperties['color'],
      grayBox: React.CSSProperties['color'],
      green: React.CSSProperties['color'],
      orange: React.CSSProperties['color'],
      orangeDark: React.CSSProperties['color'],
      red: React.CSSProperties['color'],
      pink: React.CSSProperties['color']
    }
  }
}

export default {
  black: '#000',
  blue: '#109CF1',
  white: '#fff',
  slate: '#001E33',
  divider: '#EBEFF2',
  grayDarkest: '#546079',
  grayDark: '#677796',
  grayMedium: '#B3BBCA',
  grayLight: '#F0F1F4',
  grayBox:'#EBEFF2',
  green: '#B5DFD0',
  orange: '#FFC43E',
  orangeDark: '#FF8C00',
  red: '#f00',
  pink: '#FF3C5F'
};
