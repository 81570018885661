/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import List from '@material-ui/core/List';
import NavItem from './NavItem';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0
  }
}));

type PageItem = {
  access?: () => boolean,
  exact?: boolean,
  external?: boolean,
  to?: string,
  title: string,
  items?: PageItem[],
  icon?: React.ReactElement,
};

type Props = {
  className?: string,
  collapsed: boolean,
  pages: PageItem[],
}

const SidebarNav = (props: Props) => {
  const { pages, className, collapsed, ...rest } = props;
  const classes = useStyles();
  return (
    <List
      {...rest}
      className={clsx(classes.root, className, {'list-short': collapsed})}
    >
      {pages.map(page => {
        if (page.access && !page.access()) {
          return null;
        }
        return (
          <NavItem
            collapsed={collapsed}
            key={page.title}
            page={{
              ...page,
              items: page.items?.filter(item => !item.access || item.access()),
            }}
          />
        )
      })}
    </List>
  );
};

export default SidebarNav;
