import { useEffect, useState } from 'react';
import FeaturesHandler from 'services/FeaturesHandler';

const useFeatureFlagging = (feature: string) => {
  const [ allowed, setAllowed ] = useState(FeaturesHandler.isAllowedFromCache(feature));
  useEffect(() => {
    (async () => {
      setAllowed(await FeaturesHandler.isAllowed(feature));
    })()
  }, []);
  return allowed;
}

export default useFeatureFlagging;
