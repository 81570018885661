import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { NavLink as RouterLink } from 'react-router-dom';
import {
  DragDropContext,
  Droppable,
  Draggable as DraggableDnd,
  DragUpdate
} from 'react-beautiful-dnd';
import ReorderIcon from '@material-ui/icons/Reorder';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import useDefaultValue from 'inc/hooks/useDefaultValue';

type Props = {
  actions: ListAction[],
  emptyText?: React.ReactNode | string,
  idKey?: string,
  // items: Document.Base[],
  items: { id: string, name: string }[],
  labelKey?: string,
  name: string,
};

type SortableItem = {
  id: string,
  label: string,
  error: FieldError | undefined,
}

const reorder = (list: SortableItem[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const Draggable = ({ actions = [], idKey = 'id', labelKey = 'name', items, name, emptyText = '' }: Props) => {
  const [order, setOrder] = useState<string[]>([]);
  const { errors, setValue } = useFormContext();
  const sortedItems = items.map((item, index) => {
    return { id: item[idKey as 'id'], label: item[labelKey as 'name'], error: errors[`${name}.${index}`] };
  }).sort((a: SortableItem, b: SortableItem) => {
    return order.indexOf(a.id) - order.indexOf(b.id);
  });
  const ids = sortedItems.map(item => item.id);
  const onDragEnd = ({ destination, source }: DragUpdate) => {
    if (!destination) {
      return;
    }
    const sorted = reorder([...sortedItems], source.index, destination.index);
    setOrder(sorted.map(item => item.id));
  }
  useDefaultValue(name, (data: Document.Base) => {
    setOrder(data[name]);
  });
  useEffect(() => {
    setValue(name, order);
  }, [order]);
  useEffect(() => {
    if (order.length !== ids.length) {
      setOrder(ids);
    }
  }, [ids]);
  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ width: '75%' }}>Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable">
            {(provided) => {
              return (
                <TableBody
                  // isDraggingOver={droppableSnapshot.isDraggingOver}
                  ref={provided.innerRef}
                >
                  {!sortedItems.length && emptyText && (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <span>{emptyText}</span>
                      </TableCell>
                    </TableRow>
                  )}
                  {sortedItems.map((item, index) => (
                    <DraggableDnd
                      draggableId={item.id}
                      index={index}
                      key={item.id}
                    >
                      {(provided, snapshot) => (
                        <TableRow
                          {...provided.dragHandleProps}
                          // focused={
                          //   droppableSnapshot.isDraggingOver ? snapshot.isDragging.toString() : 'false'
                          // }
                          data-testid={`draggable-row-${index}`}
                          hover={snapshot.isDragging}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <TableCell>
                            <div
                              id={item.id}
                              {...provided.dragHandleProps}
                            >
                              <ReorderIcon />
                            </div>
                          </TableCell>
                          <TableCell
                            className={clsx({'field-error': !!item.error})}
                            style={{ width: '75%' }}
                          >
                            {item.label} {!!item.error && (
                              <div style={{color: '#f00'}}>{item.error.message}</div>
                            )}
                          </TableCell>
                          <TableCell>
                            {actions.length && actions.map((action, index): React.ReactNode | string => {
                              if (action.url) {
                                return <RouterLink to={action.url}>{action.label}</RouterLink>
                              }
                              if (!action.callback || typeof action.callback !== 'function') {
                                return action.label;
                              }
                              return (
                                <a
                                  href="/"
                                  key={index}
                                  onClick={action.callback(item)}
                                >{action.label}</a>
                              )
                            }).reduce((prev, curr) => [prev, ' | ', curr])}
                          </TableCell>
                        </TableRow>
                      )}
                    </DraggableDnd>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </Table>
      </DragDropContext>
    </div>
  );
}

export default Draggable;
