import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import useDialog from 'inc/hooks/useDialog';
import fileDownload from 'js-file-download';
import Dialog from 'containers/dialogs/Dialog';
import ListItem from '@material-ui/core/ListItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { NavLink as RouterLink } from 'react-router-dom';
import { fade } from '@material-ui/core/styles/colorManipulator';
import api from 'services/api';
import store from 'inc/store';
import B2b from 'services/api/providers/B2b';

type Props = {
  fields: {
    image: string,
    name: string,
    brand: (item: Document.Base) => string,
  }
  hasMorePages: boolean,
  items: Document.Base[],
  listActions?: Data,
  loading?: boolean,
  loadingMore?: boolean,
  onFileSelect: (doc: Document.Base) => void,
  order: ListOrder,
  page?: number,
  pageRows?: number,
  total?: number,
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: theme.custom.grayMedium,
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -10px',
  },
  col: {
    flex: '0 0 33.333333%',
    maxWidth: '33.333333%',
    padding: '0 10px',
    marginBottom: 20,
  },
  item: {
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    width: '100%',
    paddingBottom: '75%',
  },
  actions: {
    backgroundColor: fade(String(theme.custom.white), 0.6),
    borderRadius: 11,
    height: 22,
    width: 22,
    fontSize: 22,
    position: 'absolute',
    right: 16,
    top: 24,
    zIndex: 999,
  },
  listMenu: {
    '& .MuiList-root': {
      padding: theme.spacing(1, 0),
    },
    '& .MuiListItem-root': {
      display: 'block',
      padding: 0,
      outline: 'none',
    },
  },
  listMenuBtn: {
    display: 'block',
    padding: theme.spacing(0.5, 3),
    fontSize: 16,
    fontWeight: 400,
    width: '100%',
    textAlign: 'left',
  },
  img: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    objectFit: 'contain',
  },
  spinner: {
    margin: '0 auto',
  },
  name: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.custom.slate,
    fontSize: 14,
    lineHeight: '30px',
    height: 30,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    color: theme.custom.white,
  },
  brand: {
    position: 'absolute',
    left: 0,
    top: 24,
    whiteSpace: 'nowrap',
    backgroundColor: theme.custom.orange,
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: 10,
    padding: '0 8px',
    letterSpacing: '0.16em',
    textTransform: 'uppercase',
  },
  buttonWrapper: {
    paddingTop: 20,
    textAlign: 'center',
  },
  button: {
    width: 280,
  },
  notFound: {
    textAlign: 'center',
    fontSize: 32,
    padding: 40,
    width: '100%',
  },
}));

function usePrevious(value: Document.Base[], newLoading: boolean) {
  const ref = useRef<Document.Base[]>([]);
  useEffect(() => {
    ref.current = newLoading ? value : ref.current?.concat(value);
  }, [value.map(item => item.id).join(',')]);
  return ref.current;
}

function GalleryList({ hasMorePages, fields, items, loading, loadingMore, onFileSelect, listActions = {} }: Props) {
  const classes = useStyles();
  const { setItemChanged, setPage } = listActions;
  const newLoading = !!loading && !loadingMore;
  const newItems = usePrevious(items, newLoading);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hoveredItem, setHoveredItem] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { openDialog, dialogProps } = useDialog({
    actions: {
      onClick: async (data: Data, handleClose, setLoading) => {
        setLoading(true);
        try {
          await api.path('assets').delete(data.id, '');
          store('page.message').set({
            text: 'The asset has been deleted.',
            type: 'success',
          });
        }
        catch (err) {
          // handleErrors(err);
        }
        setLoading(false);
        handleClose();
        setItemChanged((itemChanged: number) => itemChanged + 1);
      }
    },
    title: 'Confirmation',
  });
  const imageDownload = async (data: Data) => {
    try {
      handleClose();
      const content = await (api.provider('b2b') as B2b)
        .assetDownload(data.id);
      fileDownload(content, data.filename);
    }
    catch (e) {
      store('page.message').set({
        text: 'Error on downloading the asset.',
        type: 'error',
      });
    }
  }
  return (
    <>
      <div className={classes.container}>
        {!newLoading && (
          <div className={classes.row}>
            {!loading && !newItems.length && (
              <div className={classes.notFound}>There are no items found.</div>
            )}

            {newItems.map((item) => (
              <div
                className={classes.col}
                key={item.id}
                onMouseEnter={() => setHoveredItem(item.id)}
                onMouseLeave={() => setHoveredItem('')}
              >
                <div
                  className={classes.item}
                  onClick={() => onFileSelect && onFileSelect(item)}
                >
                  {hoveredItem === item.id && !onFileSelect && (
                    <>
                      <div
                        className={classes.actions}
                        onClick={handleClick}
                      >
                        <MoreVertIcon fontSize="inherit" />
                      </div>
                      <Menu
                        anchorEl={anchorEl}
                        className={classes.listMenu}
                        id="long-menu"
                        keepMounted
                        onClose={handleClose}
                        open={open}
                        PaperProps={{
                          style: {
                            maxHeight: 150,
                            width: '20ch',
                            zIndex: 1000
                          },
                        }}
                      >
                        <ListItem>
                          <Button
                            className={classes.listMenuBtn}
                            component={RouterLink}
                            to={`/assets/edit/${item.id}`}
                          >
                            Edit
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            className={classes.listMenuBtn}
                            onClick={() => imageDownload(item)}
                          >
                            Download
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button
                            className={classes.listMenuBtn}
                            onClick={() => {
                              handleClose();
                              openDialog(item);
                            }}
                          >
                            Delete
                          </Button>
                        </ListItem>
                        {/* <MenuItem key="download" onClick={handleClose}>
                          Download
                        </MenuItem>
                        <MenuItem key="delete" onClick={handleClose}>
                          Delete
                        </MenuItem> */}
                      </Menu>
                    </>
                  )}
                  <img
                    className={classes.img}
                    src={item[fields.image]}
                    width="100px"
                  />
                  <div className={classes.name}>{item[fields.name]}</div>
                  {!!fields.brand && (
                    <div className={classes.brand}>{fields.brand(item)}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
        {newLoading && (
          <div className={classes.spinner}><CircularProgress /></div>
        )}
        {hasMorePages && (
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.button}
              color="primary"
              disabled={loading}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                setPage((page: number) => page + 1);
              }}
              variant="contained"
            >
              Load More
              {loading && (
                <CircularProgress
                  size={24}
                />
              )}
            </Button>
          </div>
        )}
        <Dialog
          {...dialogProps}
          component={() => {
            return (
              <div>Are you sure you want to remove this asset?</div>
            );
          }}
        />
      </div>
    </>
  );
}

export default GalleryList;
