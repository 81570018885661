import custom from '../custom';

export default {
  root: {
    '&, &:hover, &:focus, &.Mui-focused': {
      background: custom.white,
      border: '1px solid ' + custom.black,
      borderRadius: '2px',
    },
    '&:before': {
      display: 'none'
    },
    '&.Mui-disabled': {
      borderColor: custom.grayBox,
      background: custom.grayBox,
    }
  },
  input: {
    boxSizing: 'border-box',
    fontSize: 18,
    padding: 16,
    height: 54,
    color: custom.black,
    '&.Mui-disabled': {
      background: custom.grayBox,
      color: custom.black,
    }
  },
};
