import * as yup from 'yup';

const errorMessages = {
  number: (name: string) => {
    return `${name} should be a number.`;
  },
  required: (name: string) => {
    return `${name} is a required field.`;
  }
};

export const message = (type: keyof typeof errorMessages, name: string) => {
  return errorMessages[type](name);
};

type AssetImage = { alt?: string, asset?: string } | undefined;

export const assetSchema = (required = false, fieldLabel = 'Image') => {
  return yup.lazy<AssetImage>(image => {
    return yup.object().shape({
      asset: required ? yup.string().ensure().required(message('required', fieldLabel)) : yup.string().ensure(),
      alt: yup.string().test('image-alt', message('required', 'Alternative Text'), (value) => {
        return !image || !image.asset || !!value;
      }),
    });
  })
}

export const imageSchema = (required = false, fieldLabel = 'Image') => {
  return yup.lazy<AssetImage>(image => {
    return yup.object().shape({
      url: required ? yup.string().ensure().required(message('required', fieldLabel)) : yup.string().ensure(),
      alt: yup.string().test('image-alt', message('required', 'Alternative Text'), (value) => {
        return !image || !image.asset || !!value;
      }),
    });
  })
}

export const bundleSchema = (name: string, formNotEmpty: boolean) => yup.mixed().test(name, message('required', name), (value) => {
  return !!value || !formNotEmpty;
});

export const accessSchema = yup.lazy((value?: Data) => {
  return yup.object()
    .shape(Object.keys(value || {}).reduce((a: Data, key: string) => {
      a[key] = yup.boolean();
      return a;
    }, {}))
    .test('at-least-one-number', 'you must provide at least one number', v => {
      return Object.keys(v || {}).some(key => v && v[key] === '1');
    });
});

export const richSchema = yup.string().transform(function(value) {
  return value;//.replace(/<\/?[^>]+(>|$)/g, '');
}).trim();
