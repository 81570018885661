/**
 * LocalStorage api provider class.
 */

class LocalStorage {

  /**
   * Creates a document by a given collection and data.
   */
  createDocument(collection: string, data: Document.Base) {
    this.__saveItem(collection, data);
    return new Promise(resolve => resolve(data));
  }

  /**
   * Updates a document by a given collection, data and document ID.
   */
  updateDocument(collection: string, data: Document.Base) {
    this.__saveItem(collection, data);
    return new Promise(resolve => resolve(data));
  }

  /**
   * Removes a document by the given collection and ID.
   */
  deleteDocument(collection: string) {
    const items: Document.Base[] = this.__getItem(collection) || [];
    const index = items.findIndex(item => item.id === item.id);
    index > -1 && items.splice(index, 1);
    localStorage.setItem(collection, JSON.stringify(items));
  }

  /**
   * Returns an item from the local storage.
   */
  __getItem(id: string) {
    const item = localStorage.getItem(id);
    if (!item) {
      return null;
    }
    try {
      return JSON.parse(item);
    }
    catch (e) {
      return null;
    }
  }

  /**
   * Saves an item to the local storage.
   */
  __saveItem(collection: string, item: Document.Base) {
    const items: Document.Base[] = this.__getItem(collection) || [];
    const index = items.findIndex(item => item.id === item.id);
    index > -1 ? (items[index] = item) : items.push(item);
    localStorage.setItem(collection, JSON.stringify(items));
  }

  /**
   * Returns a document by the given collection and ID.
   */
  getDocument(collection: string, id: string) {
    const items: Document.Base[] = this.__getItem(collection) || [];
    return new Promise(resolve => resolve(items.find(item => item.id === id)));
  }

  /**
   * Returns a list of documents by the given filter parameters.
   */
  getDocuments({ after, collection, condition = [], published = false, order }: ApiListParameters & { after?: string }) {
    let items: Document.Base[] = Object.values(this.__getItem(collection) || {});
    condition.forEach((condition: ApiConditionItem) => {
      items = items.filter(item => item[condition.field] === condition.value);
    });
    if (published) {
      items = items.filter(item => item.published);
    }
    if (order && order.by) {
      items.sort((a, b) =>
        ('asc' === order.dir)
          ? a[order.by] - b[order.by]
          : b[order.by] - a[order.by]);
    }
    if (after) {
      const index = items.findIndex(o => o.id === after);
      items = items.slice(index > -1 ? index + 1 : 0);
    }
    return new Promise(resolve => resolve(items));
  }
}

export default LocalStorage;
