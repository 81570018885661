import {useEffect, useState} from 'react';
import api from 'services/api';
import auth0 from 'services/Auth0';
import axios from 'axios';

type Props = {
  component: (entity: Document.Base | undefined, loading: boolean) => React.ReactElement | null,
  condition?: ApiConditionItem[],
  fromCache?: boolean,
  id: string,
  loadComponent?: React.ReactElement | null,
  onLoad?: (entity: Document.Base) => void,
  order?: ListOrder,
  path: string,
  provider?: 'b2b' | 'ls',
  waitForLoad?: boolean,
}

const ServiceEntity = ({ id, component, condition = [], loadComponent, onLoad, path, provider, waitForLoad = true, fromCache = false }: Props) => {
  const [entity, setEntity] = useState<Document.Base>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let mount = true;
    setLoading(true);
    const source = axios.CancelToken.source();
    mount && api.use(provider || (auth0.canFetch(path) ? 'b2b' : 'ls'))
      .path(path)
      .get(id, undefined, fromCache)
      .then((entity: Document.Base) => {
        setEntity(entity);
        onLoad && onLoad(entity);
      }).finally(() => {
        setLoading(false);
      });
    return () => {
      mount = false;
      source && source.cancel();
    };
  }, condition.map(item => item.value));
  if (waitForLoad && loading) {
    return loadComponent || null;
  }
  return component(entity, loading);
};

export default ServiceEntity;
