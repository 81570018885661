import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { Minimal as MinimalLayout } from 'layouts';
import { NotFound as NotFoundView } from 'views';

const RouteWithLayout = (props: RouteProps) => {
  const { layout: Layout, component: Component, access, redirect, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (access && !access((props.match || {}).params || {})) {
          return <MinimalLayout><NotFoundView /></MinimalLayout>
        }
        if (redirect) {
          return <Redirect to={redirect}/>
        }
        if (!Layout || !Component) {
          return null;
        }
        return <Layout>
          <Component {...(props.match || {})} />
        </Layout>
      }}
    />
  );
};

export default RouteWithLayout;
