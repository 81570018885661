import React from 'react';
import { CheckboxGroup, RichEditor, TextField } from 'components/Form';
import { Grid, GridContainer } from 'components/Layout';
import access from 'data/access.json';

const RedemptionCoupon = () => {
  return (
    <div>
      <CheckboxGroup
        label="Access Control"
        name="roles"
        options={access}
        required
      />
      <GridContainer>
        <Grid>
          <TextField
            label="Coupon Code"
            name="attributes.coupon_code"
          />
        </Grid>
      </GridContainer>
      <RichEditor
        label="Instructions"
        name="instructions"
        required
      />
    </div>
  );
};

export default RedemptionCoupon;
