import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import auth0 from 'services/Auth0';
import store from 'inc/store';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: 50,
    paddingRight: 40,
    '.role-hub-admin &': {
      backgroundColor: theme.custom.divider,
      color: theme.custom.grayDark
    }
  },
  logo: {
    marginRight: theme.spacing(1)
  },
  toolbar: {
    minHeight: 77
  },
  flexGrow: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(1),
    '& .MuiSvgIcon-root': {
      fontSize: 20
    }
  },
  helpButton: {
    fontSize: 16,
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1)
    }
  }
}));

type Props = {
  className?: string,
  onSidebarOpen: () => void,
} & AppBarProps;

const Topbar = ({ className, onSidebarOpen, ...rest }: Props) => {
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      const company = await auth0.company();
      !!company && store('company').set({
        id: company.id,
        name: company.name,
      });
    })();
  }, []);
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar
        classes={{root: classes.toolbar}}
        disableGutters
      >
        <RouterLink
          className={classes.logo}
          to="/"
        >
          <img
            alt=""
            height="43px"
            src={process.env.PUBLIC_URL + '/images/wesalute.svg'}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden xsDown>
          {/* <IconButton
            className={clsx(classes.button, classes.helpButton)}
            color="inherit"
          >
            <ModeCommentIcon /> Need Help?
          </IconButton>
          <IconButton
            className={classes.button}
            color="inherit"
          >
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <IconButton
            className={classes.button}
            color="inherit"
            onClick={() => { auth0.logout() }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden smUp>
          <IconButton
            className={classes.button}
            color="inherit"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
