import { useEffect } from 'react';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import { useFormContext } from 'react-hook-form';
import { cacheFormKey } from 'inc/utils';
import ls from 'inc/storage/LocalStorage';

type Props = {
  callback?: (data: Document.Base) => void,
  controlled?: boolean
}

const useField = (name: string, { callback, controlled = true }: Props) => {
  const { getValues, errors, register, setValue } = useFormContext();
  const values = getValues();
  const formType = values['__form_type'];
  const cacheKey = cacheFormKey(values['__form_id'], formType);
  const data = values[FORM_STATE_DATA] || {};
  const dataLoaded = !!values[FORM_STATE_LOADED];
  useEffect(() => {
    controlled && register({ name });
  }, []);
  useEffect(() => {
    if (dataLoaded && data[name]) {
      callback && callback(data);
    }
  }, [dataLoaded, formType]);
  const setLocalValue = (value: any) => {
    if ('create' === formType) {
      ls.set(cacheKey, (data: Data = {}) => ({...data, [name]: value || undefined}));
    }
  }
  return {
    getValues,
    editValue: data[name],
    formValue: values[name],
    error: errors[name],
    formRendered: !!values['__form_id'],
    register,
    setLocalValue,
    setValue: (value: any, update?: boolean, useLocal?: boolean) => {
      useLocal && setLocalValue(value);
      setValue(name, value, update);
    }
  };
}

export default useField;
