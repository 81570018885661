import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import useField from 'inc/hooks/useField';
import FieldWrapper from './FieldWrapper';
import api from 'services/api';
import useDialog from 'inc/hooks/useDialog';
import Dialog from 'containers/dialogs/Dialog';
import Chip from '@material-ui/core/Chip';

type Props = {
  altProps?: Data,
  Component: React.ElementType,
  componentProps?: Data,
  description?: React.ReactNode | string,
  entityType: string,
  helperText?: React.ReactNode | string,
  fromLibrary?: boolean,
  label?: string,
  selectLabel?: string,
  name: string,
  onChange?: (asset: Data) => void,
  required?: boolean,
};

const useStyles = makeStyles(() => ({
  root: {
    border: '1px dashed #ccc',
    padding: '35px 10px',
    textAlign: 'center',
    '&.error': {
      borderColor: '#f00',
    },
  },
  button: {
    marginRight: 10,
    marginTop: 10,
  }
}));

const Reference = ({ Component, componentProps, description, entityType, helperText, label, name, onChange, required, selectLabel = 'Select' }: Props) => {
  const classes = useStyles();
  const [entities, setEntities] = useState<Document.Base[]>([]);
  const { error, setValue } = useField(name, {
    callback: async (data: Document.Base) => {
      setValue(data[name]);
      const promises: Array<Promise<Document.Base>> = [];
      data[name].forEach((entityId: string) => {
        promises.push(api.path(entityType).get(entityId));
      });
      const items = await Promise.all(promises);
      setEntities(items);
    },
  });
  useEffect(() => {
    setValue(entities.map(entity => entity.id), true, true);
  }, [entities]);
  const { openDialog, closeDialog, dialogProps } = useDialog({
    title: selectLabel,
  });
  return (
    <FieldWrapper
      description={description}
      helperText={helperText}
      label={label}
      name={`${name}.asset`}
      required={required}
    >
      <div
        data-testid={`${name}-input`}
      >
        <div
          className={clsx({
            [classes.root]: true,
            error: !!error,
          })}
        >
          {!!entities.length && entities.map((entity: Document.Base, index) => (
            <Chip
              key={index}
              label={entity.name}
              onDelete={() => {
                setEntities(entities => entities.filter(item => item.id !== entity.id));
              }}
              variant="outlined"
            />
          ))}
          <div>
            <Button
              className={classes.button}
              color="primary"
              component="label"
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                openDialog({});
              }}
              variant="contained"
            >
              {selectLabel}
            </Button>
          </div>
        </div>
      </div>
      <Dialog
        {...dialogProps}
        component={() => {
          return (
            <Component
              onFileSelect={(doc: Document.Base) => {
                onChange && onChange(doc);
                setEntities((entities) => ([...entities, doc as Document.Base]));
                closeDialog();
              }}
              {...componentProps}
            />
          );
        }}
      />
    </FieldWrapper>
  )
};

export default Reference;
