import React from 'react';

type Props = {
  document: Data,
}

function Brand({ document }: Props) {
  return (
    <>
      <div
        className="block block-va-brand block-brand-banner"
        data-block-plugin-id="brand_banner"
        id="block-brand-banner"
      >
        <div
          about="/cvspharmacy"
          className="brand brand--hero hero"
          role="article"
        >
          <div className="brand--hero image">
            <img
              height="539"
              src={document['content.hero.__preview']}
              // src="https://members.veteransadvantage.com/sites/default/files/styles/hero_image/public/cvsstore.jpg?itok=HZSbv4yC"
              width="2000"
            />
          </div>
          <div className="brand--logo">
            <img
              height="72"
              src={document['content.logo.__preview']}
              width="150"
            />
          </div>
        </div>
      </div>
      <section className="content-section sidebar-right">
        <div className="container">
          <div className="columns sidebar-columns">
            <div className="column is-8">
              <div className="block-region-content-left">
                <div
                  className="block block-ctools block-entity-viewnode"
                  data-block-plugin-id="entity_view:node"
                >
                  <div
                    about="/cvspharmacy"
                    className="brand brand--default"
                    role="article"
                  >
                    <div className="intro has-offer">
                      <h1 className="brand--headline">{document['content.headline']}</h1>
                      <div
                        className="brand--description description"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: document['content.description'] }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4 sidebar-column">
              <div className="block-region-content-right">
                <div
                  className="block block-va-brand block-brand-featured-offer"
                  data-block-plugin-id="brand_featured_offer"
                >
                  <div className="brand-featured-offer enlist">
                    <h2 className="brand-featured-offer--title">Ready to Redeem This VetRewards Exclusive?</h2>
                    <h3 className="brand-featured-offer--subtitle">Get A Real Thank You™</h3>
                    <div className="brand-featured-offer--description description">
                      <p>See the details on how to access this exclusive offer.</p>
                    </div>
                    <div className="brand-featured-offer--cta">
                      <a
                        className="btn btn-small"
                        href="/offers/get-20-sitewide-free-shipping-combinable-weekly-deals"
                      >Yes, Redeem My Offer</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Brand;
