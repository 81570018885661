import { programCreationStepCompleted } from 'services/typewriter';
import {
  getEntity,
  segmentBrandObjectReference,
  segmentCompanyObjectReference,
  segmentContextObject,
  segmentProgramObject,
} from './helpers';

export default async ({ program, stepName }: Data) => {
  const company = await getEntity('companies', program['company']);
  const brand = await getEntity('brands', program['brand']);
  programCreationStepCompleted({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObjectReference(company),
    brand: segmentBrandObjectReference(brand),
    program: segmentProgramObject(program),
    stepName,
  });
};
