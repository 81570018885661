import errorMessages from 'inc/errors/messages';
import * as Sentry from '@sentry/browser';
import config from 'services/Config';
import auth0 from 'services/Auth0';

/**
 * Error handler global class.
 */
class ErrorHandler {

  /**
   * Handles application errors.
   */
  handle(type: string, error: any, context: Data = {}) {
    const { message, log = true } = context;
    let msg = (errorMessages as Data)[type] || { message, type };
    if ('function' === typeof msg) {
      msg = msg(context, error)
    }
    msg = 'string' === typeof msg
      ? { message: msg } : msg;
    log && this.log(type, error, msg);
    return msg;
  }

  /**
   * Logs application errors with Sentry.io.
   */
  log(type: string, error: any, context: any = {}) {
    const { sub } = auth0.getUser();
    config.get('sentryDSN') && Sentry.withScope((scope: any) => {
      scope.setLevel(context.severity || 'error');
      scope.setTag('error.type', type);
      scope.setExtras(context);
      scope.setUser({ sub });
      return Sentry.captureException(error);
    });
  }

}

export default new ErrorHandler();
