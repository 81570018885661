import auth0  from 'services/Auth0';
import { signedIn } from 'services/typewriter';
import {
  segmentCompanyObject,
  segmentContextObject,
  segmentUserObject
} from './helpers';
import { flatten } from 'inc/utils';

export default async () => {
  const company = flatten(await auth0.company() || {});
  const userData = segmentUserObject(auth0.getUser());
  window.analytics.identify(userData.id, {
    ...userData,
    ...(company.id ? { company: segmentCompanyObject(company || {}) } : {}),
  });
  signedIn({
    context: segmentContextObject(company),
    username: userData['username'],
    user: userData,
  });
};
