import custom from '../custom';

export default {
  root: {
    borderRadius: 2,
    padding: '13px 16px'
  },
  outlined: {
    padding: '12px 16px'
  },
  outlinedPrimary: {
    color: custom.slate,
    borderColor: custom.slate
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: custom.white,
    '&:hover': {
      boxShadow: 'none'
    }
  }
};
