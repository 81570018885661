import api from 'services/api';
import B2b from './api/providers/B2b';

/**
 * Config global class.
 */
class Config {

  /**
   * An object of variables.
   */
  private variables: Data;

  /**
   * An object of async callbacks that return variable values.
   */
  private asyncVariables: any;

  /**
   * Constructor for the Config object.
   */
  constructor(variables?: Data, asyncVariables?: Data) {
    this.addVariables(variables);
    this.addAsyncVariables(asyncVariables);
  }

  /**
   * Adds variables to the internal container.
   */
  addVariables(variables?: Data) {
    this.variables = variables || {};
  }

  /**
   * Adds async callbacks to the internal container.
   */
  addAsyncVariables(asyncVariables?: Data) {
    this.asyncVariables = asyncVariables || {};
  }

  /**
   * Returns a config variable.
   */
  get(name: string) {
    return this.variables[name];
  }

  /**
   * Returns an async config variable.
   */
  async getAsync(name: string) {
    if (this.variables[name]) {
      return this.variables[name];
    }
    this.variables[name] = await this.asyncVariables[name].bind(this)();
    return this.variables[name];
  }

  /**
   * Loads async config variables.
   */
  async loadAsync() {
    const varNames = Object.keys(this.asyncVariables);
    const promises: Promise<Data>[] = [];
    varNames.forEach(name => {
      promises.push(this.asyncVariables[name].bind(this)());
    });
    const results = await Promise.all(promises);
    results.forEach((res, index) => {
      this.variables[varNames[index]] = res;
    });
  }

}

export default new Config({
  environment: process.env.REACT_APP_ENV || 'dev', // eslint-disable-line
  platformClientId: process.env.REACT_APP_PLATFORM_CLIENT_ID, // eslint-disable-line
  b2bBaseUrl: process.env.REACT_APP_B2B_API_BASE_URL, // eslint-disable-line
  sentryDSN: process.env.REACT_APP_SENTRY_DSN, // eslint-disable-line
  flatfileApiKey: process.env.REACT_APP_FLATFILE_API_KEY, // eslint-disable-line
  segmentKey: process.env.REACT_APP_SEGMENT_KEY, // eslint-disable-line
}, {
  platformUrl: async function() {
    let info: Data = {};
    const platformClientId = this.get('platformClientId');
    platformClientId && (info = await (api.provider('b2b') as B2b)
      .subscriptionInfo(platformClientId));
    return info.url;
  }
});
