import ClassicEditorBase from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat';
import Link from '@ckeditor/ckeditor5-link/src/link';
import List from '@ckeditor/ckeditor5-list/src/list';
// import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageTextAlternative from '@ckeditor/ckeditor5-image/src/imagetextalternative';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote';
// import Heading from '@ckeditor/ckeditor5-heading/src/heading';
// import Indent from '@ckeditor/ckeditor5-indent/src/indent';
// import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed';
// import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
// import Table from '@ckeditor/ckeditor5-table/src/table';
// import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
// import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation';
// import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
// import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';
// import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg';

// class InsertImage extends Plugin {
//   init() {
//     const editor = this.editor;
//     const loadPopup = editor.config.get('assets').loadPopup
//     editor.ui.componentFactory.add('insertImage', (locale: Data) => {
//       const view = new ButtonView( locale );
//       view.set({
//         label: 'Insert image',
//         icon: imageIcon,
//         tooltip: true
//       });
//       view.on('execute', () => {
//         loadPopup((imageUrl: string) => {
//           editor.model.change((writer: Data) => {
//             const imageElement = writer.createElement('image', {
//               src: imageUrl
//             });
//             editor.model.insertContent(imageElement, editor.model.document.selection);
//           });
//         })
//       });
//       return view;
//     });
//   }
// };

export default class ClassicEditor extends ClassicEditorBase {};

ClassicEditor.builtinPlugins = [
  Essentials,
  Autoformat,
  Bold,
  Italic,
  Link,
  List,
  // BlockQuote,
  // Heading,
  // Image,
  // ImageCaption,
  // ImageStyle,
  // ImageToolbar,
  // Indent,
  // MediaEmbed,
  // Paragraph,
  // PasteFromOffice,
  // Table,
  // TableToolbar,
  // TextTransformation,
  // ImageTextAlternative,
  // InsertImage
];

ClassicEditor.defaultConfig = {
  toolbar: {
    items: [
      // 'heading',
      // '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      // '|',
      // 'indent',
      // 'outdent',
      // '|',
      // 'insertImage',
      // 'blockQuote',
      // 'insertTable',
      // 'mediaEmbed',
      // 'undo',
      // 'redo'
    ]
  },
  image: {
    toolbar: [
      'imageTextAlternative',
      '|',
      'imageStyle:alignLeft',
      'imageStyle:full',
      'imageStyle:alignRight'
    ],
    styles: [
      'full',
      'alignLeft',
      'alignRight'
    ]
  },
  table: {
    contentToolbar: [
		  'tableColumn',
		  'tableRow',
		  'mergeTableCells'
	  ]
  },
  language: 'en'
};
