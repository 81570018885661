import React, { useState } from 'react';
import { Select } from 'components/Form';
import GalleryList from 'components/List/GalleryList';
import ServicePaginationList from 'components/ServiceList';

type Props = {
  entityId?: string,
  entityType?: 'company' | 'brand',
  onFileSelect?: (file: Data) => void,
}

const AssetList = ({ entityId, entityType, onFileSelect }: Props) => {
  const condition: ApiConditionItem[] = [];
  const [ orderBy, setOrderBy ] = useState('updated.on');
  entityId && entityType && condition.push({ field: entityType, value: entityId });
  'company' === entityType && condition.push({ field: 'brand:null', value: '' });
  return (
    <>
      <Select
        defaultValue="updated.on"
        label="Order By"
        name="__orderBy"
        onChange={(e: Data) => setOrderBy(e.target.value)}
        options={[
          { id: 'updated.on', name: 'Most Recent' },
          { id: 'name', name: 'Name' },
        ]}
      />
      <ServicePaginationList
        Component={GalleryList}
        componentProps={{
          fields: {
            image: 'file',
            name: 'name',
          },
          onFileSelect,
        }}
        condition={condition}
        defaults={{
          pageRows: 12
        }}
        order={{ by: orderBy, dir: 'name' === orderBy ? 'asc' : 'desc' }}
        path="assets"
      />
    </>
  );
};

export default AssetList;
