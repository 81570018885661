import React from 'react';

export default {
  sub_title: 'WeSalute helps your brands connect with the greater military community. How you present your brand to our audience of military veterans and families will determine the success of your offers and programs.',
  name: 'Your brand name will appear to customers. The company is the parent entity and brands are entities within the company. The brand name can be different or the same as your company.',
  country: 'What country does your brand serve primarily? If your brand serves multiple countries, create a brand for each country.',
  mcc_category: 'Merchant Category Code (MCC) is used to classify your brand by the types of goods or services it provides to consumers.',
  naics_category: 'The North American Industry Classification System or NAICS is a classification of business establishments by type of economic activity. Please choose the classification that best describes your brand.',
  address: 'This address may be the same as the company address. It should be the address where you’d want to receive correspondence specifically for the brand.',
  description: 'In the summary you can go more in-depth about how and/or why your brand is serving the greater military community. This is your opportunity to speak directly to military and veteran families about your brand. Be authentic and sincere. [1000 characters or less is recommended]',
  headline: 'Write a line here about how your brand honors our military, veterans and their families. The best headlines are short and to-the-point.',
  hero: (
    <>
      <p>Image Requirements:</p>
      <ol>
        <li>JPG, PNG, SVG file types are accepted</li>
        <li>Must be less than 10mb</li>
        <li>Size: 2000x539px</li>
      </ol>
      <p>Your brand hero image will be used as the header image for your custom brand homepage. We will apply an overlay to the image so it may appear differently on the page.</p>
    </>
  ),
  logo: (
    <div>
      Image Requirements:
      <ol>
        <li>JPG, PNG, SVG file types are accepted</li>
        <li>Must be less than 10mb</li>
        <li>Must be transparent</li>
      </ol>
    </div>
  ),
}
