/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react';
import clsx from 'clsx';
import { NavLink as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Popover from '@material-ui/core/Popover';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import { Theme } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap'
  },
  open: {
    '& $button, & + div $button': {
      color: theme.custom.slate
    }
  },
  subList: {},
  button: {
    color: theme.custom.grayDark,
    fontSize: 16,
    lineHeight: 1.75,
    padding: '11px 24px 11px 0',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 400,
    '.list-short &': {
      paddingLeft: 24,
      '& $icon': {
        marginRight: 40
      }
    }
  },
  buttonPopover: {
    paddingLeft: 24
  },
  icon: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  active: {
    color: theme.custom.slate,
    fontWeight: 700,
    '& $icon': {
      color: theme.custom.slate
    }
  }
}));

const CustomRouterLink = forwardRef((props: Data & { to: string }, ref: React.Ref<HTMLDivElement>) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    {props.external ? (
      <a
        className={props.className}
        href={props.to || '/'}
        rel="noopener noreferrer"
        target="_blank"
      >
        {props.children}
      </a>
    ) : (
      <RouterLink {...props} />
    )}
  </div>
));

type PageItem = {
  access?: () => boolean,
  active?: RegExp,
  exact?: boolean,
  external?: boolean,
  to?: string,
  title: string,
  items?: PageItem[],
  icon?: React.ReactElement,
};

type Props = {
  collapsed: boolean,
  page: PageItem
} & RouteComponentProps;

const NavItem = (props: Props) => {
  const classes = useStyles();
  const { collapsed, match, page } = props;
  const hasChildren = page.items && page.items.length > 0;
  const isActiveLink = (item: PageItem) => () => {
    return match && (item.to === match.url || (item.active && match.url.match(item.active)));
  }
  const parentActive = hasChildren && page.items?.some(item => isActiveLink(item)());
  const [open, setOpen] = useState(parentActive);
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleOpen = (event: any) => {
    if (!hasChildren) {
      return false
    }
    !collapsed && setOpen(!open)
    collapsed && setAnchorEl(event.currentTarget)
  }

  return hasChildren ? (
    <>
      <ListItem
        className={clsx(classes.item, {[classes.open]: open})}
        disableGutters
        key={page.title}
      >
        <Button
          className={classes.button}
          onClick={handleOpen}
        >
          <div className={classes.icon}>{page.icon}</div>
          {page.title}
          {collapsed ? (
            <IconExpandLess
              style={{
                position: 'absolute',
                fontSize: 14,
                top: '50%',
                marginTop: '-0.5em',
                transform: 'rotate(90deg)',
                right: '3px',
              }}
            />
          ) : (
            <div style={{ position: 'absolute', right: 5, top: 13 }}>
              {open ? <IconExpandLess /> : <IconExpandMore />}
            </div>
          )}
        </Button>
      </ListItem>
      {collapsed ? (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={() => setAnchorEl(null)}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <List
            component="div"
            disablePadding
          >
            {page.items?.map(item => (
              <ListItem
                className={classes.item}
                disableGutters
                key={item.title}
              >
                <Button
                  activeClassName={classes.active}
                  className={clsx(classes.button, classes.buttonPopover)}
                  component={CustomRouterLink}
                  exact={item.exact}
                  external={item.external}
                  to={item.to || '/'}
                >
                  {/* <div className={classes.icon}>{item.icon}</div> */}
                  {item.title}
                </Button>
              </ListItem>
            ))}
          </List>
        </Popover>
      ) : (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
        >
          <List
            className={classes.subList}
            component="div"
            disablePadding
          >
            {page.items?.map(item => (
              <ListItem
                className={classes.item}
                disableGutters
                key={item.title}
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  exact={item.exact}
                  external={item.external}
                  isActive={isActiveLink(item)}
                  style={{ paddingLeft: 16 }}
                  to={item.to || '/'}
                >
                  {/* <div className={classes.icon}>{item.icon}</div> */}
                  {item.title}
                </Button>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  ) : (
    <ListItem
      className={classes.item}
      disableGutters
      key={page.title}
    >
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        exact={page.exact}
        external={page.external}
        to={page.to || '/'}
      >
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    </ListItem>
  );
};

export default withRouter(NavItem);
