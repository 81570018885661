import React from 'react';
import DialogWrapper from './DialogWrapper';
import { TabPanel, Tabs } from 'components/Display';
import useTabs from 'inc/hooks/useTabs';
import AssetList from 'containers/AssetList';
import { AssetImage } from 'components/Form';
import useObservable from 'inc/hooks/useObservable';

type Props = {
  document: Document.Base,
  onClose: () => void,
  onFileSelect: (file: Data) => void,
  open: boolean,
  width: 'md' | 'xs' | 'sm' | 'lg' | 'lg',
}

const MediaDialog = ({ onFileSelect, ...props }: Props) => {
  const { tabIndex, handleTabChange } = useTabs(0);
  const company = useObservable('company');
  return (
    <DialogWrapper
      title="Select a file"
      {...props}
    >
      <Tabs
        onChange={handleTabChange}
        tabs={[
          { label: 'Upload' },
          { label: 'Library' },
        ]}
        value={tabIndex}
      >
        <TabPanel
          index={0}
          value={tabIndex}
        >
          <AssetImage
            entityId={company.id}
            entityType="company"
            fromLibrary={false}
            label="Upload file"
            name="__image"
            onUpload={onFileSelect}
          />
        </TabPanel>
        <TabPanel
          index={1}
          value={tabIndex}
        >
          <AssetList onFileSelect={onFileSelect} />
        </TabPanel>
      </Tabs>
    </DialogWrapper>
  );
};

export default MediaDialog;
