import React from 'react';
import { Grid, GridContainer } from 'components/Layout';
import { CheckboxGroup, RichEditor, TextField } from 'components/Form';
import access from 'data/access.json';

const RedemptionPhone = () => {
  return (
    <div>
      <CheckboxGroup
        label="Access Control"
        name="roles"
        options={access}
        required
      />
      <GridContainer>
        <Grid>
          <TextField
            name="attributes.phone_number"
            placeholder="Phone"
          />
        </Grid>
        <Grid>
          <TextField
            name="attributes.affiliate_attributes"
            placeholder="Affiliate Attributes"
          />
        </Grid>
        <Grid>
          <TextField
            name="attributes.url_user_tokens"
            placeholder="URL User Tokens"
          />
        </Grid>
      </GridContainer>
      <RichEditor
        label="Instructions"
        name="instructions"
        required
      />
    </div>
  );
};

export default RedemptionPhone;
