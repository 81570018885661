import React, { useState, useReducer, useEffect, useRef } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Checkbox from '@material-ui/core/Checkbox';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MuiTablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { flatten } from 'inc/utils';
import useObservable from 'inc/hooks/useObservable';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    '& .word-break': {
      wordBreak: 'break-word'
    }
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 768,
    overflowX: 'auto'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

function usePrevious(value: number) {
  const ref = useRef<number>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

type TableState = {
  loading: boolean,
  items: Document.Base[],
  order: ListOrder,
  lastLoaded: { [k: number]: Document.Base | null },
  selectedItems: string[],
  page: number,
  total: number,
  pageRows: number,
}

type TableConfigDefault = {
  order?: ListOrder,
  pageRows?: number,
}

type TableAction = {
  type: 'SORT_ITEMS' | 'SELECT_ITEMS' | 'SET_ITEMS' | 'SET_PAGE' | 'SET_PAGE_ROWS',
  payload: Document.Base[] | ListOrder | string[] | string | number,
};

type TablePaginationReturn = {
  setItemChanged: React.Dispatch<React.SetStateAction<number>>,
  handleFieldSort: (by: string) => () => void,
  handleSelectOne: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void,
  handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
  handlePageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
  handleRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
} & TableState;

const useTablePagination = (fetch: (filters: Omit<ApiListParameters, 'collection'> & { pageRows: number }) => Promise<Document.Base[]>, condition: ApiConditionItem[] = [], defaults: TableConfigDefault = {}, dependencies: (string | number)[] = []): TablePaginationReturn => {
  const config = {
    order: { by: 'updated.on', dir: 'desc' },
    pageRows: 10,
    ...defaults,
  }
  const reducer = (state: TableState, action: TableAction): TableState => {
    let documents, hasMore, total, items;
    switch (action.type) {
      case 'SORT_ITEMS':
        return {
          ...state,
          order: (action.payload as ListOrder),
        }
      case 'SELECT_ITEMS':
        return {
          ...state,
          selectedItems: (action.payload as string[]),
        }
      case 'SET_ITEMS':
        documents = (action.payload || []) as Document.Base[];
        hasMore = documents.length && !!documents[state.pageRows];
        total = (state.page + 1) * state.pageRows + (hasMore ? 1 : 0);
        items = documents.slice(0, state.pageRows);
        return {
          ...state,
          total,
          lastLoaded: {...state.lastLoaded, [state.page]: items.length ? items[items.length - 1] : null},
          items,
        }
      case 'SET_PAGE':
        return {
          ...state,
          page: (action.payload as number),
        }
      case 'SET_PAGE_ROWS':
        return {
          ...state,
          page: 0,
          pageRows: (action.payload as number),
        }
      default:
        throw new Error(`No case for type ${action.type} found.`)
    }
  }
  const [state, dispatch] = useReducer(reducer, ({
    loading: true,
    items: [],
    order: config.order,
    lastLoaded: {},
    selectedItems: [],
    page: 0,
    total: 0,
    pageRows: config.pageRows,
  } as TableState));
  const [ itemChanged, setItemChanged ] = useState<number>(0);
  const [ loading, setLoading ] = useState(false);
  const prevPage = usePrevious(state.page);
  useEffect(() => {
    setLoading(true);
    let after = null;
    if ((state.page - 1) in state.lastLoaded && prevPage !== state.page) {
      after = state.lastLoaded[state.page - 1] || {};
    }
    fetch({ condition, pageRows: state.pageRows, after, order: state.order}).then(data => {
      setLoading(false);
      dispatch({ type: 'SET_ITEMS', payload: data });
    });
    // let unsubscribe = fn.onSnapshot(snap => {
    //   dispatch({ type: 'SET_ITEMS', payload: snap.docs });
    // });
    // return () => unsubscribe();
  }, [state.pageRows, state.page, state.order, itemChanged, condition.map(c => c.value).join('')].concat(dependencies));

  const handleFieldSort = (by: string) => () => {
    const { by: orderBy, dir } = state.order;
    dispatch({ type: 'SORT_ITEMS', payload: { by, dir: by === orderBy && dir === 'asc' ? 'desc' : 'asc' } });
  };
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedItems: string[];
    if (event.target.checked) {
      selectedItems = state.items.map((brand: Document.Base) => brand.id);
    } else {
      selectedItems = [];
    }
    dispatch({ type: 'SELECT_ITEMS', payload: selectedItems });
  };
  const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    dispatch({ type: 'SET_PAGE', payload: page });
  };
  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({ type: 'SET_PAGE_ROWS', payload: event.target.value });
  };
  const handleSelectOne = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const selectedIndex = state.selectedItems.indexOf(id);
    let newSelectedItems: string[] = [];
    if (selectedIndex === -1) {
      newSelectedItems = newSelectedItems.concat(state.selectedItems, id);
    } else if (selectedIndex === 0) {
      newSelectedItems = newSelectedItems.concat(state.selectedItems.slice(1));
    } else if (selectedIndex === state.selectedItems.length - 1) {
      newSelectedItems = newSelectedItems.concat(state.selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedItems = newSelectedItems.concat(
        state.selectedItems.slice(0, selectedIndex),
        state.selectedItems.slice(selectedIndex + 1)
      );
    }
    dispatch({ type: 'SELECT_ITEMS', payload: newSelectedItems });
  };
  return {
    ...state,
    loading,
    setItemChanged,
    handleFieldSort,
    handleSelectOne,
    handleSelectAll,
    handlePageChange,
    handleRowsPerPageChange,
  }
}

type Props = {
  actions?: List.Action[],
  className?: string,
  condition?: ApiConditionItem[],
  defaults?: TableConfigDefault,
  dependencies?: (string | number)[],
  fetch: (filters: Omit<ApiListParameters, 'collection'> & { pageRows: number }) => Promise<Document.Base[]>,
  fields: ListField[],
  paginationProps?: Data,
}

const TablePagination = ({ className, fetch, fields, actions = [], condition = [], dependencies = [], defaults = {}, paginationProps = {}, ...rest }: Props) => {
  const classes = useStyles();
  const contextLoading = useObservable('page.loading');
  const [ activeItem, setActiveItem ] = useState<List.ActiveItem | null>();
  const {
    loading,
    selectedItems,
    page,
    pageRows,
    items,
    order,
    total,
    setItemChanged,
    handleFieldSort,
    // handleSelectOne,
    // handleSelectAll,
    handlePageChange,
    handleRowsPerPageChange
  } = useTablePagination(fetch, condition, defaults, dependencies);
  const flattenItems = items.map((item: Document.Base) => flatten(item));
  if (process.env.REACT_APP_TESTING) {
    paginationProps['backIconButtonProps'] = {
      'data-testid': 'prev-icon'
    };
    paginationProps['nextIconButtonProps'] = {
      'data-testid': 'next-icon'
    };
    paginationProps['SelectProps'] = {
      'data-testid': 'rows-page-select',
      native: true
    };
  }
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            {loading || contextLoading ? <LinearProgress /> : <div style={{height: '4px'}} />}
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedItems.length === items.length}
                      color="primary"
                      indeterminate={
                        selectedItems.length > 0 &&
                        selectedItems.length < items.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                  {fields.map(field => <TableCell key={field.id}>
                    <TableSortLabel
                      active={order.by === field.id}
                      data-testid={`sortable-${field.id}`}
                      direction={order.dir}
                      onClick={() => handleFieldSort(field.id)()}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>)}
                  {!!actions.length && (
                    <TableCell>Operations</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!loading && !flattenItems.length && (
                  <TableRow>
                    <TableCell colSpan={fields.length + 2}>
                      There are no items found.
                    </TableCell>
                  </TableRow>
                )}
                {flattenItems.map((item: Document.Base) => (
                  <TableRow
                    data-testid={`table-row-${item.id}`}
                    hover
                    key={item.id}
                    selected={selectedItems.indexOf(item.id) !== -1}
                  >
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedItems.indexOf(item.id) !== -1}
                        color="primary"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSelectOne(event, item.id)}
                        value="true"
                      />
                    </TableCell> */}
                    {fields.map(field => {
                      const value = item[field.id] || '';
                      return (
                        <TableCell
                          className={clsx(field.class)}
                          key={field.id}
                          style={{ width: field.width || 'auto' }}
                        >
                          {field.prepare ? field.prepare(value, item) : value}
                        </TableCell>
                      );
                    })}
                    {!!actions.length && (
                      <TableCell>
                        {actions.length && actions.map((action, index): React.ReactNode | string => {
                          if (action.component) {
                            return (
                              <a
                                href="/"
                                key={index}
                                onClick={(e) => { e.preventDefault(); setActiveItem({
                                  item,
                                  component: (action.component as React.ElementType),
                                  props: action.props || {}
                                }); }}
                              >{action.label}</a>
                            )
                          }
                          if (action.callback) {
                            return (
                              <a
                                href="/"
                                key={index}
                                onClick={(e) => { e.preventDefault(); action.callback && action.callback(item)(e); }}
                              >{action.label}</a>
                            )
                          }
                          if (action.url && typeof action.url === 'function') {
                            return (
                              action.external ? (
                                <a
                                  className={action.class}
                                  href={action.url(item as Document.Base)}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >{action.label}</a>
                              ) : (
                                <RouterLink
                                  className={action.class}
                                  key={index}
                                  to={action.url(item as Document.Base)}
                                >{action.label}</RouterLink>
                              )
                            )
                          }
                          return action.label;
                        }).reduce((prev, curr) => [prev, ' | ', curr])}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
        {activeItem && !!activeItem.item && (
          <activeItem.component
            document={activeItem.item}
            onClose={() => setActiveItem(null)}
            onComplete={() => { setActiveItem(null); setItemChanged(Math.random()); }}
            open
            {...activeItem.props}
          />
        )}
      </CardContent>
      <CardActions className={classes.actions}>
        <MuiTablePagination
          component="div"
          count={+total}
          labelDisplayedRows={({from, to}) => `${from}-${to}`}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={+page}
          rowsPerPage={+pageRows}
          rowsPerPageOptions={[1, 2, 5, 10, 25]}
          {...paginationProps}
        />
      </CardActions>
    </Card>
  );
};

export default TablePagination;
