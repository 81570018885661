import React, { useState } from 'react';
import clsx from 'clsx';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import PageviewIcon from '@material-ui/icons/Pageview';
import DialogWrapper from 'containers/dialogs/DialogWrapper';
import { Select } from 'components/Form';
import { Brand as BrandPreview, Offer as OfferPreview } from 'components/PreviewTemplates';

function Preview({ match, style = {}, className }: RouteComponentProps & { style?: Data } & {className?: string}) {
  const type = match.path.indexOf('/offers/') === 0 ? 'offer' : 'brand'
  const [ previewOpen, setPreviewOpen ] = useState(false);
  const [ previewWidth, setPreviewWidth ] = useState<'md' | 'xs' | 'sm' | 'lg' | 'xl'>('lg');
  const { getValues } = useFormContext();
  const values = getValues();
  return (
    <>
      <Button
        className={className}
        color="primary"
        onClick={() => { setPreviewOpen(true) }}
        startIcon={<PageviewIcon />}
        style={style}
        variant="contained"
      >
        Preview
      </Button>
      <DialogWrapper
        document={getValues() as Document.Base}
        onClose={() => { setPreviewOpen(false) }}
        open={previewOpen}
        title="Document Preview"
        width={previewWidth}
      >
        <Select
          name="test"
          onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => { setPreviewWidth((e.target.value as 'md' | 'xs' | 'sm' | 'lg' | 'lg')) }}
          options={[
            {
              id: 'xs',
              name: 'xs',
            },
            {
              id: 'sm',
              name: 'sm',
            },
            {
              id: 'md',
              name: 'md',
            },
            {
              id: 'lg',
              name: 'lg',
            },
            {
              id: 'xl',
              name: 'xl',
            },
          ]}
          value={previewWidth}
        />
        <div
          className={clsx({
            'preview': true,
            'sm': ['sm', 'md', 'lg', 'xl'].includes(previewWidth),
            'md': ['md', 'lg', 'xl'].includes(previewWidth),
            'lg': ['lg', 'xl'].includes(previewWidth),
            'xl': 'xl' === previewWidth,
          })}
          style={{ padding: '20px 0' }}
        >
          {'offer' === type ? (
            <OfferPreview document={values} />
          ) : (
            <BrandPreview document={values} />
          )}
        </div>
      </DialogWrapper>
    </>
  );
}

export default withRouter(Preview);
