import * as yup from 'yup';
import { message, assetSchema, richSchema } from './helpers';
import auth0 from 'services/Auth0';

export default yup.object().shape({
  name: yup.string().required(message('required', 'Name')),
  company: yup.mixed().test('company', message('required', 'Company'), (value) => {
    return !!value || !auth0.isAdmin();
  }),
  billing_info: yup.object().shape({
    __use_company: yup.boolean(),
    email: yup.string().when('__use_company', {
      is: false,
      then: yup.string().ensure().required(message('required', 'E-mail')),
    }),
    phone: yup.string().when('__use_company', {
      is: false,
      then: yup.string().ensure().required(message('required', 'Phone Number')),
    }),
    address: yup.object().when('__use_company', {
      is: false,
      then: yup.object().shape({
        line1: yup.string().ensure().required(message('required', 'Line1')),
        city: yup.string().ensure().required(message('required', 'City')),
        state: yup.string().ensure().required(message('required', 'State')),
        postal_code: yup.string().ensure().required(message('required', 'ZIP')),
      }),
    }),
  }),
  content: yup.object().shape({
    logo: assetSchema(true),
    hero: assetSchema(),
    country: yup.string().required(message('required', 'Country')),
    description: richSchema.required(message('required', 'Description')),
    featured: yup.boolean(),
    page_enabled: yup.boolean(),
    headline: yup.string().required(message('required', 'Headline')),
    category: yup.mixed().test('primary-category', message('required', 'Primary Category'), (value) => {
      return !!value || !auth0.isAdmin();
    }),
  }),
  // keywords
  // featured_offer
});
