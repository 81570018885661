import React, { useState } from 'react';
import { ServiceList } from 'containers';
import { Select } from 'components/Form';
// import useObservable from 'inc/hooks/useObservable';
import TableList from 'components/List/TableList';
import ServicePaginationList from 'components/ServiceList';
import moment from 'moment';

type Props = {
  company: string,
  onFileSelect?: (file: Data) => void,
}

const OfferList = ({ company, onFileSelect }: Props) => {
  // const company = useObservable('company');
  const condition = [{ field: 'company', value: company }];
  const [filters, setFilters] = useState({
    brand: '',
  });
  filters.brand && condition.push({ field: 'brand', value: filters.brand });
  return (
    <>
      <ServiceList
        component={(items, loading) =>
          <Select
            disabled={loading}
            label="Brand"
            name="__brand"
            onChange={(e: Data) => setFilters(filters => ({...filters, brand: e.target.value}))}
            options={items}
            style={{ marginRight: 10 }}
          />
        }
        condition={[
          {
            field: 'company',
            value: company,
          },
        ]}
        path="brands"
        waitForLoad={false}
      />
      <ServicePaginationList
        Component={TableList}
        componentProps={{
          actions: [
            {
              callback: onFileSelect,
              label: 'Select',
            }
          ],
          fields: [
            {
              id: 'name',
              label: 'Title',
              sortable: true,
              width: '55%',
            },
            {
              id: 'updated.on',
              label: 'Updated',
              prepare: (value: number) => {
                return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
              },
              sortable: true,
            },
          ],
        }}
        condition={condition}
        path="offers"
      />
    </>
  );
};

export default OfferList;
