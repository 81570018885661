import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useField from 'inc/hooks/useField';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    '& $label span': {
      color: theme.custom.red
    }
  }
}));

type Props = {
  defaultChecked?: boolean,
  inputProps?: ComponentInputProps,
  label: string,
  name: string,
  value?: string | number,
} & CheckboxProps;

function Checkbox({ defaultChecked, label, name, value, ...props }: Props) {
  const classes = useStyles();
  const { error, formValue, formRendered, register, setValue } = useField(name, {
    callback: (data: Document.Base) => setValue(data[name]),
    controlled: false,
  });
  useEffect(() => {
    formRendered && 'boolean' === typeof defaultChecked && setValue(defaultChecked, true);
  }, [formRendered, defaultChecked]);
  if (process.env.REACT_APP_TESTING) {
    props['inputProps'] = {
      className: 'reset',
      'data-testid': `${name}-input`,
    };
  }
  return (
    <div
      className={clsx({
        [classes.error]: !!error,
        'field-error': !!error
      })}
    >
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={!!formValue}
            color="primary"
            inputRef={register}
            name={name}
            onChange={(e) => setValue(e.target.checked, false, true)}
            value={value || true}
            {...props}
          />
        }
        label={label}
      />
    </div>
  );
}

export default Checkbox;
