// @ts-nocheck
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import MuiAutocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

const CustomMuiAutocomplete = withStyles({
  inputRoot: {
    backgroundColor: '#fff',
    padding: '3px!important',
  },
}) (MuiAutocomplete);

function Autocomplete({ options, error, ...props }: AutocompleteProps) {
  return (
    <CustomMuiAutocomplete
      getOptionLabel={(option: CategoryItem) => option.name}
      options={options}
      renderInput={(params: Data) => (
        <MuiTextField
          {...{
            ...params,
            inputProps: {...(params.inputProps || {}), role: 'textbox'}
          }}
          error={error}
          variant="outlined"
        />
      )}
      style={{ width: 300 }}
      {...props}
    />
  );
}

export default Autocomplete;
