import { useState, useEffect } from 'react';
import api from 'services/api';
import ConfirmationDialog from 'containers/dialogs/ConfirmationDialog';
import ModerationForm from 'containers/dialogs/ModerationForm';
import B2b from 'services/api/providers/B2b';
import Config from 'services/Config';
import { toSingular } from 'inc/utils';
import event from 'services/EventManager';
import store from 'inc/store';

type ServiceTableProps = {
  provider?: 'b2b' | 'ls',
  path: string,
  parentId?: string,
  moderate?: boolean,
  published?: boolean,
  revision?: boolean,
}

const useServiceTable = ({ provider = 'b2b', path, parentId, moderate = false, published = false, revision = false }: ServiceTableProps) => {
  const [platformUrl, setPlatformUrl] = useState('');
  useEffect(() => {
    (async () => {
      setPlatformUrl(await Config.getAsync('platformUrl'));
    })();
  }, []);
  const fetchItems = ({ pageRows, after, order, condition = [] }: Omit<ApiListParameters, 'collection'> & { pageRows: number }): Promise<Document.Base[]> => {
    return api.use(provider).path(path)
      .getAll({
        condition,
        revision: revision ? parentId : '',
        order,
        after: ((after as Data) || {})[revision ? 'revision' : 'id'],
        limit: pageRows + 1,
        published,
        parentId,
      });
  }
  const listActions: List.Action[] = [
    {
      label: revision ? 'View' : 'Edit',
      url: (item: Document.Base) => revision ? `/${path}/edit/${parentId}/revisions/${item.id}` : `/${path}/edit/${item.id}`,
    },
  ];
  !moderate && listActions.push({
    label: 'Delete',
    component: ConfirmationDialog,
    props: {
      // @todo replace IData to another type
      confirmHandler: (document: Document.Base, { handleErrors, onClose, onComplete, setLoading }: Data) => async () => {
        setLoading(true);
        try {
          await api.use(provider).path(path).delete(document.id, parentId || '');
          setLoading(false);
          store('page.message').set({
            text: 'The document has been deleted.',
            type: 'success',
          });
          onComplete && onComplete();
          event.dispatch(`${event.getEventPrefix(path)}_DELETED`, { data: document });
          return document.id;
        }
        catch (err) {
          handleErrors(err);
          onClose && onClose();
        }
      },
      text: 'Please confirm that you want to delete this document.',
    }
  });
  published && platformUrl && listActions.push({
    label: 'View',
    external: true,
    url: (item: Document.Base) => (
      platformUrl
        .replace('{id}', item.id)
        .replace('{type}', toSingular(path))
    ),
  });
  published && listActions.push({
    label: 'Unpublish',
    component: ConfirmationDialog,
    props: {
      // @todo replace IData to another type
      confirmHandler: (document: Document.Base, { handleErrors, onClose, onComplete, setLoading }: Data) => async () => {
        setLoading(true);
        try {
          await (api.provider('b2b') as B2b).unpublish(path, document.id);
          setLoading(false);
          store('page.message').set({
            text: 'The document has been unpublished.',
            type: 'success',
          });
          onComplete && onComplete();
          return document.id;
        }
        catch (err) {
          handleErrors(err);
          onClose && onClose();
        }
      },
      text: 'Please confirm that you want to unpublish this document.',
    }
  });
  moderate && !published && listActions.push({
    label: 'Moderate',
    component: ModerationForm,
    props: {
      collection: path,
    }
  });
  return {
    fetchItems,
    listActions,
  }
}

export default useServiceTable;
