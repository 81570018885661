const errors: Data = {
  '.content.category': 'Primary category field was not selected. Please select it prior to publishing the offer.'
};
export default (context: Data) => {
  const { details: { field = '.' } = {} } = context?.details;
  return {
    details: { field },
    message: errors[field] || 'The document can\'t be published since not all the fields have beed filled.',
    severity: 'info',
  };
}
