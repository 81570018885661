import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import MuiSwitch, { SwitchProps } from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useField from 'inc/hooks/useField';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    '& $label span': {
      color: theme.custom.red
    }
  }
}));

type Props = {
  defaultChecked?: boolean,
  inputProps?: ComponentInputProps,
  label: string,
  name: string,
} & SwitchProps;

function Switch({ defaultChecked, label, name, ...props }: Props) {
  const classes = useStyles();
  const { error, formValue, formRendered, setValue } = useField(name, {
    callback: (data: Document.Base) => setValue(data[name]),
  });
  useEffect(() => {
    formRendered && 'boolean' === typeof defaultChecked && setValue(defaultChecked, true);
  }, [formRendered, defaultChecked]);
  if (process.env.REACT_APP_TESTING) {
    props['inputProps'] = {
      className: 'reset',
      'data-testid': `${name}-input`,
    };
  }
  return (
    <div
      className={clsx({
        [classes.error]: !!error,
        'field-error': !!error
      })}
    >
      <FormControlLabel
        control={
          <MuiSwitch
            checked={!!formValue}
            color="primary"
            name={name}
            onChange={(e) => setValue(e.target.checked, true, true)}
            {...props}
          />
        }
        label={label}
      />
    </div>
  );
}

export default Switch;
