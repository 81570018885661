import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import { Sidebar, Topbar, Footer } from './components';
import Message from 'components/Display/Message';
import ls from 'inc/storage/LocalStorage';
import auth0 from 'services/Auth0';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 77,
    height: '100%'
  },
  shiftContent: {
    paddingLeft: 350
  },
  shiftContentClosed: {
    paddingLeft: 73
  },
  content: {
    minHeight: '100%',
    padding: theme.spacing(5)
  },
}));

type Props = {
  children: React.ReactNode,
}

const Main = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  const isHubAdmin = auth0.isAdmin();
  const [ open, setOpen ] = React.useState(!!ls.get('sidebarOpen'));
  const toggleSidebar = () => {
    setOpen(!open);
    open
      ? ls.remove('sidebarOpen')
      : ls.set('sidebarOpen', '1');
  };
  return (
    <div
      className={clsx({
        'role-hub-admin': isHubAdmin,
        [classes.root]: true,
        [classes.shiftContent]: open,
        [classes.shiftContentClosed]: !open
      })}
    >
      <Topbar onSidebarOpen={toggleSidebar} />
      <Sidebar
        open={open}
        toggleSidebar={toggleSidebar}
        variant={'persistent'}
      />
      <main className={classes.content}>
        <Message />
        {children}
        <Footer />
      </main>
    </div>
  );
};

export default Main;
