import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import {
  Select,
  TextField
} from './';
import {
  Details,
  Fieldset,
} from '../Display';
import moderationStates from 'data/states.json';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import auth0 from 'services/Auth0';

type Props = {
  history?: Data[],
  moderation?: boolean,
}

function EditSidebarForm({ history = [], moderation }: Props) {
  const { getValues } = useFormContext();
  const [states, setStates] = useState(moderationStates);
  const values = getValues();
  useEffect(() => {
    if (values[FORM_STATE_LOADED] && values[FORM_STATE_DATA]['meta.state.update']) {
      const update = values[FORM_STATE_DATA]['meta.state.update'];
      setStates(moderationStates.filter(state => update.includes(state.id)));
    }
  }, [values[FORM_STATE_LOADED]]);
  return (
    <Typography component="div">
      <Details>
        {!!moderation && (
          <>
            <Fieldset
              defaultExpanded={false}
              label="Moderation"
            >
              <Select
                defaultValue="draft"
                label="State"
                name="state"
                options={states}
              />
              <TextField
                helperText="Briefly describe the changes you have made."
                label="Revision log message"
                multiline
                name="log"
                rows={2}
                rowsMax={4}
              />
            </Fieldset>
            {!!history.length && (
              <Fieldset
                defaultExpanded={false}
                label="History"
              >
                {history.map((item: Data, index: number) => {
                  return (
                    <div
                      key={index}
                      style={{ border: '1px solid #ccc', borderRadius: 4, marginBottom: 10, padding: 10 }}
                    >
                      <div style={{ borderBottom: '1px solid #eee', display: 'flex', justifyContent: 'space-between', paddingBottom: 5 }}>
                        <div>
                          {!!item.from && (<>from <strong>{item.from}</strong> </>)}to <strong>{item.to}</strong>
                        </div>
                        <div>
                          {moment.unix(item.on / 1000).format('MM/DD/YYYY HH:mm')}
                        </div>
                      </div>
                      <div style={{ padding: '15px 0 6px' }}>
                        {item.comment}
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <div>
                          By <i>{item.by?.name}</i>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Fieldset>
            )}
          </>
        )}
        {!!values['content.affiliate_url'] && (
          <Fieldset
            defaultExpanded={false}
            label="Affiliate"
          >
            <strong>Affiliate URL:</strong>
            <div>https://wesalute.go2cloud.org/aff_lsr?transaction_id=TRANSACTION_ID</div>
          </Fieldset>
        )}
        <Fieldset
          access={auth0.isAdmin}
          defaultExpanded={false}
          label="Meta Tags"
        >
          <TextField
            label="Title"
            name="content.metatags.title"
          />
          <TextField
            label="Description"
            multiline
            name="content.metatags.description"
            rows={2}
            rowsMax={4}
          />
        </Fieldset>
        <Fieldset
          access={auth0.isAdmin}
          defaultExpanded={false}
          label="URL Settings"
        >
          <TextField
            label="URL Alias"
            name="content.url_alias"
          />
        </Fieldset>
      </Details>
    </Typography>
  );
}

export default EditSidebarForm;
