import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import MuiTab from '@material-ui/core/Tab';
import MuiTabs from '@material-ui/core/Tabs';

type Props = {
  children?: React.ReactNode,
  onChange?: (e: React.ChangeEvent<{}> | null, index: any) => void,
  tabs: { hidden?: boolean, label: string, icon?: JSX.Element, to?: string }[],
  value: number,
}

function Tabs({ value, onChange, tabs, children }: Props) {
  const ref = useRef(null);
  const { formState } = useFormContext() || {};
  const [errorTabs, setErrorTabs] = useState<number[]>([]);
  useEffect(() => {
    const errorTabs = [];
    const form = ReactDOM.findDOMNode(ref.current);
    for (let i = 0; i < tabs.length; i++) {
      if ((form as HTMLElement).querySelectorAll(`#simple-tabpanel-${i} .field-error`).length) {
        errorTabs.push(i);
      }
    }
    if (errorTabs.length) {
      onChange && onChange(null, errorTabs.shift());
      errorTabs.length && setErrorTabs(errorTabs);
    }
  }, [formState && formState.submitCount]);
  const removeTabError = (index: number) => () => {
    if (errorTabs.includes(index)) {
      setErrorTabs(errorTabs.filter(item => item !== index));
    }
  }
  return (
    <div ref={ref}>
      <MuiTabs
        indicatorColor="primary"
        onChange={onChange}
        scrollButtons="on"
        textColor="primary"
        value={value}
      >
        {tabs.map((tab, index) => !tab.hidden ?
          (tab.to ? (
            <MuiTab
              component={Link}
              data-testid={`tab-${index}`}
              icon={tab.icon}
              key={index}
              label={<span>{tab.label}{errorTabs.includes(index) ? <span style={{color: '#f00', display: 'inline'}}> (*)</span> : null}</span>}
              to={tab.to}
            />
          ) : (
            <MuiTab
              data-testid={`tab-${index}`}
              icon={tab.icon}
              key={index}
              label={<span>{tab.label}{errorTabs.includes(index) ? <span style={{color: '#f00', display: 'inline'}}> (*)</span> : null}</span>}
              onClick={removeTabError(index)}
            />
          ))
          : null)}
      </MuiTabs>
      {children}
    </div>
  );
}

export default Tabs;
