import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardContent from '@material-ui/core/CardContent';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import FlatfileImporter from 'flatfile-csv-importer'
import {
  TabPanel,
} from 'components/Display';
import {
  Checkbox,
  // CheckboxGroup,
  FieldWrapper,
  Select,
  TextField,
} from 'components/Form';
import { PageBlock } from 'components/Layout';
import Invoice from './components/Invoice';
import ThankYou from './components/ThankYou';
import { ServiceList } from 'containers';
import useFormCrud from 'inc/hooks/useFormCrud';
import useScroll from 'inc/hooks/useScroll';
import useCompanyId from 'inc/hooks/useCompanyId';
import useMrpPricing from 'inc/hooks/useMrpPricing';
import { MrpCreateSchema } from 'inc/validation';
import useConfigAsync from 'inc/hooks/useConfigAsync';
import useObservable from 'inc/hooks/useObservable';
import auth0 from 'services/Auth0';
import event from 'services/EventManager';
import customStyles from '../../theme/styles';

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  reviewCheckbox: {
    maxWidth: 410,
    margin: '0 auto'
  },
  getBox: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.custom.grayMedium,
    borderRadius: 2,
    margin: '0 auto',
    maxWidth: 800,
    textAlign: 'center',
    padding: theme.spacing(6, 5)
  },
  getTitle: {
    fontSize: 32,
    marginBottom: 0
  },
  getCaption: {
    marginBottom: 22
  },
  getCopy: {
    backgroundColor: theme.custom.divider,
    padding: '15px 20px',
    fontSize: 18,
    maxWidth: 630,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Brand>;

FlatfileImporter.setVersion(2);

const ProgramCreate = () => {
  const classes = useStyles();
  const { setTriggered: setErrorsTriggered } = useScroll('.field-error');
  const [program, setProgram] = useState<Document.Base | null>(null);
  const [activeStep, setActiveStep] = useState(0);
  const saveLoading = useObservable('page.loading');
  const clientCompanyId = useCompanyId();
  const platformUrl = useConfigAsync('platformUrl');
  const { handleEditSubmit, clearError, errors, methods, triggerValidation, values } = useFormCrud({
    path: 'mrps',
    schema: MrpCreateSchema,
    success: (program: Document.Base) => { setActiveStep(3); setProgram(program); },
    process: (data) => {
      return data;
    }
  });
  const coupons = parseInt(values['mrp_batch.coupons'] || 0, 10);
  const travelProtection = !!values['config.travel_protection'];
  useMrpPricing();
  // useMrpPricing(values['discount.id']);
  const companyId = methods.watch('company') || clientCompanyId;
  const copyText = () => {
    const elm: any = document.getElementById('mrp-url');
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(elm);
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy');
    }
  }
  const steps = [
    'Get Started',
    'Program Information',
    'Review Program',
    'Get Recipient Codes',
  ];
  useEffect(() => {
    const eventContext = { program: values, stepName: steps[activeStep] };
    event.dispatch('PROGRAM_CREATION_STEP_VIEWED', eventContext);
    activeStep > 0 && event.dispatch('PROGRAM_CREATION_STEP_COMPLETED', {
      ...eventContext,
      stepName: steps[activeStep - 1]
    });
  }, [activeStep]);
  const fieldsToValidate = [
    [
      { name: 'brand' },
      { name: 'type' },
      { name: 'content.recipients' },
    ],
    [
      { name: 'name' },
      { name: 'content.title' },
      { name: 'method' },
      { name: 'mrp_batch.coupons' },
    ],
  ];
  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title}
            component="h2"
            variant="h2"
          >
            Create a Program
          </Typography>
          <Typography
            component="p"
            variant="body2"
          >
            WeSalute Programs allow you to make a bulk order of 12-month WeSalute+ subscriptions for your chosen user base.
            For example, does your company employ veterans? You can give them the gift of WeSalute with a Military Recognition Program.
          </Typography>
        </CardContent>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className={classes.stepper}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <FormContext {...methods}>
          <form onSubmit={methods.handleSubmit(handleEditSubmit({}))}>
            <TabPanel
              index={0}
              value={activeStep}
            >
              <div className={classes.formContent}>
                {auth0.isAdmin() && (
                  <PageBlock>
                    <ServiceList
                      component={(items, loading) =>
                        <Select
                          description="Which company will this program be associated with?"
                          disabled={loading}
                          label="Company"
                          name="company"
                          options={items}
                        />
                      }
                      path="companies"
                      waitForLoad={false}
                    />
                  </PageBlock>
                )}
                <PageBlock>
                  {!!companyId && (
                    <ServiceList
                      component={(items, loading) =>
                        <Select
                          description={
                            <p>Which brand will this program be associated with? <Link to="/brands/create">Go here to create a new brand.</Link></p>
                          }
                          disabled={loading}
                          label="Choose Brand"
                          name="brand"
                          options={items}
                        />
                      }
                      condition={[
                        {
                          field: 'company',
                          value: companyId as string,
                          op: '=='
                        }
                      ]}
                      path="brands"
                      waitForLoad={false}
                    />
                  )}
                </PageBlock>
                <PageBlock>
                  <Select
                    description={
                      <>
                        <p>Military Recognition Program: This is a sponsorship or gift program that allows you to give VetRewards to your employees, customers, or organization members.</p>
                        <p>More programs coming soon!</p>
                      </>
                    }
                    label="Program Type"
                    name="type"
                    options={[
                      { id: 'mrp', name: 'Military Recognition Program' },
                    ]}
                  />
                </PageBlock>
                <PageBlock>
                  <Select
                    description={
                      <p>
                        Is this program for your employees, customers, or organization members? Depending on your selection, we will customize your program landing page for your specified recipients.
                      </p>
                    }
                    label="Program Recipients"
                    name="content.recipients"
                    options={[
                      { id: 'employees', name: 'Employees' },
                      { id: 'customers', name: 'Customers' },
                      { id: 'member_organizations', name: 'Member Organization' },
                    ]}
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={1}
              value={activeStep}
            >
              <div className={classes.formContent}>
                <PageBlock>
                  <TextField
                    helperText={
                      <p>
                        This is the title that will appear on your program landing page and will be visible to the recipients of your program. Keep it short and specific.
                      </p>
                    }
                    label="Recipient-Facing Program Title"
                    name="content.title"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={
                      <p>
                        This title is for you and your team internally in case you need to search for this program to make edits.
                      </p>
                    }
                    label="Internal Program Title"
                    name="name"
                    required
                  />
                </PageBlock>
                <PageBlock>
                  <Select
                    description={
                      <>
                        <p>Choose how WeSalute will deliver the product for your program. This selection is permanent for this program.</p>
                        <p>More delivery methods coming soon!</p>
                      </>
                    }
                    label="Delivery Method"
                    name="method"
                    options={[
                      { id: 'coupons', name: 'Bulk Order Recipient Codes' },
                    ]}
                  />
                </PageBlock>
                <PageBlock>
                  <TextField
                    helperText={
                      <p>
                        This is how many paid subscriptions you’ll be purchasing for this program. If you don’t know, that’s okay, leave it at 0. You can easily order more codes for this program after you create the program, in your Manage tools.
                      </p>
                    }
                    inputProps={{ min: 0, max: 10000 }}
                    label="Enter initial amount of recipient codes"
                    name="mrp_batch.coupons"
                    required
                    type="number"
                  />
                </PageBlock>
                <PageBlock>
                  <FieldWrapper
                    helperText={
                      <>
                        <p>These are products that go hand-in-hand with VetRewards and add value for recipients.</p>
                        <p>TravelProtection is a valuable package of insurance and assistance services that protects VetRewards users should they experience a medical or travel emergency away from home.</p>
                        <p>This selection is permanent for this program.</p>
                      </>
                    }
                    label="Add-ons"
                    name="config.travel_protection"
                  >
                    <Checkbox
                      label="Include Travel Protection ($20 per user)"
                      name="config.travel_protection"
                    />
                  </FieldWrapper>
                </PageBlock>
                <PageBlock adminView>
                  <TextField
                    helperText={
                      <p>
                        Pricing rate id
                      </p>
                    }
                    inputProps={{ maxLength: 20 }}
                    label="Pricing rate id"
                    name="discount.id"
                  />
                </PageBlock>
              </div>
            </TabPanel>
            <TabPanel
              index={2}
              value={activeStep}
            >
              <PageBlock>
                <Invoice
                  coupons={coupons}
                  travelProtection={travelProtection}
                />
              </PageBlock>
              <PageBlock>
                <div className={classes.reviewCheckbox}>
                  <Checkbox
                    label="I agree to pay the invoice for this program within 30 days. You can submit this program and get your recipient codes now, then pay your invoice later in Billing."
                    name="__confirm"
                  />
                </div>
              </PageBlock>
            </TabPanel>
            <TabPanel
              index={3}
              value={activeStep}
            >
              <ThankYou
                coupons={coupons}
                program={program}
              />
              {(!!platformUrl && !!program) && (
                <PageBlock>
                  <div className={classes.getBox}>
                    <Typography
                      className={classes.getTitle}
                      variant="h2"
                    >
                      Landing page URL
                    </Typography>
                    <Typography
                      className={classes.getCaption}
                      display="block"
                      variant="body2"
                    >
                      Share this URL with your recipients. Page will be available within 2 minutes. They will enter their promo code here:
                    </Typography>
                    <div className={classes.getCopy}>
                      <span id="mrp-url">
                        {platformUrl
                          .replace('{id}', program.id)
                          .replace('{type}', 'mrp')
                        }
                      </span>
                      <Button
                        className={classes.orangeButton}
                        onClick={copyText}
                      >
                        COPY
                      </Button>
                    </div>
                  </div>
                </PageBlock>
              )}
            </TabPanel>
            <div className={classes.actions}>
              {(activeStep > 0 && activeStep < steps.length - 1) ? (
                <Button
                  className={clsx(classes.button, classes.formButtonMedium)}
                  color="primary"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setActiveStep(activeStep - 1);
                  }}
                  variant="outlined"
                >
                  Back
                </Button>
              ) : null}
              {(activeStep < steps.length - 2) ? (
                <Button
                  className={clsx(classes.button, classes.formButtonLarge)}
                  color="primary"
                  data-testid="submit-next"
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    !!errors['__confirm'] && clearError('__confirm');
                    const result = await triggerValidation(fieldsToValidate[activeStep] || [], true);
                    result ? setActiveStep(activeStep + 1) : setErrorsTriggered(triggered => triggered + 1);
                  }}
                  variant="contained"
                >
                  Next Step
                </Button>
              ) : null}
              {(activeStep === 2) && (
                <>
                  {/* <Typography
                    gutterBottom
                    variant="subtitle2"
                  >
                    Visit Billing to set up your billing options and pay your invoices. You can submit this program and get your coupon codes now, then pay your invoice later.
                  </Typography> */}
                  <Button
                    className={clsx(classes.button, classes.formButtonLarge)}
                    color="primary"
                    data-testid="submit-save"
                    disabled={saveLoading}
                    type="submit"
                    variant="contained"
                  >
                    Submit and get recipient codes
                    {saveLoading && (
                      <CircularProgress
                        className={classes.buttonProgress}
                        size={24}
                      />
                    )}
                  </Button>
                </>
              )}
            </div>
          </form>
        </FormContext>
      </Card>
    </div>
  );
};

export default withRouter(ProgramCreate);
