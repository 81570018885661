import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useField from 'inc/hooks/useField';
import { useDropzone } from 'react-dropzone';
import FieldWrapper from './FieldWrapper';
import { AssetList } from 'containers';
import api from 'services/api';
import B2b from 'services/api/providers/B2b';
import TextField from './TextField';
import useDialog from 'inc/hooks/useDialog';
import Dialog from 'containers/dialogs/Dialog';
import {
  FILE_DEFAULT,
  FILE_REMOVED,
  FILE_LOADING,
  FILE_UPLOADING
} from 'inc/constants';

type Props = {
  altProps?: Data,
  description?: React.ReactNode | string,
  entityId: string,
  entityType: 'brand' | 'company',
  helperText?: React.ReactNode | string,
  fromLibrary?: boolean,
  label?: string,
  name: string,
  onChange?: (asset: Data) => void,
  onUpload?: (asset: Document.Base) => void,
  required?: boolean,
};

type AssetObject = {
  id: string,
  name: string,
  preview: string,
  status: number,
  protected: boolean,
}

const useStyles = makeStyles(() => ({
  root: {
    border: '1px dashed #ccc',
    padding: '35px 10px',
    textAlign: 'center',
    '&.error': {
      borderColor: '#f00',
    },
  },
  button: {
    marginRight: 10,
    marginTop: 10,
  }
}));

const AssetImage = ({ description, helperText, entityId, entityType, fromLibrary = true, label, name, onChange, onUpload, required, altProps }: Props) => {
  const classes = useStyles();
  const [image, setImage] = useState<Partial<AssetObject>>({});
  const { register, setValue: setFieldValue } = useFormContext();
  const { error, setValue } = useField(`${name}.asset`, {
    callback: async (data: Document.Base) => {
      setValue(data[`${name}.asset`]);
      setImage(image => ({...image, status: FILE_LOADING}));
      const file = await api
        .path('assets')
        .get(data[`${name}.asset`]);
      onChange && onChange(file);
      file.id && setImage(image => ({
        ...image,
        id: file.id,
        preview: file.file,
        status: FILE_DEFAULT,
      }));
    },
  });
  useEffect(() => {
    if (image.id || image.status) {
      setValue(image.id, true, true);
      setFieldValue(`${name}.__preview`, image.preview);
    }
  }, [image]);
  useEffect(() => {
    register({ name: `${name}.__preview` });
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      setImage(image => ({
        ...image,
        name: file.name,
        preview: URL.createObjectURL(file),
      }));
      const reader = new FileReader()
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = async () => {
        setImage(image => ({
          ...image,
          id: '',
          status: FILE_UPLOADING,
        }));
        const fileUpload: Data = {
          name: file.name,
          type: 'image',
          filename: file.name,
          private: false,
          file: window.btoa(reader.result as string),
        };
        fileUpload[entityType] = entityId;
        const ret: Document.Base = await (api.provider('b2b') as B2b).upload(fileUpload, {});
        setImage(image => ({
          ...image,
          id: ret.id,
          status: FILE_DEFAULT,
        }));
        onChange && onChange({...ret, new: true});
        onUpload && onUpload(ret);
      }
      // @todo replace with more stable approach.
      // reader.readAsDataURL(file.rawFile)
      reader.readAsBinaryString(file);
    })
  }, [entityId]);
  const { openDialog, closeDialog, dialogProps } = useDialog({
    title: 'Select a file',
  });
  const {getRootProps, getInputProps, open: uploadDesktopFiles} = useDropzone({
    accept: 'image/*',
    noClick: true,
    multiple: false,
    onDrop,
  });
  return (
    <FieldWrapper
      description={description}
      helperText={helperText}
      label={label}
      name={`${name}.asset`}
      required={required}
    >
      <div
        data-testid={`${name}-input`}
        {...getRootProps()}
      >
        <div
          className={clsx({
            [classes.root]: true,
            error: !!error,
          })}
        >
          <input {...getInputProps()} />
          {!image.id && ![FILE_LOADING, FILE_UPLOADING].includes(image.status || FILE_DEFAULT) && (
            <>
              <h2>Drag and drop files</h2>
              <p>or</p>
              {fromLibrary && (
                <>
                  <Button
                    className={classes.button}
                    color="primary"
                    component="label"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      uploadDesktopFiles();
                    }}
                    variant="outlined"
                  >
                    Upload from Computer
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    component="label"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      openDialog({});
                    }}
                    variant="contained"
                  >
                    Select from Library
                  </Button>
                </>
              )}
            </>
          )}
          {!!image.preview && !image.protected && (
            <p>
              <img
                src={image.preview}
                width="180"
              />
            </p>
          )}
          {!image.id && FILE_LOADING === image.status && (
            <>
              <CircularProgress />
              <h3>Loading...</h3>
            </>
          )}
          {!image.id && FILE_UPLOADING === image.status && (
            <>
              <CircularProgress />
              <h3>Uploading...</h3>
            </>
          )}
          {!!image.id && (
            <>
              <TextField
                label="Alternative Text"
                name={`${name}.alt`}
                required
                style={{ width: 300 }}
                {...altProps}
              />
              <Button
                component="label"
                onClick={() => { setImage({ status: FILE_REMOVED }); onChange && onChange({}); }}
                variant="contained"
              >
                Remove
              </Button>
            </>
          )}
          {/* {!!progress && (<p>Progress: {progress} / 100</p>)} */}
        </div>
      </div>
      <Dialog
        {...dialogProps}
        component={() => {
          return (
            <AssetList
              entityId={entityId}
              entityType={entityType}
              onFileSelect={(doc) => {
                onChange && onChange(doc);
                setImage({
                  id: doc.id,
                  name: doc.filename,
                  protected: doc.protected,
                  preview: doc.file,
                });
                closeDialog();
              }}
            />
          );
        }}
      />
    </FieldWrapper>
  )
};

export default AssetImage;
