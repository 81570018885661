import React from 'react';
import clsx from 'clsx';
import {
  NavLink as RouterLink,
  Link,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  button: {
    marginLeft: theme.spacing(1),
    padding: 0
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  buttonLink: {
    color: '#fff',
    padding: '13px 16px'
  }
}));

type Props = {
  buttons: { name: string, path: string }[],
  children?: React.ReactNode,
  className?: string,
  tabs?: { name: string, path: string }[],
} & RouteComponentProps;

const ListToolbar = ({ buttons = [], tabs = [], children, className, match }: Props) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {buttons.length && (
          buttons.map(button => (
            <Button
              className={classes.button}
              color="primary"
              key={button.path}
              variant="contained"
            >
              <RouterLink
                className={classes.buttonLink}
                to={button.path}
              >{button.name}</RouterLink>
            </Button>
          ))
        )}
      </div>
      {((!!children || !!tabs.length) && (
        <div
          className={clsx(classes.row, 'tabs-wrapper')}
          style={{ marginTop: '24px' }}
        >
          {!!tabs.length && (
            <>
              <Tabs
                aria-label="scrollable force tabs example"
                indicatorColor="primary"
                scrollButtons="on"
                textColor="primary"
                value={tabs.findIndex(tab => tab.path === match.url)}
              >
                {tabs.map(tab => (
                  <Tab
                    component={Link}
                    key={tab.path}
                    label={tab.name}
                    to={tab.path}
                  />
                ))}
              </Tabs>
              <div className={clsx(classes.spacer, 'tabs-spacer')} />
            </>
          )}
          {children}
        </div>
      ))}
    </div>
  );
};

export default withRouter(ListToolbar);
