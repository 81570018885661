import React, { useState } from 'react';
import MuiRadio from '@material-ui/core/Radio';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useField from 'inc/hooks/useField';
import FieldWrapper from './FieldWrapper';

type Props = {
  helperText?: React.ReactNode | string,
  label?: string,
  name: string,
  options: {
    id: string,
    inputProps?: ComponentInputProps,
    name: string,
  }[],
  required?: boolean,
};

function RadioGroup({ helperText, label, name, options, required }: Props) {
  const [checked, setChecked] = useState('');
  const { setValue, error } = useField(name, {
    callback: (data: Document.Base) => {
      setChecked(data[name]);
      setValue(data[name], !!error);
    },
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist && e.persist();
    setChecked(e.target.value);
    setValue(e.target.value, !!error, true);
  }
  if (process.env.REACT_APP_TESTING) {
    options = options.map(option => {
      return {...option, inputProps: {
        className: 'reset',
        'data-testid': `${name}-${option.id}-input`,
      }};
    });
  }
  return (
    <FieldWrapper
      helperText={helperText}
      label={label}
      name={name}
      required={required}
    >
      <MuiRadioGroup
        name={name}
        onChange={onChange}
        value={checked}
      >
        {options.map(option => {
          return (
            <FormControlLabel
              control={<MuiRadio />}
              key={option.id}
              label={option.name}
              value={option.id}
            />
          )
        })}
      </MuiRadioGroup>
    </FieldWrapper>
  );
}

export default RadioGroup;
