/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

/**
 * Ajv is a peer dependency for development builds. It's used to apply run-time validation
 * to message payloads before passing them on to the underlying analytics instance.
 *
 * Note that the production bundle does not depend on Ajv.
 *
 * You can install it with: `npm install --save-dev ajv`.
 */
import Ajv from 'ajv'
import * as Segment from './segment'

export interface Context {
	groupId: string
}
export interface Address {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface User {
	/**
	 * Whether the user has two factor auth enabled or not
	 */
	'2fa'?: boolean
	/**
	 * User address
	 */
	address?: Address
	/**
	 * User birthday
	 */
	birthday?: string
	/**
	 * Wate user was created
	 */
	createdAt?: string
	/**
	 * Email of user
	 */
	email: string
	/**
	 * First name of user
	 */
	firstName?: string
	/**
	 * User ID
	 */
	id: string
	/**
	 * Date of last login
	 */
	lastLogin?: string
	/**
	 * Last name of user
	 */
	lastName?: string
	/**
	 * Number of times the user has logged in
	 */
	loginCount?: number
	/**
	 * User phone number
	 */
	phone?: string
	/**
	 * Permissions of user within account
	 */
	role?: string
	/**
	 * Title of the user of their job function
	 */
	title?: string
	/**
	 * Type of signup, e.g. 'invited', 'organic'
	 */
	type?: string
	/**
	 * Username
	 */
	username?: string
	/**
	 * User website URL
	 */
	website?: string
}
export interface AccountAddedUser {
	context: Context
	/**
	 * The permission group for this user in this account
	 */
	role: string
	/**
	 * User information
	 */
	user: User
}
export interface Address1 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company {
	/**
	 * Company address
	 */
	address: Address1
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context1 {
	groupId: string
}
export interface AccountCreated {
	accountName: string
	/**
	 * Company information
	 */
	company: Company
	context: Context1
}
export interface Address2 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company1 {
	/**
	 * Company address
	 */
	address: Address2
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context2 {
	groupId: string
}
export interface AccountDeleted {
	accountName: string
	/**
	 * Company information
	 */
	company: Company1
	context: Context2
}
export interface Context3 {
	groupId: string
}
export interface Address3 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface User1 {
	/**
	 * Whether the user has two factor auth enabled or not
	 */
	'2fa'?: boolean
	/**
	 * User address
	 */
	address?: Address3
	/**
	 * User birthday
	 */
	birthday?: string
	/**
	 * Wate user was created
	 */
	createdAt?: string
	/**
	 * Email of user
	 */
	email: string
	/**
	 * First name of user
	 */
	firstName?: string
	/**
	 * User ID
	 */
	id: string
	/**
	 * Date of last login
	 */
	lastLogin?: string
	/**
	 * Last name of user
	 */
	lastName?: string
	/**
	 * Number of times the user has logged in
	 */
	loginCount?: number
	/**
	 * User phone number
	 */
	phone?: string
	/**
	 * Permissions of user within account
	 */
	role: string
	/**
	 * Title of the user of their job function
	 */
	title?: string
	/**
	 * Type of signup, e.g. 'invited', 'organic'
	 */
	type?: string
	/**
	 * Username
	 */
	username?: string
	/**
	 * User website URL
	 */
	website?: string
}
export interface AccountRemovedUser {
	context: Context3
	/**
	 * Permissions of user being deleted within account
	 */
	role: string
	/**
	 * User information
	 */
	user: User1
}
export interface Address4 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company2 {
	/**
	 * Company address
	 */
	address: Address4
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context4 {
	groupId: string
}
export interface AccountUpdated {
	/**
	 * Company information
	 */
	company: Company2
	context: Context4
}
export interface Approver {
	/**
	 * Email of approver
	 */
	email: string
	/**
	 * ID of approver
	 */
	id: string
}
export interface Address5 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand {
	/**
	 * Brand address
	 */
	address?: Address5
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address6 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company3 {
	/**
	 * Company address
	 */
	address?: Address6
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context5 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandApproved {
	/**
	 * Approver information
	 */
	approver: Approver
	/**
	 * Brand information
	 */
	brand: Brand
	/**
	 * Company information
	 */
	company: Company3
	/**
	 * Context
	 */
	context: Context5
}
export interface Address7 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand1 {
	/**
	 * Brand address
	 */
	address: Address7
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode: string
	/**
	 * Brand NAICS code
	 */
	naicsCode: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address8 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company4 {
	/**
	 * Company address
	 */
	address?: Address8
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context6 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandCreated {
	/**
	 * Brand information
	 */
	brand: Brand1
	/**
	 * Company information
	 */
	company: Company4
	/**
	 * Context
	 */
	context: Context6
}
export interface Address9 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand2 {
	/**
	 * Brand address
	 */
	address: Address9
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode: string
	/**
	 * Brand NAICS code
	 */
	naicsCode: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address10 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company5 {
	/**
	 * Company address
	 */
	address?: Address10
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context7 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandCreationStepCompleted {
	/**
	 * Brand information
	 */
	brand: Brand2
	/**
	 * Company information
	 */
	company: Company5
	/**
	 * Context
	 */
	context: Context7
	/**
	 * Name of step in form/wizard
	 */
	stepName: string
}
export interface Address11 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand3 {
	/**
	 * Brand address
	 */
	address?: Address11
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string | null
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string | null
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address12 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company6 {
	/**
	 * Company address
	 */
	address?: Address12
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string | null
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string | null
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context8 {
	/**
	 * Company ID
	 */
	groupId?: string
}
export interface BrandCreationStepViewed {
	/**
	 * Brand information
	 */
	brand: Brand3
	/**
	 * Company information
	 */
	company: Company6
	/**
	 * Context
	 */
	context?: Context8
	/**
	 * Name of step in form/wizard
	 */
	stepName: string
}
export interface Address13 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand4 {
	/**
	 * Brand address
	 */
	address?: Address13
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address14 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company7 {
	/**
	 * Company address
	 */
	address?: Address14
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context9 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandDeleted {
	/**
	 * Brand information
	 */
	brand: Brand4
	/**
	 * Company information
	 */
	company: Company7
	/**
	 * Context
	 */
	context: Context9
}
export interface Address15 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand5 {
	/**
	 * Brand address
	 */
	address: Address15
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode: string
	/**
	 * Brand NAICS code
	 */
	naicsCode: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address16 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company8 {
	/**
	 * Company address
	 */
	address?: Address16
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context10 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandPublished {
	/**
	 * Brand information
	 */
	brand: Brand5
	/**
	 * Company information
	 */
	company: Company8
	/**
	 * Context
	 */
	context: Context10
}
export interface Address17 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand6 {
	/**
	 * Brand address
	 */
	address?: Address17
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address18 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company9 {
	/**
	 * Company address
	 */
	address?: Address18
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context11 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Rejector {
	/**
	 * Email of rejector
	 */
	email: string
	/**
	 * ID of rejector
	 */
	id: string
}
export interface Rejection {
	/**
	 * Rejection reason
	 */
	reason: string
	/**
	 * Rejector information
	 */
	rejector: Rejector
}
export interface BrandRejected {
	/**
	 * Brand information
	 */
	brand: Brand6
	/**
	 * Company information
	 */
	company: Company9
	/**
	 * Context
	 */
	context: Context11
	/**
	 * Rejection information
	 */
	rejection: Rejection
}
export interface Address19 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand7 {
	/**
	 * Brand address
	 */
	address?: Address19
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address20 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company10 {
	/**
	 * Company address
	 */
	address?: Address20
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context12 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Submitter {
	/**
	 * Submitter email
	 */
	email: string
	/**
	 * Submitter ID
	 */
	id: string
}
export interface BrandSubmittedForReview {
	/**
	 * Brand information
	 */
	brand: Brand7
	/**
	 * Company information
	 */
	company: Company10
	/**
	 * Context
	 */
	context: Context12
	/**
	 * Submitter information
	 */
	submitter: Submitter
}
export interface Address21 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand8 {
	/**
	 * Brand address
	 */
	address?: Address21
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address22 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company11 {
	/**
	 * Company address
	 */
	address?: Address22
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context13 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandUnpublished {
	/**
	 * Brand information
	 */
	brand: Brand8
	/**
	 * Company information
	 */
	company: Company11
	/**
	 * Context
	 */
	context: Context13
}
export interface Address23 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand9 {
	/**
	 * Brand address
	 */
	address?: Address23
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address24 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company12 {
	/**
	 * Company address
	 */
	address?: Address24
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context14 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandUnpulished {
	/**
	 * Brand information
	 */
	brand: Brand9
	/**
	 * Company information
	 */
	company: Company12
	/**
	 * Context
	 */
	context: Context14
}
export interface Address25 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand10 {
	/**
	 * Brand address
	 */
	address: Address25
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode: string
	/**
	 * Brand NAICS code
	 */
	naicsCode: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address26 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company13 {
	/**
	 * Company address
	 */
	address?: Address26
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context15 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface BrandUpdated {
	/**
	 * Brand information
	 */
	brand: Brand10
	/**
	 * Company information
	 */
	company: Company13
	/**
	 * Context
	 */
	context: Context15
}
export interface InviteSent {
	/**
	 * The email address of the person receiving the invite
	 */
	inviteeEmail: string
	/**
	 * The first name of the person receiving the invite
	 */
	inviteeFirstName: string
	/**
	 * The last name of the person receiving the invite
	 */
	inviteeLastName: string
	/**
	 * The permission group for the person receiving the invite
	 */
	inviteeRole: string
}
export interface Approver1 {
	/**
	 * email of approver
	 */
	email: string
	/**
	 * id of approver
	 */
	id: string
}
export interface Address27 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand11 {
	/**
	 * Brand address
	 */
	address?: Address27
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address28 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company14 {
	/**
	 * Company address
	 */
	address?: Address28
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context16 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * published date
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferApproved {
	/**
	 * Approver information
	 */
	approver: Approver1
	/**
	 * Brand information
	 */
	brand: Brand11
	/**
	 * Company information
	 */
	company: Company14
	/**
	 * Context
	 */
	context: Context16
	/**
	 * Offer information
	 */
	offer: Offer
}
export interface Address29 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand12 {
	/**
	 * Brand address
	 */
	address?: Address29
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address30 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company15 {
	/**
	 * Company address
	 */
	address?: Address30
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context17 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer1 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * boolean whether offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferCreated {
	/**
	 * Brand information
	 */
	brand: Brand12
	/**
	 * Company information
	 */
	company: Company15
	/**
	 * Context
	 */
	context: Context17
	/**
	 * Offer information
	 */
	offer: Offer1
}
export interface Address31 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand13 {
	/**
	 * Brand address
	 */
	address?: Address31
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id?: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name?: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address32 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company16 {
	/**
	 * Company address
	 */
	address?: Address32
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id?: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name?: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context18 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer2 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id?: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferCreationStepCompleted {
	/**
	 * Brand information
	 */
	brand: Brand13
	/**
	 * Company information
	 */
	company: Company16
	/**
	 * Context
	 */
	context: Context18
	/**
	 * Offer information
	 */
	offer: Offer2
	/**
	 * Name of step completed
	 */
	stepName: string
}
export interface Address33 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand14 {
	/**
	 * Brand address
	 */
	address?: Address33
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id?: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name?: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address34 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company17 {
	/**
	 * Company address
	 */
	address?: Address34
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id?: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name?: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context19 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer3 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id?: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferCreationStepViewed {
	/**
	 * Brand information
	 */
	brand: Brand14
	/**
	 * Company information
	 */
	company: Company17
	/**
	 * Context
	 */
	context: Context19
	/**
	 * Offer information
	 */
	offer: Offer3
	/**
	 * Name of step viewed
	 */
	stepName: string
}
export interface Address35 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand15 {
	/**
	 * Brand address
	 */
	address?: Address35
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address36 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company18 {
	/**
	 * Company address
	 */
	address?: Address36
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context20 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer4 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * offer published date
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferDeleted {
	/**
	 * Brand information
	 */
	brand: Brand15
	/**
	 * Company information
	 */
	company: Company18
	/**
	 * Context
	 */
	context: Context20
	/**
	 * Offer information
	 */
	offer: Offer4
}
export interface Address37 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand16 {
	/**
	 * Brand address
	 */
	address?: Address37
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address38 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company19 {
	/**
	 * Company address
	 */
	address?: Address38
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context21 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer5 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferPublished {
	/**
	 * Brand information
	 */
	brand: Brand16
	/**
	 * Company information
	 */
	company: Company19
	/**
	 * Context
	 */
	context: Context21
	/**
	 * Offer information
	 */
	offer: Offer5
}
export interface Address39 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand17 {
	/**
	 * Brand address
	 */
	address?: Address39
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address40 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company20 {
	/**
	 * Company address
	 */
	address?: Address40
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context22 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer6 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface Rejector1 {
	/**
	 * ID of rejector
	 */
	email: string
	/**
	 * email of rejector
	 */
	id: string
}
export interface Rejection1 {
	/**
	 * Rejection reason
	 */
	reason: string
	/**
	 * Rejector information
	 */
	rejector: Rejector1
}
export interface OfferRejected {
	/**
	 * Brand information
	 */
	brand: Brand17
	/**
	 * Company information
	 */
	company: Company20
	/**
	 * Context
	 */
	context: Context22
	/**
	 * Offer information
	 */
	offer: Offer6
	/**
	 * Rejection information
	 */
	rejection: Rejection1
}
export interface Address41 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand18 {
	/**
	 * Brand address
	 */
	address?: Address41
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address42 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company21 {
	/**
	 * Company address
	 */
	address?: Address42
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context23 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer7 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferSubmissionCanceled {
	/**
	 * Brand information
	 */
	brand: Brand18
	/**
	 * Company information
	 */
	company: Company21
	/**
	 * Context
	 */
	context: Context23
	/**
	 * Offer information
	 */
	offer: Offer7
}
export interface Address43 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand19 {
	/**
	 * Brand address
	 */
	address?: Address43
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address44 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company22 {
	/**
	 * Company address
	 */
	address?: Address44
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context24 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer8 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date offer published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface Submitter1 {
	/**
	 * email of submitter
	 */
	email: string
	/**
	 * id of submitter
	 */
	id: string
}
export interface OfferSubmittedForReview {
	/**
	 * Brand information
	 */
	brand: Brand19
	/**
	 * Company information
	 */
	company: Company22
	/**
	 * Context
	 */
	context: Context24
	/**
	 * Offer information
	 */
	offer: Offer8
	/**
	 * Submitter information
	 */
	submitter: Submitter1
}
export interface Address45 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand20 {
	/**
	 * Brand address
	 */
	address?: Address45
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address46 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company23 {
	/**
	 * Company address
	 */
	address?: Address46
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context25 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Offer9 {
	/**
	 * custom path entered
	 */
	customUrlPath?: string
	/**
	 * destinatlon url generated
	 */
	destinationUrl?: Record<string, any>
	/**
	 * hero subtitle entered
	 */
	heroSubtitle?: string
	/**
	 * hero title entered
	 */
	heroTitle?: string
	/**
	 * Offer ID
	 */
	id: string
	/**
	 * mcc entered
	 */
	merchantCategoryCode?: string
	/**
	 * active duty, former servicemembers, military families
	 */
	militaryAudienceSegment?: any[]
	/**
	 * name of offer entered
	 */
	offerName?: string
	/**
	 *  'published', 'draft', etc
	 */
	offerStatus?: string
	/**
	 * subtitle of offer entered
	 */
	offerSubtitle?: string
	/**
	 * premium or basic
	 */
	offerValue?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * whether or not offer is published
	 */
	published?: boolean
	/**
	 * date published
	 */
	publishedDate?: string
	/**
	 * array of redemption channels listed
	 */
	redemptionChannels?: any[]
}
export interface OfferUnpublished {
	/**
	 * Brand information
	 */
	brand: Brand20
	/**
	 * Company information
	 */
	company: Company23
	/**
	 * Context
	 */
	context: Context25
	/**
	 * Offer information
	 */
	offer: Offer9
}
export interface Address47 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand21 {
	/**
	 * Brand address
	 */
	address?: Address47
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address48 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company24 {
	/**
	 * Company address
	 */
	address?: Address48
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context26 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient {
	/**
	 * Recipient facing program title text
	 */
	title?: string
	/**
	 * Type of recipient
	 */
	type?: string
}
export interface Program {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal?: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial?: number
	/**
	 * Program delivery method
	 */
	deliveryMethod?: string
	/**
	 * Program ID
	 */
	id: string
	/**
	 * Internal facing program title text entered
	 */
	internalTitle?: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId?: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee?: number
	/**
	 * Recipient information
	 */
	recipient?: Recipient
	/**
	 * Subtotal for program selected
	 */
	total?: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon?: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramCodesDownloaded {
	/**
	 * Brand information
	 */
	brand: Brand21
	/**
	 * Company information
	 */
	company: Company24
	/**
	 * Context
	 */
	context: Context26
	/**
	 * Program information
	 */
	program: Program
}
export interface Address49 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand22 {
	/**
	 * Brand address
	 */
	address?: Address49
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address50 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company25 {
	/**
	 * Company address
	 */
	address?: Address50
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context27 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient1 {
	/**
	 * Recipient facing program title text
	 */
	title?: string
	/**
	 * Type of recipient
	 */
	type?: string
}
export interface Program1 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal?: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount?: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial?: number
	/**
	 * Program delivery method
	 */
	deliveryMethod?: string
	/**
	 * Program ID
	 */
	id: string
	/**
	 * Internal facing program title text entered
	 */
	internalTitle?: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId?: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee?: number
	/**
	 * Recipient information
	 */
	recipient?: Recipient1
	/**
	 * Subtotal for program selected
	 */
	total?: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon?: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramCreated {
	/**
	 * Brand information
	 */
	brand: Brand22
	/**
	 * Company information
	 */
	company: Company25
	/**
	 * Context
	 */
	context: Context27
	/**
	 * Program information
	 */
	program: Program1
}
export interface Address51 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand23 {
	/**
	 * Brand address
	 */
	address?: Address51
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address52 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company26 {
	/**
	 * Company address
	 */
	address?: Address52
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context28 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient2 {
	/**
	 * Recipient facing program title text
	 */
	title: string
	/**
	 * Type of recipient
	 */
	type: string
}
export interface Program2 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal?: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial: number
	/**
	 * Program delivery method
	 */
	deliveryMethod: string
	/**
	 * Program ID
	 */
	id: Record<string, any>
	/**
	 * Internal facing program title text entered
	 */
	internalTitle: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee: number
	/**
	 * Recipient information
	 */
	recipient: Recipient2
	/**
	 * Subtotal for program selected
	 */
	total: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramCreationStepCompleted {
	/**
	 * Brand information
	 */
	brand: Brand23
	/**
	 * Company information
	 */
	company: Company26
	/**
	 * Context
	 */
	context: Context28
	/**
	 * Program information
	 */
	program: Program2
	/**
	 * Name of step in form/wizard
	 */
	stepName: string
}
export interface Address53 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand24 {
	/**
	 * Brand address
	 */
	address?: Address53
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address54 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company27 {
	/**
	 * Company address
	 */
	address?: Address54
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context29 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient3 {
	/**
	 * Recipient facing program title text
	 */
	title?: string
	/**
	 * Type of recipient
	 */
	type?: string
}
export interface Program3 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal?: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount?: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial?: number
	/**
	 * Program delivery method
	 */
	deliveryMethod?: string
	/**
	 * Program ID
	 */
	id: string
	/**
	 * Internal facing program title text entered
	 */
	internalTitle?: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId?: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee?: number
	/**
	 * Recipient information
	 */
	recipient?: Recipient3
	/**
	 * Subtotal for program selected
	 */
	total?: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon?: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramCreationStepViewed {
	/**
	 * Brand information
	 */
	brand: Brand24
	/**
	 * Company information
	 */
	company: Company27
	/**
	 * Context
	 */
	context: Context29
	/**
	 * Program information
	 */
	program: Program3
	/**
	 * Name of step in form/wizard
	 */
	stepName?: string
}
export interface Address55 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand25 {
	/**
	 * Brand address
	 */
	address?: Address55
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address56 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company28 {
	/**
	 * Company address
	 */
	address?: Address56
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context30 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient4 {
	/**
	 * Recipient facing program title text
	 */
	title: string
	/**
	 * Type of recipient
	 */
	type: string
}
export interface Program4 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial: number
	/**
	 * Program delivery method
	 */
	deliveryMethod: string
	/**
	 * Program ID
	 */
	id: Record<string, any>
	/**
	 * Internal facing program title text entered
	 */
	internalTitle: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee: number
	/**
	 * Recipient information
	 */
	recipient: Recipient4
	/**
	 * Subtotal for program selected
	 */
	total: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramDeleted {
	/**
	 * Brand information
	 */
	brand: Brand25
	/**
	 * Company information
	 */
	company: Company28
	/**
	 * Context
	 */
	context: Context30
	/**
	 * Program information
	 */
	program: Program4
}
export interface Address57 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand26 {
	/**
	 * Brand address
	 */
	address?: Address57
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address58 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company29 {
	/**
	 * Company address
	 */
	address?: Address58
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context31 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient5 {
	/**
	 * Recipient facing program title text
	 */
	title: string
	/**
	 * Type of recipient
	 */
	type: string
}
export interface Program5 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial: number
	/**
	 * Program delivery method
	 */
	deliveryMethod: string
	/**
	 * Program ID
	 */
	id: string
	/**
	 * Internal facing program title text entered
	 */
	internalTitle: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee: number
	/**
	 * Recipient information
	 */
	recipient: Recipient5
	/**
	 * Subtotal for program selected
	 */
	total: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramPublished {
	/**
	 * Brand information
	 */
	brand: Brand26
	/**
	 * Company information
	 */
	company: Company29
	/**
	 * Context
	 */
	context: Context31
	/**
	 * Program information
	 */
	program: Program5
}
export interface Address59 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Brand27 {
	/**
	 * Brand address
	 */
	address?: Address59
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date brand was created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Brand description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count
	 */
	employees?: number
	/**
	 * Headline text
	 */
	headline?: string
	/**
	 * Whether hero was uploaded or not
	 */
	heroUploaded?: boolean
	/**
	 * ID of brand
	 */
	id: string
	/**
	 * Industry of brand
	 */
	industry?: string
	/**
	 * Whether logo was uploaded or not
	 */
	logoUploaded?: boolean
	/**
	 * Brand MCC
	 */
	merchantCategoryCode?: string
	/**
	 * Brand NAICS code
	 */
	naicsCode?: string
	/**
	 * Brand name
	 */
	name: string
	/**
	 * Brand phone number
	 */
	phone?: string
	/**
	 * State of publication
	 */
	publicationStatus?: string
	/**
	 * Whether brand is published on site
	 */
	published?: boolean
	/**
	 * Date brand is published on site
	 */
	publishedDate?: string
	/**
	 * Brand website
	 */
	website?: string
}
export interface Address60 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country code
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface Company30 {
	/**
	 * Company address
	 */
	address?: Address60
	/**
	 * ???
	 */
	avatar?: string
	/**
	 * Date company was entered and created on site
	 */
	createdAt?: string
	/**
	 * Created by - internal vs external
	 */
	createdBy?: string
	/**
	 * Company description
	 */
	description?: string
	/**
	 * Email domain of company (e.g. @wesalute.com)
	 */
	email?: string
	/**
	 * Employee count of the company
	 */
	employees?: number
	/**
	 * WeSalute generated ID for company
	 */
	id: string
	/**
	 * Company industry
	 */
	industry?: string
	/**
	 * Company name
	 */
	name: string
	/**
	 * Company phone number
	 */
	phone?: string
	/**
	 * Company website URL
	 */
	website?: string
}
export interface Context32 {
	/**
	 * Company ID
	 */
	groupId: string
}
export interface Recipient6 {
	/**
	 * Recipient facing program title text
	 */
	title: string
	/**
	 * Type of recipient
	 */
	type: string
}
export interface Program6 {
	/**
	 * Subtotal for addons selected
	 */
	addonTotal: number
	/**
	 * Bulk order quantity, number of codes
	 */
	codesCount: number
	/**
	 * Number of initial coupon codes entered
	 */
	codesCountInitial: number
	/**
	 * Program delivery method
	 */
	deliveryMethod: string
	/**
	 * Program ID
	 */
	id: string
	/**
	 * Internal facing program title text entered
	 */
	internalTitle: string
	/**
	 * Pricing rate id from Stripe prices API
	 */
	pricingRateId: string
	/**
	 * Subtotal for processing fee
	 */
	processingFee: number
	/**
	 * Recipient information
	 */
	recipient: Recipient6
	/**
	 * Subtotal for program selected
	 */
	total: number
	/**
	 * Whether the addon was selected or not
	 */
	travelProtectionAddon: boolean
	/**
	 * Type of program
	 */
	type: string
}
export interface ProgramUnpublished {
	/**
	 * Brand information
	 */
	brand: Brand27
	/**
	 * Company information
	 */
	company: Company30
	/**
	 * Context
	 */
	context: Context32
	/**
	 * Program information
	 */
	program: Program6
}
export interface Context33 {
	groupId: string
}
export interface Address61 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface User2 {
	/**
	 * Whether the user has two factor auth enabled or not
	 */
	'2fa'?: boolean
	/**
	 * User address
	 */
	address?: Address61
	/**
	 * User birthday
	 */
	birthday?: string
	/**
	 * Wate user was created
	 */
	createdAt?: string
	/**
	 * Email of user
	 */
	email: string
	/**
	 * First name of user
	 */
	firstName?: string
	/**
	 * User ID
	 */
	id?: string
	/**
	 * Date of last login
	 */
	lastLogin?: string
	/**
	 * Last name of user
	 */
	lastName?: string
	/**
	 * Number of times the user has logged in
	 */
	loginCount?: number
	/**
	 * User phone number
	 */
	phone?: string
	/**
	 * Permissions of user within account
	 */
	role?: string
	/**
	 * Title of the user of their job function
	 */
	title?: string
	/**
	 * Type of signup, e.g. 'invited', 'organic'
	 */
	type?: string
	/**
	 * Username
	 */
	username?: string
	/**
	 * User website URL
	 */
	website?: string
}
export interface SignedIn {
	context: Context33
	/**
	 * User information
	 */
	user: User2
	/**
	 * The username of the user logging in
	 */
	username: string
}
export interface Context34 {
	groupId: string
}
export interface Address62 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface User3 {
	/**
	 * Whether the user has two factor auth enabled or not
	 */
	'2fa'?: boolean
	/**
	 * User address
	 */
	address?: Address62
	/**
	 * User birthday
	 */
	birthday?: string
	/**
	 * Wate user was created
	 */
	createdAt?: string
	/**
	 * Email of user
	 */
	email: string
	/**
	 * First name of user
	 */
	firstName?: string
	/**
	 * User ID
	 */
	id: string
	/**
	 * Date of last login
	 */
	lastLogin?: string
	/**
	 * Last name of user
	 */
	lastName?: string
	/**
	 * Number of times the user has logged in
	 */
	loginCount?: number
	/**
	 * User phone number
	 */
	phone?: string
	/**
	 * Permissions of user within account
	 */
	role?: string
	/**
	 * Title of the user of their job function
	 */
	title?: string
	/**
	 * Type of signup, e.g. 'invited', 'organic'
	 */
	type?: string
	/**
	 * Username
	 */
	username?: string
	/**
	 * User website URL
	 */
	website?: string
}
export interface SignedOut {
	context: Context34
	/**
	 * User information
	 */
	user: User3
	/**
	 * The username of the user logging in
	 */
	username: string
}
export interface Context35 {
	groupId: string
}
export interface Address63 {
	/**
	 * City
	 */
	city?: string
	/**
	 * Country
	 */
	country?: string
	/**
	 * Address line 1
	 */
	line1?: string
	/**
	 * Address line 2
	 */
	line2?: string
	/**
	 * ZIP code
	 */
	postalCode?: string
	/**
	 * State
	 */
	state?: string
}
export interface User4 {
	/**
	 * Whether the user has two factor auth enabled or not
	 */
	'2fa'?: boolean
	/**
	 * User address
	 */
	address?: Address63
	/**
	 * User birthday
	 */
	birthday?: string
	/**
	 * Wate user was created
	 */
	createdAt?: string
	/**
	 * Email of user
	 */
	email?: string
	/**
	 * First name of user
	 */
	firstName?: string
	/**
	 * User ID
	 */
	id: string
	/**
	 * Date of last login
	 */
	lastLogin?: string
	/**
	 * Last name of user
	 */
	lastName?: string
	/**
	 * Number of times the user has logged in
	 */
	loginCount?: number
	/**
	 * User phone number
	 */
	phone?: string
	/**
	 * Permissions of user within account
	 */
	role?: string
	/**
	 * Title of the user of their job function
	 */
	title?: string
	/**
	 * Type of signup, e.g. 'invited', 'organic'
	 */
	type?: string
	/**
	 * Username
	 */
	username?: string
	/**
	 * User website URL
	 */
	website?: string
}
export interface SignedUp {
	context: Context35
	/**
	 * The email of the user
	 */
	email: string
	/**
	 * The first name of the user
	 */
	firstName: string
	/**
	 * The last name of the user
	 */
	lastName: string
	/**
	 * The phone number of the user
	 */
	phone: string
	/**
	 * The title of the user
	 */
	title: string
	/**
	 * The type of signup, e.g. invited, organic.
	 */
	type: string
	/**
	 * User information
	 */
	user?: User4
	/**
	 * The username of the user
	 */
	username: string
}
export interface Context36 {
	/**
	 * The id of the account being created
	 */
	groupId: string
}
export interface TrialEnded {
	context: Context36
	/**
	 * The date when the trial ends. It is an ISO-8601 date string
	 */
	trialEndDate: string
	/**
	 * The name of the plan being trialed
	 */
	trialPlanName: string
	/**
	 * The date when the trial starts. It is an ISO-8601 date string
	 */
	trialStartDate: string
}
export interface Context37 {
	/**
	 * The id of the account being created
	 */
	groupId: Record<string, any>
}
export interface TrialStarted {
	context: Context37
	/**
	 * The date when the trial ends. It is an ISO-8601 date string
	 */
	trialEndDate: string
	/**
	 * The name of the plan being trialed
	 */
	trialPlanName: string
	/**
	 * The date when the trial starts. It is an ISO-8601 date string
	 */
	trialStartDate: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: Ajv.ErrorObject[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let onViolation = defaultValidationErrorHandler

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
	onViolation = options.onViolation || onViolation
}

/**
 * Validates a message against a JSON Schema using Ajv. If the message
 * is invalid, the `onViolation` handler will be called.
 */
function validateAgainstSchema(message: Record<string, any>, schema: object) {
	const ajv = new Ajv({ schemaId: 'auto', allErrors: true, verbose: true })
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
	ajv.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

	if (!ajv.validate(schema, message) && ajv.errors) {
		onViolation(message, ajv.errors)
	}
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.3.0',
			},
		},
	}
}

/**
 * @typedef Context
 * @property {string} groupId -
 */
/**
 * @typedef Address
 * @property {string} [city] - City
 * @property {string} [country] - Country
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef User
 * @property {boolean} [2fa] - Whether the user has two factor auth enabled or not
 * @property {Address} [address] - User address
 * @property {string} [birthday] - User birthday
 * @property {string} [createdAt] - Wate user was created
 * @property {string} email - Email of user
 * @property {string} [firstName] - First name of user
 * @property {string} id - User ID
 * @property {string} [lastLogin] - Date of last login
 * @property {string} [lastName] - Last name of user
 * @property {number} [loginCount] - Number of times the user has logged in
 * @property {string} [phone] - User phone number
 * @property {string} [role] - Permissions of user within account
 * @property {string} [title] - Title of the user of their job function
 * @property {string} [type] - Type of signup, e.g. 'invited', 'organic'
 * @property {string} [username] - Username
 * @property {string} [website] - User website URL
 */
/**
 * @typedef AccountAddedUser
 * @property {Context} context -
 * @property {string} role - The permission group for this user in this account
 * @property {User} user - User information
 */
/**
 * @typedef Address1
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company
 * @property {Address1} address - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} phone - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context1
 * @property {string} groupId -
 */
/**
 * @typedef AccountCreated
 * @property {string} accountName -
 * @property {Company} company - Company information
 * @property {Context1} context -
 */
/**
 * @typedef Address2
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company1
 * @property {Address2} address - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} phone - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context2
 * @property {string} groupId -
 */
/**
 * @typedef AccountDeleted
 * @property {string} accountName -
 * @property {Company1} company - Company information
 * @property {Context2} context -
 */
/**
 * @typedef Context3
 * @property {string} groupId -
 */
/**
 * @typedef Address3
 * @property {string} [city] - City
 * @property {string} [country] - Country
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef User1
 * @property {boolean} [2fa] - Whether the user has two factor auth enabled or not
 * @property {Address3} [address] - User address
 * @property {string} [birthday] - User birthday
 * @property {string} [createdAt] - Wate user was created
 * @property {string} email - Email of user
 * @property {string} [firstName] - First name of user
 * @property {string} id - User ID
 * @property {string} [lastLogin] - Date of last login
 * @property {string} [lastName] - Last name of user
 * @property {number} [loginCount] - Number of times the user has logged in
 * @property {string} [phone] - User phone number
 * @property {string} role - Permissions of user within account
 * @property {string} [title] - Title of the user of their job function
 * @property {string} [type] - Type of signup, e.g. 'invited', 'organic'
 * @property {string} [username] - Username
 * @property {string} [website] - User website URL
 */
/**
 * @typedef AccountRemovedUser
 * @property {Context3} context -
 * @property {string} role - Permissions of user being deleted within account
 * @property {User1} user - User information
 */
/**
 * @typedef Address4
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company2
 * @property {Address4} address - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} phone - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context4
 * @property {string} groupId -
 */
/**
 * @typedef AccountUpdated
 * @property {Company2} company - Company information
 * @property {Context4} context -
 */
/**
 * @typedef Approver
 * @property {string} email - Email of approver
 * @property {string} id - ID of approver
 */
/**
 * @typedef Address5
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand
 * @property {Address5} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address6
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company3
 * @property {Address6} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context5
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandApproved
 * @property {Approver} approver - Approver information
 * @property {Brand} brand - Brand information
 * @property {Company3} company - Company information
 * @property {Context5} context - Context
 */
/**
 * @typedef Address7
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand1
 * @property {Address7} address - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} merchantCategoryCode - Brand MCC
 * @property {string} naicsCode - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} phone - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address8
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company4
 * @property {Address8} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context6
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandCreated
 * @property {Brand1} brand - Brand information
 * @property {Company4} company - Company information
 * @property {Context6} context - Context
 */
/**
 * @typedef Address9
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand2
 * @property {Address9} address - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} headline - Headline text
 * @property {boolean} heroUploaded - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} logoUploaded - Whether logo was uploaded or not
 * @property {string} merchantCategoryCode - Brand MCC
 * @property {string} naicsCode - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} phone - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address10
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company5
 * @property {Address10} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context7
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandCreationStepCompleted
 * @property {Brand2} brand - Brand information
 * @property {Company5} company - Company information
 * @property {Context7} context - Context
 * @property {string} stepName - Name of step in form/wizard
 */
/**
 * @typedef Address11
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand3
 * @property {Address11} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string | null} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string | null} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address12
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company6
 * @property {Address12} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string | null} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string | null} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context8
 * @property {string} [groupId] - Company ID
 */
/**
 * @typedef BrandCreationStepViewed
 * @property {Brand3} brand - Brand information
 * @property {Company6} company - Company information
 * @property {Context8} [context] - Context
 * @property {string} stepName - Name of step in form/wizard
 */
/**
 * @typedef Address13
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand4
 * @property {Address13} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address14
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company7
 * @property {Address14} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context9
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandDeleted
 * @property {Brand4} brand - Brand information
 * @property {Company7} company - Company information
 * @property {Context9} context - Context
 */
/**
 * @typedef Address15
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand5
 * @property {Address15} address - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} headline - Headline text
 * @property {boolean} heroUploaded - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} logoUploaded - Whether logo was uploaded or not
 * @property {string} merchantCategoryCode - Brand MCC
 * @property {string} naicsCode - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} phone - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address16
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company8
 * @property {Address16} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context10
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandPublished
 * @property {Brand5} brand - Brand information
 * @property {Company8} company - Company information
 * @property {Context10} context - Context
 */
/**
 * @typedef Address17
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand6
 * @property {Address17} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address18
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company9
 * @property {Address18} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context11
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Rejector
 * @property {string} email - Email of rejector
 * @property {string} id - ID of rejector
 */
/**
 * @typedef Rejection
 * @property {string} reason - Rejection reason
 * @property {Rejector} rejector - Rejector information
 */
/**
 * @typedef BrandRejected
 * @property {Brand6} brand - Brand information
 * @property {Company9} company - Company information
 * @property {Context11} context - Context
 * @property {Rejection} rejection - Rejection information
 */
/**
 * @typedef Address19
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand7
 * @property {Address19} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address20
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company10
 * @property {Address20} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context12
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Submitter
 * @property {string} email - Submitter email
 * @property {string} id - Submitter ID
 */
/**
 * @typedef BrandSubmittedForReview
 * @property {Brand7} brand - Brand information
 * @property {Company10} company - Company information
 * @property {Context12} context - Context
 * @property {Submitter} submitter - Submitter information
 */
/**
 * @typedef Address21
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand8
 * @property {Address21} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address22
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company11
 * @property {Address22} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context13
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandUnpublished
 * @property {Brand8} brand - Brand information
 * @property {Company11} company - Company information
 * @property {Context13} context - Context
 */
/**
 * @typedef Address23
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand9
 * @property {Address23} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address24
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company12
 * @property {Address24} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context14
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandUnpulished
 * @property {Brand9} brand - Brand information
 * @property {Company12} company - Company information
 * @property {Context14} context - Context
 */
/**
 * @typedef Address25
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand10
 * @property {Address25} address - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} createdBy - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} email - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} headline - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} merchantCategoryCode - Brand MCC
 * @property {string} naicsCode - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} phone - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address26
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company13
 * @property {Address26} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context15
 * @property {string} groupId - Company ID
 */
/**
 * @typedef BrandUpdated
 * @property {Brand10} brand - Brand information
 * @property {Company13} company - Company information
 * @property {Context15} context - Context
 */
/**
 * @typedef InviteSent
 * @property {string} inviteeEmail - The email address of the person receiving the invite
 * @property {string} inviteeFirstName - The first name of the person receiving the invite
 * @property {string} inviteeLastName - The last name of the person receiving the invite
 * @property {string} inviteeRole - The permission group for the person receiving the invite
 */
/**
 * @typedef Approver1
 * @property {string} email - email of approver
 * @property {string} id - id of approver
 */
/**
 * @typedef Address27
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand11
 * @property {Address27} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address28
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company14
 * @property {Address28} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context16
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - published date
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferApproved
 * @property {Approver1} approver - Approver information
 * @property {Brand11} brand - Brand information
 * @property {Company14} company - Company information
 * @property {Context16} context - Context
 * @property {Offer} offer - Offer information
 */
/**
 * @typedef Address29
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand12
 * @property {Address29} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address30
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company15
 * @property {Address30} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context17
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer1
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - boolean whether offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferCreated
 * @property {Brand12} brand - Brand information
 * @property {Company15} company - Company information
 * @property {Context17} context - Context
 * @property {Offer1} offer - Offer information
 */
/**
 * @typedef Address31
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand13
 * @property {Address31} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} [id] - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} [name] - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address32
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company16
 * @property {Address32} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} [id] - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} [name] - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context18
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer2
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} [id] - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferCreationStepCompleted
 * @property {Brand13} brand - Brand information
 * @property {Company16} company - Company information
 * @property {Context18} context - Context
 * @property {Offer2} offer - Offer information
 * @property {string} stepName - Name of step completed
 */
/**
 * @typedef Address33
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand14
 * @property {Address33} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} [id] - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} [name] - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address34
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company17
 * @property {Address34} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} [id] - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} [name] - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context19
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer3
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} [id] - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferCreationStepViewed
 * @property {Brand14} brand - Brand information
 * @property {Company17} company - Company information
 * @property {Context19} context - Context
 * @property {Offer3} offer - Offer information
 * @property {string} stepName - Name of step viewed
 */
/**
 * @typedef Address35
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand15
 * @property {Address35} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address36
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company18
 * @property {Address36} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context20
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer4
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - offer published date
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferDeleted
 * @property {Brand15} brand - Brand information
 * @property {Company18} company - Company information
 * @property {Context20} context - Context
 * @property {Offer4} offer - Offer information
 */
/**
 * @typedef Address37
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand16
 * @property {Address37} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address38
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company19
 * @property {Address38} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context21
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer5
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferPublished
 * @property {Brand16} brand - Brand information
 * @property {Company19} company - Company information
 * @property {Context21} context - Context
 * @property {Offer5} offer - Offer information
 */
/**
 * @typedef Address39
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand17
 * @property {Address39} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address40
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company20
 * @property {Address40} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context22
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer6
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef Rejector1
 * @property {string} email - ID of rejector
 * @property {string} id - email of rejector
 */
/**
 * @typedef Rejection1
 * @property {string} reason - Rejection reason
 * @property {Rejector1} rejector - Rejector information
 */
/**
 * @typedef OfferRejected
 * @property {Brand17} brand - Brand information
 * @property {Company20} company - Company information
 * @property {Context22} context - Context
 * @property {Offer6} offer - Offer information
 * @property {Rejection1} rejection - Rejection information
 */
/**
 * @typedef Address41
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand18
 * @property {Address41} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address42
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company21
 * @property {Address42} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context23
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer7
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferSubmissionCanceled
 * @property {Brand18} brand - Brand information
 * @property {Company21} company - Company information
 * @property {Context23} context - Context
 * @property {Offer7} offer - Offer information
 */
/**
 * @typedef Address43
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand19
 * @property {Address43} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address44
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company22
 * @property {Address44} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context24
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer8
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date offer published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef Submitter1
 * @property {string} email - email of submitter
 * @property {string} id - id of submitter
 */
/**
 * @typedef OfferSubmittedForReview
 * @property {Brand19} brand - Brand information
 * @property {Company22} company - Company information
 * @property {Context24} context - Context
 * @property {Offer8} offer - Offer information
 * @property {Submitter1} submitter - Submitter information
 */
/**
 * @typedef Address45
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand20
 * @property {Address45} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address46
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company23
 * @property {Address46} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context25
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Offer9
 * @property {string} [customUrlPath] - custom path entered
 * @property {Record<string, any>} [destinationUrl] - destinatlon url generated
 * @property {string} [heroSubtitle] - hero subtitle entered
 * @property {string} [heroTitle] - hero title entered
 * @property {string} id - Offer ID
 * @property {string} [merchantCategoryCode] - mcc entered
 * @property {any[]} [militaryAudienceSegment] - active duty, former servicemembers, military families
 * @property {string} [offerName] - name of offer entered
 * @property {string} [offerStatus] -  'published', 'draft', etc
 * @property {string} [offerSubtitle] - subtitle of offer entered
 * @property {string} [offerValue] - premium or basic
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - whether or not offer is published
 * @property {string} [publishedDate] - date published
 * @property {any[]} [redemptionChannels] - array of redemption channels listed
 */
/**
 * @typedef OfferUnpublished
 * @property {Brand20} brand - Brand information
 * @property {Company23} company - Company information
 * @property {Context25} context - Context
 * @property {Offer9} offer - Offer information
 */
/**
 * @typedef Address47
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand21
 * @property {Address47} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address48
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company24
 * @property {Address48} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context26
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient
 * @property {string} [title] - Recipient facing program title text
 * @property {string} [type] - Type of recipient
 */
/**
 * @typedef Program
 * @property {number} [addonTotal] - Subtotal for addons selected
 * @property {number} codesCount - Bulk order quantity, number of codes
 * @property {number} [codesCountInitial] - Number of initial coupon codes entered
 * @property {string} [deliveryMethod] - Program delivery method
 * @property {string} id - Program ID
 * @property {string} [internalTitle] - Internal facing program title text entered
 * @property {string} [pricingRateId] - Pricing rate id from Stripe prices API
 * @property {number} [processingFee] - Subtotal for processing fee
 * @property {Recipient} [recipient] - Recipient information
 * @property {number} [total] - Subtotal for program selected
 * @property {boolean} [travelProtectionAddon] - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramCodesDownloaded
 * @property {Brand21} brand - Brand information
 * @property {Company24} company - Company information
 * @property {Context26} context - Context
 * @property {Program} program - Program information
 */
/**
 * @typedef Address49
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand22
 * @property {Address49} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address50
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company25
 * @property {Address50} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context27
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient1
 * @property {string} [title] - Recipient facing program title text
 * @property {string} [type] - Type of recipient
 */
/**
 * @typedef Program1
 * @property {number} [addonTotal] - Subtotal for addons selected
 * @property {number} [codesCount] - Bulk order quantity, number of codes
 * @property {number} [codesCountInitial] - Number of initial coupon codes entered
 * @property {string} [deliveryMethod] - Program delivery method
 * @property {string} id - Program ID
 * @property {string} [internalTitle] - Internal facing program title text entered
 * @property {string} [pricingRateId] - Pricing rate id from Stripe prices API
 * @property {number} [processingFee] - Subtotal for processing fee
 * @property {Recipient1} [recipient] - Recipient information
 * @property {number} [total] - Subtotal for program selected
 * @property {boolean} [travelProtectionAddon] - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramCreated
 * @property {Brand22} brand - Brand information
 * @property {Company25} company - Company information
 * @property {Context27} context - Context
 * @property {Program1} program - Program information
 */
/**
 * @typedef Address51
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand23
 * @property {Address51} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address52
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company26
 * @property {Address52} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context28
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient2
 * @property {string} title - Recipient facing program title text
 * @property {string} type - Type of recipient
 */
/**
 * @typedef Program2
 * @property {number} [addonTotal] - Subtotal for addons selected
 * @property {number} codesCount - Bulk order quantity, number of codes
 * @property {number} codesCountInitial - Number of initial coupon codes entered
 * @property {string} deliveryMethod - Program delivery method
 * @property {Record<string, any>} id - Program ID
 * @property {string} internalTitle - Internal facing program title text entered
 * @property {string} pricingRateId - Pricing rate id from Stripe prices API
 * @property {number} processingFee - Subtotal for processing fee
 * @property {Recipient2} recipient - Recipient information
 * @property {number} total - Subtotal for program selected
 * @property {boolean} travelProtectionAddon - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramCreationStepCompleted
 * @property {Brand23} brand - Brand information
 * @property {Company26} company - Company information
 * @property {Context28} context - Context
 * @property {Program2} program - Program information
 * @property {string} stepName - Name of step in form/wizard
 */
/**
 * @typedef Address53
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand24
 * @property {Address53} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address54
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company27
 * @property {Address54} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context29
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient3
 * @property {string} [title] - Recipient facing program title text
 * @property {string} [type] - Type of recipient
 */
/**
 * @typedef Program3
 * @property {number} [addonTotal] - Subtotal for addons selected
 * @property {number} [codesCount] - Bulk order quantity, number of codes
 * @property {number} [codesCountInitial] - Number of initial coupon codes entered
 * @property {string} [deliveryMethod] - Program delivery method
 * @property {string} id - Program ID
 * @property {string} [internalTitle] - Internal facing program title text entered
 * @property {string} [pricingRateId] - Pricing rate id from Stripe prices API
 * @property {number} [processingFee] - Subtotal for processing fee
 * @property {Recipient3} [recipient] - Recipient information
 * @property {number} [total] - Subtotal for program selected
 * @property {boolean} [travelProtectionAddon] - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramCreationStepViewed
 * @property {Brand24} brand - Brand information
 * @property {Company27} company - Company information
 * @property {Context29} context - Context
 * @property {Program3} program - Program information
 * @property {string} [stepName] - Name of step in form/wizard
 */
/**
 * @typedef Address55
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand25
 * @property {Address55} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address56
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company28
 * @property {Address56} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context30
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient4
 * @property {string} title - Recipient facing program title text
 * @property {string} type - Type of recipient
 */
/**
 * @typedef Program4
 * @property {number} addonTotal - Subtotal for addons selected
 * @property {number} codesCount - Bulk order quantity, number of codes
 * @property {number} codesCountInitial - Number of initial coupon codes entered
 * @property {string} deliveryMethod - Program delivery method
 * @property {Record<string, any>} id - Program ID
 * @property {string} internalTitle - Internal facing program title text entered
 * @property {string} pricingRateId - Pricing rate id from Stripe prices API
 * @property {number} processingFee - Subtotal for processing fee
 * @property {Recipient4} recipient - Recipient information
 * @property {number} total - Subtotal for program selected
 * @property {boolean} travelProtectionAddon - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramDeleted
 * @property {Brand25} brand - Brand information
 * @property {Company28} company - Company information
 * @property {Context30} context - Context
 * @property {Program4} program - Program information
 */
/**
 * @typedef Address57
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand26
 * @property {Address57} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address58
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company29
 * @property {Address58} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context31
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient5
 * @property {string} title - Recipient facing program title text
 * @property {string} type - Type of recipient
 */
/**
 * @typedef Program5
 * @property {number} addonTotal - Subtotal for addons selected
 * @property {number} codesCount - Bulk order quantity, number of codes
 * @property {number} codesCountInitial - Number of initial coupon codes entered
 * @property {string} deliveryMethod - Program delivery method
 * @property {string} id - Program ID
 * @property {string} internalTitle - Internal facing program title text entered
 * @property {string} pricingRateId - Pricing rate id from Stripe prices API
 * @property {number} processingFee - Subtotal for processing fee
 * @property {Recipient5} recipient - Recipient information
 * @property {number} total - Subtotal for program selected
 * @property {boolean} travelProtectionAddon - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramPublished
 * @property {Brand26} brand - Brand information
 * @property {Company29} company - Company information
 * @property {Context31} context - Context
 * @property {Program5} program - Program information
 */
/**
 * @typedef Address59
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Brand27
 * @property {Address59} [address] - Brand address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date brand was created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Brand description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count
 * @property {string} [headline] - Headline text
 * @property {boolean} [heroUploaded] - Whether hero was uploaded or not
 * @property {string} id - ID of brand
 * @property {string} [industry] - Industry of brand
 * @property {boolean} [logoUploaded] - Whether logo was uploaded or not
 * @property {string} [merchantCategoryCode] - Brand MCC
 * @property {string} [naicsCode] - Brand NAICS code
 * @property {string} name - Brand name
 * @property {string} [phone] - Brand phone number
 * @property {string} [publicationStatus] - State of publication
 * @property {boolean} [published] - Whether brand is published on site
 * @property {string} [publishedDate] - Date brand is published on site
 * @property {string} [website] - Brand website
 */
/**
 * @typedef Address60
 * @property {string} [city] - City
 * @property {string} [country] - Country code
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef Company30
 * @property {Address60} [address] - Company address
 * @property {string} [avatar] - ???
 * @property {string} [createdAt] - Date company was entered and created on site
 * @property {string} [createdBy] - Created by - internal vs external
 * @property {string} [description] - Company description
 * @property {string} [email] - Email domain of company (e.g. @wesalute.com)
 * @property {number} [employees] - Employee count of the company
 * @property {string} id - WeSalute generated ID for company
 * @property {string} [industry] - Company industry
 * @property {string} name - Company name
 * @property {string} [phone] - Company phone number
 * @property {string} [website] - Company website URL
 */
/**
 * @typedef Context32
 * @property {string} groupId - Company ID
 */
/**
 * @typedef Recipient6
 * @property {string} title - Recipient facing program title text
 * @property {string} type - Type of recipient
 */
/**
 * @typedef Program6
 * @property {number} addonTotal - Subtotal for addons selected
 * @property {number} codesCount - Bulk order quantity, number of codes
 * @property {number} codesCountInitial - Number of initial coupon codes entered
 * @property {string} deliveryMethod - Program delivery method
 * @property {string} id - Program ID
 * @property {string} internalTitle - Internal facing program title text entered
 * @property {string} pricingRateId - Pricing rate id from Stripe prices API
 * @property {number} processingFee - Subtotal for processing fee
 * @property {Recipient6} recipient - Recipient information
 * @property {number} total - Subtotal for program selected
 * @property {boolean} travelProtectionAddon - Whether the addon was selected or not
 * @property {string} type - Type of program
 */
/**
 * @typedef ProgramUnpublished
 * @property {Brand27} brand - Brand information
 * @property {Company30} company - Company information
 * @property {Context32} context - Context
 * @property {Program6} program - Program information
 */
/**
 * @typedef Context33
 * @property {string} groupId -
 */
/**
 * @typedef Address61
 * @property {string} [city] - City
 * @property {string} [country] - Country
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef User2
 * @property {boolean} [2fa] - Whether the user has two factor auth enabled or not
 * @property {Address61} [address] - User address
 * @property {string} [birthday] - User birthday
 * @property {string} [createdAt] - Wate user was created
 * @property {string} email - Email of user
 * @property {string} [firstName] - First name of user
 * @property {string} [id] - User ID
 * @property {string} [lastLogin] - Date of last login
 * @property {string} [lastName] - Last name of user
 * @property {number} [loginCount] - Number of times the user has logged in
 * @property {string} [phone] - User phone number
 * @property {string} [role] - Permissions of user within account
 * @property {string} [title] - Title of the user of their job function
 * @property {string} [type] - Type of signup, e.g. 'invited', 'organic'
 * @property {string} [username] - Username
 * @property {string} [website] - User website URL
 */
/**
 * @typedef SignedIn
 * @property {Context33} context -
 * @property {User2} user - User information
 * @property {string} username - The username of the user logging in
 */
/**
 * @typedef Context34
 * @property {string} groupId -
 */
/**
 * @typedef Address62
 * @property {string} [city] - City
 * @property {string} [country] - Country
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef User3
 * @property {boolean} [2fa] - Whether the user has two factor auth enabled or not
 * @property {Address62} [address] - User address
 * @property {string} [birthday] - User birthday
 * @property {string} [createdAt] - Wate user was created
 * @property {string} email - Email of user
 * @property {string} [firstName] - First name of user
 * @property {string} id - User ID
 * @property {string} [lastLogin] - Date of last login
 * @property {string} [lastName] - Last name of user
 * @property {number} [loginCount] - Number of times the user has logged in
 * @property {string} [phone] - User phone number
 * @property {string} [role] - Permissions of user within account
 * @property {string} [title] - Title of the user of their job function
 * @property {string} [type] - Type of signup, e.g. 'invited', 'organic'
 * @property {string} [username] - Username
 * @property {string} [website] - User website URL
 */
/**
 * @typedef SignedOut
 * @property {Context34} context -
 * @property {User3} user - User information
 * @property {string} username - The username of the user logging in
 */
/**
 * @typedef Context35
 * @property {string} groupId -
 */
/**
 * @typedef Address63
 * @property {string} [city] - City
 * @property {string} [country] - Country
 * @property {string} [line1] - Address line 1
 * @property {string} [line2] - Address line 2
 * @property {string} [postalCode] - ZIP code
 * @property {string} [state] - State
 */
/**
 * @typedef User4
 * @property {boolean} [2fa] - Whether the user has two factor auth enabled or not
 * @property {Address63} [address] - User address
 * @property {string} [birthday] - User birthday
 * @property {string} [createdAt] - Wate user was created
 * @property {string} [email] - Email of user
 * @property {string} [firstName] - First name of user
 * @property {string} id - User ID
 * @property {string} [lastLogin] - Date of last login
 * @property {string} [lastName] - Last name of user
 * @property {number} [loginCount] - Number of times the user has logged in
 * @property {string} [phone] - User phone number
 * @property {string} [role] - Permissions of user within account
 * @property {string} [title] - Title of the user of their job function
 * @property {string} [type] - Type of signup, e.g. 'invited', 'organic'
 * @property {string} [username] - Username
 * @property {string} [website] - User website URL
 */
/**
 * @typedef SignedUp
 * @property {Context35} context -
 * @property {string} email - The email of the user
 * @property {string} firstName - The first name of the user
 * @property {string} lastName - The last name of the user
 * @property {string} phone - The phone number of the user
 * @property {string} title - The title of the user
 * @property {string} type - The type of signup, e.g. invited, organic.
 * @property {User4} [user] - User information
 * @property {string} username - The username of the user
 */
/**
 * @typedef Context36
 * @property {string} groupId - The id of the account being created
 */
/**
 * @typedef TrialEnded
 * @property {Context36} context -
 * @property {string} trialEndDate - The date when the trial ends. It is an ISO-8601 date string
 * @property {string} trialPlanName - The name of the plan being trialed
 * @property {string} trialStartDate - The date when the trial starts. It is an ISO-8601 date string
 */
/**
 * @typedef Context37
 * @property {Record<string, any>} groupId - The id of the account being created
 */
/**
 * @typedef TrialStarted
 * @property {Context37} context -
 * @property {string} trialEndDate - The date when the trial ends. It is an ISO-8601 date string
 * @property {string} trialPlanName - The name of the plan being trialed
 * @property {string} trialStartDate - The date when the trial starts. It is an ISO-8601 date string
 */

/**
 * [Segment B2B Spec] Triggered when a user is added to an account
 *
 * @param {AccountAddedUser} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountAddedUser(
	props: AccountAddedUser,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] Triggered when a user is added to an account',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					role: {
						description: 'The permission group for this user in this account',
						type: 'string',
					},
					user: {
						description: 'User information',
						properties: {
							'2fa': {
								description:
									'Whether the user has two factor auth enabled or not',
								type: 'boolean',
							},
							address: {
								description: 'User address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							birthday: {
								description: 'User birthday',
								format: 'date-time',
								type: 'string',
							},
							createdAt: {
								description: 'Wate user was created',
								format: 'date-time',
								type: 'string',
							},
							email: {
								description: 'Email of user',
								type: 'string',
							},
							firstName: {
								description: 'First name of user',
								type: 'string',
							},
							id: {
								description: 'User ID',
								type: 'string',
							},
							lastLogin: {
								description: 'Date of last login',
								format: 'date-time',
								type: 'string',
							},
							lastName: {
								description: 'Last name of user',
								type: 'string',
							},
							loginCount: {
								description: 'Number of times the user has logged in',
								type: 'integer',
							},
							phone: {
								description: 'User phone number',
								type: 'string',
							},
							role: {
								description: 'Permissions of user within account',
								pattern: '',
								type: 'string',
							},
							title: {
								description: 'Title of the user of their job function',
								type: 'string',
							},
							type: {
								description: "Type of signup, e.g. 'invited', 'organic'",
								type: 'string',
							},
							username: {
								description: 'Username',
								type: 'string',
							},
							website: {
								description: 'User website URL',
								type: 'string',
							},
						},
						required: ['id', 'email'],
						type: 'object',
					},
				},
				required: ['context', 'user', 'role'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Account Added User',
		type: 'object',
	}
	const message = {
		event: 'Account Added User',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Added User',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] Triggered when a company is created
 *
 * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountCreated(
	props: AccountCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '[Segment B2B Spec] Triggered when a company is created',
		labels: {
			spec: 'b2b-saas',
			topic: 'marketing',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					accountName: {
						description: '',
						type: 'string',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['name', 'address', 'email', 'id', 'phone', 'createdBy'],
						type: 'object',
					},
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['context', 'company', 'accountName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Account Created',
		type: 'object',
	}
	const message = {
		event: 'Account Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] Triggered when user deletes their company
 *
 * @param {AccountDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountDeleted(
	props: AccountDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '[Segment B2B Spec] Triggered when user deletes their company',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					accountName: {
						description: '',
						type: 'string',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['name', 'address', 'email', 'id', 'phone', 'createdBy'],
						type: 'object',
					},
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['context', 'company', 'accountName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Account Deleted',
		type: 'object',
	}
	const message = {
		event: 'Account Deleted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] Triggered when a user is removed from an account
 *
 * @param {AccountRemovedUser} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountRemovedUser(
	props: AccountRemovedUser,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] Triggered when a user is removed from an account',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					role: {
						description: 'Permissions of user being deleted within account',
						type: 'string',
					},
					user: {
						description: 'User information',
						properties: {
							'2fa': {
								description:
									'Whether the user has two factor auth enabled or not',
								type: 'boolean',
							},
							address: {
								description: 'User address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							birthday: {
								description: 'User birthday',
								format: 'date-time',
								type: 'string',
							},
							createdAt: {
								description: 'Wate user was created',
								format: 'date-time',
								type: 'string',
							},
							email: {
								description: 'Email of user',
								type: 'string',
							},
							firstName: {
								description: 'First name of user',
								type: 'string',
							},
							id: {
								description: 'User ID',
								type: 'string',
							},
							lastLogin: {
								description: 'Date of last login',
								format: 'date-time',
								type: 'string',
							},
							lastName: {
								description: 'Last name of user',
								type: 'string',
							},
							loginCount: {
								description: 'Number of times the user has logged in',
								type: 'integer',
							},
							phone: {
								description: 'User phone number',
								type: 'string',
							},
							role: {
								description: 'Permissions of user within account',
								type: 'string',
							},
							title: {
								description: 'Title of the user of their job function',
								type: 'string',
							},
							type: {
								description: "Type of signup, e.g. 'invited', 'organic'",
								type: 'string',
							},
							username: {
								description: 'Username',
								type: 'string',
							},
							website: {
								description: 'User website URL',
								type: 'string',
							},
						},
						required: ['email', 'role', 'id'],
						type: 'object',
					},
				},
				required: ['context', 'user', 'role'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Account Removed User',
		type: 'object',
	}
	const message = {
		event: 'Account Removed User',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Removed User',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user updates their company details
 *
 * @param {AccountUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function accountUpdated(
	props: AccountUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when user updates their company details',
		labels: {
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['address', 'id', 'name', 'phone', 'createdBy'],
						type: 'object',
					},
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Account Updated',
		type: 'object',
	}
	const message = {
		event: 'Account Updated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Account Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is approvedd
 *
 * @param {BrandApproved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandApproved(
	props: BrandApproved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a brand is approvedd',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					approver: {
						description: 'Approver information',
						properties: {
							email: {
								description: 'Email of approver',
								type: 'string',
							},
							id: {
								description: 'ID of approver',
								type: 'string',
							},
						},
						required: ['email', 'id'],
						type: 'object',
					},
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['approver', 'brand', 'context', 'company'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Approved',
		type: 'object',
	}
	const message = {
		event: 'Brand Approved',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Approved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a BRAND user is added to the database (when brand draft is started)
 *
 * @param {BrandCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandCreated(
	props: BrandCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when a BRAND user is added to the database (when brand draft is started)',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [
							'address',
							'email',
							'id',
							'name',
							'phone',
							'createdBy',
							'merchantCategoryCode',
							'naicsCode',
						],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Created',
		type: 'object',
	}
	const message = {
		event: 'Brand Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a page in the Brand creation flow is completed
 *
 * @param {BrandCreationStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandCreationStepCompleted(
	props: BrandCreationStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when a page in the Brand creation flow is completed',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [
							'address',
							'email',
							'id',
							'name',
							'phone',
							'headline',
							'createdBy',
							'heroUploaded',
							'logoUploaded',
							'merchantCategoryCode',
							'naicsCode',
						],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					stepName: {
						description: 'Name of step in form/wizard',
						type: 'string',
					},
				},
				required: ['brand', 'company', 'stepName', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Creation Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Brand Creation Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Creation Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user views a brand creation step
 *
 * @param {BrandCreationStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandCreationStepViewed(
	props: BrandCreationStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user views a brand creation step',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: ['string', 'null'],
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: ['string', 'null'],
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: ['string', 'null'],
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: ['string', 'null'],
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					stepName: {
						description: 'Name of step in form/wizard',
						type: 'string',
					},
				},
				required: ['brand', 'company', 'stepName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Creation Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Brand Creation Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Creation Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is deleted
 *
 * @param {BrandDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandDeleted(
	props: BrandDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a brand is deleted',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Deleted',
		type: 'object',
	}
	const message = {
		event: 'Brand Deleted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand pushed to live by creator/admin
 *
 * @param {BrandPublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandPublished(
	props: BrandPublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a brand pushed to live by creator/admin',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [
							'address',
							'email',
							'id',
							'headline',
							'name',
							'phone',
							'merchantCategoryCode',
							'heroUploaded',
							'createdBy',
							'logoUploaded',
							'naicsCode',
						],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Published',
		type: 'object',
	}
	const message = {
		event: 'Brand Published',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Published',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is rejected
 *
 * @param {BrandRejected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandRejected(
	props: BrandRejected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a brand is rejected',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					rejection: {
						description: 'Rejection information',
						properties: {
							reason: {
								description: 'Rejection reason',
								type: 'string',
							},
							rejector: {
								description: 'Rejector information',
								properties: {
									email: {
										description: 'Email of rejector',
										type: 'string',
									},
									id: {
										description: 'ID of rejector',
										type: 'string',
									},
								},
								required: ['email', 'id'],
								type: 'object',
							},
						},
						required: ['reason', 'rejector'],
						type: 'object',
					},
				},
				required: ['brand', 'context', 'company', 'rejection'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Rejected',
		type: 'object',
	}
	const message = {
		event: 'Brand Rejected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Rejected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggrred when a brand being created is submitted for review
 *
 * @param {BrandSubmittedForReview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandSubmittedForReview(
	props: BrandSubmittedForReview,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggrred when a brand being created is submitted for review',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					submitter: {
						description: 'Submitter information',
						properties: {
							email: {
								description: 'Submitter email',
								type: 'string',
							},
							id: {
								description: 'Submitter ID',
								type: 'string',
							},
						},
						required: ['email', 'id'],
						type: 'object',
					},
				},
				required: ['submitter', 'context', 'brand', 'company'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Submitted For Review',
		type: 'object',
	}
	const message = {
		event: 'Brand Submitted For Review',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Submitted For Review',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is unpublished by uesr - moved back to draft
 *
 * @param {BrandUnpublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandUnpublished(
	props: BrandUnpublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when a brand is unpublished by uesr - moved back to draft',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Unpublished',
		type: 'object',
	}
	const message = {
		event: 'Brand Unpublished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Unpublished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is unpublished by uesr - moved back to draft
 *
 * @param {BrandUnpulished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandUnpulished(
	props: BrandUnpulished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered when a brand is unpublished by uesr - moved back to draft',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Unpulished',
		type: 'object',
	}
	const message = {
		event: 'Brand Unpulished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Unpulished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a brand is updated
 *
 * @param {BrandUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function brandUpdated(
	props: BrandUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a brand is updated',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'pubished',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [
							'address',
							'email',
							'id',
							'name',
							'phone',
							'headline',
							'merchantCategoryCode',
							'createdBy',
							'naicsCode',
						],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Brand Updated',
		type: 'object',
	}
	const message = {
		event: 'Brand Updated',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Brand Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] This event should be sent when a user invites another user
 *
 * @param {InviteSent} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function inviteSent(
	props: InviteSent,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] This event should be sent when a user invites another user',
		labels: {
			spec: 'b2b-saas',
			topic: 'marketing',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					inviteeEmail: {
						description: 'The email address of the person receiving the invite',
						type: 'string',
					},
					inviteeFirstName: {
						description: 'The first name of the person receiving the invite',
						type: 'string',
					},
					inviteeLastName: {
						description: 'The last name of the person receiving the invite',
						type: 'string',
					},
					inviteeRole: {
						description:
							'The permission group for the person receiving the invite',
						type: 'string',
					},
				},
				required: [
					'inviteeEmail',
					'inviteeRole',
					'inviteeFirstName',
					'inviteeLastName',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Invite Sent',
		type: 'object',
	}
	const message = {
		event: 'Invite Sent',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Invite Sent',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
 *
 * @param {OfferApproved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerApproved(
	props: OfferApproved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user unpublished an offer - puts it back in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					approver: {
						description: 'Approver information',
						properties: {
							email: {
								description: 'email of approver',
								type: 'string',
							},
							id: {
								description: 'id of approver',
								type: 'string',
							},
						},
						required: ['id', 'email'],
						type: 'object',
					},
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'published date',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer', 'approver'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Approved',
		type: 'object',
	}
	const message = {
		event: 'Offer Approved',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Approved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user first creates the Offer and it's in 'draft' mode
 *
 * @param {OfferCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCreated(
	props: OfferCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user first creates the Offer and it's in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'boolean whether offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Created',
		type: 'object',
	}
	const message = {
		event: 'Offer Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user completes a step in Offer creation
 *
 * @param {OfferCreationStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCreationStepCompleted(
	props: OfferCreationStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user completes a step in Offer creation',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: [],
						type: 'object',
					},
					stepName: {
						description: 'Name of step completed',
						type: 'string',
					},
				},
				required: ['brand', 'company', 'context', 'offer', 'stepName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Creation Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Offer Creation Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Creation Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user views a step in Offer creation
 *
 * @param {OfferCreationStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerCreationStepViewed(
	props: OfferCreationStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user views a step in Offer creation',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: [],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'pubished',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: [],
						type: 'object',
					},
					stepName: {
						description: 'Name of step viewed',
						type: 'string',
					},
				},
				required: ['brand', 'company', 'context', 'offer', 'stepName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Creation Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Offer Creation Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Creation Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user deletes an offer
 *
 * @param {OfferDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerDeleted(
	props: OfferDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user deletes an offer',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'offer published date',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Deleted',
		type: 'object',
	}
	const message = {
		event: 'Offer Deleted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
 *
 * @param {OfferPublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerPublished(
	props: OfferPublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user unpublished an offer - puts it back in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Published',
		type: 'object',
	}
	const message = {
		event: 'Offer Published',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Published',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when an offer is approve for publication
 *
 * @param {OfferRejected} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerRejected(
	props: OfferRejected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when an offer is approve for publication',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
					rejection: {
						description: 'Rejection information',
						properties: {
							reason: {
								description: 'Rejection reason',
								type: 'string',
							},
							rejector: {
								description: 'Rejector information',
								properties: {
									email: {
										description: 'ID of rejector',
										type: 'string',
									},
									id: {
										description: 'email of rejector',
										type: 'string',
									},
								},
								required: ['id', 'email'],
								type: 'object',
							},
						},
						required: ['reason', 'rejector'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer', 'rejection'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Rejected',
		type: 'object',
	}
	const message = {
		event: 'Offer Rejected',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Rejected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when an update submission is canceled
 *
 * @param {OfferSubmissionCanceled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSubmissionCanceled(
	props: OfferSubmissionCanceled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when an update submission is canceled',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Submission Canceled',
		type: 'object',
	}
	const message = {
		event: 'Offer Submission Canceled',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Submission Canceled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when edits on an offer are submitted for review
 *
 * @param {OfferSubmittedForReview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerSubmittedForReview(
	props: OfferSubmittedForReview,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when edits on an offer are submitted for review',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpubished',
									'update requested',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date offer published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
					submitter: {
						description: 'Submitter information',
						properties: {
							email: {
								description: 'email of submitter',
								type: 'string',
							},
							id: {
								description: 'id of submitter',
								type: 'string',
							},
						},
						required: ['email', 'id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer', 'submitter'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Submitted For Review',
		type: 'object',
	}
	const message = {
		event: 'Offer Submitted For Review',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Submitted For Review',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
 *
 * @param {OfferUnpublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function offerUnpublished(
	props: OfferUnpublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user unpublished an offer - puts it back in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					offer: {
						description: 'Offer information',
						properties: {
							customUrlPath: {
								description: 'custom path entered',
								type: 'string',
							},
							destinationUrl: {
								description: 'destinatlon url generated',
								type: 'object',
							},
							heroSubtitle: {
								description: 'hero subtitle entered',
								type: 'string',
							},
							heroTitle: {
								description: 'hero title entered',
								type: 'string',
							},
							id: {
								description: 'Offer ID',
								type: 'string',
							},
							merchantCategoryCode: {
								description: 'mcc entered',
								type: 'string',
							},
							militaryAudienceSegment: {
								description:
									'active duty, former servicemembers, military families',
								type: 'array',
							},
							offerName: {
								description: 'name of offer entered ',
								type: 'string',
							},
							offerStatus: {
								description: " 'published', 'draft', etc",
								type: 'string',
							},
							offerSubtitle: {
								description: 'subtitle of offer entered',
								type: 'string',
							},
							offerValue: {
								description: 'premium or basic',
								enum: ['basic', 'premium'],
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'update required',
								],
								type: 'string',
							},
							published: {
								description: 'whether or not offer is published',
								type: 'boolean',
							},
							publishedDate: {
								description: 'date published',
								format: 'date-time',
								type: 'string',
							},
							redemptionChannels: {
								description: 'array of redemption channels listed',
								type: 'array',
							},
						},
						required: ['id'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'offer'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Offer Unpublished',
		type: 'object',
	}
	const message = {
		event: 'Offer Unpublished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Offer Unpublished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when user clicks on 'Download .csv file' on 'Get Coupon Codes' page of Program creation flow
 *
 * @param {ProgramCodesDownloaded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programCodesDownloaded(
	props: ProgramCodesDownloaded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when user clicks on 'Download .csv file' on 'Get Coupon Codes' page of Program creation flow",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'string',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: ['id', 'type', 'codesCount'],
						type: 'object',
					},
				},
				required: ['company', 'context', 'program', 'brand'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Codes Downloaded',
		type: 'object',
	}
	const message = {
		event: 'Program Codes Downloaded',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Codes Downloaded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user first creates the Program and it's in 'draft' mode
 *
 * @param {ProgramCreated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programCreated(
	props: ProgramCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user first creates the Program and it's in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'string',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: ['id', 'type'],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'program', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Created',
		type: 'object',
	}
	const message = {
		event: 'Program Created',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user completes a step in Program creation
 *
 * @param {ProgramCreationStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programCreationStepCompleted(
	props: ProgramCreationStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user completes a step in Program creation',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'object',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: ['type', 'title'],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: [
							'id',
							'type',
							'recipient',
							'total',
							'codesCount',
							'codesCountInitial',
							'deliveryMethod',
							'internalTitle',
							'pricingRateId',
							'processingFee',
							'travelProtectionAddon',
						],
						type: 'object',
					},
					stepName: {
						description: 'Name of step in form/wizard',
						type: 'string',
					},
				},
				required: ['context', 'program', 'brand', 'company', 'stepName'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Creation Step Completed',
		type: 'object',
	}
	const message = {
		event: 'Program Creation Step Completed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Creation Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user views a step in Program creation
 *
 * @param {ProgramCreationStepViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programCreationStepViewed(
	props: ProgramCreationStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user views a step in Program creation',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'string',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: ['id', 'type'],
						type: 'object',
					},
					stepName: {
						description: 'Name of step in form/wizard',
						type: 'string',
					},
				},
				required: ['brand', 'company', 'program', 'context'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Creation Step Viewed',
		type: 'object',
	}
	const message = {
		event: 'Program Creation Step Viewed',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Creation Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user deletes a program
 *
 * @param {ProgramDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programDeleted(
	props: ProgramDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: 'Triggered when a user deletes a program',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'object',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: ['title', 'type'],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: [
							'type',
							'id',
							'recipient',
							'total',
							'travelProtectionAddon',
							'addonTotal',
							'codesCount',
							'codesCountInitial',
							'deliveryMethod',
							'internalTitle',
							'pricingRateId',
							'processingFee',
						],
						type: 'object',
					},
				},
				required: ['context', 'program', 'brand', 'company'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Deleted',
		type: 'object',
	}
	const message = {
		event: 'Program Deleted',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered after the program workflow is approved. Entity goes live
 *
 * @param {ProgramPublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programPublished(
	props: ProgramPublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'Triggered after the program workflow is approved. Entity goes live',
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'string',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: ['type', 'title'],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: [
							'id',
							'type',
							'recipient',
							'total',
							'addonTotal',
							'codesCount',
							'codesCountInitial',
							'deliveryMethod',
							'internalTitle',
							'pricingRateId',
							'processingFee',
							'travelProtectionAddon',
						],
						type: 'object',
					},
				},
				required: ['brand', 'company', 'context', 'program'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Published',
		type: 'object',
	}
	const message = {
		event: 'Program Published',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Published',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when a user unpublished a program - puts it back in 'draft' mode
 *
 * @param {ProgramUnpublished} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function programUnpublished(
	props: ProgramUnpublished,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			"Triggered when a user unpublished a program - puts it back in 'draft' mode",
		labels: {
			topic: 'product',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					brand: {
						description: 'Brand information',
						properties: {
							address: {
								description: 'Brand address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								pattern: '',
								type: 'string',
							},
							createdAt: {
								description: 'Date brand was created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Brand description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count',
								type: 'integer',
							},
							headline: {
								description: 'Headline text',
								type: 'string',
							},
							heroUploaded: {
								description: 'Whether hero was uploaded or not',
								type: 'boolean',
							},
							id: {
								description: 'ID of brand',
								type: 'string',
							},
							industry: {
								description: 'Industry of brand',
								type: 'string',
							},
							logoUploaded: {
								description: 'Whether logo was uploaded or not',
								type: 'boolean',
							},
							merchantCategoryCode: {
								description: 'Brand MCC',
								type: 'string',
							},
							naicsCode: {
								description: 'Brand NAICS code',
								type: 'string',
							},
							name: {
								description: 'Brand name',
								type: 'string',
							},
							phone: {
								description: 'Brand phone number',
								type: 'string',
							},
							publicationStatus: {
								description: 'State of publication',
								enum: [
									'approved',
									'archived',
									'draft',
									'in review',
									'published',
									'ready for review',
									'rejected',
									'unpublished',
									'updates required',
								],
								type: 'string',
							},
							published: {
								description: 'Whether brand is published on site',
								type: 'boolean',
							},
							publishedDate: {
								description: 'Date brand is published on site',
								format: 'date-time',
								type: 'string',
							},
							website: {
								description: 'Brand website',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					company: {
						description: 'Company information',
						properties: {
							address: {
								description: 'Company address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country code',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							avatar: {
								description: '???',
								type: 'string',
							},
							createdAt: {
								description: 'Date company was entered and created on site',
								format: 'date-time',
								type: 'string',
							},
							createdBy: {
								description: 'Created by - internal vs external',
								type: 'string',
							},
							description: {
								description: 'Company description',
								type: 'string',
							},
							email: {
								description: 'Email domain of company (e.g. @wesalute.com)',
								type: 'string',
							},
							employees: {
								description: 'Employee count of the company',
								type: 'integer',
							},
							id: {
								description: 'WeSalute generated ID for company',
								type: 'string',
							},
							industry: {
								description: 'Company industry',
								type: 'string',
							},
							name: {
								description: 'Company name',
								type: 'string',
							},
							phone: {
								description: 'Company phone number',
								type: 'string',
							},
							website: {
								description: 'Company website URL',
								type: 'string',
							},
						},
						required: ['id', 'name'],
						type: 'object',
					},
					context: {
						description: 'Context',
						properties: {
							groupId: {
								description: 'Company ID',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					program: {
						description: 'Program information',
						properties: {
							addonTotal: {
								description: 'Subtotal for addons selected',
								type: 'number',
							},
							codesCount: {
								description: 'Bulk order quantity, number of codes',
								type: 'integer',
							},
							codesCountInitial: {
								description: 'Number of initial coupon codes entered',
								type: 'integer',
							},
							deliveryMethod: {
								description: 'Program delivery method',
								type: 'string',
							},
							id: {
								description: 'Program ID',
								type: 'string',
							},
							internalTitle: {
								description: 'Internal facing program title text entered',
								type: 'string',
							},
							pricingRateId: {
								description: 'Pricing rate id from Stripe prices API',
								type: 'string',
							},
							processingFee: {
								description: 'Subtotal for processing fee',
								type: 'number',
							},
							recipient: {
								description: 'Recipient information',
								properties: {
									title: {
										description: 'Recipient facing program title text',
										type: 'string',
									},
									type: {
										description: 'Type of recipient',
										type: 'string',
									},
								},
								required: ['title', 'type'],
								type: 'object',
							},
							total: {
								description: 'Subtotal for program selected',
								type: 'number',
							},
							travelProtectionAddon: {
								description: 'Whether the addon was selected or not',
								type: 'boolean',
							},
							type: {
								description: 'Type of program',
								type: 'string',
							},
						},
						required: [
							'id',
							'type',
							'recipient',
							'total',
							'addonTotal',
							'codesCount',
							'codesCountInitial',
							'deliveryMethod',
							'internalTitle',
							'pricingRateId',
							'processingFee',
							'travelProtectionAddon',
						],
						type: 'object',
					},
				},
				required: ['context', 'program', 'brand', 'company'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Program Unpublished',
		type: 'object',
	}
	const message = {
		event: 'Program Unpublished',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Program Unpublished',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] Triggered when a user logs into their account
 *
 * @param {SignedIn} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedIn(
	props: SignedIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] Triggered when a user logs into their account',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					user: {
						description: 'User information',
						properties: {
							'2fa': {
								description:
									'Whether the user has two factor auth enabled or not',
								type: 'boolean',
							},
							address: {
								description: 'User address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							birthday: {
								description: 'User birthday',
								format: 'date-time',
								type: 'string',
							},
							createdAt: {
								description: 'Wate user was created',
								format: 'date-time',
								type: 'string',
							},
							email: {
								description: 'Email of user',
								type: 'string',
							},
							firstName: {
								description: 'First name of user',
								type: 'string',
							},
							id: {
								description: 'User ID',
								type: 'string',
							},
							lastLogin: {
								description: 'Date of last login',
								format: 'date-time',
								type: 'string',
							},
							lastName: {
								description: 'Last name of user',
								type: 'string',
							},
							loginCount: {
								description: 'Number of times the user has logged in',
								type: 'integer',
							},
							phone: {
								description: 'User phone number',
								type: 'string',
							},
							role: {
								description: 'Permissions of user within account',
								type: 'string',
							},
							title: {
								description: 'Title of the user of their job function',
								type: 'string',
							},
							type: {
								description: "Type of signup, e.g. 'invited', 'organic'",
								type: 'string',
							},
							username: {
								description: 'Username',
								type: 'string',
							},
							website: {
								description: 'User website URL',
								type: 'string',
							},
						},
						required: ['email'],
						type: 'object',
					},
					username: {
						description: 'The username of the user logging in',
						type: 'string',
					},
				},
				required: ['context', 'username', 'user'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Signed In',
		type: 'object',
	}
	const message = {
		event: 'Signed In',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * [Segment B2B Spec] Triggered when a user logs out of their account
 *
 * @param {SignedOut} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedOut(
	props: SignedOut,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] Triggered when a user logs out of their account',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					user: {
						description: 'User information',
						properties: {
							'2fa': {
								description:
									'Whether the user has two factor auth enabled or not',
								type: 'boolean',
							},
							address: {
								description: 'User address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							birthday: {
								description: 'User birthday',
								format: 'date-time',
								type: 'string',
							},
							createdAt: {
								description: 'Wate user was created',
								format: 'date-time',
								type: 'string',
							},
							email: {
								description: 'Email of user',
								type: 'string',
							},
							firstName: {
								description: 'First name of user',
								type: 'string',
							},
							id: {
								description: 'User ID',
								type: 'string',
							},
							lastLogin: {
								description: 'Date of last login',
								format: 'date-time',
								type: 'string',
							},
							lastName: {
								description: 'Last name of user',
								type: 'string',
							},
							loginCount: {
								description: 'Number of times the user has logged in',
								type: 'integer',
							},
							phone: {
								description: 'User phone number',
								type: 'string',
							},
							role: {
								description: 'Permissions of user within account',
								type: 'string',
							},
							title: {
								description: 'Title of the user of their job function',
								type: 'string',
							},
							type: {
								description: "Type of signup, e.g. 'invited', 'organic'",
								type: 'string',
							},
							username: {
								description: 'Username',
								type: 'string',
							},
							website: {
								description: 'User website URL',
								type: 'string',
							},
						},
						required: ['id', 'email'],
						type: 'object',
					},
					username: {
						description: 'The username of the user logging in',
						type: 'string',
					},
				},
				required: ['username', 'context', 'user'],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Signed Out',
		type: 'object',
	}
	const message = {
		event: 'Signed Out',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed Out', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * [Segment B2B Spec] Triggred when a user profile is created
 *
 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function signedUp(
	props: SignedUp,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: '[Segment B2B Spec] Triggred when a user profile is created',
		labels: {
			spec: 'b2b-saas',
			topic: 'account',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: '',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					email: {
						description: 'The email of the user',
						type: 'string',
					},
					firstName: {
						description: 'The first name of the user',
						type: 'string',
					},
					lastName: {
						description: 'The last name of the user',
						type: 'string',
					},
					phone: {
						description: 'The phone number of the user',
						type: 'string',
					},
					title: {
						description: 'The title of the user',
						type: 'string',
					},
					type: {
						description: 'The type of signup, e.g. invited, organic.',
						type: 'string',
					},
					user: {
						description: 'User information',
						properties: {
							'2fa': {
								description:
									'Whether the user has two factor auth enabled or not',
								type: 'boolean',
							},
							address: {
								description: 'User address',
								properties: {
									city: {
										description: 'City',
										type: 'string',
									},
									country: {
										description: 'Country',
										type: 'string',
									},
									line1: {
										description: 'Address line 1',
										type: 'string',
									},
									line2: {
										description: 'Address line 2',
										type: 'string',
									},
									postalCode: {
										description: 'ZIP code',
										type: 'string',
									},
									state: {
										description: 'State',
										type: 'string',
									},
								},
								required: [],
								type: 'object',
							},
							birthday: {
								description: 'User birthday',
								format: 'date-time',
								type: 'string',
							},
							createdAt: {
								description: 'Wate user was created',
								format: 'date-time',
								type: 'string',
							},
							email: {
								description: 'Email of user',
								type: 'string',
							},
							firstName: {
								description: 'First name of user',
								type: 'string',
							},
							id: {
								description: 'User ID',
								type: 'string',
							},
							lastLogin: {
								description: 'Date of last login',
								format: 'date-time',
								type: 'string',
							},
							lastName: {
								description: 'Last name of user',
								type: 'string',
							},
							loginCount: {
								description: 'Number of times the user has logged in',
								type: 'integer',
							},
							phone: {
								description: 'User phone number',
								type: 'string',
							},
							role: {
								description: 'Permissions of user within account',
								type: 'string',
							},
							title: {
								description: 'Title of the user of their job function',
								type: 'string',
							},
							type: {
								description: "Type of signup, e.g. 'invited', 'organic'",
								type: 'string',
							},
							username: {
								description: 'Username',
								type: 'string',
							},
							website: {
								description: 'User website URL',
								type: 'string',
							},
						},
						required: ['id'],
						type: 'object',
					},
					username: {
						description: 'The username of the user',
						type: 'string',
					},
				},
				required: [
					'lastName',
					'email',
					'type',
					'phone',
					'context',
					'username',
					'title',
					'firstName',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Signed Up',
		type: 'object',
	}
	const message = {
		event: 'Signed Up',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track('Signed Up', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * [Segment B2B Spec] Triggered when a user's trial expires
 *
 * @param {TrialEnded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function trialEnded(
	props: TrialEnded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description: "[Segment B2B Spec] Triggered when a user's trial expires",
		labels: {
			spec: 'b2b-saas',
			topic: 'tbd',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: 'The id of the account being created',
								type: 'string',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					trialEndDate: {
						description:
							'The date when the trial ends. It is an ISO-8601 date string',
						format: 'date-time',
						type: 'string',
					},
					trialPlanName: {
						description: 'The name of the plan being trialed',
						type: 'string',
					},
					trialStartDate: {
						description:
							'The date when the trial starts. It is an ISO-8601 date string',
						format: 'date-time',
						type: 'string',
					},
				},
				required: [
					'context',
					'trialPlanName',
					'trialStartDate',
					'trialEndDate',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Trial Ended',
		type: 'object',
	}
	const message = {
		event: 'Trial Ended',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Trial Ended',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * [Segment B2B Spec] Triggered when a user successfully signs up for a trial
 *
 * @param {TrialStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function trialStarted(
	props: TrialStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const schema = {
		$schema: 'http://json-schema.org/draft-07/schema#',
		description:
			'[Segment B2B Spec] Triggered when a user successfully signs up for a trial',
		labels: {
			spec: 'b2b-saas',
			topic: 'tbd',
		},
		properties: {
			context: {},
			properties: {
				properties: {
					context: {
						description: '',
						properties: {
							groupId: {
								description: 'The id of the account being created',
								type: 'object',
							},
						},
						required: ['groupId'],
						type: 'object',
					},
					trialEndDate: {
						description:
							'The date when the trial ends. It is an ISO-8601 date string',
						format: 'date-time',
						type: 'string',
					},
					trialPlanName: {
						description: 'The name of the plan being trialed',
						type: 'string',
					},
					trialStartDate: {
						description:
							'The date when the trial starts. It is an ISO-8601 date string',
						format: 'date-time',
						type: 'string',
					},
				},
				required: [
					'context',
					'trialStartDate',
					'trialEndDate',
					'trialPlanName',
				],
				type: 'object',
			},
			traits: {
				type: 'object',
			},
		},
		required: ['properties'],
		title: 'Trial Started',
		type: 'object',
	}
	const message = {
		event: 'Trial Started',
		properties: props || {},
		options,
	}
	validateAgainstSchema(message, schema)

	const a = analytics()
	if (a) {
		a.track(
			'Trial Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * [Segment B2B Spec] Triggered when a user is added to an account
	 *
	 * @param {AccountAddedUser} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountAddedUser,
	/**
	 * [Segment B2B Spec] Triggered when a company is created
	 *
	 * @param {AccountCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountCreated,
	/**
	 * [Segment B2B Spec] Triggered when user deletes their company
	 *
	 * @param {AccountDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountDeleted,
	/**
	 * [Segment B2B Spec] Triggered when a user is removed from an account
	 *
	 * @param {AccountRemovedUser} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountRemovedUser,
	/**
	 * Triggered when user updates their company details
	 *
	 * @param {AccountUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	accountUpdated,
	/**
	 * Triggered when a brand is approvedd
	 *
	 * @param {BrandApproved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandApproved,
	/**
	 * Triggered when a BRAND user is added to the database (when brand draft is started)
	 *
	 * @param {BrandCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandCreated,
	/**
	 * Triggered when a page in the Brand creation flow is completed
	 *
	 * @param {BrandCreationStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandCreationStepCompleted,
	/**
	 * Triggered when a user views a brand creation step
	 *
	 * @param {BrandCreationStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandCreationStepViewed,
	/**
	 * Triggered when a brand is deleted
	 *
	 * @param {BrandDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandDeleted,
	/**
	 * Triggered when a brand pushed to live by creator/admin
	 *
	 * @param {BrandPublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandPublished,
	/**
	 * Triggered when a brand is rejected
	 *
	 * @param {BrandRejected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandRejected,
	/**
	 * Triggrred when a brand being created is submitted for review
	 *
	 * @param {BrandSubmittedForReview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandSubmittedForReview,
	/**
	 * Triggered when a brand is unpublished by uesr - moved back to draft
	 *
	 * @param {BrandUnpublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandUnpublished,
	/**
	 * Triggered when a brand is unpublished by uesr - moved back to draft
	 *
	 * @param {BrandUnpulished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandUnpulished,
	/**
	 * Triggered when a brand is updated
	 *
	 * @param {BrandUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	brandUpdated,
	/**
	 * [Segment B2B Spec] This event should be sent when a user invites another user
	 *
	 * @param {InviteSent} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	inviteSent,
	/**
	 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
	 *
	 * @param {OfferApproved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerApproved,
	/**
	 * Triggered when a user first creates the Offer and it's in 'draft' mode
	 *
	 * @param {OfferCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCreated,
	/**
	 * Triggered when a user completes a step in Offer creation
	 *
	 * @param {OfferCreationStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCreationStepCompleted,
	/**
	 * Triggered when a user views a step in Offer creation
	 *
	 * @param {OfferCreationStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerCreationStepViewed,
	/**
	 * Triggered when a user deletes an offer
	 *
	 * @param {OfferDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerDeleted,
	/**
	 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
	 *
	 * @param {OfferPublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerPublished,
	/**
	 * Triggered when an offer is approve for publication
	 *
	 * @param {OfferRejected} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerRejected,
	/**
	 * Triggered when an update submission is canceled
	 *
	 * @param {OfferSubmissionCanceled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerSubmissionCanceled,
	/**
	 * Triggered when edits on an offer are submitted for review
	 *
	 * @param {OfferSubmittedForReview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerSubmittedForReview,
	/**
	 * Triggered when a user unpublished an offer - puts it back in 'draft' mode
	 *
	 * @param {OfferUnpublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	offerUnpublished,
	/**
	 * Triggered when user clicks on 'Download .csv file' on 'Get Coupon Codes' page of Program creation flow
	 *
	 * @param {ProgramCodesDownloaded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programCodesDownloaded,
	/**
	 * Triggered when a user first creates the Program and it's in 'draft' mode
	 *
	 * @param {ProgramCreated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programCreated,
	/**
	 * Triggered when a user completes a step in Program creation
	 *
	 * @param {ProgramCreationStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programCreationStepCompleted,
	/**
	 * Triggered when a user views a step in Program creation
	 *
	 * @param {ProgramCreationStepViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programCreationStepViewed,
	/**
	 * Triggered when a user deletes a program
	 *
	 * @param {ProgramDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programDeleted,
	/**
	 * Triggered after the program workflow is approved. Entity goes live
	 *
	 * @param {ProgramPublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programPublished,
	/**
	 * Triggered when a user unpublished a program - puts it back in 'draft' mode
	 *
	 * @param {ProgramUnpublished} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	programUnpublished,
	/**
	 * [Segment B2B Spec] Triggered when a user logs into their account
	 *
	 * @param {SignedIn} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedIn,
	/**
	 * [Segment B2B Spec] Triggered when a user logs out of their account
	 *
	 * @param {SignedOut} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedOut,
	/**
	 * [Segment B2B Spec] Triggred when a user profile is created
	 *
	 * @param {SignedUp} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	signedUp,
	/**
	 * [Segment B2B Spec] Triggered when a user's trial expires
	 *
	 * @param {TrialEnded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	trialEnded,
	/**
	 * [Segment B2B Spec] Triggered when a user successfully signs up for a trial
	 *
	 * @param {TrialStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	trialStarted,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/wesalute-business/protocols/tracking-plans/rs_1gwzdTeh4s7M8Zy4sjwzvwMKPS2`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
