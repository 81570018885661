import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const SplashScreen = () => (
  <div className="splash-screen">
    <div className="lds-dual-ring">
      <img
        alt=""
        src={process.env.PUBLIC_URL + '/images/wesalute.svg'}
        style={{ display: 'block' }}
      />
      <CircularProgress />
    </div>
  </div>
);

export default SplashScreen;
