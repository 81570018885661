import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ServiceList } from 'containers';
import { FormContext } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Hidden,
  Select,
  TextField
} from 'components/Form';
import fileDownload from 'js-file-download';
import useFormCrud from 'inc/hooks/useFormCrud';
import useObservable from 'inc/hooks/useObservable';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import api from 'services/api';
import store from 'inc/store';
import B2b from 'services/api/providers/B2b';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Asset>;

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 30,
  },
  title: {
    fontSize: theme.spacing(3),
    marginBottom: 0,
  },
  button: {
    fontSize: 14,
    padding: '8px 40px',
  },
  mainContainer: {
    //
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 -35px',
  },
  colLeft: {
    flex: '0 0 65%',
    maxWidth: '65%',
    padding: '0 35px',
  },
  colRight: {
    flex: '0 0 35%',
    maxWidth: '35%',
    padding: '0 35px',
  },
  image: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
    marginBottom: 20,
  },
  name: {
    marginBottom: 20,
  },
  brand: {
    marginBottom: 20,
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiSelect-outlined': {
      minWidth: 0,
    },
  },
  save: {
    width: '100%',
  },
}));

const AssetEdit = ({ history, match }: Props) => {
  const classes = useStyles();
  const [data, setData] = useState<Data>({});
  const saveLoading = useObservable('page.loading');
  const company = useObservable('company');
  const {
    handleEditSubmit,
    methods,
    values,
  } = useFormCrud({
    id: match.params['assetId'] || '',
    success: () => history.push('/assets'),
    path: 'assets'
  });
  const imageDownload = async (data: Data) => {
    try {
      const content = await (api.provider('b2b') as B2b)
        .assetDownload(data.id);
      fileDownload(content, data.filename);
    }
    catch (e) {
      store('page.message').set({
        text: 'Error on downloading the asset.',
        type: 'error',
      });
    }
  }
  useEffect(() => {
    if (values[FORM_STATE_LOADED]) {
      setData(values[FORM_STATE_DATA] || {});
    }
  }, [values[FORM_STATE_LOADED]]);
  return (
    <div>
      <FormContext {...methods}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
        >
          <span>Assets</span>
          <span>{data.name}</span>
        </Breadcrumbs>
        <form onSubmit={methods.handleSubmit(handleEditSubmit())}>
          <div className={classes.headerContainer}>
            <Typography
              className={classes.title}
              component="h2"
              variant="h2"
            >
              {data.name}
            </Typography>
            <Button
              className={classes.button}
              color="primary"
              onClick={() => imageDownload(data)}
              variant="contained"
            >
              Download
            </Button>
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.row}>
              <div className={classes.colLeft}>
                <img
                  className={classes.image}
                  src={data.file}
                />
              </div>
              <div className={classes.colRight}>
                <div className={classes.name}>
                  <TextField
                    label="Asset Name"
                    name="name"
                    required
                  />
                </div>
                <div className={classes.brand}>
                  <ServiceList
                    component={(items, loading) =>
                      <Select
                        disabled={loading}
                        label="Brand"
                        name="brand"
                        options={items}
                      />
                    }
                    condition={[
                      {
                        field: 'company',
                        value: company.id as string,
                        op: '=='
                      }
                    ]}
                    path="brands"
                    waitForLoad={false}
                  />
                </div>
                <Hidden
                  name="type"
                  value="image"
                />
                <Hidden
                  name="private"
                  value={false}
                />
                <Button
                  className={classes.save}
                  color="primary"
                  data-testid="submit-save"
                  disabled={saveLoading}
                  type="submit"
                  variant="outlined"
                >
                  Save
                  {saveLoading && (
                    <CircularProgress
                      size={24}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormContext>
    </div>
  );
};

export default withRouter(AssetEdit);
