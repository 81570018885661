import React from 'react';
import Grid from '@material-ui/core/Grid';
import withAccess from 'components/Visibility/withAccess';

type Props = {
  children: React.ReactNode,
  className?: string,
}

function GridContainer({ children, className }: Props) {
  return (
    <Grid
      className={className}
      container
      spacing={1}
    >
      {children}
    </Grid>
  );
}

export default withAccess(GridContainer);
