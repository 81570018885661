import { brandPublished } from 'services/typewriter';
import { flatten } from 'inc/utils';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const brand = flatten(values);
  const company = await getEntity('companies', brand['company']);
  brandPublished({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
  });
};
