import { useEffect } from 'react';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import { useFormContext } from 'react-hook-form';

const useDefaultValue = (fieldName: string, callback: (data: Document.Base) => void, shouldRegister = true) => {
  const { getValues, register } = useFormContext();
  const values = getValues();
  useEffect(() => {
    shouldRegister && register({ name: fieldName });
  }, []);
  useEffect(() => {
    if (values[FORM_STATE_LOADED] && values[FORM_STATE_DATA][fieldName]) {
      callback(values[FORM_STATE_DATA]);
    }
  }, [values[FORM_STATE_LOADED]]);
}

export default useDefaultValue;
