import { brandCreationStepCompleted } from 'services/typewriter';

import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ brand, stepName }: Data) => {
  const company = await getEntity('companies', brand['company']);
  brandCreationStepCompleted({
    context: segmentContextObject(company, brand),
    company: segmentCompanyObject(company),
    brand: segmentBrandObject(brand, company),
    stepName,
  });
};
