import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import {
  FieldWrapper,
} from 'components/Form';
import { State } from 'components/Display';
import { ReadOnly, PageBlock } from 'components/Layout';
import { ServiceEntity } from 'containers';
import useFormCrud from 'inc/hooks/useFormCrud';
import { BrandSchema } from 'inc/validation';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';
import helperTexts from 'inc/constants/fields/brand';
// import Moderation from './components/Moderation';
import Moderation from 'containers/Workflow/Moderation';
import countries from 'data/countries.json';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import moderationStates from 'data/states.json';
import { editStyles } from 'theme/styles';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Brand>;

const useStyles = makeStyles((theme: Theme) => ({
  ...editStyles(theme),
}));

const BrandEdit = ({ match }: Props) => {
  const classes = useStyles();
  const [data, setData] = useState<Data>({});
  const {
    handleEditSubmit,
    docId,
    methods,
    values,
  } = useFormCrud({
    id: match.params['brandId'] || '',
    path: 'brands',
    schema: BrandSchema
  });
  useEffect(() => {
    if (values[FORM_STATE_LOADED]) {
      setData(values[FORM_STATE_DATA] || {});
    }
  }, [values[FORM_STATE_LOADED]]);
  return (
    <div className={classes.root}>
      {!!data['id'] && (
        <Breadcrumbs
          aria-label="breadcrumb"
          separator=">"
        >
          <ServiceEntity
            component={(entity) =>
              <span>{!!entity && entity.name}</span>
            }
            id={data['company']}
            path="companies"
          />
          <span>{data.name}</span>
        </Breadcrumbs>
      )}
      <div className={classes.titleWrapper}>
        <Typography
          className={classes.title}
          component="h2"
          variant="h2"
        >
          Manage Brand
        </Typography>
        {!!data['id'] && (
          <State
            className={classes.state}
            state={moderationStates.find(state => data['state'] === state.id) as ModerationState}
          />
        )}
      </div>
      <Typography
        component="p"
        variant="body2"
      >
        {helperTexts.sub_title}
      </Typography>
      <FormContext {...methods}>
        <Moderation
          data={data}
          docId={docId}
          entityLabel="Brand"
          setData={setData}
          type="brands"
          values={values}
        />
        <form onSubmit={methods.handleSubmit(handleEditSubmit({}))}>
          <div className={classes.contentWrapper}>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.logo}
                label="Brand Logo"
                name="content.logo"
              >
                {!!data['content.logo.asset'] && (
                  <ReadOnly>
                    <ServiceEntity
                      component={(entity) =>
                        <div>{!!entity && <img src={entity.file} />}</div>
                      }
                      id={data['content.logo.asset']}
                      path="assets"
                    />
                  </ReadOnly>
                )}
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.hero}
                label="Brand Hero"
                name="content.hero"
              >
                {!!data['content.hero.asset'] && (
                  <ReadOnly>
                    <ServiceEntity
                      component={(entity) =>
                        <div>{!!entity && <img src={entity.file} />}</div>
                      }
                      id={data['content.hero.asset']}
                      path="assets"
                    />
                  </ReadOnly>
                )}
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.name}
                label="Brand Name"
                name="name"
              >
                <ReadOnly>
                  {data['name']}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.country}
                label="Country"
                name="content.country"
              >
                <ReadOnly>
                  {countries.find(item => item.id === data['content.country'])?.name}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock>
              <FieldWrapper
                helperText={helperTexts.description}
                label="Summary"
                name="content.description"
              >
                <ReadOnly>
                  <div
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: data['content.description'] }}
                  />
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
            <PageBlock adminView>
              <FieldWrapper
                label="Keywords"
                name="content.keywords"
              >
                <ReadOnly>
                  {(data['content.keywords'] || []).map((keyword: string, index: number) => (
                    <Chip
                      key={index}
                      label={keyword}
                      variant="outlined"
                    />
                  ))}
                </ReadOnly>
              </FieldWrapper>
            </PageBlock>
          </div>
        </form>
      </FormContext>
    </div>
  );
};

export default withRouter(BrandEdit);
