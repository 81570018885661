import { brandSubmittedForReview } from 'services/typewriter';
import { flatten } from '../../../utils';
import {
  getEntity,
  segmentBrandObject,
  segmentCompanyObject,
  segmentContextObject,
} from './helpers';

export default async ({ data: values }: Data) => {
  const brand = flatten(values);
  const company = await getEntity('companies', brand['company']);
  brandSubmittedForReview({
    context: segmentContextObject(company, brand),
    brand: segmentBrandObject(brand, company),
    company: segmentCompanyObject(company),
    submitter: {
      id: brand['updated.by.id'],
      email: brand['updated.by.mail'],
    }
  });
};
