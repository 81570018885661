import typography from '../typography';
import custom from '../custom';

export default {
  root: {
    ...typography.body1,
    borderBottom: '0 none'
  },
  body: {
    padding: '16px 26px',
    fontFamily: 'Akkurat Mono LL, monospace',
    '& a': {
      color: 'inherit',
      '&:hover, &:focus': {
        textDecoration: 'underline'
      }
    },
    '& .blue': {
      color: custom.blue
    }
  },
  head: {
    padding: '6px 26px'
  }
};
