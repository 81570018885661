import custom from '../custom';

export default {
  root: {
    borderRadius: 2,
    '&$error $notchedOutline': {
      borderColor: custom.red
    }
  },
  notchedOutline: {
    borderColor: custom.slate
  },
  input: {
    boxSizing: 'border-box',
    fontSize: 16,
    padding: 16,
    height: 54,
    '&.Mui-disabled': {
      background: 'rgba(240, 241, 244, 0.3)',
    }
  },
};
