import React from 'react';
import clsx from 'clsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import customStyles from '../../theme/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Preview } from 'components/Display';
import useObservable from 'inc/hooks/useObservable';

const useStyles = makeStyles((theme: Theme) => ({
  ...customStyles(theme),
  topContent: {
    marginBottom: theme.spacing(6)
  },
}));

type Props = {
  children: React.ReactNode,
  className?: string,
  document?: Document.Base,
  isRevision: boolean,
  loading: boolean,
  moderate?: boolean,
  preview?: boolean,
  subheader: string,
  title: string,
  type?: string,
}

function EditFormWrapper({ children, loading, preview, title, subheader }: Props) {
  const classes = useStyles();
  const saveLoading = useObservable('page.loading');
  return (
    <>
      {saveLoading || loading ? <LinearProgress/> : <div style={{height: '4px'}} />}
      <div className={classes.topContent}>
        <Typography
          className={classes.title}
          component="h2"
          variant="h2"
        >
          {title}
        </Typography>
        <Typography
          component="p"
          variant="body2"
        >
          {subheader}
        </Typography>
      </div>
      <div className={classes.formContent}>
        {children}
      </div>
      <div className={classes.actions}>
        <Button
          className={clsx(classes.button, classes.formButtonLarge)}
          color="primary"
          data-testid="submit-save"
          disabled={saveLoading}
          type="submit"
          variant="contained"
        >
          Save
          {saveLoading && (
            <CircularProgress
              className={classes.buttonProgress}
              size={24}
            />
          )}
        </Button>
        {preview && <Preview />}
      </div>
    </>
  );
}

export default EditFormWrapper;
