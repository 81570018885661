import {
  DataConstraintHandler,
  DocumentPublishHandler
} from './handlers';

export default {
  'react.fatal': {
    message: 'React fatal error.',
    severity: 'fatal',
    zone: 'popup',
  },
  'auth0.token-not-fetched': {
    message: 'Auth0 token has not been fetched.',
    severity: 'fatal',
    zone: 'popup',
  },
  'b2b-api.data.required': {
    message: 'There are some missing required fields. Please fill them.',
    show: 'header',
    log: false,
  },
  'b2b-api.data.constraint': DataConstraintHandler,
  'b2b-api.access.document.tune': 'The offer can\'t be published since its tune one was not yet created.',
  'b2b-api.access.document.publish.validator': DocumentPublishHandler,
}

/*

message: string
details: string
severity: 'fatal' | 'error' | 'warning' | 'info'
show: ['snackbar', 'header', 'popup']
log: boolean

*/
