import React, { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import FieldWrapper from './FieldWrapper';
import useField from 'inc/hooks/useField';

type Props = {
  defaultValue?: string,
  description?: React.ReactNode | string,
  label?: string,
  name: string,
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void,
  options: CategoryItem[],
  required?: boolean,
  value?: React.ReactNode | string,
} & SelectProps;

const StyledMuiSelect = withStyles({
  outlined: {
    minWidth: 280
  },
})(MuiSelect);

function Select({ defaultValue = '', description, label, name, onChange, options, required, value, ...props }: Props) {
  const [selected, setSelected] = useState(defaultValue);
  const { error, formValue, setValue } = useField(name, {
    callback: (data: Document.Base) => {
      setSelected(data[name]);
      setValue(data[name], true);
    },
  });
  if (process.env.REACT_APP_TESTING) {
    props['inputProps'] = {
      'data-testid': `${name}-input`,
    };
    props.native = true;
  }
  let selectValue = value || formValue || selected;
  if (selectValue && !options.find(option => option.id === selectValue)) {
    selectValue = defaultValue ? defaultValue : '';
  }
  return (
    <FieldWrapper
      helperText={description}
      label={label}
      name={name}
      required={required}
    >
      <FormControl
        error={!!error}
      >
        <StyledMuiSelect
          autoWidth
          displayEmpty
          onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
            setSelected(e.target.value as string);
            setValue(e.target.value, true, true);
            onChange && onChange(e);
          }}
          value={selectValue}
          variant="outlined"
          {...props}
        >
          {props.native ? (
            <option value="">--- Select ---</option>
          ) : (
            <MenuItem value="">--- Select ---</MenuItem>
          )}
          {props.native ? (
            options.map((option: CategoryItem) => (
              <option
                key={option.id}
                value={option.id}
              >
                {option.name}
              </option>
            ))
          ) : (
            options.map((option: { id: string, name: string }) => (
              <MenuItem
                key={option.id}
                value={option.id}
              >
                {option.name}
              </MenuItem>
            ))
          )}
        </StyledMuiSelect>
      </FormControl>
    </FieldWrapper>
  );
}

export default Select;
