import { Theme, createStyles } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';

export const alertStyles = (theme: Theme) => {
  return createStyles({
    alert: {
      width: '100%',
      margin: '0 0 50px',
      border: '1px solid' + theme.palette.error.main,
      '& a': {
        color: theme.palette.error.main,
        textDecoration: 'underline',
        '&:hover, &:focus': {
          textDecoration: 'none',
        }
      }
    },
    alertWrapper: {
      width: '100%'
    },
  });
};

export const editStyles = (theme: Theme) => {
  return createStyles({
    root: {
      maxWidth: 865
    },
    contentWrapper: {
      margin: '0 auto',
      maxWidth: 660
    },
    titleWrapper: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    state: {
      marginBottom: theme.spacing(1),
      fontSize: 18,
      fontWeight: 700,
      marginLeft: 20,
    },
    subTitle: {
      fontSize: 24,
    },
    actions: {
      textAlign: 'center',
    },
    button: {
      minWidth: 384,
    },
    history: {
      marginTop: theme.spacing(15)
    }
  });
};

export const newStyles = (theme: Theme) => {
  return createStyles({
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    state: {
      marginBottom: theme.spacing(1),
      backgroundColor: fade(String(theme.custom.pink), 0.18),
      fontSize: 18,
      borderRadius: 4,
      padding: theme.spacing(1, 2),
    },
    lastUpdated: {
      fontSize: 18,
      lineHeight: 1.6,
      marginTop: theme.spacing(1.5),
      fontFamily: 'Akkurat Mono LL, monospace',
      '& span': {
        color: theme.custom.grayDark,
      },
    },
    settings: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.custom.slate,
      padding: theme.spacing(2, 5, 0, 5),
      borderRadius: 3,
      margin: theme.spacing(0, -2.5),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 2.5, 0, 2.5),
        margin: 0,
      }
    },
    settingsTitle: {
      fontWeight: 400,
      marginBottom: theme.spacing(5),
    },
    domainUrl: {
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      '& .MuiInputBase-input': {
        backgroundColor: 'transparent',
        color: theme.custom.slate,
        fontSize: 14,
        paddingLeft: 0,
        paddingRight: 0,
        fontWeight: 700,
      },
    },
  });
};

export default (theme: Theme) => {
  return createStyles({
    root: {
      maxWidth: 900
    },
    card: {
      padding: 0,
      boxShadow: 'none'
    },
    cardContent: {
      padding: 0
    },
    title: {
      padding: 0
    },
    stepper: {
      padding: 0,
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(9)
    },
    formContent: {
      margin: '0 auto',
      maxWidth: 660
    },
    actions: {
      margin: '0 auto',
      maxWidth: 680,
      padding: theme.spacing(4) + ' 0 0',
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(4),
      '& > button': {
        '& + button': {
          margin: '0 10px !important'
        }
      }
    },
    button: {
      margin: '0 10px'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    orangeButton: {
      backgroundColor: theme.custom.orange,
      borderRadius: 0,
      color: theme.custom.black,
      padding: '5px 10px',
      fontFamily: 'Akkurat Mono LL, monospace',
      fontSize: '16px',
      lineHeight: '1.2',
      '&:hover, &:focus': {
        background: theme.custom.orangeDark
      }
    },
    transparentButton: {
      backgroundColor: 'transparent',
      border: '1px solid ' + theme.custom.black,
      borderRadius: 0,
      color: theme.custom.black,
      padding: '5px 10px',
      fontFamily: 'Akkurat Mono LL, monospace',
      fontSize: '16px',
      lineHeight: '1.2',
      '&:hover, &:focus': {
        background: theme.custom.black,
        color: theme.custom.white,
      }
    },
    formButtonLarge: {
      width: '384px',
      margin: '0 149px !important'
    },
    formButtonMedium: {
      width: '200px'
    },
    formOverride: {
      marginTop: '-22px',
      '& .field-label': {
        marginTop: '22px',
        display: 'block'
      }
    }
  });
};
