import React from 'react';
import ListToolbar from 'components/Display/ListToolbar';
import { ServiceList } from 'containers';
import { Select } from 'components/Form';
import TablePagination from 'components/Table';
import useServiceTable from 'inc/hooks/useServiceTable';
import useForm, { FormContext } from 'react-hook-form';
import moment from 'moment';
import auth0 from 'services/Auth0';
import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  listToolbar: {
    [theme.breakpoints.down('lg')]: {
      '& .tabs-wrapper': {
        flexWrap: 'wrap',
        height: 'auto'
      },
      '& .tabs-spacer': {
        width: '100%',
        marginBottom: 20
      }
    }
  },
}));

const OfferListPublished = () => {
  const classes = useStyles();
  const methods = useForm();
  const { setValue, watch } = methods;
  const companyId = watch('company');
  const brandId = watch('brand');
  const state = watch('state-filter');
  const condition = [];
  companyId && condition.push({ field: 'company', value: companyId });
  brandId && condition.push({ field: 'brand', value: brandId });
  state && condition.push({ field: 'state', value: state });
  companyId
  const { fetchItems, listActions } = useServiceTable({
    moderate: true,
    path: 'offers',
    published: true,
  });
  const fields = [
    {
      id: 'name',
      label: 'Label',
      sortable: true,
      width: '55%',
    },
    {
      id: 'updated.on',
      label: 'Updated',
      prepare: (value: number) => {
        return value ? moment.unix(value / 1000).format('MM/DD/YYYY HH:mm') : '';
      },
      sortable: true,
    },
  ];
  return (
    <div>
      <FormContext {...methods}>
        <ListToolbar
          buttons={[
            {
              name: 'Add Offer',
              path: '/offers/create',
            }
          ]}
          className={classes.listToolbar}
          tabs={[
            {
              name: 'All',
              path: '/offers'
            },
            {
              name: 'Live',
              path: '/offers/published'
            }
          ]}
        >
          {auth0.canListCompanies() && (
            <ServiceList
              component={(items, loading) =>
                <Select
                  disabled={loading}
                  label="Company"
                  name="company"
                  onChange={() => setValue('brand', null)}
                  options={items}
                  style={{ marginRight: 10 }}
                />
              }
              path="companies"
              waitForLoad={false}
            />
          )}
          <ServiceList
            component={(items, loading) =>
              <Select
                disabled={loading}
                label="Brand"
                name="brand"
                options={items}
                style={{ marginRight: 10 }}
              />
            }
            condition={auth0.canListCompanies() ? [
              {
                field: 'company',
                value: companyId,
                op: '=='
              }
            ] : []}
            path="brands"
            waitForLoad={false}
          />
        </ListToolbar>
        <TablePagination
          actions={listActions}
          condition={condition}
          fetch={fetchItems}
          fields={fields}
        />
      </FormContext>
    </div>
  );
};

export default OfferListPublished;
