import React from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import LinearProgress from '@material-ui/core/LinearProgress';
import useObservable from 'inc/hooks/useObservable';

type Props = {
  children: React.ReactNode,
  description?: string | React.ReactNode,
  helperText?: string | React.ReactNode,
  helperTextTooltip?: React.ReactNode | string,
  helperTextBottom?: React.ReactNode | string,
  label?: string,
  name: string,
  required?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: 12,
    lineHeight: 1.4,
    letterSpacing: '0.16em',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(1),
    fontFamily: 'Akkurat Mono LL, monospace',
  },
  helperText: {
    position: 'relative',
    marginBottom: theme.spacing(1),
    backgroundColor: theme.custom.grayLight,
    padding: '12px 10px 12px 32px',
    fontSize: 12,
    lineHeight: 1.17,
    '& p:first-of-type': {
      marginTop: 0
    },
    '& p:last-of-type': {
      marginBottom: 0
    }
  },
  helperTextIcon: {
    fontSize: 12,
    position: 'absolute',
    top: 14,
    left: 12
  },
  description: {
    marginBottom: theme.spacing(1)
  },
  message: {
    color: theme.custom.red
  },
  error: {
    '& > div $label': {
      color: theme.custom.red
    },
    '& .rdw-editor-toolbar, & .demo-editor': {
      border: '1px solid #f00 !important'
    }
  },
  helperTooltipIcon: {
    fontSize: 14,
    verticalAlign: 'top',
    marginTop: '3px'
  },
  helperTooltip: {
    fontFamily: 'Akkurat Mono LL, monospace',
    fontSize: '10px',
    lineHeight: '140%',
    letterSpacing: '0.16em',
    backgroundColor: theme.custom.orange,
    color: theme.palette.primary.main,
    borderRadius: '0'
  },
  helperTextBottom: {
    margin: '16px 0',
    fontSize: '10px',
    lineHeight: '14px',
  }
}));

function FieldWrapper({ description, helperText, helperTextTooltip, helperTextBottom, label, name, required, children, ...props }: Props) {
  const classes = useStyles();
  const { errors } = useFormContext();
  const loading = useObservable('data.loading');
  return (
    <div
      className={clsx({
        [classes.error]: !!errors[name],
        'field-error': !!errors[name]
      })}
      {...props}
    >
      <div>
        <label className={clsx(classes.label, 'field-label')}>
          {label}
          {required ? <span> *</span> : null}
          {!!helperTextTooltip &&
          <Tooltip
            classes={{tooltip: classes.helperTooltip}}
            placement="right"
            title={helperTextTooltip}
          >
            <HelpIcon
              className={classes.helperTooltipIcon}
              color="primary"
            />
          </Tooltip>
          }
        </label>
        {!!helperText && (
          <div className={classes.helperText}>
            <HelpOutlinedIcon className={classes.helperTextIcon}/>
            {helperText}
          </div>
        )}
        {!!description && (
          // <div style={{ fontSize: 12, fontStyle: 'italic', lineHeight: '15px', marginBottom: 3, marginTop: 3 }}>
          <div className={classes.description}>
            {description}
          </div>
        )}
      </div>
      {children}
      {loading && <LinearProgress />}
      {!!errors[name] && (
        <div className={clsx(classes.message, 'error-message')}>{(errors[name] as FieldError).message}</div>
      )}
      {!!helperTextBottom &&
        <div className={classes.helperTextBottom}>
          {helperTextBottom}
        </div>
      }
    </div>
  );
}

export default FieldWrapper;
