import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormContext } from 'react-hook-form';
import {
  Fieldset,
  TabPanel,
  Tabs,
} from 'components/Display';
import {
  AssetImage,
  Autocomplete,
  Checkbox,
  Date,
  EditFormWrapper,
  EditSidebarForm,
  Hidden,
  ReadOnly,
  Reward,
  RichEditor,
  Select,
  TextField,
} from 'components/Form';
import { Grid, GridContainer } from 'components/Layout';
import { RedemptionsWrapper } from 'containers';
import OfferTabs from './OfferTabs';
import ServiceList from 'containers/ServiceList';
import useFormCrud from 'inc/hooks/useFormCrud';
import useTabs from 'inc/hooks/useTabs';
import useObservable from 'inc/hooks/useObservable';
import useDocumentHistory from 'inc/hooks/useDocumentHistory';
import CallToActionIcon from '@material-ui/icons/CallToAction';
import DescriptionIcon from '@material-ui/icons/Description';
import shortid from 'shortid';
import { OfferCreateSchema } from 'inc/validation';
import merchantCategories from 'data/merchantCategories.json';
import api from 'services/api';
import auth0 from 'services/Auth0';
import { FORM_STATE_DATA, FORM_STATE_LOADED } from 'inc/constants';

type Props = {
  className?: string,
} & RouteComponentProps<MatchParams.Offer>;

const OfferEdit = ({ className, history, match }: Props) => {
  const company = useObservable('company');
  const { handleEditSubmit, docId, methods, values, isRevision } = useFormCrud({
    id: match.params['offerId'] || '',
    revisionId: match.params['revisionId'] || '',
    path: 'offers',
    schema: OfferCreateSchema,
    success: () => history.push('/offers'),
    process: (data) => {
      const redemptions: { [k: string]: Document.Redemption } = {};
      const redemptions_order: string[] = [];
      ((data.content || {}).redemptions || []).forEach((r: Document.Redemption) => {
        const uuid = `${r.type}-${shortid.generate()}`;
        redemptions[uuid] = r;
        redemptions_order.push(uuid);
      });
      const res = {
        ...data,
        content: {
          ...data.content,
          keywords: (data.content.keywords || []).join(', '),
          redemptions
        },
        'content.redemptions': redemptions,
        __redemptions__order: redemptions_order
      };
      delete res.content.redemptions;
      return res;
    }
  });
  const [ brand, setBrand ] = useState<Document.Base>();
  useEffect(() => {
    if ((values[FORM_STATE_DATA] || {}).brand) {
      api.path('brands').get(values[FORM_STATE_DATA].brand).then((brand: Document.Base) => setBrand(brand));
    }
  }, [values[FORM_STATE_LOADED]]);
  const { history: docHistory } = useDocumentHistory('offers', docId, values);
  const { tabIndex, handleTabChange } = useTabs(0);
  const prepareData = async (values: Document.Base) => {
    const {
      content = {},
    } = values;
    return {
      ...values,
      content: {
        ...values.content,
        keywords: (content.keywords || '').split(','),
        redemptions: (values['__redemptions__order'] || []).map((uuid: string) => {
          const [rId, ] = uuid.split('-');
          const { roles, instructions, attributes } = (content.redemptions || {})[uuid] || {};
          return { roles, instructions, attributes, type: rId };
        })
      }
    }
  }
  return (
    <div>
      <FormContext {...methods}>
        <form onSubmit={methods.handleSubmit(handleEditSubmit({ prepare: prepareData }))}>
          <OfferTabs
            docId={docId}
            index={isRevision ? 1 : 0}
          />
          <EditFormWrapper
            className={className}
            document={values[FORM_STATE_DATA]}
            isRevision={isRevision}
            loading={!!docId && !values[FORM_STATE_LOADED]}
            moderate
            preview
            subheader="The information can be edited"
            title="Offer"
            type="offers"
          >
            <GridContainer>
              <Grid col={8}>
                <Tabs
                  onChange={handleTabChange}
                  tabs={[
                    { label: 'General', icon: <CallToActionIcon /> },
                    { label: 'Redemptions', icon: <DescriptionIcon /> },
                  ]}
                  value={tabIndex}
                >
                  <TabPanel
                    index={0}
                    value={tabIndex}
                  >
                    <GridContainer>
                      <Grid>
                        <TextField
                          label="Label"
                          name="name"
                          required
                        />
                        <Hidden
                          name="content.__type"
                          value={auth0.isAdmin() ? 'admin' : 'client'}
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="Title"
                          name="content.title"
                          required
                        />
                      </Grid>
                      <Grid>
                        {docId ? (
                          <ReadOnly
                            label="Brand"
                            name="brand"
                            value={brand ? brand.name : ''}
                          />
                        ) : (
                          // <CompanyBrandSelect name="brand" />
                          <ServiceList
                            component={(items, loading) =>
                              <Select
                                disabled={loading}
                                label="Brand"
                                name="brand"
                                options={items}
                              />
                            }
                            condition={[
                              {
                                field: 'company',
                                value: company.id as string,
                                op: '=='
                              }
                            ]}
                            path="brands"
                            waitForLoad={false}
                          />
                        )}
                      </Grid>
                      <Grid>
                        <Autocomplete
                          description={
                            <span>
                              Help WeSalute users quickly and easily find your brand by choosing your merchant category code.
                              <br />Don’t see a code that fits? Not to worry, just pick the code that best fits your brand.
                            </span>
                          }
                          label="Merchant Category Code"
                          name="content.mcc_category"
                          options={merchantCategories}
                          required
                        />
                      </Grid>
                      <Grid>
                        <RichEditor
                          label="Summary"
                          name="content.summary"
                          required
                        />
                      </Grid>
                      <Grid access={auth0.isAdmin}>
                        <ServiceList
                          component={(items) =>
                            <Reward
                              categories={items}
                              name="content.category"
                              required
                            />
                          }
                          path="categories"
                        />
                      </Grid>
                      <Grid>
                        <Fieldset
                          defaultExpanded={false}
                          label="Banner"
                        >
                          <GridContainer>
                            <Grid>
                              <TextField
                                label="Title"
                                name="content.banner.title"
                              />
                            </Grid>
                            <Grid>
                              <TextField
                                label="Description"
                                multiline
                                name="content.banner.description"
                                rows={2}
                                rowsMax={4}
                              />
                            </Grid>
                            {(docId || !!values['brand']) && (
                              <Grid>
                                <AssetImage
                                  entityId={values['brand']}
                                  entityType="brand"
                                  label="Image"
                                  name="content.banner.image"
                                />
                              </Grid>
                            )}
                            <Grid>
                              <Fieldset label="CTA Link">
                                <GridContainer>
                                  <Grid>
                                    <TextField
                                      label="Title"
                                      name="content.banner.cta.title"
                                    />
                                  </Grid>
                                  <Grid>
                                    <TextField
                                      label="Url"
                                      name="content.banner.cta.url"
                                    />
                                  </Grid>
                                </GridContainer>
                              </Fieldset>
                            </Grid>
                          </GridContainer>
                        </Fieldset>
                      </Grid>
                      {(docId || !!values['brand']) && (
                        <Grid>
                          <AssetImage
                            entityId={values['brand']}
                            entityType="brand"
                            label="Image"
                            name="content.image"
                            required
                          />
                        </Grid>
                      )}
                      <Grid>
                        <RichEditor
                          label="Details"
                          name="content.details"
                          required
                        />
                      </Grid>
                      <Grid>
                        <TextField
                          label="Keywords"
                          name="content.keywords"
                        />
                      </Grid>
                      <Grid>
                        <Fieldset
                          defaultExpanded={false}
                          label="Testimonial"
                        >
                          <GridContainer>
                            <Grid>
                              <RichEditor
                                label="Text"
                                name="content.testimonial.text"
                              />
                            </Grid>
                            <Grid>
                              <RichEditor
                                label="Signature"
                                name="content.testimonial.signature"
                              />
                            </Grid>
                          </GridContainer>
                        </Fieldset>
                      </Grid>
                      <Grid>
                        <RichEditor
                          label="Terms and Conditions"
                          name="content.terms_conditions"
                        />
                      </Grid>
                      <Grid access={auth0.isAdmin}>
                        <Fieldset
                          defaultExpanded={false}
                          label="Benefits"
                        >
                          <GridContainer>
                            <Grid>
                              <TextField
                                label="Headline"
                                name="content.benefits.headline"
                              />
                            </Grid>
                            <Grid>
                              <RichEditor
                                label="Description"
                                name="content.benefits.description"
                              />
                            </Grid>
                          </GridContainer>
                        </Fieldset>
                      </Grid>
                      <Grid access={auth0.isAdmin}>
                        <Fieldset
                          defaultExpanded={false}
                          label="Flags"
                        >
                          <GridContainer>

                            <Grid>
                              <Checkbox
                                label="Exclusive"
                                name="content.flags.exclusive"
                              />
                            </Grid>
                            <Grid>
                              <Checkbox
                                label="Featured"
                                name="content.flags.featured"
                              />
                            </Grid>
                            <Grid>
                              <Checkbox
                                label="Limited Time"
                                name="content.flags.limited_time"
                              />
                            </Grid>
                            <Grid>
                              <Checkbox
                                label="Military Community"
                                name="content.flags.community"
                              />
                            </Grid>
                            <Grid>
                              <Checkbox
                                label="New"
                                name="content.flags.new"
                              />
                            </Grid>
                            {!!values['content.flags.new'] && (
                              <Grid>
                                <Date
                                  label="New Until"
                                  name="content.new_until"
                                />
                              </Grid>
                            )}
                            <Grid>
                              <Checkbox
                                label="Unverified"
                                name="content.flags.unverified"
                              />
                            </Grid>
                          </GridContainer>
                        </Fieldset>
                      </Grid>
                      <Grid access={auth0.isAdmin}>
                        <Date
                          label="Expiration Date"
                          name="content.expiration_date"
                        />
                      </Grid>
                    </GridContainer>
                  </TabPanel>
                  <TabPanel
                    index={1}
                    value={tabIndex}
                  >
                    <RedemptionsWrapper name="content.redemptions" />
                  </TabPanel>
                </Tabs>
              </Grid>
              <Grid col={4}>
                <EditSidebarForm
                  history={docHistory}
                  moderation
                />
              </Grid>
            </GridContainer>
          </EditFormWrapper>
        </form>
      </FormContext>
    </div>
  );
};

export default withRouter(OfferEdit);
